import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Accordion } from 'react-bootstrap';
import "./MainInvestor.css";
import { Gralinfo } from './Gralinfo';
import { Portfolio } from './Portfolio';
import { Teamembers } from './Teamembers';
import { PencilDot } from '../../../@custom/components/PencilDot/PencilDot';
import imgMainProfile from '../../../assets/images/Logo-placeholder.jpg';
import imgBanner from '../../../assets/images/background_main.jpg';
import { Button, Tabs, Tab, ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSubmit } from "./ModalSubmit";
import { customAlert } from "../../../helpers/alerts";


import {
    submitInvestor,
    getCompanies,
    getInvestor,
    getTeammembers,
    updateBannerGralInfo,
    updateImgGralInfo,
    getFilledSectionsInvestor,
    setValidFormAction
} from '../../../redux/actions/investorAction';

import { InvestmentPreferences } from './InvestmentPreferences';
import { setImgInfoAction } from '../../../redux/actions/uiAction';

export const MainInvestor = () => {
    const dispatch = useDispatch();
    const [key, setKey] = useState('1');
    const [percent, setPercent] = useState(0);
    const { loadedCats } = useSelector((state) => state.ui);
    const [reloadAbouUs, setReloadAboutUs] = useState(true)

    const { gralInfo, investorId, filledSections, validForm } = useSelector(state => state.investor);

    // const [validForm, setValidForm] = useState({
    //     about: false,
    //     leadership: false,
    //     portfolio: false,
    //     investment: false
    // })

    const handleImgPencil = useCallback((img) => {
        setReloadAboutUs(false)
        dispatch(updateImgGralInfo(img));
        dispatch(setImgInfoAction(img));
    }, [dispatch])

    const handleImgPencilBanner = useCallback((img) => {
        setReloadAboutUs(false)
        dispatch(updateBannerGralInfo(img));
    }, [dispatch])


    const handleSubmitInvestor = () => {
        dispatch(submitInvestor())
        if(filledSections.filled_sections <= 4){
            customAlert('','Thank you for applying to join FundLyst. We will review your application within 24 hours. If you have any specific questions, please contact support@fundlyst.net for assistance.');

        }
    }

    const handleValidateForm = useCallback((componentName, isValid) => {
        // setValidForm({ ...validForm, [componentName]: isValid })
        // console.log('componentName-inv',componentName, isValid)
        dispatch(setValidFormAction(componentName, isValid))
    }, [validForm, dispatch])

    useEffect(() => {
        setPercent(() => filledSections.filled_sections * 25)
    }, [filledSections]);

    useEffect(() => {
        if (loadedCats) { 
            dispatch(getFilledSectionsInvestor());
            dispatch(getInvestor());
            dispatch(getTeammembers(investorId))
            dispatch(getCompanies(investorId))
        }
    }, [dispatch, loadedCats, investorId]);


    const onValidateForm = () => {
        const values = Object.values(validForm).map((v) => v);
        const ev = values.every(v => v === true);
        return !ev
    }

    const checkColorTab = (no) => {
        if (key === String(no)) return 'text-secondary'
        if (no > filledSections.filled_sections) return 'text-muted text-opacity-50'
        if (filledSections.filled_sections >= no) return 'text-primary'
    }

    const checkIconTab = (no) => {
        return 'far fa-' + (no > filledSections.filled_sections ? 'dot' : 'check') + '-circle';
    }

    const accordion = [
        { label: 'ABOUT US', element: 1 },
        { label: 'LEADERSHIP TEAM', element: 2 },
        { label: 'PORTFOLIO', element: 3 },
        { label: 'INVESTMENT PREFERENCES', element: 4 },
    ] 

    //Para probar cada paso de la informacion Cuidado con descomentar
    //filledSections.filled_sections = 4;
    //console.log('filledSections investor',filledSections);

    // console.log('validForm-investor',validForm);
    return (
        <>
            <div className="imgMainCover" style={{ backgroundImage: 'url(' + (gralInfo.banner || imgBanner) + ')' }}>
                <img src={gralInfo.investor_logo || imgMainProfile} alt="logo" className="imgRedondaMain" />
                <div className={filledSections.filled_sections === 5 ? "contentDot disable-mode" : "contentDot"}>
                    <PencilDot isWebp={true} validation1={true} handleImgPencil={handleImgPencil} />
                </div>
                <div className={filledSections.filled_sections === 5 ? "contentDot2 disable-mode" : "contentDot2"}>
                    <PencilDot isWebp={true} validation1={true} handleImgPencil={handleImgPencilBanner} />
                </div>
            </div>

            <Row>
                <Col xs={12} className="main-contenedor-progreso">
                    <div className="contenedorProgreso">
                        <Row>
                            <Col sm={12}>
                                <div className="textoArribaProgreso mb-3"><span>Complete your profile to start the account validation process.</span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={7}>
                                {/* <ProgressBar now={key === '1' ? 34 : key === '2' ? 66 : 100} /> */}
                                <ProgressBar now={percent} />
                                <br />
                                {/* <span className="pasosCompletos">{key}</span> <span> of <span className="pasosIncompletos"> 4</span> steps completed.</span> */}
                                <span className="pasosCompletos">{filledSections.filled_sections > 4 ? 4 : filledSections.filled_sections}</span> <span> of <span className="pasosIncompletos"> 4</span> steps completed.</span>
                            </Col>
                            <Col sm={5} className="alineacionBtn">
                                    <Button
                                        className="px-5"
                                        type="button"
                                        variant="primary"
                                        disabled={filledSections.filled_sections !== 4 || onValidateForm()}
                                        onClick={handleSubmitInvestor}>
                                        {filledSections.filled_sections > 4 ? 'Submitted' : 'Submit'}
                                    </Button>
                            </Col>
                        </Row>
                    </div>

                    <Accordion 
                        className='accordion-mobile'
                        onSelect={(k) => filledSections.filled_sections > (Number(k)-2) && setKey(k) }
                        defaultActiveKey="1">
                        {accordion.map( (el) => (
                            <Accordion.Item eventKey={String(el.element)}>
                                <Accordion.Header>
                                    <div className={`d-flex align-items-center ${checkColorTab(el.element)}`} >
                                        <i className={`${checkIconTab(el.element)} me-1`} style={{ fontSize: '26px' }}></i>
                                        <span>{el.label}</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {el.element === 1 && <Gralinfo reloadAbouUs={reloadAbouUs} handleValidateForm={handleValidateForm} />}
                                    {el.element === 2 && filledSections.filled_sections > 0 && <Teamembers handleValidateForm={handleValidateForm} />}
                                    {el.element === 3 && filledSections.filled_sections > 1 && <Portfolio handleValidateForm={handleValidateForm} />}
                                    {el.element === 4 && filledSections.filled_sections > 2 && <InvestmentPreferences handleValidateForm={handleValidateForm} />}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>

                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3 tabs-desktop">
                            {accordion.map( (el) => (
                                <Tab
                                    eventKey={String(el.element)}
                                    disabled={filledSections.filled_sections < (Number(el.element)-1)}
                                    title={
                                        <div className={`d-flex align-items-center ${checkColorTab(el.element)}`} >
                                            <i className={`${checkIconTab(el.element)} me-1`} style={{ fontSize: '26px' }}></i>
                                            <span>{el.label}</span>
                                        </div>}>

                                        {el.element === 1 && <Gralinfo reloadAbouUs={reloadAbouUs} handleValidateForm={handleValidateForm} />}
                                        {el.element === 2 && filledSections.filled_sections > 0 && <Teamembers handleValidateForm={handleValidateForm} />}
                                        {el.element === 3 && filledSections.filled_sections > 1 && <Portfolio handleValidateForm={handleValidateForm} />}
                                        {el.element === 4 && filledSections.filled_sections > 2 && <InvestmentPreferences handleValidateForm={handleValidateForm} />}
                                </Tab>
                            ))}
                    </Tabs>


                    {/* <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab
                            eventKey="1"
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(1)}`} >
                                    <i className={`${checkIconTab(1)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span>ABOUT US</span>
                                </div>}>
                            <Gralinfo reloadAbouUs={reloadAbouUs} handleValidateForm={handleValidateForm} />
                        </Tab>
                        <Tab
                            eventKey="2"
                            disabled={filledSections.filled_sections < 1}
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(2)}`} >
                                    <i className={`${checkIconTab(2)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span> LEADERSHIP TEAM</span>
                                </div>}>
                            {filledSections.filled_sections > 0 && <Teamembers handleValidateForm={handleValidateForm} />}
                        </Tab>
                        <Tab
                            eventKey="3"
                            disabled={filledSections.filled_sections < 2}
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(3)}`}>
                                    <i className={`${checkIconTab(3)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span>PORTFOLIO</span>
                                </div>}>

                            {filledSections.filled_sections > 1 && <Portfolio handleValidateForm={handleValidateForm} />}
                        </Tab>
                        <Tab
                            eventKey="4"
                            disabled={filledSections.filled_sections < 3}
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(4)}`}>
                                    <i className={`${checkIconTab(4)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span>INVESTMENT PREFERENCES</span>
                                </div>}>

                            {filledSections.filled_sections > 2 && <InvestmentPreferences handleValidateForm={handleValidateForm} />}
                        </Tab>
                    </Tabs> */}
                </Col>
            </Row>

            <div id={`openModalSubmit`} className="modalDialog">
                <ModalSubmit
                />
            </div>
        </>


    )
}
