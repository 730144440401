import { Card, Row, Col, Button, Modal } from "react-bootstrap"
import { Requests } from "../../../@core/interfaces/matchActivity.model";
import imgUser from '../../../assets/images/user.jpg';
import { useDispatch } from "react-redux";
import { cancelRequestsSent, removeRequestsSent } from "../../../redux/actions/matchActivityAction";
import { RequestCard } from "./RequestCard";
import React, { useState } from "react";
import { TypeUser } from '../../../@core/interfaces/common.model';

interface Props {
    requests: Requests[];
    handleGetIdToShow: (id: number) => void;
}

enum RequestOption {
    CANCEL = 0,
    REMOVE
}

export const RequestsSent = React.memo(({ requests, handleGetIdToShow }: Props) => {

    const dispatch = useDispatch()
    const userType = Number(localStorage.getItem('type'));
    const isInvestorType = Number(userType) === TypeUser.INVESTOR;
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [infoModal, setInfoModal] = useState("");

    const handleRequests = (idOption: number, investor_id: number, company_investor_request_id: number,  company_user_id: number, name = '' ) => {
        //console.log('name',name);
        setInfoModal(name);
        if (idOption === RequestOption.REMOVE) {
            dispatch(removeRequestsSent(investor_id, String(company_investor_request_id), String(company_user_id),handleShowModalRemove))
            
        }

        if (idOption === RequestOption.CANCEL){
            dispatch(cancelRequestsSent(investor_id, String(company_investor_request_id)))
        }
            
    }

    const handleShowModalRemove = () => setShowModalRemove(true);
    const handleCloseModalRemove = () => setShowModalRemove(false);
    
    return (
        <>
            {
                requests.map((req) => (
                    <>
                        <RequestCard
                            key={req.company_investor_request_id}
                            req={req}
                            handleRequests={handleRequests}
                            handleGetIdToShow={handleGetIdToShow}
                        />
                        <Modal
                                show={showModalRemove}
                                onHide={handleCloseModalRemove}
                                centered
                                dialogClassName="modal-common"
                                animation={false}
                            >
                                <Modal.Body>
                                    <div className="d-flex justify-content-end">
                                        <i className="far fa-times-circle text-primary"
                                            onClick={handleCloseModalRemove}
                                            style={{ fontSize: '24px', cursor: 'pointer' }}
                                        ></i>
                                    </div>
                                    <div className="text-center px-3 py-4 lh-sm">
                                        <p className="mb-0"><b>{infoModal}</b></p>
                                        <p>Will be moved to your Saved {isInvestorType ? 'Companies' : 'Investors'}. </p>
                                    </div>
                                </Modal.Body>
                        </Modal>
                    </>
                ))
            }
        </>
    )
})