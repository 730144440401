import React, { useState, useEffect } from "react";
import Amplify from "aws-amplify";
import awsconfig from "../../aws-exports";
import { Auth } from "aws-amplify";
import logo from '../../assets/images/logo-color.svg';
import { useNavigate } from "react-router-dom";
//import { fetchSinToken } from "../../helpers/fetch";
import { useForm } from '../../hooks/useForm';
import { customAlert } from '../../helpers/alerts';
import { Button } from "react-bootstrap"
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchCatalogs } from "../../helpers/fetch";
import { Tooltip } from "react-bootstrap";

const initialForm = {
    phone: "",
    email: "",
    type: "1",
    pass: "",
    firstname: "",
    fundtname: "",
    lastname: "",
    roles: "",
    companyName: "",
    investorType: "",
    agree: ''
}
 
export const Register = () => {
    const navigate = useNavigate();

    const [state, setState] = useState({
        catPositions: [],
        catInvestor: [],
        catPrivacyPolicy: [],
        hidePassword: true,//new
    });

    const { hidePassword } = state; //new 
    const format_pass = "The password must have at least 8 characters, an uppercase letter, at least one number and at least one of the following special characters:";
    //const characters = "^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -";
    const characters = "^ $ * . [ ] { } ( ) ? \" ! @ # % & / , > < ' : ; | _ ~ ` = + -";

    //new
    const handleHidePassword = () => {
        setState({
            ...state,
            hidePassword: !hidePassword
        })
    }

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        void getCats()
    }, []);

    // FORM
    const validationsForm = (form, issubmitClick = false) => {
        let errors = {};
        let skipFields = form.type == 1 ? ['investorType'] : ['roles', 'companyName'];

        // const regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        // const regexEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        const regexEmail = /^(\w+[/./-]?){1,}@[a-zA-Z0-9]+[/.]\w{2,}$/;
        // const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        // const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#^()-_+.]{8,}$/;
        const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d"!@#%&,><':;|_~`=+$-^}{]{8,}$/; //OK
        //const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=["!@#%&,><':;|_~`=+$-^])[a-zA-Z\d]{8,}$/;
        // ^$*.[]{}()?/\"!@#%&,><':;|_~`=+-
        const mustHave = [..."^$*.[]{}()\/?\"!@#%&,><':;|_~`=+-"];

        const containsSpecialCharacters = mustHave.some(element => {
            if (form.pass.trim().includes(element)) {
                return true;
            }
            return false;
        });        
    
        if(isSubmit || issubmitClick)
        {
            if (!form.companyName.trim() && !skipFields.includes('companyName')) {
                errors.companyName = 'Missing field';
            }
            else if (!form.investorType.trim() && !skipFields.includes('investorType')) {
                errors.investorType = 'Missing field';
            }
            else if (!form.roles.trim() && !skipFields.includes('roles')) {
                errors.roles = 'Missing field';
            }
            else if (!form.fundtname.trim() && !skipFields.includes('investorType')) {
                errors.fundtname = 'Missing field';
            }
            else if (!form.firstname.trim()) {
                errors.firstname = 'Missing field';
            }
            else if (!form.lastname.trim()) {
                errors.lastname = 'Missing field';
            }else if (!form.email.trim()) {
                errors.email = 'Missing field';
            } else if (!regexEmail.test(form.email.trim())) {
                errors.email = 'The email is invalid';
            }
            else if (!form.pass.trim()) {
                errors.pass = 'Missing field';
            } else if (!regexPassword.test(form.pass.trim()) || !containsSpecialCharacters) {
                //errors.pass = 'The password must have an uppercase letter, a lowercase letter and at least one number';
                errors.pass = format_pass
            }            

            
        }
        return errors;
    }

    const {
        form,
        errors,
        handleChange,
        handleBlur,
        handleSubmit
    } = useForm(initialForm, validationsForm);

    const { email, type, pass, firstname, fundtname, lastname, roles, companyName, investorType, agree } = form;

    const getCats = async () => {

        const catsJson = await Promise.all([
            fetchCatalogs("prod?catname=cat_investor_type"),
            fetchCatalogs("prod?catname=cat_company_positions"),
            fetchCatalogs("prod?catname=cat_privacy_policy"),
        ]);
        const cats = await Promise.all(catsJson.map((r) => r.json()));

        setState({
            ...state,
            catInvestor: cats[0],
            catPositions: cats[1],
            catPrivacyPolicy: cats[2],
        });
    }
    /*
    const getInvestorType = async () => { 
        //const data = await fetchSinToken('default/get_cat_investortype');
        const data = await fetchSinToken('prod/registro/get_cat_investortype');
        const cat = await data.json();
        return cat;
    }

    const getCatPositions = async () => {
        //const data = await fetchSinToken('get_cat_positions');
        const data = await fetchSinToken('prod/registro/get_cat_positions');
        const cat = await data.json();
        return cat;
    }
    */
    const handleChangeType = (e) => {
        handleChange(e);
        validationsForm(form)
    }

    const handleReturn = () => {
        navigate('/login')
    }

    const signUp = async (e) => {
        try {
            e.preventDefault();
            setIsSubmit(true)
            handleSubmit(true);
            const _errors = validationsForm(form, true);
            if (Object.keys(_errors).length > 0 || !agree) {
                //handleSubmit();
                return customAlert('Alert', 'The form is invalid', 'error');
            }
            const customAttributes = {
                "custom:user_type": type,
                "custom:position_id": roles,
                "custom:company_name": companyName,
                "custom:investor_type": investorType,
                "custom:fund_name": fundtname,
            }
            if (type == 1) {
                delete customAttributes["custom:investor_type"];
                delete customAttributes["custom:fund_name"];
            } else {
                delete customAttributes["custom:company_name"];
                delete customAttributes["custom:position_id"];
            }
            const { user } = await Auth.signUp({
                username: email,
                password: pass,

                attributes: {
                    email: email, // optional
                    name: firstname,
                    family_name: lastname,

                    //nuevos
                    locale: String(state.catPrivacyPolicy[1].privacy_policy_id),
                    picture: String(state.catPrivacyPolicy[0].privacy_policy_id),

                    // ...customAttributes,
                    // "custom:user_type": type,
                    "custom:Company_Type": type,
                    "nickname": roles, // positionId
                    "website": companyName,
                    "profile": investorType,
                    "zoneinfo": fundtname,
                },
            });
            
            customAlert('Successful registration', '').then(() => {
                navigate(`/confirm-code?email=${email}`)
            })
            // resetState();
        } catch (error) {
            customAlert('Error to register', error.message, 'error');
        }
    };

    const [showTooltip, setShowTooltip] = useState(false);
    const handleFocusPassword = (show) =>{
        setShowTooltip(show);
    }

    return (
        <form className="_register">
            <div className="main">
                <section className="image d-none d-md-block">
                    <img src={logo} alt="logo" />
                </section>
                <section className="data">

                    <h4 className="mb-4">Create an account and find your best fit.</h4>
                    <div className="checks mb-3">
                        <label>Register as:</label>
                        <div className="d-flex">
                            <div className="form-check me-5">
                                <input className="form-check-input" type="radio" name="type" id="company1" value={'1'}
                                    checked={type === '1'} onChange={handleChangeType} />
                                <label className="form-check-label" htmlFor="company1">
                                    Company
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type" id="investor2" value={'2'}
                                    checked={type === '2'} onChange={handleChangeType} />
                                <label className="form-check-label" htmlFor="investor2">
                                    Investor
                                </label>
                            </div>
                        </div>
                    </div>


                    {(type !== '2') &&
                        <>
                            <div className="mb-3 form-group">
                                <label htmlFor="companyname" className="form-label">Company name:</label>
                                <input type="text" className={`form-control ${errors.companyName && 'is-invalid'}`} id="companyName" name="companyName" value={companyName} onChange={handleChange} onBlur={handleBlur} />
                                {(errors?.companyName) &&
                                    <div className="invalid-feedback">
                                        {errors?.companyName}
                                    </div>
                                }
                            </div>

                            <div className="mb-3 form-group">
                                <label htmlFor="who" className="form-label">Who is the Primary Account User:</label>
                                <select className={`form-control ${errors.roles && 'is-invalid'}`} name="roles" value={roles} onChange={handleChange} onBlur={handleBlur} >
                                    <option value={''}>Select...</option>
                                    ({state?.catPositions.map((a) => (
                                        <option value={a.company_position_id} key={a.company_position_id}>{a.company_position}</option>
                                    ))})
                                </select>
                                {(errors?.roles) &&
                                    <div className="invalid-feedback">
                                        {errors?.roles}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {(type === '2') &&
                        <>
                            <div className="mb-3 form-group">
                                <label htmlFor="typeInvestor" className="form-label">Type of investor:</label>
                                <select className={`form-control ${errors.investorType && 'is-invalid'}`} name="investorType" value={investorType} onChange={handleChange} onBlur={handleBlur} >
                                    <option value={''}>Select...</option>
                                    ({
                                    /*state.catInvestor.map((c) => (
                                        <option value={c[0]} key={c[0]}>{c[1]}</option>
                                    ))*/
                                    state?.catInvestor.map((c) => (
                                        <option value={c.investor_type_id} key={c.investor_type_id}>
                                            {c.investor_type_name}
                                        </option>
                                    ))})
                                </select>
                                {(errors?.investorType) &&
                                    <div className="invalid-feedback">
                                        {errors?.investorType}
                                    </div>
                                }
                            </div>

                            <div className="mb-3 form-group">
                                <label htmlFor="fundtname" className="form-label">Fund name:</label>
                                <input type="text" name="fundtname" className={`form-control ${errors.fundtname && 'is-invalid'}`} id="fundtname" value={fundtname} onChange={handleChange} onBlur={handleBlur} />
                                {(errors?.fundtname) &&
                                    <div className="invalid-feedback">
                                        {errors?.fundtname}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    <div className="mb-3 form-group">
                        <label htmlFor="firstname" className="form-label">First name:</label>
                        <input type="text" name="firstname" className={`form-control ${errors.firstname && 'is-invalid'}`} id="firstname" value={firstname} onChange={handleChange} onBlur={handleBlur} />
                        {(errors?.firstname) &&
                            <div className="invalid-feedback">
                                {errors?.firstname}
                            </div>
                        }
                    </div>

                    <div className="mb-3 form-group">
                        <label htmlFor="lastname" className="form-label">Last name:</label>
                        <input type="text" name="lastname" className={`form-control ${errors.lastname && 'is-invalid'}`} id="lastname" value={lastname} onChange={handleChange} onBlur={handleBlur} />
                        {errors.lastname &&
                            <div className="invalid-feedback">
                                {errors.lastname}
                            </div>
                        }
                    </div>

                    <div className="mb-3 form-group">
                        <label htmlFor="email" className="form-label">Email:</label>
                        <input type="email" name="email" className={`form-control ${errors.email && 'is-invalid'}`} id="email" value={email} onChange={handleChange} onBlur={handleBlur} />
                        {errors.email &&
                            <div className="invalid-feedback" name="email">
                                {errors.email}
                            </div>
                        }
                    </div>

                    <div className="mb-3 form-group container-password">
                        <label htmlFor="password" className="form-label">Password:</label>
                        <div className={`content-input ${errors.pass && 'is-invalid'}`}>
                            <input 
                                type={hidePassword ? 'password' : 'text'}
                                name="pass" 
                                className={`form-control pass ${errors.pass && 'is-invalid'}`} 
                                id="password" 
                                value={pass} 
                                onChange={handleChange} 
                                onFocus={()=>handleFocusPassword(true)}
                                onBlur={(e)=>{
                                    handleBlur(e);
                                    handleFocusPassword(false);
                                    }
                                } />

                                <div className="icon-font" onClick={handleHidePassword}>
                                    <FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye} size="lg" />
                                </div>
                        </div>
                        {
                            showTooltip && (
                                <Tooltip>
                                    {format_pass}<br></br>{characters}
                                </Tooltip>
                            )
                        }
                            {/* <div style={{'fontSize':'0.875em', 'margin-top': '0.25rem'}} className={errors.pass ? 'invalid-feedback':''} name="password">
                                {format_pass}
                            </div> */}

                            {errors.pass &&
                                <div className="invalid-feedback" name="password">
                                    {format_pass}<br></br>{characters}
                                </div>
                            }
                    </div>

                    <div className="btn-container mt-4">
                        <input
                            type="checkbox"
                            name="agree"
                            onChange={(event) => handleChange(event, true)}
                            value={true}
                            className={`form-check-input id="acceptTerms" me-3`}
                        />
                        <span>I certify that I have read and agree to FundLyst's&nbsp;
                            <a 
                                style={{ fontWeight: "bold", color: "#0d6efd"}} 
                                // href='https://fundlyst.net/terms-of-services/' 
                                href={state.catPrivacyPolicy[1]?.url}
                                target="_blank">Terms of Service
                            </a> and 
                            <a 
                                style={{ fontWeight: "bold", color: "#0d6efd"}} 
                                // href='https://fundlyst.net/privacy-policy-2/' 
                                href={state.catPrivacyPolicy[0]?.url}
                                target="_blank" > Privacy Policy.
                            </a>
                        </span>
                        <br />
                    </div>
                        {(!agree && isSubmit) &&
                            <div className="text-danger" name="agree" style={{fontSize: '14px'}}>
                                {'Might accept terms and conditions'}
                            </div>
                        }

                    <div className="w-100 mt-4 btn-container-return d-flex justify-content-center justify-content-md-end">
                        <button type="button" onClick={handleReturn} className="my-2 btn btn-outline-primary estilos-boton modificador1">Cancel</button>
                        <Button type="submit" variant="primary" className="my-2" onClick={signUp} disabled={!agree}>Register</Button>
                    </div>
                </section>
            </div>
        </form>
    );
};

Amplify.configure(awsconfig);