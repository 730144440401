import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import imgUser from '../../../assets/images/Logo-placeholder.jpg';
import { InvestorPublicProfileInfo } from "./InvestorPublicProfileInfo";
import { InvestorPublicProfileLeadership } from "./InvestorPublicProfileLeadership";
import { InvestorPublicProfilePortfolio } from "./InvestorPublicProfilePortfolio";
import { getCompanies, getInvestorAbout, getTeammembers } from '../../../redux/actions/investorAction';
const banner = (require as any).context('../../../assets/images', true)

export const InvestorPublicProfile = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const  {companies, about, teammembers} = useSelector((state: StoreApp) => state.investor)
    const  {company_investor_id} = useSelector((state: StoreApp) => state.notifications.notificationCount)

    const [idInvestor, setIdInvestor] = useState<string | null>(null)


    useEffect(() => {
      getParams();
    }, [])

    const getParams = () => {
        setIdInvestor(searchParams.get('idInvestor') || null);
        // setAvailable(searchParams.get('available' || null))
    }

    // console.log('available',available);

    useEffect(() => {
        getParams();
        const id = idInvestor ? idInvestor : company_investor_id;
        if (!id) return;
        dispatch(getInvestorAbout(id));
        dispatch(getTeammembers(id));
        dispatch(getCompanies(id));
    }, [company_investor_id, dispatch, idInvestor])


    return (
        <>

            <div className="_InvestorPublicProfile">
                <div className="imgMainCover" style={{ backgroundImage: 'url(' + (about.investor_banner ? about.investor_banner : banner('./background_main.jpg').default) + ')' }}>
                    <img src={ about.investor_logo ? about.investor_logo : imgUser} alt="logo" className="imgRedondaMain" />
                    <div className="contentDot">
                        {/* <PencilDot isWebp={true} handleImgPencil={handleImgPencil} /> */}
                    </div>
                    <div className="contentDot2">
                        {/* <PencilDot isWebp={true} handleImgPencil={handleImgPencilBanner} /> */}
                    </div>
                </div>
                <InvestorPublicProfileInfo about={about} />
                {/* <div className='contenedor-cards-financial'>

                    <Card className='tarjetas-financials mx-3' >
                        <Card.Body>
                            <Card.Title>Range of Investment:</Card.Title>
                            <Card.Text className='precio-financial'>
                                {about.investment_bracket_description}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='tarjetas-financials mx-3' >
                        <Card.Body>
                            <Card.Title>Previous Exits:</Card.Title>
                            <Card.Text className='precio-financial'>
                                {about.exits}+
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </div> */}

                <InvestorPublicProfileLeadership members={teammembers}/>

                <InvestorPublicProfilePortfolio companies={companies}/>
            </div>

        </>
    )
}
