import React, { useState, useEffect } from 'react'
import { TypeUser } from '../../../@core/interfaces/common.model'
import { useSearchParams } from "react-router-dom";
import { PublicProfileViewAdminView } from '../../@components/Profile/PublicProfileViewAdminView';
import { InvestorPublicProfileAdminView } from '../../@components/InvestorPublicProfile/InvestorPublicProfileAdminView';

export const ViewProfileAdmin = () => {

    const [searchParams] = useSearchParams();
    const [acountType, setAcountType] = useState<string | null>(null)

    const getParams = () => {
        setAcountType(searchParams.get('type') || null); 
    }

    useEffect(() => {
        getParams();
    }, [acountType, searchParams])

    const isCompany = Number(acountType) === TypeUser.COMPANY;
    const isInvestor = Number(acountType) === TypeUser.INVESTOR;

    console.log('isCompany',isCompany,'isInvestor',isInvestor)

  return (
        <> 
            {isInvestor && <InvestorPublicProfileAdminView />}
            {isCompany && <PublicProfileViewAdminView />}
        </>
  )
}
