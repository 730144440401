import {baseCatalogs} from '../../src/environment';
//const baseUrl = 'https://vs6yms6ml5.execute-api.us-east-2.amazonaws.com';
//const baseCatalogs = 'https://fumkfk8y9j.execute-api.us-east-1.amazonaws.com';

//console.log('baseCatalogs',baseCatalogs)

/*
const fetchSinToken = (endpoint, data, method = 'GET') => {

    // const url = `${baseUrl}/${endpoint}`;
    const url = `${baseCatalogs}/${endpoint}`;

    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: { 
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}*/

const fetchCatalogs = (endpoint, data, method = 'GET') => {

    const url = `${baseCatalogs}/${endpoint}`;

    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}


export const fetchUrl = (endpoint, data, method = 'GET') => {

    const url = `${baseCatalogs}/${endpoint}`;

    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}

// const fetchConToken = (endpoint, data, method = 'GET') => {

//     const url = `${baseUrl}/${endpoint}`;
//     const token = localStorage.getItem('token') || '';

//     if (method === 'GET') {
//         return fetch(url, {
//             method,
//             headers: {
//                 'x-token': token
//             }
//         });
//     } else {
//         return fetch(url, {
//             method,
//             headers: {
//                 'Content-type': 'application/json',
//                 'x-token': token
//             },
//             body: JSON.stringify(data)
//         });
//     }
// }

const fetchImage = (b64) => {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // 'Content-type': 'application/json',
    // myHeaders.append("id", "1");
    // myHeaders.append("section", "investor");
    // myHeaders.append("filetype", "webp");
    // myHeaders.append("imageid", "1");

    let file = b64;

    file = file.substr(file.indexOf(',') + 1)
    // console.log(file)

    const  body = {
        id: '3',
        img: file,
        section: 'investor',
        imageid: '3',
        filetype: 'webp'
    }

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        // redirect: 'follow'
    };   

    return fetch("https://ey3w9vw7ue.execute-api.us-east-2.amazonaws.com/prod/upload", requestOptions)
        // .then(response => response.text())
        // .then(result => console.log(result))
        // .catch(error => console.log('error', error));

}

export {
    //fetchSinToken,
    // fetchConToken,
    fetchCatalogs,
    fetchImage
}
