import { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ProfileViewProfile } from '../../../@core/interfaces/profileView.model';
import { StoreApp } from '../../../@core/interfaces/store';
import { getFinancials } from '../../../redux/actions/companyAction';
import { CustomCardProfile } from './@custom/CustomCardProfile';

interface Props {
    profile: ProfileViewProfile;
}

export const PublicProfileFinancials = ({ profile }: Props) => {

    const dispatch = useDispatch();
    const { headers, values } = useSelector((state: StoreApp) => state.company.financials);

    const [dataTable, setDataTable] = useState({
        annual: '',
        bootstrapped: '',
        capital: '',

        gross_1: '',
        gross_2: '',
        gross_3: '',
        gross_4: '',
        gross_5: '',

        direct_1: '',
        direct_2: '',
        direct_3: '',
        direct_4: '',
        direct_5: '',

        operating_1: '',
        operating_2: '',
        operating_3: '',
        operating_4: '',
        operating_5: '',

        profit_1: '',
        profit_2: '',
        profit_3: '',
        profit_4: '',
        profit_5: '',

        profitMargin_1: '',
        profitMargin_2: '',
        profitMargin_3: '',
        profitMargin_4: '',
        profitMargin_5: '',
    });

    //Se paso a la PublicProfileView.tsx
    // useEffect(() => {
    //     dispatch(getFinancials(true))
    // }, [dispatch]);

    useEffect(() => {
        if (values?.length > 0 && headers?.length > 0) {
            const regExCurrency = /(\d)(?=(\d{3})+(?!\d))/g;
            // console.log('$' + values[0]?.[headers?.[0]]?.replace(regExCurrency, '$1,'))
            setDataTable((v) => ({
                ...v,
                gross_1: '$' + values[0][headers[0]].replace(regExCurrency, '$1,') || '',
                gross_2: '$' + values[0][headers[1]].replace(regExCurrency, '$1,') || '',
                gross_3: '$' + values[0][headers[2]].replace(regExCurrency, '$1,') || '',
                gross_4: '$' + values[0][headers[3]].replace(regExCurrency, '$1,') || '',
                gross_5: '$' + values[0][headers[4]].replace(regExCurrency, '$1,') || '',

                direct_1: '$' + values[1][headers[0]].replace(regExCurrency, '$1,') || '',
                direct_2: '$' + values[1][headers[1]].replace(regExCurrency, '$1,') || '',
                direct_3: '$' + values[1][headers[2]].replace(regExCurrency, '$1,') || '',
                direct_4: '$' + values[1][headers[3]].replace(regExCurrency, '$1,') || '',
                direct_5: '$' + values[1][headers[4]].replace(regExCurrency, '$1,') || '',

                operating_1: '$' + values[2][headers[0]].replace(regExCurrency, '$1,') || '',
                operating_2: '$' + values[2][headers[1]].replace(regExCurrency, '$1,') || '',
                operating_3: '$' + values[2][headers[2]].replace(regExCurrency, '$1,') || '',
                operating_4: '$' + values[2][headers[3]].replace(regExCurrency, '$1,') || '',
                operating_5: '$' + values[2][headers[4]].replace(regExCurrency, '$1,') || '',

                profit_1: '$' + values[3][headers[0]].replace(regExCurrency, '$1,') || '',
                profit_2: '$' + values[3][headers[1]].replace(regExCurrency, '$1,') || '',
                profit_3: '$' + values[3][headers[2]].replace(regExCurrency, '$1,') || '',
                profit_4: '$' + values[3][headers[3]].replace(regExCurrency, '$1,') || '',
                profit_5: '$' + values[3][headers[4]].replace(regExCurrency, '$1,') || '',

                profitMargin_1: values[4][headers[0]] + ' %' || '',
                profitMargin_2: values[4][headers[1]] + ' %' || '',
                profitMargin_3: values[4][headers[2]] + ' %' || '',
                profitMargin_4: values[4][headers[3]] + ' %' || '',
                profitMargin_5: values[4][headers[4]] + ' %' || '',

                annual: '$' + values[5]?.annual || '',
                bootstrapped: '$' + values[5]?.bootstrapped || '',
                capital: '$' + values[5]?.capital || '',
            }));
        }
    }, [values, headers]);

    //console.log('dataTable',dataTable);

    return (
        <>
            {
                (values && headers) &&

                <div className='financial-section'>
                    <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                        <div className='titulo-seccion'>FINANCIALS</div>
                        <div className="line"></div>

                    </p>
                    <Row>
                        <Col md={4} sm={12}>
                            <CustomCardProfile title="Annual Gross Revenue:" text={profile.Anual_Gross_Revenue} />
                        </Col>
                        <Col md={4} sm={12}>
                            <CustomCardProfile title="Bootstrapped:" text={profile.Bootstraped} />
                        </Col>
                        <Col md={4} sm={12}>
                            <CustomCardProfile title="Capital Raised to Date:" text={profile.Capital_Raised_to_Date} />
                        </Col>
                    </Row>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Financial Projections</Accordion.Header>
                            <Accordion.Body>
                                <Table responsive="lg" className='mb-0'>
                                    <thead>
                                        <tr className="encabezados">
                                            <th style={{ color: '#3816b0' }}></th>
                                            <th>{headers[0]}</th>
                                            <th>{headers[1]}</th>
                                            <th>{headers[2]}</th>
                                            <th>{headers[3]}</th>
                                            <th>{headers[4]}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span><b>Gross Revenue</b></span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.gross_1}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.gross_2}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.gross_3}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.gross_4}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.gross_5}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span><b>Direct Cost</b></span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.direct_1}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.direct_2}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.direct_3}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.direct_4}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.direct_5}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                <span><b>Operating Expenses</b></span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.operating_1}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.operating_2}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.operating_3}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.operating_4}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.operating_5}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span><b>Profit</b></span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profit_1}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profit_2}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profit_3}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profit_4}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profit_5}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span><b>Profit Margin</b></span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profitMargin_1}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profitMargin_2}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profitMargin_3}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profitMargin_4}</span>
                                            </td>
                                            <td className='modificador-celda-table'>
                                                <span>{dataTable.profitMargin_5}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>


            }
        </>
    )
};
