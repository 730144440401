import { Col, Row, Card, Button } from 'react-bootstrap';
import { FeaturedInvestors } from '../../../@core/interfaces/home.models';
import userFeautred from '../../../assets/images/user.jpg';
import { useNavigate } from 'react-router-dom';


interface Props {
    investors: FeaturedInvestors[];
    handleGetIdToShow: (id: number)=> void;
}

export const FeautredInvestorItem = ({ investors, handleGetIdToShow }: Props) => {
    const navigate = useNavigate();

    return (
        <>
            {
                investors?.length > 0 &&
                <>
                    <Row className="contenedorFeautredItem">
                        {
                            investors.map((inv) => (
                                <Col md={6} sm={12} lg={4} key={inv.investor_id}>
                                    <div className="contenedor_carta_relativ" >
                                        <Card className='my-3'>
                                            <Card.Img 
                                                variant="top" 
                                                src={inv.investor_logo ? inv.investor_logo : userFeautred} 
                                                className='my-3 border-img' 
                                                onClick={ () => handleGetIdToShow(inv.investor_id) }/>

                                            <Card.Body className='pt-0'>
                                                <Card.Title onClick={ () => handleGetIdToShow(inv.investor_id) }>
                                                    {inv.investor_fund_name}
                                                </Card.Title>
                                                <Card.Text></Card.Text>
                                                <p className="mb-1"><i className="fas fa-dollar-sign"></i> Range of investment:
                                                    <br /> <b> &nbsp;&nbsp;&nbsp;&nbsp; {inv.investment_bracket_description} </b></p>
                                                <b><p className="mb-1"><i className="fas fa-user-tie"></i> {inv.investor_type_name}</p>
                                                    <p className="mb-1"><i className="fas fa-industry"></i> {inv.industries}</p>
                                                    <p className="mb-1"><i className="fas fa-map-marker-alt"></i> {inv.city_state}</p></b>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>

                    <div className="contenedor-btn mt-3">
                        <Button
                            type="submit"
                            variant="primary"
                            className="px-5"
                            onClick={() => navigate('/search')}

                        >
                            Find More
                        </Button>
                    </div>
                </>
            }
        </>
    )
}