import React, { useState, useEffect } from 'react';
import '../ConfirmCode/ConfirmCode.css';
import logo from '../../../assets/images/logo-color.svg';
import { InputPassword } from '../../../@custom/components/InputPassword/InputPassword';
import '../../../../src/pages/public/ResetPassword/ResetPassword.css'
import { Auth } from "aws-amplify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-bootstrap";
import Swal from 'sweetalert2';

export const ResetPassword = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showTooltip, setShowTooltip] = useState(false);

    const [state, setState] = useState({
        password: '',
        confirmPassword: '',
        code: '',
        email: '',
        passwordError: false
    });

    const format_pass = "The password must have at least 8 characters, an uppercase letter, at least one number and at least one of the following special characters:";
    // const characters = "^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -";
    const characters = "^ $ * . [ ] { } ( ) ? \" ! @ # % & / , > < ' : ; | _ ~ ` = + -";

    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d"!@#%&,><':;|_~`=+$-^}{]{8,}$/;
    const mustHave = [..."^$*.[]{}()\/?\"!@#%&,><':;|_~`=+-"];
    //console.log('mustHave',mustHave)

    useEffect(() => {
        getParams();
    }, []);

    const { password, confirmPassword, code, email, passwordError } = state;

    const getParams = () => {
        const getEmail = searchParams.get('email') || null;
        setState({
            ...state,
            email: getEmail
        })
    }

    const handlePass = ({ target }) => {
        setState({
            ...state,
            password: target.value
        })
    }

    const handleConfirmPass = ({ target }) => {
        setState({
            ...state,
            confirmPassword: target.value
        })
    }

    const handleCode = ({ target }) => {
        setState({
            ...state,
            code: target.value
        })
    }

    const forgotPasswordSubmit = async () => {
        try {
            setState({...state,passwordError: false})
            const containsSpecialCharacters = mustHave.some(element => {
                if (password.trim().includes(element)) {
                    return true;
                }
                return false;
            });

            if (!regexPassword.test(password.trim()) || !containsSpecialCharacters) {
                setState({...state,passwordError: true})
                Swal.fire('Failed to forgotPasswordSubmit', 'The password is invalid', 'error');
                return false;
            }
            
            await Auth.forgotPasswordSubmit(email, code, password)
            Swal.fire('Alert', 'Your password was reset successfully', 'success').then(() => {
                navigate('/')
            })
        } catch (error) {
            Swal.fire('Failed to forgotPasswordSubmit', error.message, 'error');
        }
    }

    const handleFocus = (show) =>{
        setShowTooltip(show);
        setState({...state,passwordError: false})
    }


    return (
        <>
            <div className="container main-container">
                <img src={logo} alt="Fundlyst" />
                <div className="wrapper">
                    <h2 className="mb-5">Enter the new password</h2>
                    <div className="form-group mb-3">
                        <label htmlFor="code">Enter code</label>
                        <input type="text" name="code" className="form-control" id="code" value={code} onChange={handleCode} />
                    </div>

                    <div className='position-relative reset-password'>
                        <InputPassword 
                            pass={password} 
                            focus={()=>handleFocus(true)}
                            blur={()=>handleFocus(false)}
                            passChange={handlePass}
                            textLabel={'Password'} />
                        {
                            showTooltip && (
                                <Tooltip>
                                    {format_pass}<br></br>{characters}
                                </Tooltip>
                            )
                        }
                    </div>
                    <InputPassword pass={confirmPassword} passChange={handleConfirmPass} textLabel={'Repeat password'}></InputPassword>

                    {
                        passwordError && (
                            <>
                                <div className='is-invalid'></div>
                                <div className='invalid-feedback'>
                                    {format_pass}<br></br>{characters}
                                </div>
                            </>
                        )
                    }
                    

                    <div className="btn-wrapper w-100 mt-5 text-center">
                        <button  className='btn-primary btn'  onClick={forgotPasswordSubmit}>Accept</button>
                    </div>
                </div>
            </div>

        </>
    )
}
