import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { Col, Pagination } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import * as SearchModel from '../../../@core/interfaces/search.model';
import { StoreApp } from '../../../@core/interfaces/store';

import { 
    getSearch, 
    getSearchCompanies, 
    searchContactable, 
    searchCompaniesContactable, 
    resetPagerAction } from '../../../redux/actions/searchAction';

import CardSearch from './CardSearch';
import { PublicProfileView } from '../../@components/Profile/PublicProfileView';
import { InvestorPublicProfile } from '../../@components/InvestorPublicProfile/InvestorPublicProfile';
import { TypeUser } from '../../../@core/interfaces/common.model';
import * as _ from "lodash";

export const Search = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [pagination, setPagination] = useState(0);
    const [active, setActive] = useState('1');
    const [paginationGroup, setPaginationGroup] = useState<any[]>([]);
    const [gpActive, setGpActive] = useState(0);
    const { type } = useSelector((store: StoreApp) => store.notifications.notificationCount)
    const [isProfileShow, setIsProfileShow ] = useState(false);

    const isCompany = Number(type) === TypeUser.COMPANY;

    const handleShowProfile = (param:string) => {
        navigate({pathname: '/search',search: param});
        setIsProfileShow(true)

        if(param !== ''){
            window.scrollTo(0, 0)
        }
    }
    const handleBack = () => {
        handleShowProfile('');
        setIsProfileShow(false);
    }
    const max_pages = 15;


    // const searAllVariables: Partial<SearchModel.Search> = {
    //     investor_type: [{ label: "All", value: "all" }],
    //     industries: [{ label: "All", value: "all" }],
    //     countries: [{ label: "All", value: "all" }],
    //     states: [{ label: "All", value: "all" }],
    //     p_range_investment: [{ label: "All", value: "all" }],
    // }
    const searAllVariables: Partial<SearchModel.Search> = {
        investor_type: [{ label: "All", value: "All" }],
        industries: [{ label: "All", value: "All" }],
        countries: [{ label: "All", value: "All" }],
        states: [{ label: "All", value: "All" }],
        p_range_investment: [{ label: "All", value: "All" }],
        fundingstage: [{ label: "All", value: "All" }],
    }

    const { contactable, noContactable, searchResponse, search, searchVariables, resetPager } = useSelector((store: StoreApp) => store.search);
    //console.log('-------',resetPager,'----'); 

    useEffect(() => {

        if(resetPager){
            initialPager()
            dispatch(resetPagerAction(false))
        }
        
        handleBack();

        if (search && searchResponse.length === 0) return setPagination(0);
        if (searchResponse.length > 0) {
            const pageNum = searchResponse[0].pages_total;
            setPagination(pageNum);
        } else {
            const pageNum = contactable?.[0]?.pages_total;
            setPagination(pageNum);
        }

    }, [contactable, noContactable, searchResponse, search])

    useEffect(()=>{
        const gp = CreatePages(Array(pagination).fill(null).map((_, i) => i),max_pages)
        setPaginationGroup(gp);
    },[pagination])

    const initialPager = () =>{
        setActive('1');
        setGpActive(0);
    }


    //console.log('paginationGroup',paginationGroup, active, pagination);

    const CreatePages = (array:any, onpage:any) => {
        let pages = [];
        if(array.length > 0){
          pages = array.map((e:any, i:number) => {
                          return i % onpage === 0 ? array.slice(i, i + onpage) : null;
                        }).filter((e:any) => {
                          return e;
                        });
        }
        return pages;
      }



    const handleClickPagination = (page: string) => {
        setActive(page);

        if (Number(type) === TypeUser.INVESTOR) {

            if (!search) {
                dispatch(searchCompaniesContactable(searAllVariables, page));
            }

            dispatch(getSearchCompanies(searchVariables, page));

        } else {
            if (!search) {
                return dispatch(searchContactable(searAllVariables, page));
            }

            dispatch(getSearch(searchVariables, page));
        }
    }

    const disabledPrev = (): boolean | undefined => {
        if (active === '0' || active === '1') return true;
        return false;
    }

    searchResponse.map( (s) => {
        s.handleShowProfile = handleShowProfile
    })

    contactable.map( (s) => {
        s.handleShowProfile = handleShowProfile
    })

    noContactable.map( (s) => {
        s.handleShowProfile = handleShowProfile
    })

    const contactableView = (
        <>
            <>
                <div className="contenedor-titulo title d-flex flex-nowrap align-items-center">
                    <div className='titulo-seccion'>YOUR BEST FIT</div>
                    <div className="line"></div>
                </div>

                {
                    contactable.map((c) => (
                        <CardSearch  {...c} key={Number(type) === 2 ? c.investor_id : c.company_id} />
                    ))
                }
            </>
            <>
                {
                    noContactable.length > 0 && (
                        <div className="contenedor-titulo title d-flex mt-5 flex-nowrap align-items-center">
                            <div className="title-not-there titulo-seccion"  >YOU'RE NOT THERE YET</div>
                            <div className="line"></div>
                        </div>

                    )}
                {
                    noContactable.map((c) => (
                        <div className='fondo-gris' key={Number(type) === 2 ? c.investor_id : c.company_id}>
                            <CardSearch {...c} />
                        </div>
                    ))
                }
            </>
        </>
    )

    const searchResultsView = (
        <>
            <div className="contenedor-titulo title d-flex flex-nowrap align-items-center">
                <div  className='titulo-seccion'>YOUR BEST FIT</div>
                <div className="line"></div>
            </div>

            {
                searchResponse.map((c) => (
                    <CardSearch {...c} key={c.company_id} />
                ))
            }
        </>
    )

    const handlePreviusPage = () => {
        const prevPage = gpActive < 1 ? 0 : gpActive-1;
        handleClickPagination(String(paginationGroup[prevPage][0]+1))
        setGpActive(prevPage)
    }

    const handleNextPage = () =>{
        const nextPage = gpActive >= paginationGroup.length -1 ? paginationGroup.length-1 : gpActive+1;
        handleClickPagination(String(paginationGroup[nextPage][0]+1))
        setGpActive(nextPage)
    }

    const handleLastPage = () =>{
        handleClickPagination(String(pagination))
        setGpActive(paginationGroup.length -1)
    }

    return (
        <div className="_search">
            {
                isProfileShow ? 
                <> 
                    <div className='mb-1'>
                        <a className='btn-profile-back' onClick={handleBack}>
                            <b><i className="fa fa-chevron-left"></i> Back</b>
                        </a>
                    </div>
                    {(isCompany ? <InvestorPublicProfile /> : <PublicProfileView />)}
                </>
                :
                    (
                    <>
                    <Col sm={12}>
                        { search ? searchResultsView : contactableView }
                    </Col>
                    {
                        (pagination > 1) &&
                        <Col sm={12} className="mt-4">
                            <Pagination className="justify-content-center">
                                {/* <Pagination.Prev disabled={disabledPrev()} onClick={() => handleClickPagination(String(Number(active) - 1))} >Previous</Pagination.Prev> */}
                                <Pagination.Prev disabled={gpActive===0} onClick={handlePreviusPage} >Previous</Pagination.Prev>
                                {
                                    // Array.from(Array(pagination).keys()).map((k) => (
                                    //     <Pagination.Item key={k} active={String(k + 1) === active} onClick={() => handleClickPagination(String(k + 1))}>
                                    //         {k + 1}
                                    //     </Pagination.Item>
                                    // ))
                                    paginationGroup.length > 0 && paginationGroup[gpActive].map( (page:any, k:any) => (
                                        <Pagination.Item key={page} active={String(page + 1) === active} onClick={() => handleClickPagination(String(page + 1))}>
                                            {page + 1}
                                        </Pagination.Item>
                                    ))
                                }
                                {
                                    gpActive < paginationGroup.length-1 && (
                                        <>
                                            <Pagination.Item> {'...'} </Pagination.Item>
                                            <Pagination.Item onClick={handleLastPage}> {pagination} </Pagination.Item>
                                        </>
                                    )   
                                }
                                {/* <Pagination.Next disabled={Number(active) === pagination} onClick={() => handleClickPagination(String(Number(active) + 1))}>Next</Pagination.Next> */}
                                <Pagination.Next disabled={!(gpActive < paginationGroup.length-1)} onClick={handleNextPage}>Next</Pagination.Next>
                            </Pagination>
                        </Col>
                    }
                    </>
                    )
            }
        </div>
    )
}
