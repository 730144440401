import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { TypeUser } from '../@core/interfaces/common.model';
import { setIdInvestor } from '../redux/actions/investorAction';
import { setEmailAction, setIdCompany } from '../redux/actions/uiAction';

export const PrivateRoute = ({ children }: any) => {

    const [checking, setChecking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const dispatch = useDispatch();
    const { pathname, search } = useLocation()
    localStorage.setItem('lastPath', pathname + search)


    useEffect(() => {
        const email = localStorage.getItem('email');
        const idCompanyInvestor = localStorage.getItem('idCompany');
        const type = localStorage.getItem('type');
        if (email && idCompanyInvestor && type) {
            dispatch(setEmailAction(email))
            if (Number(type) === TypeUser.COMPANY) {
                dispatch(setIdCompany(idCompanyInvestor))
            }
            if (Number(type) === TypeUser.INVESTOR) {
                dispatch(setIdInvestor(idCompanyInvestor))
            }
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
        setChecking(false);
    }, [dispatch, setChecking, setIsLoggedIn])


    if (checking) {
        return (
            <></>
        )
    }

    return isLoggedIn
        ? children
        : <Navigate to='/login' />;
}