import { Search, TypeSearch } from "../../@core/interfaces/search.model";
import { ChatResponse } from "../../@core/interfaces/chat.model";
import { StoreApp } from "../../@core/interfaces/store";
import { searchCompaniesService } from "../../@core/services/investor.service";
import { searchService } from "../../@core/services/search.service";
import { TypeUser } from "../../@core/interfaces/common.model";
import { customErrorAlert } from "../../helpers/alerts";
import { chatTypes } from "../types/chatTypes";
import { setLoading } from "./uiAction";
import { toast } from "react-toastify";
import { _progressTrackerChat } from "../../@core/services/matchActivity.service";
import * as _ from 'lodash';

//const userType = Number(localStorage.getItem('type'));

export const getUsersChatMatch = () => {
    return async (dispatch: any, getState: () => StoreApp) => {
      dispatch(setLoading(true));
      try {
        const { investor, company, notifications } = getState();
        //console.log('getState()',getState());  

        //const userType = investor?.investorId > 0 ? TypeUser.INVESTOR : (company?.idCompany > 0  ? TypeUser.COMPANY : 0 )
        //console.log('userType--demos',userType); 
        const userType = Number(notifications.notificationCount.type)
        //console.log('userType--demos',userType); 
        const user_id = Number(notifications.notificationCount.company_investor_id)

        //const user_id = userType == TypeUser.INVESTOR ? investor.investorId : (userType == TypeUser.COMPANY) ? company.idCompany : 0;
        
        if(user_id !== 0){
            const progress = await _progressTrackerChat(String(user_id), String(userType));
            //console.log('progress',progress);
            if(progress.length>0){

                const str_grup_by = userType == TypeUser.INVESTOR ? 'company_id' : (userType == TypeUser.COMPANY) ? 'investor_id' : '';
                //console.log('str_grup_by',str_grup_by);
                const group = _.groupBy(progress, str_grup_by);
                const usersChat0 = Object.keys(group).map( (e, i, g) => {
                    if(group[e][0].show_chat === 1){
                        return group[e][0]
                    }
                });

                const usersChat = usersChat0.filter(function( element ) {
                    return element !== undefined;
                 })

                //console.log('usersChat',usersChat); 

                if(usersChat.length > 0){
                    const usersChatResult = usersChat;
                    //console.log('usersChatResult',usersChatResult);
                    dispatch(setUsersChatAction(usersChatResult,userType));
                }else{
                    console.log('no data chat');
                }
            }
        }else{
            console.log('no user id');
        }
        dispatch(setLoading(false));
      } catch (error: any) {
        // customErrorAlert(error, "Alert");
        dispatch(setLoading(false));
        console.log('Error chat cA.ts');
      }
    };
  };

// const setUsersChatAction = (chat: ChatResponse[]) => ({
const setUsersChatAction = (chat: any = [], userType: number) => ({
    type: chatTypes.setChat,
    payload: { chat, userType },
  });

  export const setChatConnection = (status: boolean) => ({
    type: chatTypes.setChatConnection,
    payload: status,
  });

  export const setCountMsg = (cant_msg: number) => ({
    type: chatTypes.setCountMsg,
    payload: cant_msg,
  });

  export const setCountChat = (cant_chat: number) => ({
    type: chatTypes.setCountChats,
    payload: cant_chat,
  });

  export const setActiveChat = (id_company_investor: number) => ({
    type: chatTypes.setActiveChat,
    payload: id_company_investor,
  });

  