import React, { useEffect, useState, useRef, useCallback, useDebugValue } from 'react';
import { Navigate, useNavigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap'
// import 'react-pro-sidebar/dist/css/styles.css';
import { Navbar } from './Navbar';
import { NavbarAdmin } from './NavbarAdmin';
// import logoWhite from '../../assets/icons/logo_white.png';
import { MainInvestor } from '../@components/InvestorComp/MainInvestor';
import { Footer } from './Footer';
import { Company } from '../@components/Company/Company';

//chat----
import { Chat } from '../@components/Chat/Chat';
//Chat----

import { SidebarCustom } from './SidebarCustom';
import { useDispatch, useSelector } from 'react-redux';
import { loadCats } from '../../redux/actions/catalogsAction';
import { Search } from '../@components/Search/Search';
import { PublicProfileView } from '../@components/Profile/PublicProfileView';
// import { HomeCompany } from '../@components/HomeCompany/HomeCompany';
import { SavedInvestorsComponente } from '../@components/SavedInvestors/SavedInvestorsComponente';
import { MatchActivity } from '../@components/MathActivity/MatchActivity';
import { Notifications } from './Notifications';
import { StoreApp } from '../../@core/interfaces/store';
import { TypeUser } from "../../@core/interfaces/common.model";
import { HomeInvestor } from '../@components/InvestorProfile_home/HomeInvestor';
import { SavedCompaniesComponent } from '../@components/SavedCompanies/SavedCompaniesComponent';
import { SearchCompany } from '../@components/SearchCompanies/SearchCompany';
import { InvestorPublicProfile } from '../@components/InvestorPublicProfile/InvestorPublicProfile';
import { useMedia } from '../../hooks/useMedia';
import { Home } from '../@components/Home/Home';
// import { URL_SOCKET } from "../../@core/interfaces/chat.model";
import { URL_SOCKET } from "../../../src/environment";
import { setChatConnection, setCountChat, setActiveChat } from '../../redux/actions/chatAction';
import { refreshPathActivity } from '../../redux/actions/refreshAction';
import { ViewProfileAdmin } from '../@components/Home/ViewProfileAdmin';
import * as _ from 'lodash';

export const Dashboard = () => {

    const dispatch = useDispatch(); 

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { notification, userInfo } = useSelector((state: StoreApp) => state.ui)
    const { company, investor } = useSelector((store: StoreApp) => store);
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
    const [ sidebar , setSidebar ] = useState(false)
    //const isMobile = useMedia("(max-width: 1100px)");
    //const isDesktop = useMedia("(min-width: 1100px)");
    const user_acepted = Number(notificationCount.accepted_status) === 1;
    const is_admin = Number(notificationCount.is_admin) === 1;

    const userType = Number(notificationCount.type);
    const user_id = Number(notificationCount.company_investor_id);

    const id_company  = Number(company.idCompany);
    const id_investor = Number(investor.investorId);

    const [reconect, setReconect] = useState(false)
    const [reconecting, setReconecting ] = useState(false)
    //console.log('user_id',user_id)

    const pathname_no_acepted_inv = ['/investor','/public-profile-investor']
    const pathname_no_acepted_com = ['/company','/public-profile-company']

    //Redirigir cuando el usuario no sea aceptado
    if(userType === TypeUser.INVESTOR && !user_acepted){
        if(!(pathname_no_acepted_inv.indexOf(pathname) > -1)){
            navigate('/investor')
        }
    }

    if(userType === TypeUser.COMPANY && !user_acepted){
        if(!(pathname_no_acepted_com.indexOf(pathname) > -1)){
            navigate('/company')
        }
    }

    //Redirigir cuando intente entrar a perfil no correspondiente
    if(userType === TypeUser.INVESTOR && pathname === '/company'){
        navigate('/investor')
    }

    //Redirigir cuando intente entrar a perfil no correspondiente
    if(userType === TypeUser.COMPANY && pathname === '/investor'){
        navigate('/company')
    }


    //chat-----------------------------------------------------------chat
    const socket = useRef<WebSocket | null>(null);
    const { chatResponse } = useSelector((store: StoreApp) => store.chat);

    const onSocketOpen = useCallback(() => {

        if(isNaN(user_id) || isNaN(userType)){
            return
        } 

        dispatch(setChatConnection(true)); 
        console.log('Conected on chat with id in dashboard:',user_id,userType)
        const jsonc = JSON.stringify({action: "setName",id_usr:String(user_id),type_usr:String(userType)});
        socket.current?.send(jsonc);
      }, [userInfo, user_id, userType]); 

    const onSocketMessage = useCallback((dataStr) => {
        const data = JSON.parse(dataStr);
        console.log('data',data)
        if (data.action === 'newMsg'){
            dispatch(setActiveChat(Number(data.extradata)))
            dispatch(setCountChat(1));
        }else{
            setReconect(true)
            setReconecting(false)
        }
    }, []);
 
    const onSocketClose = useCallback(() => {
        dispatch(setChatConnection(false));
      }, [userType, user_id]);

    const onDisconnect = useCallback(() => {
        setReconect(false)
        console.log('Desconectando socket del dashboard...')
        socket.current?.close();
    }, []);

    const onConnect = useCallback(() => {
        if (socket.current?.readyState !== WebSocket.OPEN) {
          setReconecting(true)
          socket.current = new WebSocket(URL_SOCKET);
          socket.current.addEventListener('open', onSocketOpen);
          socket.current.addEventListener('close', onSocketClose);
          socket.current.addEventListener('message', (event) => {
            onSocketMessage(event.data);
          });
        }
      }, [URL_SOCKET ,userInfo, chatResponse, user_id, userType]);
      
    useEffect(() => {
        if( pathname !== '/chat'){
            socket.current?.close();
            onConnect();
        }else{
            //console.log('no conectando')
        }

        return () => {
          socket.current?.close();
        };
      }, [userInfo, chatResponse, pathname]);

       //--------------------------------------------------------RECONECTAR SOCKET ------------------------------
    if (socket.current?.readyState !== WebSocket.OPEN && reconect && !reconecting && pathname !== '/chat') {
        setReconect(false)
        console.log('Reconectando...',socket.current?.readyState)
        onConnect()
    }

    //interval para enviar ping
    useEffect(() => {
        const interval = setInterval(() => {
            if( pathname !== '/chat'){
                socket.current?.send(JSON.stringify({"action": "ping"}))
            }
        }, 300000);
    }, [dispatch]);
    //--------------------------------------------------------RECONECTAR SOCKET ------------------------------

    //chat--------------------------------------------------chat

    useEffect(() => {
        dispatch(loadCats())
    }, [dispatch]);

    // const onSetSidebarOpen = (open: boolean) => {
    //     // dispatch(openSidebarAction(open))
    //     setSidebar(open)
    // }

    //-------------------------------------------------- REFRESH AUTOMATICO ---------------------------------
    const [SearchParams] = useSearchParams();
    const id_profile_investor =  Number(SearchParams.get('idInvestor')) || 0
    const id_profile_company  =  Number(SearchParams.get('idCompany')) || 0

    const handdleRefresh = (inpathname: string, user_acepted: boolean, user_id: number, TypeUser: any) => {
        let id_profile = 0;
        let userType = 0;
        if(inpathname.startsWith('/public-profile-company') || inpathname.startsWith('/public-profile-investor')){
            if(user_id === id_company && inpathname ==='/public-profile-investor'){
                id_profile = id_profile_investor
                userType = TypeUser.INVESTOR
            }
            if(user_id === id_investor && inpathname.startsWith('/public-profile-company')){
                id_profile = id_profile_company
                userType = TypeUser.COMPANY
            }
        }
        dispatch(refreshPathActivity(inpathname, id_profile, userType))
    }

    useEffect(() => {
        if(!user_acepted || user_id === 0){
            console.log('no data')
            return
        }

        const intervalRefresh = setInterval(()=>{
            handdleRefresh(pathname,user_acepted, user_id, TypeUser)
        }, 20000)
        return () => clearInterval(intervalRefresh);
    }, [pathname, user_acepted, user_id, TypeUser]);

    //-------------------------------------------------- END REFRESH AUTOMATICO ------------------------------

    return (
        <div>
            { !is_admin ? <Navbar onDisconnect={onDisconnect} /> : <NavbarAdmin onDisconnect={onDisconnect} /> }

            <div className="container-dashboard">
                <Row className="__dashboard mt-3 justify-content-center">
                    {/* {
                        isMobile && (
                            <Sidebar
                                sidebar={<SidebarCustom />}
                                open={sidebar}
                                docked={false}
                                onSetOpen={onSetSidebarOpen}
                                styles={{ sidebar: { background: "white", maxWidth: '500px' } }}
                            >
                                <button onClick={() => setSidebar(!sidebar)}>
                                Open sidebar
                            </button>
                            </Sidebar>
                        )
                    } */}
                    {/* {
                        isDesktop && ( */}
                    {
                        !is_admin && 
                        <Col sm={3} className="menu-desktop">
                            <SidebarCustom />
                        </Col>
                    }


                    {/* <Col sm={pathname.includes('search') ? 9 : 10} className="__dashboard__wrapper mb-4"> */}
                    <Col sm={9} className="__dashboard__wrapper">

                        {
                            // notification && 
                            // <Notifications 
                            //     HanddleCloseMenuNotif={()=>{}}
                            //     styles={{ width: '400px', heigh: '700px' }} />
                        }
                        <Routes>
                            {
                                user_acepted ? (
                                <>
                                    <Route path='/home' element={<Home />} />
                                    <Route path='/investor' element={<MainInvestor />} />
                                    <Route path='/public-profile-investor' element={<InvestorPublicProfile />} />
                                    <Route path='/company' element={<Company />} />
                                    <Route path='/public-profile-company' element={<PublicProfileView />} />
                                    <Route path='/chat' element={<Chat />} />
                                    <Route path='/companyTabs' element={<Company />} />
                                    <Route path='/search' element={<Search />} />
                                    <Route path='/saved-investors' element={<SavedInvestorsComponente />} />
                                    <Route path='/match-activity' element={<MatchActivity />} />
                                    <Route path='/investor-saved' element={<SavedCompaniesComponent />} />
                                    <Route path='/investor-company' element={<SearchCompany />} />

                                    <Route path="*" element={<Navigate to='/home' replace />} />
                                </>)
                                :
                                (<>
                                    {
                                    userType === TypeUser.INVESTOR && (
                                        <>
                                        <Route path='/investor' element={<MainInvestor />} />
                                        <Route path='/public-profile-investor' element={<InvestorPublicProfile />} />
                                        </>
                                    )}
                                    {
                                    userType === TypeUser.COMPANY && (
                                        <>
                                        <Route path='/company' element={<Company />} />
                                        <Route path='/public-profile-company' element={<PublicProfileView />} />
                                        </>
                                    )}
                                </>)
                            }

                            {is_admin && <Route path='/view-profile' element={<ViewProfileAdmin />} />}
                            
                        </Routes>
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    )
}
