import { Leadership } from "../../../@core/interfaces/company.model";
import { CatValueLabel } from "../../../@core/interfaces/investor.model";
import imgUser from '../../../assets/images/user.jpg';

export const SearchCompanyLeadership = () => {

    return (
        <>
            <div className="company-leadership">
                <p className='contenedor-titulo'>
                    <span className='titulo-seccion'>LEADERSHIP TEAM</span> <hr />
                </p>
                <div className="contenedor-cards-leadership">


                    <div className="card-leadership-member" >
                        <div className="contenedor-imagen">
                            <img src={imgUser} alt="" />
                        </div>
                        <span> <b>Alysha Perry</b> </span>
                        <span><b>CEO and Co-founder</b></span>
                        <span>Expertise: <b>Technology, Financials</b></span>
                        <span>Previus Exists: <b>None</b></span>
                        <a target='_blank' href={'#!'}>
                            <i className="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
};