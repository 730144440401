export const companyTypes = {

    getAboutUs: '[ABOUT US] Get about us',
    setAboutUs: '[ABOUT US] Set about us',
    updateAboutUs: '[ABOUT US] Update about us',
    updateCompanyLogo: '[ABOUT US] Update logo',
    updateCompanyBanner: '[ABOUT US] Update banner',
    
    setValueProposition: '[VALUE PROPOSITION] Set Value porposition us',

    getLeadership: '[LEADERSHIP] get leadership',
    addLeadership: '[LEADERSHIP] add leadership',
    setLeadership: '[LEADERSHIP] set leadership',
    updateLeadership: '[LEADERSHIP] update leadership',
    deleteLeadership: '[LEADERSHIP] delete leadership',
    updatePhotoLeadership: '[LEADERSHIP] update photo',


    getFinancials: '[FINANCIALS] Get',
    setFinancials: '[FINANCIALS] Set',

    getFunding: '[FUNDING] Get',
    setFunding: '[FUNDING] Set',
    updateFileFunding: '[LEADERSHIP] update file',

    getFilledSections: '[FILLED SECTIONS] get',
    updateFilledSection: '[FILLED SECTIONS] update id',

    setIdCompany: '[COMPANY] set id company',
    submitCompany: '[COMPANY] submit',

    setValidForm: '[COMPANY] set valid form'



}