import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { Col, Row, Button, Card } from 'react-bootstrap';
import { MyTextInput } from "../../../@custom/components/form/MyTextInput";
import imgUser from '../../../assets/images/user.jpg';
import { ChatResponse, PropsChatSocket, PropsMessage } from "../../../@core/interfaces/chat.model";
import { StoreApp } from '../../../@core/interfaces/store';
import { TypeUser } from "../../../@core/interfaces/common.model";
import imgMainProfile from '../../../assets/images/Logo-placeholder.jpg';



interface Props {
  onPublicMessage: (message: string) => void;
  onPrivateMessage: (message: string, to: string) => void;
  // chatRows: React.ReactNode[];
  chatRows: any[];
  chatMember: any;
  handleBack: () => void;
}

export const ChatMessage = ({ onPublicMessage, onPrivateMessage, chatRows, chatMember, handleBack }: Props) => {

  const formikRef = useRef<FormikProps<any>>(null);
  const messageContainerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [imgUserActive, setTmgUserActive] = useState("");
  const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
  const { userInfo } = useSelector(( state: StoreApp) => state.ui);
  const userType = Number(notificationCount.type);
  const user_id = Number(notificationCount.company_investor_id)

  //console.log('userInfo',userInfo)

  const handleSendMessage = (message: any)=> {
    onPublicMessage(message);
  }

  const handleSendPrivateMessage= (message: string, to: string)=> {
    onPrivateMessage(message, to);
  }

  const handleClickSubmit = () =>{
    formikRef.current?.handleSubmit();
  }

  const onEnterPress = (e: any) => {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      formikRef.current?.handleSubmit();
    }
  }

  const scrollToBottom = () => {
      messageContainerRef.current.scrollIntoView({ block: "end" })
  }
  //console.log('chatMember',chatMember);

  useEffect(() => {
    if(chatMember){
      scrollToBottom()
    }
  }, [chatRows, chatMember]);

  // const ContactMessage = ({mensaje, time, image}: PropsMessage) => {
  const ContactMessage = ({mensaje, time, image}: any) => {
    return (
      <div className="d-flex justify-content-start mb-4">
        <div className="img_cont_msg">
          <img src={image} className="rounded-circle user_img_msg" />
        </div>
        <div className="msg_cotainer">
        <span className="msg_time">{time}</span>
          {
            mensaje?.split('\n').map((word: any) => {
                return <p>{word}</p>;
            })
          }
        </div>
      </div>
    );
  }

  // const MyMessage = ({mensaje, time, image}: PropsMessage) => {
  const MyMessage = ({mensaje, time, image}: any) => {
    return (
      <div className="d-flex justify-content-end mb-4">
        <div className="msg_cotainer_send">
        <span className="msg_time_send">{time}</span>
          {
            mensaje?.split('\n').map((word: any) => {
                return <p>{word}</p>;
            })
          }
        </div>
        <div className="img_cont_msg">
          <img src={image} className="rounded-circle user_img_msg" />
        </div>
      </div>
    );
  }

  // console.log('user_id',user_id);
  //console.log('chatRows v2',chatRows)


  return <>
  {
    chatMember && (
        <div className="card">

          <div className="card-header msg_head">
            <div className="d-flex bd-highlight">
              <Button onClick={handleBack} className='btn-back ps-0'><i className="fa fa-arrow-left"></i></Button>
              <div className="img_cont">
                <img src={(chatMember?.investor_logo ? chatMember?.investor_logo : chatMember?.company_logo) || imgUser} className="rounded-circle user_img" />
                {/* <span className="online_icon"></span> */}
              </div>
              <div className="user_info">
                <span className='text-dark'>{chatMember?.investor_fund_name ? chatMember?.investor_fund_name : chatMember?.company_name }</span>
                {/* <p>1767 Messages</p> */}
              </div>
            </div>
          </div>

          <div className="card-body msg_card_body">
            {
              chatRows.map((item) => (
                item?.sender_id == user_id ? 
                <MyMessage 
                  key={item?.message_id}
                  time={item?.created_timestamp}
                  image={(userInfo.logo || imgUser)}
                  mensaje={item?.message} /> 
                : 
                <ContactMessage 
                  key={item?.message_id}
                  time={item?.created_timestamp}
                  image={(chatMember?.investor_logo ? chatMember?.investor_logo : chatMember?.company_logo) || imgUser}
                  mensaje={item.message} />
              ))
            }
            <div ref={messageContainerRef} />
          </div>

          <div className="card-footer">
              {/* <div className="input-group-append">
                <span className="input-group-text attach_btn"><i className="fas fa-paperclip mb-1"></i></span>
              </div> */}

              <Formik
                innerRef={formikRef}
                initialValues={{
                  message: "",
                }}
                onSubmit={(values) => {
                  //handleSendMessage(values.message);
                  //const to = chatMember?.investor_id === 0 ? chatMember?.company_id : chatMember?.investor_id
                  const to = userType === TypeUser.COMPANY ? chatMember?.investor_id : chatMember?.company_id
                  //console.log('userType to', userType, to);
                  handleSendPrivateMessage(values.message,to);
                  values.message="";
                }}
                validationSchema={Yup.object({
                  message: Yup.string().required("Required"),
                })}
              >
                {(formik) => (
                  <Form>
                    <div className="input-group custom-input-group">
                        <MyTextInput
                          disableLabel={true}
                          label=""
                          textArea={true}
                          onKeyDown={onEnterPress}
                          className="form-control type_msg"
                          name="message"
                          placeholder="Type your message..."
                          errors={formik.errors}
                        />

                      <div 
                      className="input-group-append"
                      onClick={handleClickSubmit}>
                        <span className="input-group-text send_btn"><i className="fas fa-paper-plane"></i></span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
          </div>
        </div>
    )
  }{
    !chatMember && (
      <div className='card d-flex justify-content-center text-center'>
        <h4>Select a contact</h4>
      </div>
    )
  }
  </>

}