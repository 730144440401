import { fetchCatalogs } from "../../helpers/fetch";

export const _getRefresh = async (
    id_investor: string,
    id_company: string,
    page: string,
    userType: string,
    action: string
  ): Promise<[{}]> => {

  const json = {
    iIdInvestor: id_investor,
    iIdCompany: id_company,
    page: page,
    type: userType,
    action: action
  }

  const params = new URLSearchParams(json).toString()
  const fetch = await fetchCatalogs('prod/refresh?'+params,'GET');
  return await fetch.json();
};
