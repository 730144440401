import { fetchCatalogs } from "../../helpers/fetch";
import { Result } from "../interfaces/common.model";
import { NotificationCount, NotificationInfo, NotificationUpdate, UserInfo } from "../interfaces/notifications.model";

export const _getNotificationCount = async (email: string): Promise<NotificationCount[]> => {
  const res = await fetchCatalogs("prod/notifications/getcount?username=" + email);
  return await res.json();
};

export const _getNotificationInfo = async (userType: string, userId: string): Promise<NotificationInfo[]> => {
  const res = await fetchCatalogs(`prod/notifications?user_type=${userType}&user_id=${userId}`);
  return await res.json();
};

export const _updateNotification = async (notification: NotificationUpdate): Promise<Result> => {
  const res = await fetchCatalogs(`prod/notifications/status`, notification, 'PUT');
  return await res.json();
};

export const _getUserInfo = async (company_investor_id: string, userType: string): Promise<UserInfo[]> => {
  const res = await fetchCatalogs(`prod/gralinfo/userinfo?company_investor_id=${company_investor_id}&user_type=${userType}`);
  return await res.json();
};
