import React, { createRef, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'

interface Props {
    catRejection: any[];
    handleIdRejection: (e: any) => void;
    idRejection: string | number;
    handleActionModal: () => void;
    textTitle: string;
}

export const ModalRejection = React.memo(({ catRejection, handleIdRejection, idRejection, handleActionModal, textTitle }: Props) => {

    const refText = createRef<any>();

    useEffect(() => {
      refText.current.innerHTML= textTitle;
    }, [textTitle])
    

    return (
        <div>
            <a href="#close" title="Close" className="close">
                <i className="far fa-times-circle text-primary"></i>
            </a>
            <div className="circulo mb-2 mt-4">
                <i className="fas fa-thumbs-down"></i>
            </div>
            <p className='my-2' ref={refText}></p>
            
            <select name="select" className='my-2' onChange={handleIdRejection}>
                <option value={''}>Select...</option>
                {catRejection.map((r) => (
                    <option key={r.rejection_reason_id} value={r.rejection_reason_id}>{r.rejection_reason}</option>
                ))}
            </select>


            <Button
                type="button"
                variant="primary"
                className='estilos-boton my-3'
                disabled={!idRejection}
                onClick={handleActionModal}
            >
                Decline
            </Button>
        </div>
    )
})
