import { fetchCatalogs } from "../../helpers/fetch";
import { GetHighlightsCatalog } from "../interfaces/cats.model";
import { Result } from "../interfaces/common.model";
import { SavedInvestors } from "../interfaces/home.models";
import {
  CatValueLabel,
  InvestmentPreferences,
  InvestmentPreferencesModel,
  InvestorAbout,
  SavedCompanies,
  FilledSections
} from "../interfaces/investor.model";

import { Search, SearchResponse } from "../interfaces/search.model";

export const searchService = async (
  idCompany: number,
  pagina: string = "1"
): Promise<SavedInvestors[]> => {
  const regxpag = "8"; // Número máximo de registros por página.

  const res = await fetchCatalogs(
    "prod/company/actions/saved_investors?company_id=" +
      idCompany +
      "&regxpag=" +
      regxpag +
      "&pagenum=" +
      pagina
  );
  return await res.json();
};


export const searchCompaniesService = async (
  idCompany: number,
  search: Search | null,
  pagina: string = "1",
  tipoBusqueda?: string
): Promise<SearchResponse[]> => {
  const regxpag = "8"; // Número máximo de registros por página.

  let queryParams = "";
  //console.log('search',search);
  if (search) {
      const valuesJoin = Object.keys(search).reduce((acc, key) => {
        return {
          ...acc,
          [key]: Array.isArray(search[key as keyof Search])? search[key as keyof Search].map((p) => p.value).join(): search[key as keyof Search],
        };
      }, {});
      Object.entries(valuesJoin).forEach(([key, value], i) => {
        queryParams += `&${key}=${value}`;
      });
  }

  const res = await fetchCatalogs(
    "prod/search/companies?investor_id=" +
      idCompany +
      queryParams +
      "&regxpag=" +
      regxpag +
      "&pagenum=" +
      pagina
  );
  return await res.json();
};


export const _getSavedCompanies = async (
  idInvestor: string,
  pagina: string = "1"
): Promise<SavedCompanies[]> => {
  const regxpag = "8"; // Número máximo de registros por página.
  const res = await fetchCatalogs(
    "prod/investor/actions/savedcompanies?investor_id=" +
      idInvestor +
      "&regxpag=" +
      regxpag +
      "&pagenum=" +
      pagina
  );
  return await res.json();
};

export const _getInvestorAbout = async (
  email: string,
  idInvestor: number,
  idCompany: number
): Promise<InvestorAbout[]> => {
  const res = await fetchCatalogs(
    // "prod/investor/profile?sUserName=" + email + "&iIdInvestor=" + idInvestor
    "prod/investor/publicprofile?iIdInvestor=" + idInvestor + "&iIdCompany=" + idCompany
  );
  return await res.json();
};

export const _getHighlightsCatalog =
  async (): Promise<GetHighlightsCatalog> => {
    const catsJson = await Promise.all([
      fetchCatalogs("prod?catname=cat_highlights1"),
      fetchCatalogs("prod?catname=cat_highlights2"),
    ]);
    const cats = await Promise.all(catsJson.map((r) => r.json()));

    const catHighlights = cats[0].map((ind: any) => ({
      value: ind["expertise_highlight1_id"],
      label: ind["expertise_highlight1"],
    }));

    const catHighlights2 = cats[1].map((ind: any) => ({
      value: ind["expertise_highlight2_id"],
      label: ind["expertise_highlight2"],
    }));

    return { catHighlights, catHighlights2 };
  };

export const _getCatIndustries = async (): Promise<CatValueLabel[]> => {
  const catsJson = await Promise.all([
    fetchCatalogs("prod?catname=cat_industries"),
  ]);
  const cats = await Promise.all(catsJson.map((r) => r.json()));
  const catIndustries = cats[0].map((ind: any) => ({
    value: ind["industry_id"],
    label: ind["industry_name"],
  }));
  return catIndustries;
};

export const _getInvestmentPreferences = async (idInvestor: string): Promise<InvestmentPreferences[]> => {
  const resp = await fetchCatalogs("prod/investor/preferences?iIdInvestor=" + idInvestor);
  return await resp.json();
};

export const _saveInvestmentPreferences = async (idInvestor: string, preferences: InvestmentPreferencesModel): Promise<Result> => {
  const req = {
    iIdInvestor: idInvestor,
    ...preferences
  }
  const resp = await fetchCatalogs("prod/investor/preferences", req, 'POST');
  return await resp.json();
};

export const _getFilledSectionsInvestor = async (
  email: string
): Promise<FilledSections[]> => {
  const res = await fetchCatalogs(
    `prod/investorgralinfo/filledsections?s_email=${email}`
  );
  return await res.json();
};

export const _submitInvestor = async (email: string): Promise<Result> => {
  const req = {
    sUserName: email,
  };
  const fetch = await fetchCatalogs("prod/investor/actions/submit_request",req,"POST");
  return await fetch.json();
};