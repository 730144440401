import { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Row, Col, Button } from "react-bootstrap"
import { Requests } from "../../../@core/interfaces/matchActivity.model"
import imgUser from '../../../assets/images/user.jpg';
import { TypeUser } from '../../../@core/interfaces/common.model';
import { StoreApp } from '../../../@core/interfaces/store';


interface Props {
    req: Requests;
    handleRequests: any;
    handleGetIdToShow: (id: number) => void;
}

enum RequestOption {
    CANCEL = 0,
    REMOVE
}

export const RequestCard = memo(({ req, handleRequests, handleGetIdToShow }: Props) => {

    //const userType = Number(localStorage.getItem('type')); 
    const { type } = useSelector((state: StoreApp) => state.notifications.notificationCount)

    const CardRequestInvestor = () => {
        return (
            <Card className='mt-4 card-content p-3' key={req.company_investor_request_id}>
                <Row className="fila">
                    <Col sm={12} lg={'auto'} className="d-flex align-items-center justify-content-center px-3">
                        <div className="card-content_image my-3">
                            <img 
                                src={req.company_logo ? req.company_logo : imgUser} 
                                alt={''} 
                                className="border-img"
                                onClick={ () => handleGetIdToShow(req.company_id) } />
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center px-2" >
                        <Row className="d-flex align-items-center justify-content-between px-2 col-12 alineacion-texto-mobile">

                            <Col sm={'auto'} className="d-flex flex-column justify-content-center px-2" >
                                <p className="overflow-visible mb-0">
                                    <span className="card-content_text card-content_modificador-txt"
                                        onClick={ () => handleGetIdToShow(req.company_id) } >
                                        {req.company_name}
                                    </span> {req.status} 
                                </p>
                                {/* <p>Reason: <b> {req.rejection_reason} </b></p> */}
                                { req.button_option === RequestOption.REMOVE && <p>Reason: <b> {req.rejection_reason} </b></p>}


                            </Col>
                            <Col sm={'auto'} className='d-flex align-items-center justify-content-center px-2'>
                                {/* <button
                                    type="button"
                                    className="btn btn-outline-primary estilos-boton modificador2"
                                    onClick={() => handleRequests(req.button_option, req.investor_id, req.company_investor_request_id, 1)}
                                >
                                    {req.button_caption}
                                </button> */}
                                <Button
                                    type="button"
                                    // variant="primary"
                                    className='estilos-boton my-3'
                                    variant={req.button_option === RequestOption.CANCEL ? 'outline-primary' : 'primary' }
                                    onClick={() => handleRequests(req.button_option, req.company_id, req.company_investor_request_id, 1,req.company_name)}>
                                    {req.button_caption}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        );
    }

    const CardRequestCompany = () => {
        return (
            <Card className='mt-4 card-content p-3' key={req.company_investor_request_id}>
                <Row className="fila">
                    <Col sm={12} lg={'auto'} className="d-flex align-items-center justify-content-center px-3">
                        <div className="card-content_image my-3">
                            <img 
                                src={req.investor_logo ? req.investor_logo : imgUser} 
                                alt={''} 
                                className="border-img" 
                                onClick={ () => handleGetIdToShow(req.investor_id) } />
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center px-2 " >
                        <Row className="d-flex align-items-center justify-content-between px-2 col-12 alineacion-texto-mobile">

                            <Col sm={'auto'} className="d-flex flex-column justify-content-center px-2" >
                                <p className="overflow-visible mb-0">
                                    <span className="card-content_text card-content_modificador-txt"
                                        onClick={ () => handleGetIdToShow(req.investor_id) } >
                                        {req.investor_name} - {req.investor_fund_name}
                                    </span> {req.status} 
                                </p>
                                {/* <p>Reason: <b> {req.rejection_reason} </b></p> */}
                                { req.button_option === RequestOption.REMOVE && <p>Reason: <b> {req.rejection_reason} </b></p>}


                            </Col>
                            <Col sm={'auto'} className='d-flex align-items-center justify-content-center px-2'>
                                {/* <button
                                    type="button"
                                    className="btn btn-outline-primary estilos-boton modificador2"
                                    onClick={() => handleRequests(req.button_option, req.investor_id, req.company_investor_request_id, 1)}
                                >
                                    {req.button_caption}
                                </button> */}
                                <Button
                                    type="button"
                                    // variant="primary"
                                    variant={req.button_option === RequestOption.CANCEL ? 'outline-primary' : 'primary' }
                                    className='estilos-boton my-3'
                                    onClick={() => handleRequests(req.button_option, req.investor_id, req.company_investor_request_id, 1, req.investor_fund_name)}>
                                    {req.button_caption}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        );
    }

    return (
        <>
            {Number(type) === TypeUser.INVESTOR ? <CardRequestInvestor /> : <CardRequestCompany />}
        </>
    )
})
