import React from "react";
import { Card } from "react-bootstrap";

interface Props {
  title: string;
  text: string;
}

export const CustomCardProfile = React.memo(({ title, text }: Props) => {
  return (
    <Card className="tarjetas-financials">
      <Card.Body>
        <Card.Title className="fw-normal mb-0" style={{ fontSize: "16px" }}>
          {title}
        </Card.Title>
        <Card.Text className="precio-financial">{text}</Card.Text>
      </Card.Body>
    </Card>
  );
});
