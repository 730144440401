import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import { fetchCatalogs, fetchUrl } from "../../helpers/fetch";
import { Result } from "../interfaces/common.model";
import {
  AboutUs,
  AboutUsRequest,
  FilledSections,
} from "../interfaces/company.model";

export const _getFilledSectionsCompany = async (
  email: string
): Promise<FilledSections[]> => {
  const res = await fetchCatalogs(
    `prod/company/aboutus/filled_sections?s_email=${email}`
  );
  return await res.json();
};

export const _createAboutUs = async (
  email: string,
  about: AboutUs
): Promise<{ msg: string; result: string; errorMessage?: string }> => {
  const aboutRequest: AboutUsRequest = {
    sUserName: email,
    sCompanyName: about.company_name,
    sWebsite: about.company_website,
    sDescription: about.company_description,
    sIndustries: about.industries.map((p) => p.value).join(),
    sbracketid: String(about.employees_bracket_id),
    ifoundingyear: String(about.founding_year),
    iState: String(about.state_id),
    sLinkedin: about.sLinkedin,
    companyLogo: about.company_logo,
    companyBanner: about.company_banner,
    iCity: about.iCity
  };

  if (!aboutRequest?.companyLogo?.includes("base64")) {
    delete aboutRequest.companyLogo;
  }
  if (!aboutRequest?.companyBanner?.includes("base64")) {
    delete aboutRequest?.companyBanner;
  }

  const fetch = await fetchCatalogs(
    "prod/company/aboutus",
    aboutRequest,
    "POST"
  );
  return await fetch.json();
};

export const _updateAboutUs = async (
  email: string,
  about: AboutUs
): Promise<{ msg: string; result: string; errorMessage?: string }> => {
  const aboutRequest: AboutUsRequest = {
    sUserName: email,
    sCompanyName: about.company_name,
    sWebsite: about.company_website,
    sDescription: about.company_description,
    sIndustries: about.industries.map((p) => p.value).join(),
    sbracketid: String(about.employees_bracket_id),
    ifoundingyear: String(about.founding_year),
    iState: String(about.state_id),
    sLinkedin: about.sLinkedin,
    companyLogo: about.company_logo,
    companyBanner: about.company_banner,
    iCity: about.iCity
  };

  if (!aboutRequest?.companyLogo?.includes("base64")) {
    delete aboutRequest.companyLogo;
  }
  if (!aboutRequest?.companyBanner?.includes("base64")) {
    delete aboutRequest?.companyBanner;
  }

  const fetch = await fetchCatalogs(
    "prod/company/aboutus",
    aboutRequest,
    "PUT"
  );
  return await fetch.json();
};

export const _submitCompany = async (email: string): Promise<Result> => {
  const req = {
    sUserName: email,
  };
  const fetch = await fetchCatalogs(
    "prod/company/actions/submit_request",
    req,
    "POST"
  );
  return await fetch.json();
};

export const _getUrlToUploadFile = async (id: string, extencion: string) => {
  const req = {
    sIdCompany: id,
    sType: extencion
  };
  const fetch = await fetchUrl(
    "prod/company/funding/uploaddeck",
    req,
    "POST"
  );
  return await fetch.json();
};


export const _getConfirUpload = async (id: string, filename: string) => {
  const req = {
    sIdCompany: id,
    sFileName: filename
  };
  const fetch = await fetchUrl(
    "prod/company/funding/confirmdeck",
    req,
    "POST"
  );
  return await fetch.json();
};

export const _uploadFileFundingCompany = async (file: any, url: string) => {

  const myheaders = new Headers();
  myheaders.append('Content-Type', file.type);

  var repstatus = false;

  const myfetch = await fetch(url, {
    method: 'PUT',
    headers: myheaders,
    body: file,
    redirect: 'follow'

  }).then(
    (response) => {
      //console.log('response',response);
      repstatus = response.status === 200
      //console.log('repstatus',repstatus)
    }
  ).then(
    success => {
      //console.log('success',success)
    }
  ).catch(
    error => {
      //console.log('error', error)
    }
  );

  return await repstatus;
};
