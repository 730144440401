//import { useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { getLeadership, getValueProposition } from '../../../redux/actions/companyAction';
import { getProfileView } from '../../../redux/actions/profileViewAction';
import imgUser from '../../../assets/images/user.jpg';
import { SearchCompanyInfo } from "./SearchCompanyInfo";
import { PublicProfileFinancials } from "./SearchCompanyFinancial";
import { SearchCompanyValueProposition } from "./SearchCompanyValueProposition";
import { SearchCompanyLeadership } from "./SearchCompanyLeadership";






export const SearchCompany = () => {




    return (
        <>

            <div className="_SearchCompanies">
                <div className="imgMainCover">
                    <img src={imgUser} alt="logo" className="imgRedondaMain" />
                    {/* <div className="contentDot">
                        <PencilDot isWebp={true} handleImgPencil={handleImgPencil} />
                    </div>
                    <div className="contentDot2">
                        <PencilDot isWebp={true} handleImgPencil={handleImgPencilBanner} />
                    </div> */}
                </div>


                <SearchCompanyInfo />
                <PublicProfileFinancials />
                <SearchCompanyValueProposition />
                <SearchCompanyLeadership />

                <div>
                    <p className='contenedor-titulo'>
                        <span className='titulo-seccion'>FUNDING</span> <hr />
                    </p>
                    <p className='parrafo-funding'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste nesciunt magnam nulla esse possimus reprehenderit ex repellendus minus repellat tempore nemo, at deserunt temporibus, fuga, facere ipsum similique illo incidunt.
                    </p>
                    <Row>
                        <Col md={4} sm={12}>

                            <Card className='tarjetas-financials' >
                                <Card.Body>
                                    <Card.Title>Funding Stage:</Card.Title>
                                    <Card.Text className='precio-financial'>
                                        Series B
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12}>
                            <Card className='tarjetas-financials' >
                                <Card.Body>
                                    <Card.Title>Projected Raise:</Card.Title>
                                    <Card.Text className='precio-financial'>
                                        $700M
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12}>
                            <Card className='tarjetas-financials'>
                                <Card.Body>
                                    <Card.Title>We Need Help here:</Card.Title>
                                    <Card.Text className='precio-financial'>
                                        Connections
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>



                </div>
                <div style={{ textAlign: 'center', margin: '25px 0' }}>
                    <button type="submit" className="btn btn-outline-primary">
                        Download Pitch Deck
                    </button>
                </div>

            </div>

        </>
    )
}
