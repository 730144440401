import React, { useRef, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import { MyTextInput } from "../../../@custom/components/form/MyTextInput";
import { MySelect } from "../../../@custom/components/form/MySelect";
import { MultiSelect } from "../../../@custom/components/form/Multiselect/MultiSelect";
import { Col, Row, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAboutUs,
  updateAboutUs,
} from "../../../redux/actions/companyAction";
import { fetchCatalogs } from "../../../helpers/fetch";

export const AboutUs = ({handleValidateForm}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const { investor: investorCat } = useSelector((state) => state.cats);
  const { aboutUs, filledSections } = useSelector((state) => state.company);
  const { loadedCats } = useSelector((state) => state.ui);
  const [ cities, setCities] = useState([]);
  const [ setAboutUsInfo, reSetAboutUsInfo  ] = useState(true)

  const arrayYears = [
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
  ].reverse();

  useEffect(() => {

    //Antes
    // if (loadedCats &&
    //   filledSections?.filled_sections >= 1 &&
    //   _.isEmpty(aboutUs)
    // )dispatch(getAboutUs());

    if (loadedCats && filledSections?.filled_sections >= 0 && _.isEmpty(aboutUs) ){
      dispatch(getAboutUs());
    }


    // if (filledSections.filled_sections === 6)
  }, [dispatch, filledSections, loadedCats, aboutUs]);

  const getCatCity = async (id) => {
    const citiesFetch = await fetchCatalogs(
      "prod/gralinfo/cities?state_id=" + id
    );
    const citiesResponse = await citiesFetch.json();
    setCities(Array.isArray(citiesResponse) ? citiesResponse : []);
  };

  useEffect(() => {
    if (_.isEmpty(aboutUs)) return;
    if (aboutUs?.state_id) getCatCity(aboutUs.state_id);

    //Solo actualizar las imagenes
    formikRef.current.setValues((v) => ({
      ...v,
      company_logo: aboutUs?.company_logo ?? "",
      company_banner: aboutUs?.company_banner ?? "",
    }));

    //console.log('setAboutUsInfo',setAboutUsInfo)

    //Solo actualizar los inpur la primera vez, esto para prevenir que se reinicien al cambiar imagen
    if(setAboutUsInfo){

      formikRef.current.setValues((v) => ({
        ...v,
        company_name: aboutUs?.company_name ?? "",
        company_website: aboutUs?.company_website ?? "",
        company_description: _.unescape(aboutUs?.company_description) ?? "",
        industries: aboutUs?.industries ?? "",
        employees_bracket_id: aboutUs?.employees_bracket_id ?? "",
        founding_year: aboutUs?.founding_year ?? "",
        country_id: aboutUs?.country_id ?? "",
        state_id: aboutUs?.state_id ?? "",
        sLinkedin: aboutUs?.sLinkedin ?? "",
        company_logo: aboutUs?.company_logo ?? "",
        company_banner: aboutUs?.company_banner ?? "",
        iCity: aboutUs?.iCity ?? "",
      }));

      reSetAboutUsInfo(false)
    }
    
  }, [aboutUs]);

  const handleSubmit = () => {
    formikRef.current.submitForm();
  };

  const handleBlurForm = () => {
      const validForm = formikRef.current.isValid;
      handleValidateForm('about',validForm);
  };

  return (
    <Card className={filledSections.filled_sections === 6? "_about-us disable-mode" : "_about-us"}>
      <Formik
        validate={(e) => {
            handleBlurForm()
        }}
        innerRef={formikRef}
        initialValues={{
          company_name: "",
          company_website: "",
          company_description: "",
          industries: "",
          employees_bracket_id: "",
          founding_year: "",
          country_id: "",
          state_id: "",
          iCity: "",
          sLinkedin: "",
          companyLogo: "",
          companyBanner: "",
        }}
        onSubmit={async (values) => {
          dispatch(updateAboutUs(values));
        }}
        validationSchema={Yup.object({
          company_name: Yup.string().required("Required"),
          company_website: Yup.string().required("Required"),
          company_description: Yup.string().required("Required"),
          industries: Yup.array().min(1, "Required").required("Required"),
          employees_bracket_id: Yup.string().required("Required"),
          founding_year: Yup.string().required("Required"),
          iCity: Yup.string().required("Required"),
          state_id: Yup.string().required("Required"),
          sLinkedin: Yup.string().required("Required"),
        })}
      >
        {(formik) => (
          <Form>
            <Row className="col-12 mx-0">
              <Col md={6} className="my-2">
                <MyTextInput
                  label="Company Name:"
                  name="company_name"
                  placeholder=""
                  errors={formik.errors}
                  // infoIcon={true}
                  disabled={filledSections.filled_sections === 6}
                />
              </Col>
              <Col md={6} className="my-2">
                <MyTextInput
                  label="Company Website:"
                  name="company_website"
                  placeholder=""
                  errors={formik.errors}
                  disabled={filledSections.filled_sections === 6}
                />
              </Col>
              <Col md={6} className="my-2">
                <MyTextInput
                  label="Linkedln Company Profile:"
                  name="sLinkedin"
                  placeholder=""
                  linkedIcon={true}
                  errors={formik.errors}
                  disabled={filledSections.filled_sections === 6}
                />
              </Col>
              <Col className="col-12 my-2">
                <MyTextInput
                  label="What We Do:"
                  textArea={true}
                  name="company_description"
                  placeholder="A brief intro to the company and what makes it special."
                  errors={formik.errors}
                  // infoIcon={true}
                  disabled={filledSections.filled_sections === 6}
                />
              </Col>
              <Row>
                <Col md={12} className="my-2 col-lg-6">
                  <MultiSelect
                    label="Industry:"
                    name="industries"
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    error={formik.errors.industries}
                    touched={formik.touched.industries}
                    value={formik.values.industries}
                    options={investorCat?.catIndustries}
                    disabled={filledSections.filled_sections === 6}
                  ></MultiSelect>
                </Col>
                <Col md={12} className="my-2 col-lg-3">
                  <MySelect
                    label="Number of Employees:"
                    name="employees_bracket_id"
                    errors={formik.errors}
                    disabled={filledSections.filled_sections === 6}
                  >
                    <option value="">Select...</option>
                    {investorCat?.catEmployees.map((c) => (
                      <option
                        value={c.employees_bracket_id}
                        key={c.employees_bracket_id}
                      >
                        {c.employees_bracket_description}
                      </option>
                    ))}
                  </MySelect>
                </Col>
                <Col md={12} className="my-2 col-lg-3">
                  {/* <MyTextInput
                                        label="Year Founded:"
                                        name="founding_year"
                                        placeholder=""
                                        errors={formik.errors}
                                        disabled={filledSections.filled_sections === 6}
                                    /> */}
                  <MySelect
                    label="Year Founded:"
                    name="founding_year"
                    errors={formik.errors}
                    disabled={filledSections.filled_sections === 6}
                  >
                    <option value="">Select...</option>
                    {arrayYears.map((y) => (
                      <option value={y} key={y}>
                        {y}
                      </option>
                    ))}
                  </MySelect>
                </Col>
                {/* <Col md={12} className="my-2 col-lg">
                                    <MySelect label="Country:" name="country_id" errors={formik.errors} disabled={filledSections.filled_sections === 6}>
                                        <option value="">Select...</option>
                                        {investorCat?.catCountries.map((c) => (
                                            <option value={c.country_id} key={c.country_id}>{c.country_name}</option>
                                        ))}
                                    </MySelect>
                                </Col> */}
                <Col md={12} className="my-2 col-lg">
                  <MySelect
                    label="State:"
                    name="state_id"
                    errors={formik.errors}
                    disabled={filledSections.filled_sections === 6}
                    onChange={(name, value) => {
                      formik.setFieldValue(name, value);
                      formik.setFieldValue('iCity', '');
                      getCatCity(value);
                    }}
                  >
                    <option value="">Select...</option>
                    {investorCat?.catStates.map((c) => (
                      <option value={c.state_id} key={c.state_id}>
                        {c.state_name}
                      </option>
                    ))}
                  </MySelect>
                </Col>

                <Col md={12} className="my-2 col-lg">
                  <MySelect
                    label="City:"
                    name="iCity"
                    errors={formik.errors}
                    disabled={filledSections.filled_sections === 6}
                  >
                    <option value="">Select...</option>
                    {cities.map((c) => (
                      <option value={c.city_id} key={c.city_id}>
                        {c.city_name}
                      </option>
                    ))}
                  </MySelect>
                </Col>
              </Row>
            </Row>
          </Form>
        )}
      </Formik>

      <Row>
        <Col lg={12} className="row mx-0 mt-3">
          <div className="d-block w-100 alineacionBtn">
            {/* <span className="textoRequerido">Please note all fields with * are required. </span> */}
            <Button
              type="submit"
              variant="primary"
              className="btn-save"
              disabled={filledSections.filled_sections === 6}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
