import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { getSavedCompanies } from '../../../redux/actions/investorAction';
import { CardSavedCompanies } from './CardSavedCompanies';
import { PublicProfileView } from '../../@components/Profile/PublicProfileView';


export const SavedCompaniesComponent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const { savedCompanies } = useSelector((store: StoreApp) => store.investor);
    const {  company_investor_id } = useSelector((store: StoreApp) => store.notifications.notificationCount);
    const [pagination, setPagination] = useState(0);
    const [active, setActive] = useState(company_investor_id);
    const [isProfileShow, setIsProfileShow ] = useState(false);

    const handleShowProfile = (param:string) => {
        navigate({pathname: '/investor-saved',search: param});
        setIsProfileShow(true)

        if(param !== ''){
            window.scrollTo(0, 0)
        }
    }

    const handleGetIdToShow = (id: number) => {
        if(!id) return false;
        handleShowProfile('?idCompany='+id)
    }

    const handleBack = () => {
        handleShowProfile('');
        setIsProfileShow(false);
    }

    useEffect(() => {
        if (company_investor_id) dispatch(getSavedCompanies(company_investor_id))
    }, [dispatch, company_investor_id]);

    useEffect(() => {
        if (savedCompanies.length > 0) {
            const pageNum = savedCompanies[0].pages_total;
            setPagination(pageNum);
        }
    }, [savedCompanies])

    const handleClickPagination = (page: string) => {
        setActive(page);
        dispatch(getSavedCompanies(company_investor_id, page));
    }

    const disabledPrev = (): boolean | undefined => {
        if (active === '0' || active === '1') return true;
        return false;
    }

    useEffect(() => {
        if(search === ''){
            handleBack()
        }
        
    },[search])

    return (
        isProfileShow ? 
        <> 
            <div className='mb-1'>
                <a className='btn-profile-back' onClick={handleBack}>
                    <b><i className="fa fa-chevron-left"></i> Back</b>
                </a>
            </div>
            {<PublicProfileView />}
        </>
        :
        <>

            <div className="_savedCompaniescomponente">
                <div className='contenedor-titulo  mt-2 mb-5 d-flex flex-nowrap align-items-center '>
                    <div className='titulo-seccion'>YOUR SAVED COMPANIES</div>
                    <div className="line"></div>
                </div>
                {
                    savedCompanies.map((company) => (
                        <CardSavedCompanies savedCompany={company} key={company.saved_company_id} handleGetIdToShow={handleGetIdToShow} />
                    ))
                }
            </div>

            {
                (pagination > 0) &&
                <Col sm={12} className="mt-4">
                    <Pagination className="justify-content-center">
                        <Pagination.Prev disabled={disabledPrev()} onClick={() => handleClickPagination(String(Number(active) - 1))} >Previous</Pagination.Prev>
                        {
                            Array.from(Array(pagination).keys()).map((k) => (
                                <Pagination.Item key={k} active={String(k + 1) === active} onClick={() => handleClickPagination(String(k + 1))}>
                                    {k + 1}
                                </Pagination.Item>
                            ))
                        }
                        <Pagination.Next disabled={Number(active) === pagination} onClick={() => handleClickPagination(String(Number(active) + 1))}>Next</Pagination.Next>
                    </Pagination>
                </Col>
            }
        </>

    )
}