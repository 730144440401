import React, { useRef, useImperativeHandle, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../../@custom/components/form/MyTextInput";
import { MySelect } from "../../../@custom/components/form/MySelect";
import userImg from "../../../assets/images/user.jpg";
import { PencilDot } from '../../../@custom/components/PencilDot/PencilDot';
import { MultiSelect } from '../../../@custom/components/form/Multiselect/MultiSelect';
import { Col, Row, Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { deleteTeammember, deleteTeammemberAction, saveNewTeammember, updatePhotoMember, updateTeammember } from "../../../redux/actions/investorAction";
import { customQuestion } from "../../../helpers/alerts";

export const TeamemberItem = React.forwardRef(({ member, handleActiveKey, handleValidateTeammembers }, ref) => {

    const dispatch = useDispatch();
    const formikRef = useRef();
    const { filledSections } = useSelector((state) => state.investor);
    const { email } = useSelector(state => state.ui);
    const { investor: cats } = useSelector(state => state.cats);
    const [ reloadFormTeamMember, setReloadFormTeamMember ] = useState(true);
    const [ positionName, setPositionName ] = useState("");

    useImperativeHandle(ref, () => ({
        formikRef,
        handleSubmit() {
            if (formikRef.current.dirty && formikRef.current.values.investor_team_member_id !== 0) {
                formikRef.current.handleSubmit();
            }
            if (formikRef.current.values.investor_team_member_id === 0) formikRef.current.handleSubmit();
        },

    }));

    const handleSubmitEachCard = () =>{
        if (formikRef.current.dirty && formikRef.current.values.investor_team_member_id !== 0) {
            formikRef.current.handleSubmit();
        }
        if (formikRef.current.values.investor_team_member_id === 0) formikRef.current.handleSubmit();
    }

    const handleImgPencil = (img) => {
        setReloadFormTeamMember(false);
        formikRef.current.setFieldValue('photo', img)
        dispatch(updatePhotoMember(member.investor_team_member_id, img))
    }

    const handleDelete = async () => {
        if (member.investor_team_member_id === 0) return dispatch(deleteTeammemberAction(member.investor_team_member_id));
        const result = await customQuestion('Are you sure you want to delete the team member?', 'Yes, delete');
        if (result?.isConfirmed){
            dispatch(deleteTeammember(member.investor_team_member_id, email))
            handleValidateTeammembers();
        };
    }

    useEffect(() => {
        const pname = getPositionName()? getPositionName().investor_position : '';
        setPositionName(pname)

        if(reloadFormTeamMember){
            formikRef?.current?.setValues((v) => ({
                ...v,
                investor_team_member_id: member.investor_team_member_id,
                photo: member.photo,
                member_name: member.member_name,
                investor_position_id: member.investor_position_id,
                expertise_highlights1_selection: member.expertise_highlights1_selection,
                expertise_highlights2_selection: member.expertise_highlights2_selection,
                investor_member_previous_exits: member.investor_member_previous_exits,
                investor_member_linkedin: member.investor_member_linkedin,
            }));
        }
      }, [member,reloadFormTeamMember]);

    const handleBlurForm = () => {
        setTimeout(() => {
            handleValidateTeammembers();
        }, 100)
      };

    useEffect(() => {    
        return () => {
            handleValidateTeammembers();
        }
      }, [])

    const getPositionName = () => {
        const posi = cats.catPositions.filter((pos) => ( Number(member.investor_position_id) === Number(pos.investor_position_id) ))
        if(posi.length>0)
            return posi[0]
    }

    return (
        <>
            {member &&
                <Accordion.Item
                    className="modifMargenBajo"
                    eventKey={member.investor_team_member_id}
                    key={member.investor_team_member_id}
                >

                    <Accordion.Header className="estilos-header" onClick={() => handleActiveKey(member.investor_team_member_id)}>
                        <div className="centrar-header">
                            <div className="contenedorImgCabecera">
                                <img src={member.photo || userImg} alt="logo" className="imgRedondaCabecera" />
                            </div>
                            <span className="textoCabecera">
                                {member.member_name} - {positionName}
                            </span>
                        </div>

                    </Accordion.Header>

                    <Accordion.Body>
                        <Formik
                            validate={() => { handleBlurForm() }}
                            innerRef={formikRef}
                            key={member.investor_team_member_id}
                            initialValues={
                                {
                                    investor_team_member_id: member.investor_team_member_id,
                                    photo: member.photo,
                                    member_name: member.member_name,
                                    investor_position_id: member.investor_position_id,
                                    expertise_highlights1_selection: member.expertise_highlights1_selection,
                                    expertise_highlights2_selection: member.expertise_highlights2_selection,
                                    investor_member_previous_exits: member.investor_member_previous_exits,
                                    investor_member_linkedin: member.investor_member_linkedin,
                                }}
                            onSubmit={async (values, { resetForm }) => {
                                // New
                                if (values.investor_team_member_id === 0) {
                                    dispatch(saveNewTeammember(values, email, resetForm));
                                }
                                // Update
                                if (values.investor_team_member_id !== 0) {
                                    dispatch(updateTeammember(values, email, resetForm));
                                }
                            }}
                            validationSchema={Yup.object({
                                member_name: Yup.string().required("Required"),
                                photo: Yup.string().required("Required photo"),
                                investor_position_id: Yup.string().required("Required"),
                                expertise_highlights1_selection: Yup.array()
                                    .min(1, 'Required')
                                    .required('Required'),
                                // expertise_highlights2_selection: Yup.array().min(1, 'Required').required('Required'),
                                investor_member_previous_exits: Yup.string().required("Required"),
                                investor_member_linkedin: Yup.string().required("Required"),
                            })}
                        >
                            {(formik) => (
                                <Form>
                                    <Row className={filledSections.filled_sections === 5? "disable-mode" : ""}>
                                        <Col lg={3} className="my-2">
                                            <div className="cajaImg my-2">
                                                <img src={member.photo || userImg} alt="logo" style={formik.errors?.photo && { border: '1px solid rgb(220, 53, 69)' }} name="photo" className="imgRedonda" />
                                                <div className="contentDot3">
                                                    <PencilDot isWebp={true} validation1={true} handleImgPencil={handleImgPencil} id={member.investor_team_member_id} />
                                                </div>
                                            </div>
                                            {
                                                formik.errors?.photo && (
                                                    <div className="w-100 text-center">
                                                        <small style={{ color: 'rgb(220, 53, 69)' }}>{'Required photo'} </small>
                                                    </div>
                                                )
                                            }

                                        </Col>

                                        <Col lg={9} className="my-2">
                                            <Row>
                                                <Col md={7} className="my-2">
                                                    <MyTextInput
                                                        label="Name:"
                                                        name="member_name"
                                                        placeholder="Put your name here"
                                                        errors={formik.errors}
                                                    />
                                                </Col>
                                                <Col md={5} className="my-2">
                                                    <MySelect label="Position:" name="investor_position_id" errors={formik.errors}>
                                                        <option value="">Select...</option>
                                                        {cats?.catPositions.map((p) => (
                                                            <option value={p.investor_position_id} key={p.investor_position_id}>
                                                                {p.investor_position}
                                                            </option>
                                                        ))}
                                                    </MySelect>
                                                </Col>
                                                <Col md={7} className="my-2">
                                                    <MultiSelect
                                                        label="Highlights of Expertise:"
                                                        name="expertise_highlights1_selection"
                                                        onChange={formik.setFieldValue}
                                                        onBlur={formik.setFieldTouched}
                                                        error={formik.errors.expertise_highlights1_selection}
                                                        touched={formik.touched.expertise_highlights1_selection}
                                                        value={formik.values.expertise_highlights1_selection}
                                                        options={cats?.catHighlights}
                                                        textTooltip="This helps founders understand the investor's strengths and what each brings to the table"
                                                        >

                                                    </MultiSelect>
                                                </Col>
                                                {/* <Col md={6} className="my-2">
                                                    <MultiSelect
                                                        label="Previus Exist:"
                                                        name="expertise_highlights2_selection"
                                                        onChange={formik.setFieldValue}
                                                        onBlur={formik.setFieldTouched}
                                                        error={formik.errors.expertise_highlights2_selection}
                                                        touched={formik.touched.expertise_highlights2_selection}
                                                        value={formik.values.expertise_highlights2_selection}
                                                        options={cats?.catHighlights2}>
                                                    </MultiSelect>
                                                </Col> */}

                                                <Col md={5} className="my-2">
                                                    <MySelect label="Previous Exits:" name="investor_member_previous_exits" errors={formik.errors} 
                                                    infoIcon1={true}
                                                    textTooltip="Have you had any succesful exits as a founder">
                                                        <option value="">Select...</option>
                                                        {cats?.catPreviousExits.map((p) => (
                                                            <option value={p.exit_id} key={p.exit_id}>{p.exits_number}</option>
                                                        ))}
                                                    </MySelect>
                                                </Col>
                                                <Col md={12} className="my-2">
                                                    <MyTextInput
                                                        label="Linkedln Profile:"
                                                        name="investor_member_linkedin"
                                                        placeholder="Put your linkedln profile here"
                                                        linkedIcon={true}
                                                        errors={formik.errors}
                                                    />
                                                </Col>
                                                <Col md={12} className='mt-3'>
                                                    <div
                                                        className="d-flex justify-content-end contenedor-botones-investor"
                                                    >
                                                        <button type="button" className="btn btn-outline-primary ms-3" 
                                                        disabled={filledSections.filled_sections === 5}
                                                        onClick={handleDelete}>
                                                            Delete Member
                                                        </button>
                                                            
                                                        <button type="button" className="btn btn-primary ms-3 btn-save2" 
                                                        onClick={handleSubmitEachCard}
                                                        disabled={filledSections.filled_sections === 5}>
                                                            Save Member
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Accordion.Body>
                </Accordion.Item >
            }
        </>
    )
})
