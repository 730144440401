import React, {useState} from 'react'
import { useEffect } from 'react';
import { Col, Row, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { getFeaturedInvestors, getKpis, getSavedInvestors, getMatchActivityHome } from '../../../redux/actions/homeAction';
import imgUser from '../../../assets/images/user.jpg';
// import { FeautredInvestorItem } from "./FeaturedInvestorItem";
import { ChatContacts } from "./ChatContacts"
import { ChatMessage } from "./ChatMessage"
import { ChatResponse } from "../../../@core/interfaces/chat.model";
import { TypeUser } from "../../../@core/interfaces/common.model";

interface Props {
  isConnected: boolean;
  members: ChatResponse[];
  // chatRows: React.ReactNode[];
  chatRows: ChatResponse[];
  chatActive: ChatResponse[];
  onPublicMessage: (message: string) => void;
  onPrivateMessage: (message: string, to: string) => void;
  // onConnect: () => void;
  onDisconnect: () => void;
  handleGetClickUser: (id: number) => void;
}

export const Chatclient = (props: Props) => {
  
  const [ chatMember, setchatMember] = useState();
  const [ autoClick, setAutoClick ] = useState(true);
  const [ contactsActive, setContactsActive ] = useState(true);
  const [ idActive, setIdActive ] = useState(-1)
  const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
  const userType = Number(notificationCount.type);

  const onHandleSelectContact = (member: ChatResponse, id: number) =>{
    const xmember: any = member;
    setchatMember(xmember);
    props.handleGetClickUser(id);
    setContactsActive(false)
  }

  if(props.chatActive.length>0 && autoClick){
    const xmember: any = props.chatActive[0];
    //console.log('xmember',xmember);
    setchatMember(xmember);
    const id = userType === TypeUser.COMPANY ? xmember?.investor_id : xmember?.company_id
    //const id = xmember?.investor_id > 0 ? xmember?.company_id: (xmember?.investor_id > 0 ? xmember?.investor_id : 0)
    props.handleGetClickUser(id);
    //setIdActive(id);
    setAutoClick(false)
  }

  const handleBack = () => {
    setContactsActive(true)
  }

  return (
    <>
    <div className='_chat'>
      <Row className='h-100'>
        <Col md={5} lg={4} className={`contacts-side ${contactsActive ? 'active' : '' } chat`}>
          <ChatContacts
            onHandleSelectContact={onHandleSelectContact}
            idActive={idActive}
            members={props.members}
          />
        </Col>
         <Col md={7} lg={8}  className="messages-side chat">
            <ChatMessage
              chatMember={chatMember}
              chatRows={props.chatRows}
              onPublicMessage={props.onPublicMessage}
              onPrivateMessage={props.onPrivateMessage} 
              handleBack={handleBack} />
          </Col>
        </Row>
    </div>
    </>
  )
}