import { MatchActivity } from '../../@core/interfaces/matchActivity.model'
import { matchActivityTypes } from '../types/matchActivityTypes'

const initialState: MatchActivity = {
  invitations: [],
  requests: [],
  progress: []
}

export const matchActivityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case matchActivityTypes.getMatchActivity:
      return {
        ...state,
        invitations: [...action.payload.invitations],
        requests: [...action.payload.requests],
        progress: [...action.payload.progress]
      }
    case matchActivityTypes.removeProgress:
      return {
        ...state,
        progress: state.progress.filter((m) => m.investor_id !== action.payload.investor_id)
      }
    case matchActivityTypes.removeRequestsSent:
      return {
        ...state,
        requests: state.requests.filter((m) => m.investor_id !== action.payload.investor_id)
      }
    case matchActivityTypes.cancelRequestsSent:
      return {
        ...state,
        requests: state.requests.filter((m) => m.investor_id !== action.payload.investor_id)
      }
    default:
      return state
  }
}
