import { fetchCatalogs } from "../../helpers/fetch";
import { types } from "../types/types";
import { ladedCats, setLoading } from "./uiAction";

export const loadCats = () => {
    return async (dispatch, getState) => {

        dispatch(setLoading(true));

        try {
            const catsJson = await Promise.all([
                fetchCatalogs("prod?catname=cat_investor_positions"),
                fetchCatalogs("prod?catname=cat_highlights1"),
                fetchCatalogs("prod?catname=cat_highlights2"),
                fetchCatalogs("prod?catname=cat_previous_exits"),
                fetchCatalogs('prod?catname=cat_investor_type'),
                fetchCatalogs('prod?catname=cat_countries'),
                fetchCatalogs('prod?catname=cat_states'),
                fetchCatalogs('prod?catname=cat_industries'),
                fetchCatalogs('prod?catname=cat_range_investment'),
                fetchCatalogs('prod?catname=cat_funding_stage'),
                fetchCatalogs('prod?catname=cat_employees_brackets'),
                fetchCatalogs('prod?catname=cat_revenue_brackets'),
                fetchCatalogs('prod?catname=cat_genders'),
                fetchCatalogs('prod?catname=cat_ethnicities'),
                fetchCatalogs('prod?catname=cat_company_assistance'),
                fetchCatalogs("prod?catname=cat_company_positions"),
            ]);
            const cats = await Promise.all(catsJson.map((r) => r.json()));

            // console.log('cats-----',cats);
            // console.log('----------------------------');

            const catHighlights = cats[1].map((ind) => ({
                value: ind['expertise_highlight1_id'],
                label: ind['expertise_highlight1'],
            }));

            const catHighlights2 = cats[2].map((ind) => ({
                value: ind['expertise_highlight2_id'],
                label: ind['expertise_highlight2'],
            }));

            const catIndustries = cats[7].map((ind) => ({
                value: ind['industry_id'],
                label: ind['industry_name'],
            }));

            const catsTeammembers = {
                catPositions: cats[0],
                catHighlights,
                catHighlights2,
                catPreviousExits: cats[3],
                catInvestorType: cats[4],
                catCountries: cats[5],
                catStates: cats[6],
                catIndustries,
                catRange: cats[8],
                catFunding: cats[9],
                catEmployees: cats[10],
                catRevenue: cats[11],
                catGenders: cats[12],
                catEthnicities: cats[13],
                catCompanyAssistance: cats[14],
                catPositionsCompany: cats[15],
            }

            dispatch(setCatsInvestor(catsTeammembers));
            // debugger
            dispatch(ladedCats()); 
            dispatch(setLoading(false));
            // dispatch(getAboutUs());

        } catch (error) {
            dispatch(setLoading(false));
            console.log(error);
        }


    }
}


export const setCatsInvestor = (cats) => ({
    type: types.setCatalogsInvestor,
    payload: {
        ...cats
    }
})