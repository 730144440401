import React, { useRef, useImperativeHandle, useEffect, useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../../@custom/components/form/MyTextInput";
import { MySelect } from "../../../@custom/components/form/MySelect";

import { customQuestion } from "../../../helpers/alerts";
import { Col, Row, Accordion, Button } from "react-bootstrap";
import { PencilDot } from "../../../@custom/components/PencilDot/PencilDot";
import userImg from "../../../assets/images/user.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLeadership,
  deleteLeadershipAction,
  saveLeadership,
  updateLeadership,
  updatePhotoLeadership,
} from "../../../redux/actions/companyAction";
import { MultiSelect } from "../../../@custom/components/form/Multiselect/MultiSelect";
import { toast } from "react-toastify";

const toastId = {
  error: "leadershipCompanyError",
  info: "leadershipCompanyInfo",
};

export const LeadershipTeamItem = React.memo(React.forwardRef(
  ({ leadership, handleActiveKey, handleValidateLeadership }, ref) => {
    const formikRef = useRef();
    const dispatch = useDispatch();
    const { investor: cats } = useSelector((state) => state.cats);
    const { filledSections } = useSelector((state) => state.company);
    const [ positionName, setPositionName ] = useState("");
    const [ reloadFormLeaderShip, setReloadFormLeaderShip ] = useState(true);

    useImperativeHandle(ref, () => ({
      formikRef,
      handleSubmit() {
        if (
          formikRef.current.dirty &&
          formikRef.current.values.iIdTeamMember !== 0
        ) {
          formikRef.current.handleSubmit();
        }
        if (formikRef.current.values.iIdTeamMember === 0) {
          formikRef.current.handleSubmit();
        }
        if (!formikRef.current.dirty && !toast.isActive(toastId.error))
          toast.info("Everything is updated", { toastId: toastId.info });
        if (!formikRef.current.isValid) {
          toast.dismiss(toastId.info);
          toast.error("invalid fields exist", { toastId: toastId.error });
        }
      },
    }));

    const handleImgPencil = (img) => {
      setReloadFormLeaderShip(false);
      formikRef.current.setFieldValue("photo", img);
      dispatch(updatePhotoLeadership(leadership.iIdTeamMember, img));
    };

    const handleDelete = async () => {
      if (leadership.iIdTeamMember === 0)
        return dispatch(deleteLeadershipAction(leadership.iIdTeamMember));
      const result = await customQuestion(
        "Are you sure you want to delete the leadership?",
        "Yes, delete"
      );
      if (result?.isConfirmed)
        dispatch(deleteLeadership(leadership.iIdTeamMember));
    };

    useEffect(() => {

      const pName = getPositionName()? getPositionName().company_position : '';
      setPositionName(pName);

      if(reloadFormLeaderShip){
        formikRef?.current?.setValues((v) => ({ 
          ...v, 
          iIdTeamMember: leadership.iIdTeamMember,
          sMemberName: leadership.sMemberName || "",
          photo: leadership.photo || "",
          iPosition: leadership.iPosition || "",
          iGender: leadership.iGender || "",
          iEthniciti: leadership.iEthniciti || "",
          sExpertice: leadership.sExpertice || "",
          sExits: leadership.sExits || "",
          sLinkedin: leadership.sLinkedin || "",
         }));
      }      
    }, [leadership,reloadFormLeaderShip]); 

    const handleBlurForm = () => {
      setTimeout(() => {
        handleValidateLeadership();
      }, 100)
    };

    useEffect(() => {    
      return () => {
        handleValidateLeadership();
      }
    }, [])

    const getPositionName = () => {
      return cats.catPositionsCompany.find((pos) => ( Number(leadership.iPosition) === Number(pos.company_position_id) ))
    }

    return (
      <>
        {leadership && (
          <Accordion.Item
            className="modifMargenBajo"
            eventKey={leadership.iIdTeamMember}
            key={leadership.iIdTeamMember}
          >
            <Accordion.Header
              className="estilos-header"
              onClick={() => handleActiveKey(leadership.iIdTeamMember)}
            >
              <div className="centrar-header">
                <div className="contenedorImgCabecera">
                  <img
                    src={leadership.photo || userImg}
                    alt="logo"
                    className="imgRedondaCabecera"
                  />
                </div>
                <span className="textoCabecera">
                  {leadership.sMemberName} - {leadership?.sPosition || positionName}
                </span>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <Formik
                innerRef={formikRef}
                key={leadership.iIdTeamMember}
                initialValues={{
                  iIdTeamMember: 0,
                  sMemberName: "",
                  photo: "",
                  iPosition: "",
                  iGender: "",
                  iEthniciti: "",
                  sExpertice: "",
                  sExits: "",
                  sLinkedin: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  // New
                  if (values.iIdTeamMember === 0) {
                    dispatch(saveLeadership(values, resetForm));
                  }
                  // Update
                  if (values.iIdTeamMember !== 0) {
                    dispatch(updateLeadership(values, resetForm));
                  }
                }}
                validationSchema={Yup.object({
                  photo: Yup.string().required("Required"),
                  sMemberName: Yup.string().required("Required"),
                  iPosition: Yup.string().required("Required"),
                  iGender: Yup.string().required("Required"),
                  iEthniciti: Yup.string().required("Required"),
                  sExpertice: Yup.array()
                    .min(1, "Required")
                    .required("Required"),
                  sExits: Yup.string().required("Required"),
                  sLinkedin: Yup.string().required("Required"),
                })}
                validate={() => {
                  handleBlurForm()
                  // console.log({ e });
                }}
              >
                {(formik) => (
                  <Form>
                    <div className={filledSections.filled_sections === 6? "disable-mode" : ""}>
                      <Row className="col-12 mx-0">
                        <Col lg={3} className="my-2">
                          <div className="cajaImg my-2">
                            <img
                              src={leadership.photo || userImg}
                              alt="logo"
                              name="photo"
                              className="imgRedonda"
                            />
                            <div className="contentDot3">
                              <PencilDot
                                isWebp={true}
                                validation1={true}
                                handleImgPencil={handleImgPencil}
                              />
                            </div>
                          </div>
                          {formik.errors?.photo && (
                            <div className="w-100 text-center">
                              <small style={{ color: "rgb(220, 53, 69)" }}>
                                {"Required photo"}{" "}
                              </small>
                            </div>
                          )}
                        </Col>

                        <Col lg={9} className="my-2">
                          <Row>
                            <Col md={6} className="my-2">
                              <MyTextInput
                                label="Name:"
                                name="sMemberName"
                                placeholder=""
                                errors={formik.errors}
                              />
                            </Col>
                            <Col md={6} className="my-2">
                              <MySelect
                                label="Position:"
                                name="iPosition"
                                errors={formik.errors}
                                infoIcon1={true}
                                textTooltip="Position that the member currently does in the company."
                              >
                                <option value="">Select...</option>
                                {cats?.catPositionsCompany .map((comp) => (
                                  <option
                                    value={comp.company_position_id}
                                    key={comp.company_position_id}
                                  >
                                    {comp.company_position}
                                  </option>
                                ))}
                              </MySelect>
                            </Col>
                            <Col md={6} className="my-2">
                              <MySelect
                                label="Gender:"
                                name="iGender"
                                errors={formik.errors}
                                infoIcon1={true}
                                textTooltip="Gender of the member."
                              >
                                <option value="">Select...</option>
                                {cats?.catGenders.map((inv) => (
                                  <option
                                    value={inv.gender_id}
                                    key={inv.gender_id}
                                  >
                                    {inv.gender_desc}
                                  </option>
                                ))}
                              </MySelect>
                            </Col>
                            <Col md={6} className="my-2">
                              <MySelect
                                label="Race / Ethnicity:"
                                name="iEthniciti"
                                errors={formik.errors}
                                infoIcon1={true}
                                textTooltip="Race/Ethnicity of the member."
                              >
                                <option value="">Select...</option>
                                {cats?.catEthnicities.map((inv) => (
                                  <option
                                    value={inv.ethnicity_id}
                                    key={inv.ethnicity_id}
                                  >
                                    {inv.ethnicity_desc}
                                  </option>
                                ))}
                              </MySelect>
                            </Col>

                            <Col md={6} className="my-2">
                              <MultiSelect
                                label="Highlights of Expertise:"
                                name="sExpertice"
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                error={formik.errors.sExpertice}
                                touched={formik.touched.sExpertice}
                                value={formik.values.sExpertice}
                                options={cats?.catHighlights}
                                infoIcon1={true}
                                textTooltip="Area of expertise of the member."
                              ></MultiSelect>
                            </Col>
                            <Col md={6} className="my-2">
                              <MySelect
                                label="Previous Exits:"
                                name="sExits"
                                errors={formik.errors}
                                infoIcon1={true}
                                textTooltip="How many exits has the person participated in."
                              >
                                <option value="">Select...</option>
                                {cats?.catPreviousExits.map((inv) => (
                                  <option value={inv.exit_id} key={inv.exit_id}>
                                    {inv.exits_number}
                                  </option>
                                ))}
                              </MySelect>
                            </Col>
                            <Col md={6} className="my-2">
                              <MyTextInput
                                label="Linkedln Profile:"
                                name="sLinkedin"
                                linkedIcon={true}
                                errors={formik.errors}
                              />
                            </Col>
                            <Col md={12} className="mt-3 contenedor-btns">
                              <button
                                type="button"
                                className="btn btn-outline-primary me-2"
                                disabled={filledSections.filled_sections === 6}
                                onClick={handleDelete}
                              >
                                Delete Member
                              </button>
                              <Button
                                type="submit"
                                variant="primary"
                                className="btn-save me-2"
                                disabled={filledSections.filled_sections === 6}
                                // onClick={handleSubmit}
                                // disabled={filledSections.filled_sections === 6}
                              >
                                Save Member
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </>
    );
  }
))
