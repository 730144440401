export const types = {

    setLoading: '[UI] set loading',
    loadedCats: '[UI] set loaded cats',
    setEmail: '[UI] set email',

    openSidebar: '[SIDEBAR] open',

    updateNotifications: '[NOTIFICATION] open/close',

    setInvestor: '[INVESTOR] Set Investor Gral info',
    saveInvestor: '[INVESTOR] Save',
    // updateInvestor: '[INVESTOR] Update',
    updateImgGralInfo: '[GRALINFO] Update image',
    updateBannerGralInfo: '[GRALINFO] Update banner',
    
    getCompanies: '[PORTFOLIO] get companies',
    setCompanies: '[PORTFOLIO] set companies',
    newCompany: '[PORTFOLIO] new company',
    updateCompany: '[PORTFOLIO] update company',
    deleteCompany: '[PORTFOLIO] delete company',
    updatePhotoCompany: '[PORTFOLIO] update photo company',
    
    getCatalogsInvestor: '[CATALOG] get catalogs',
    setCatalogsInvestor: '[CATALOG] set catalogs',
    
    setTeammembers: '[TEAM MEMBER] set team members',
    newMember: '[TEAM MEMBER] new team member',
    saveMember: '[TEAM MEMBER] save team member',
    saveUpdateMember: '[TEAM MEMBER] save update team member',
    updateMember: '[TEAM MEMBER] update team member',
    updatePutMember: '[TEAM MEMBER] update after PUT team member',
    updatePhotoMember: '[TEAM MEMBER] update photo team member',
    deleteMember: '[TEAM MEMBER] delete team member',

    getSavedCompanies: '[INVESTOR] get saved companies',
    getAboutInvestor: '[INVESTOR] get about',
    setIdInvestor: '[INVESTOR] set id',


    setUserInfo: '[SET USER INFO] set info user logged',
    setImgProfile: '[SET IMG NAVBAR] set image to navbar',

    setPreferences: '[INVESTMENT PREFERENCES] set',

    deleteSavedCompany: '[SAVED COMPANY] delete company',
    getFilledSections: '[FILLED SECTIONS] get',
    updateFilledSection: '[FILLED SECTIONS] update id',
    submitInvestor: '[COMPANY] submit',

    setValidForm: '[INVESTOR] set valid form'
}