import { Col, Row, Card, Button } from 'react-bootstrap';
import { FeaturedInvestors } from '../../../@core/interfaces/home.models';
import userImg from '../../../assets/images/user.jpg';
import { useNavigate } from 'react-router-dom';


interface Props {
    featured: FeaturedInvestors[];
    handleGetIdToShow?: (id: number) => void;
}

export const FeaturedCompanies = ({ featured, handleGetIdToShow }: Props) => {
    const navigate = useNavigate();

    return (
        <>
            {
                <>
                    <Row className="container-featured-investor">
                        {
                            featured.map((feat) => (
                                <Col md={6} sm={12} lg={4} key={feat.investor_id}>
                                    <div className="contenedor_carta_relativ" >
                                        <Card className='my-3'>
                                            <Card.Img variant="top" src={feat.company_logo ? feat.company_logo : userImg} className='my-3 border-img'
                                                onClick={ ()=> (handleGetIdToShow && handleGetIdToShow(feat.company_id)) } />
                                            <Card.Body className='pt-0'>
                                                <Card.Title onClick={ ()=> (handleGetIdToShow && handleGetIdToShow(feat.company_id)) }>
                                                    {feat.company_name}
                                                </Card.Title>
                                                <Card.Text></Card.Text>
                                                <p className="mb-1"><i className="fas fa-dollar-sign"></i> Annual Gross Revenue:
                                                    <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{feat.annual_gross_revenue}</b> </p>
                                                <p className="mb-1"><i className="fas fa-seedling"></i> Funding Stage: <b>{feat.funding_stage_desc}</b> </p>
                                                <p className="mb-1"><i className="fas fa-industry"></i> <b>{feat.industries}</b> </p>
                                                <p className="mb-1"><i className="fas fa-map-marker-alt"></i> <b>{feat.city_state}</b></p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            ))
                        }

                    </Row>

                    <div className="contenedor-btn mt-3">
                        <Button
                            type="submit"
                            variant="primary"
                            className="px-5"
                            onClick={() => navigate('/search')}

                        >
                            Find More
                        </Button>
                    </div>
                </>
            }
        </>
    )
}