import { createStore, combineReducers, applyMiddleware, compose, Store } from 'redux';
import thunk from 'redux-thunk';
import { StoreApp } from '../@core/interfaces/store';
import { catalogsReducer } from './reducers/catalogsReducer';
import { companyReducer } from './reducers/companyReducer';
import { homeReducer } from './reducers/homeReducer';
import { refreshReducer } from './reducers/refreshReducer';
import { investorReducer } from './reducers/investorReducer';
import { matchActivityReducer } from './reducers/matchActivityReducer';
import { notificationsReducer } from './reducers/notificationsReducer';
import { profileViewReducer } from './reducers/profileViewReducer';
import { searchReducer } from './reducers/searchReducer';
import { chatReducer } from './reducers/chatReducer';
import { uiReducer } from './reducers/uiReducer';


const composeEnhancers = (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    ui: uiReducer,
    notifications: notificationsReducer,
    home: homeReducer,
    refresh: refreshReducer,
    investor: investorReducer,
    company: companyReducer,
    cats: catalogsReducer,
    search: searchReducer,
    chat: chatReducer,
    profileView: profileViewReducer,
    matchActivity: matchActivityReducer,
});


export const store: Store<StoreApp> = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);