import { Accordion, Card, useAccordionButton,FormText } from "react-bootstrap";
import { ValueProposition } from "../../../@core/interfaces/company.model";
import _ from "lodash";

interface Props {
    proposition: ValueProposition;
}


const CustomToggle = ({ children, eventKey }: { children: any, eventKey: any }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div onClick={decoratedOnClick} className="d-flex align-items-center" style={{cursor: 'pointer' }}>
            <div className="d-flex justify-content-center align-items-center p-3 me-3" style={{ backgroundColor: 'rgba(56, 22, 176, 0.3)' }}>
                <i className="fas fa-chevron-down" style={{color: '#3816b0', fontSize: '18px' }}></i>
            </div>
            <span>{children}</span>
        </div>
    );
}


export const PublicProfileProposition = ({ proposition }: Props) => {
    return (<>
        {
            proposition &&
            <>
                <div>
                    <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                        <div className='titulo-seccion'>VALUE PROPOSITION</div>
                        <div className="line"></div>
                    </p>

                    <Accordion defaultActiveKey="0">
                        <Card className="border-bottom-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="0">The Problem We Solve</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {proposition.customer_problem?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="border-top-0 border-bottom-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="1">Products &#38; Services</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {proposition.products_services?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="border-top-0 border-bottom-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="2">Who We Help</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    {proposition.sHelp?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="border-top-0 border-bottom-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="3">Market Opportunity</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    {proposition.target_market?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="border-top-0 border-bottom-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="4">Business Model</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    {proposition.business_model?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="border-top-0 border-bottom-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="5">Sales &#38; Marketing Strategy</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    {proposition.sales_marketing_strategy?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="border-top-0 border-bottom-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="6">Competitors</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    {proposition.competitors?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="border-top-0">
                            <Card.Header className="p-0">
                                <CustomToggle eventKey="7">Competitive Advantage</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    {proposition.competitive_advantage?.split('\n').map((word: any) => {
                                        return <div>{_.unescape(word)}</div>;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </>
        }
    </>)
};
