import { StoreApp } from "../../@core/interfaces/store";
import { customErrorAlert } from "../../helpers/alerts";
import { setLoading } from "./uiAction";
import { toast } from "react-toastify";
import { _actionSaveCompany, _actionSavedInvestors, _getFeaturedInvestors, _getKpis, _getMatchActivityHome, _getSavedInvestors } from "../../@core/services/home.service";
import { FeaturedInvestors, Kpis, MatchActivityHome, SavedInvestors, TypeActionInvestor, TypeActionCompany } from "../../@core/interfaces/home.models";
import { getProfileViewService } from "../../@core/services/profileView.service";
import { _getInvestorAbout } from '../../@core/services/investor.service'
import { homeTypes } from "../types/homeTypes";
import { refreshTypes } from "../types/refreshTypes";
import { ProfileViewProfile } from "../../@core/interfaces/profileView.model";
import { profileViewTypes } from "../types/profileViewTypes";
import { types } from '../types/types'

const userType = Number(localStorage.getItem('type'));

export const getKpis = (showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));
    try {
      const { company_investor_id, type } = getState().notifications.notificationCount;
      //Antes se usaba el userType
      const kpis = await _getKpis(String(company_investor_id),String(type));
      if (kpis.length > 0) {
        dispatch(getKpisAction(kpis[0]));
      } else {
        if(showLoading){
          toast.info("No results found");
        }
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      if(showLoading){
        customErrorAlert(error, "Alert");
      }
      dispatch(setLoading(false));
    }
  };
};

const getKpisAction = (kpis: Kpis) => ({
  type: homeTypes.getKpis,
  payload: { kpis },
});


// Featured investors
export const getFeaturedInvestors = (showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));
    try {
      const { company_investor_id, type } = getState().notifications.notificationCount;
      const investors = await _getFeaturedInvestors(String(company_investor_id),type);
      if (investors.length > 0) {
        dispatch(getFeaturedInvestorsAction(investors));
      } else {
        if(showLoading){
          toast.info("No results found");
        }
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      if(showLoading){
        customErrorAlert(error, "Alert");
      }
      dispatch(setLoading(false));
    }
  };
};

const getFeaturedInvestorsAction = (investors: FeaturedInvestors[]) => ({
  type: homeTypes.getFeaturedInvestors,
  payload: { investors },
});


// Saved investors
export const getSavedInvestors = (page = '1', maxPage = '8', showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading)); 
    try {
      const { company_investor_id, type } = getState().notifications.notificationCount;
      const investors = await _getSavedInvestors(String(company_investor_id), type, page, maxPage);
      if (investors.length > 0) {
        dispatch(getSavedInvestorsAction(investors));
        dispatch(setSavedPageAction(page))
      } else {
        if(showLoading){
          toast.info("No results found");
        }
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      if(showLoading){
        customErrorAlert(error, "Alert");
      }
      dispatch(setLoading(false));
    }
  };
};

const getSavedInvestorsAction = (investors: SavedInvestors[]) => ({
  type: homeTypes.getSavedInvestors,
  payload: { investors },
});

const setSavedPageAction = (page: string) => ({
  type: refreshTypes.setSavePageActive,
  payload: { saved: {page: page} },
});


// Action Saved investors
export const actionSavedInvestors = (savedInvestorId: number, idCompany: number, idInvestor: number, type: TypeActionInvestor) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      const resp = await _actionSavedInvestors(String(idCompany), String(idInvestor), type);
      if (resp.result === 'ok') {
        if (type === 'sendreq') dispatch(sendRequestInvestorAction(savedInvestorId));
        if (type === 'unsave') dispatch(deleteSavedInvestorAction(savedInvestorId));

        const email = getState().ui.email
        const about = await _getInvestorAbout(email, idInvestor, idCompany) //pasar tercer parametro el company invetor _
        if (about.length > 0) dispatch(getInvestorAboutAction(about[0]))

      } else {
        toast.info("An unexpected error has occurred");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const sendRequestInvestorAction = (savedInvestorId: number) => ({
  type: homeTypes.sendRequestInvestor,
  payload: savedInvestorId,
});

const deleteSavedInvestorAction = (savedInvestorId: number) => ({
  type: homeTypes.deleteSavedInvestor,
  payload: savedInvestorId,
});

const getInvestorAboutAction = (about: any) => ({
  type: types.getAboutInvestor,
  payload: about
})

// Action Save, unsave company
export const actionSaveCompany = (user_id: string, company_id: string, type: TypeActionCompany, savedCompanyId = 0) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const resp = await _actionSaveCompany(user_id, company_id, type);
      if (resp.result === 'ok') {
        if (type === 'unsave') dispatch(deleteSavedCompanyAction(savedCompanyId));
        const profile = await getProfileViewService(String(company_id),String(user_id));
        if (profile.length > 0) {
          dispatch(getProfileAction(profile[0]));
        }

      } else {
        toast.info("An unexpected error has occurred");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const deleteSavedCompanyAction = (savedCompanyId: number) => ({
  type: types.deleteSavedCompany,
  payload: savedCompanyId,
});

const getProfileAction = (profile: ProfileViewProfile) => ({
  type: profileViewTypes.getProfile,
  payload: { profile },
});


export const getMatchActivityHome = (showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));
    try {
      const { company_investor_id, type } = getState().notifications.notificationCount;
      const resp = await _getMatchActivityHome(String(company_investor_id), type);
      //console.log('resp',resp);
      if (resp.length > 0) {
        dispatch(getMatchActivityHomeAction(resp));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      if(showLoading){
        customErrorAlert(error, "Alert");
      }
      dispatch(setLoading(false));
    }
  };
};

const getMatchActivityHomeAction = (matchActivity: MatchActivityHome[]) => ({
  type: homeTypes.getMatchActivityHome,
  payload: { matchActivity },
});
