import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import { TypeUser } from '../../@core/interfaces/common.model';
import { ReactComponent as LogoWhite} from '../../assets/images/FundLyst-icon.svg';
import { SearchSidebar } from './SearchSidebar';
import { StoreApp } from "../../@core/interfaces/store";
import { customAlert, customErrorAlert } from "../../helpers/alerts";

interface Menu {
    link: string;
    icon?: string;
    name: string;
    children?: MenuChildren[];
}

interface MenuChildren {
    name: string;
    path: string;
}
interface Props {
    handdleMenuBurguer?: () => void;
}

export const SidebarCustom = ({ handdleMenuBurguer }: Props) => {

    const dispatch = useDispatch();

    const { pathname } = useLocation();
    const [menu, setMenu] = useState<Menu[]>([])
    const [typeUser, setTypeUser] = useState(0)
    const [inSearch, setInSearch] = useState(false)

    const [collapseId, setCollapseId] = useState<number>(0);
    const [collapseSearch, setCollapseSearch] = useState<boolean>(false);
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
    const userType = Number(notificationCount.type);
    const user_acepted = Number(notificationCount.accepted_status) === 1;
    const { investor, company } = useSelector((state: StoreApp) => state);

    const acepted_path = ['/investor', '/company'];
    const filledSectionsCustom = userType === TypeUser.COMPANY ? company.filledSections : investor.filledSections;

    //console.log('filledSectionsCustom',filledSectionsCustom);

    const resetCollapse = (link: string) => {
        //mensaje completar perfil
        // 1 -> Company
        // 2 -> Investor
        if (!(acepted_path.indexOf(link) > -1) && !user_acepted) {
            if ((userType === TypeUser.COMPANY && filledSectionsCustom.filled_sections > 5) ||
                (userType === TypeUser.INVESTOR && filledSectionsCustom.filled_sections > 4))
                customAlert('', 'Thank you for applying to join FundLyst. We will review your application within 24 hours. If you have any specific questions, please contact support@fundlyst.net for assistance.');
            else
                customErrorAlert('Complete your profile to start the account validation process.', "Alert");
            // return
        }

        //console.log('collapseSearch',collapseSearch)

        setCollapseId(0);
        if (link === '/search') {

            // if(collapseSearch && !inSearch){
            //     handdleMenuBurguer && handdleMenuBurguer();
            // }

            setCollapseSearch(!collapseSearch);
            //setInSearch(true)
        } else {
            handdleMenuBurguer && handdleMenuBurguer();
            setCollapseSearch(true);
        }
    }

    const homeCom = [
        { link: user_acepted ? '/home' : '#', name: 'Home' }
    ]

    const menuListCompany: Menu[] = [
        // { link: '/investor-home', name: 'Home' },
        {
            link: '/company', icon: 'fas fa-user-circle', name: 'Profile', children: [
                { name: 'Edit Profile', path: '/company' },
                { name: 'Public Profile View', path: '/public-profile-company' },
            ]
        },
        // { link: '/investor', icon: 'fas fa-user-circle', name: 'Investor', children: [] },
        // { link: '/public-profile-view', icon: 'fas fa-user-circle', name: 'Public Profile View', children: [] },
        // { link: '/investor-company', icon: 'fas fa-building', name: 'Search Investors', children: [] },
        { link: user_acepted ? '/search' : '#', icon: 'fas fa-user-tie', name: 'Search Investors', children: [] },
        { link: user_acepted ? '/saved-investors' : '#', icon: 'fas fa-bookmark', name: 'Saved Investors', children: [] },
        { link: user_acepted ? '/match-activity' : '#', icon: 'fas fa-hands-helping', name: 'Match Activity', children: [] },
    ]

    const menuListCompanyNoAcepted: Menu[] = [
        {
            link: '/company', icon: 'fas fa-user-circle', name: 'Profile', children: [
                { name: 'Edit Profile', path: '/company' },
                { name: 'Public Profile View', path: '/public-profile-company' },
            ]
        }
    ]

    const menuListInvestor: Menu[] = [
        // { link: '/investor-home', name: 'Home' },
        {
            link: '/investor', icon: 'fas fa-user-circle', name: 'Profile', children: [
                { name: 'Edit Profile', path: '/investor' },
                { name: 'Public Profile View', path: '/public-profile-investor' },
            ]
        },
        // { link: '/search', icon: 'fas fa-search', name: 'Search Companies', children: [] },
        { link: user_acepted ? '/search' : '#', icon: 'fas fa-building', name: 'Search Companies', children: [] },
        { link: user_acepted ? '/investor-saved' : '#', icon: 'fas fa-bookmark', name: 'Saved Companies', children: [] },
        { link: user_acepted ? '/match-activity' : '#', icon: 'fas fa-hands-helping', name: 'Match Activity', children: [] },
    ];

    const menuListInvestorNoAcepted: Menu[] = [
        {
            link: '/investor', icon: 'fas fa-user-circle', name: 'Profile', children: [
                { name: 'Edit Profile', path: '/investor' },
                { name: 'Public Profile View', path: '/public-profile-investor' },
            ]
        }
    ];

    useEffect(() => {

        setCollapseSearch(pathname !== '/search');

        //const type = localStorage.getItem('type');
        const type = userType;
        setTypeUser(Number(type))

        if (Number(type) === TypeUser.INVESTOR) {
            //if(user_acepted){
            setMenu([...menuListInvestor])
            // }else{
            //     setMenu([...menuListInvestorNoAcepted])
            // }

        }
        if (Number(type) === TypeUser.COMPANY) {
            //if(user_acepted){
            setMenu([...menuListCompany])
            // }else{
            //     setMenu([...menuListCompanyNoAcepted])
            // }
        }

    }, [userType, user_acepted])


    const handleCollapse = (idx: number) => {
        if (idx === collapseId) return setCollapseId(0);
        setCollapseId(idx);
        setCollapseSearch(false);
    }

    const isProfile = pathname === '/investor' || pathname === '/public-profile-investor' || pathname === '/public-profile-company' || pathname === '/company';

    return (
        <div className='_sidebar px-2 px-xl-3'>
            <ul className='_sidebar__items-list'>
                {/* <li> */}
                {/* <NavLink to={item.link.home} className="_home">
                    <img className='logo-white' src={logoWhite} alt="Fundlyst" /> <span className='ms-2'>Home</span>
                    </NavLink> */}
                {
                    homeCom.map((item1) => (
                        <li key={item1.link} onClick={handdleMenuBurguer}>
                            <NavLink onClick={() => resetCollapse(item1.link)} to={item1.link} className={({ isActive }) => (isActive && user_acepted ? 'active-link logo-active' : 'deactivate-link logo-inactive')}>
                                <div className="icon-w">
                                    {/* <img className='logo-white' src={logoWhite} alt="Fundlyst" style={{ fill: 'black' }} /> */}
                                    <LogoWhite className='logo-white' />
                                
                                </div>
                                <span className='ms-2'>Home</span>
                            </NavLink>
                        </li>
                    ))
                }
                {
                    menu.map((item, index) => (
                        <React.Fragment key={item.link}>
                            {
                                (item?.children && item?.children?.length) ? (
                                    <>
                                        <li>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleCollapse(index + 1)}
                                                className={isProfile ? 'active-link' : ''}
                                            >
                                                <div className="icon-w">
                                                    <i className={item.icon}></i>
                                                </div>
                                                <span className='ms-2'>{item.name}</span>
                                            </div>
                                            {
                                                ((item?.children && item?.children?.length > 0 && collapseId === (index + 1) && item.link !== '/search') || isProfile) && (
                                                    <ul>
                                                        {
                                                            item?.children?.map((child) => (
                                                                <li className="p-0 child-item py-1" key={child.path}>
                                                                    <NavLink
                                                                        onClick={() => resetCollapse(item.link)}
                                                                        to={child.path} className={({ isActive }) => (isActive ? 'active-link' : 'deactivate-link')}>
                                                                        <i className="fas fa-circle me-2" style={{ fontSize: '5px', alignSelf: 'center' }}></i>
                                                                        <span>{child.name}</span>
                                                                    </NavLink>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                )
                                            }
                                        </li>
                                        {/* {
                                            (item.link === '/search' && pathname.includes('search') && collapseId === (index + 1)) && 
                                                <SearchSidebar key={item.name} />
                                        } */}
                                    </>
                                ) : (
                                    <>
                                        <li >
                                            <NavLink
                                                onClick={() => resetCollapse(item.link)}
                                                to={item.link}
                                                className={({ isActive }) => (isActive && user_acepted ? 'active-link' : 'deactivate-link')}
                                            >
                                                <div className="icon-w">
                                                    <i className={item.icon}></i>
                                                </div>
                                                <span className='ms-2'>{item.name}</span>
                                            </NavLink>
                                        </li>
                                        {
                                            // (item.link === '/search' && pathname.includes('search') && collapseSearch) && 
                                            //     <SearchSidebar key={item.name} userType={typeUser}/>

                                            (item.link === '/search' && pathname.includes('search')) &&
                                            <SearchSidebar key={item.name} userType={typeUser} collapseSearch={collapseSearch} handdleMenuBurguer={handdleMenuBurguer} />
                                        }
                                    </>
                                )
                            }
                        </React.Fragment>
                    ))
                }

                {/* {
                    menuListInvestor.map((item3) => (
                        <li key={item3.name}>
                            <NavLink to={item3.link} className={({ isActive }) => (isActive ? 'active-link' : 'deactivate-link')}>
                                <i className={item3.icon}></i> <span className='ms-2'>{item3.name}</span>
                            </NavLink>
                        </li>
                    ))
                } */}

            </ul>

        </div>
    )
}
