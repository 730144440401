import {
  AboutUs,
  Company,
  FilledSections,
  Financials,
  Funding,
  ValueProposition,
  ValidForm,
} from "../../@core/interfaces/company.model";
import { companyTypes } from "../types/companyTypes";

const initialState: Company = {
  idCompany: 0,
  aboutUs: {} as AboutUs,
  valueProposition: {} as ValueProposition,
  financials: {} as Financials,
  leadership: [],
  funding: {} as Funding,
  filledSections: {} as FilledSections,
  validForm: {
    about: false,
    financials: false,
    leadership: false,
    valueProposition: false,
    funding: false
  } as ValidForm,
};

export const companyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case companyTypes.getAboutUs:
      return {
        ...state,
        aboutUs: { ...action.payload.about },
      };
    case companyTypes.setAboutUs:
      return {
        ...state,
        aboutUs: {
          ...action.payload.about,
          industries: [...action.payload.industries],
        },
      };
    case companyTypes.updateCompanyLogo:
      return {
        ...state,
        aboutUs: {
          ...state.aboutUs,
          company_logo: action.payload,
        },
      };
    case companyTypes.updateCompanyBanner:
      return {
        ...state,
        aboutUs: {
          ...state.aboutUs,
          company_banner: action.payload,
        },
      };
    case companyTypes.setValueProposition:
      return {
        ...state,
        valueProposition: {
          ...action.payload,
        },
      };

    case companyTypes.getLeadership:
      return {
        ...state,
        leadership: [...action.payload.leadership],
      };
    case companyTypes.addLeadership:
      return {
        ...state,
        leadership: [...state.leadership, action.payload.leadership],
      };
    case companyTypes.setLeadership:
      return {
        ...state,
        leadership: state.leadership.map((m) =>
          m.iIdTeamMember === action.payload.idCurrent
            ? {
                ...action.payload.leadership,
                iIdTeamMember: Number(action.payload.idNew),
              }
            : m
        ),
      };
    case companyTypes.updatePhotoLeadership:
      return {
        ...state,
        leadership: state.leadership.map((m) =>
          m.iIdTeamMember === action.payload.id
            ? {
                ...m,
                photo: action.payload.photo,
              }
            : m
        ),
      };

    case companyTypes.deleteLeadership:
      return {
        ...state,
        leadership: state.leadership.filter(
          (m) => String(m.iIdTeamMember) !== String(action.payload.id)
        ),
      };

    case companyTypes.getFinancials:
      return {
        ...state,
        financials: {
          headers: [...action.payload.headers],
          values: [...action.payload.financials],
        },
      };

    case companyTypes.setFinancials:
      return {
        ...state,
        idCompany: action.payload.id,
        financials: {
          headers: [...action.payload.headers],
          values: [...action.payload.financials],
        },
      };

    case companyTypes.getFunding:
      return {
        ...state,
        funding: { ...action.payload.funding },
      };

    case companyTypes.setFunding:
      return {
        ...state,
        funding: { ...action.payload.funding },
      };

    case companyTypes.updateFileFunding:
      return {
        ...state,
        funding: {
          ...action.payload.funding,
          presentation_deck: action.payload.file,
        },
      };

    case companyTypes.getFilledSections:
      return {
        ...state,
        filledSections: { ...action.payload },
      };
    case companyTypes.updateFilledSection:
      return {
        ...state,
        filledSections: {
          ...state.filledSections,
          filled_sections: action.payload,
        },
      };
    case companyTypes.setIdCompany:
      return {
        ...state,
        idCompany: action.payload,
      };
    case companyTypes.submitCompany:
      return {
        ...state,
        filledSections: {
          ...state.filledSections,
          filled_sections: 6,
        },
      };

    case companyTypes.setValidForm:
      return {
        ...state,
        validForm: {
          ...state.validForm,
          [action.payload.component]: action.payload.isValid
        },
      };
    default:
      return state;
  }
};
