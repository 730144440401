import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-color.svg';
import { InputPassword } from '../../@custom/components/InputPassword/InputPassword';
import { customAlert } from '../../helpers/alerts';
import { useDispatch } from 'react-redux'
import { login } from '../../redux/actions/uiAction';

export const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState({
        pass: '',
        email: '',
        openModal: false,
        errors: {}
    });

    const passChange = (e) => { setState({ ...state, pass: e.target.value }) }
    const emailchange = (e) => { setState({ ...state, email: e.target.value }) }

    const handleBlur = (e) => {
        validators();
    }

    const validators = () => {
        let errors = {};

        // const regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        const regexEmail = /^(\w+[/./-]?){1,}@[a-zA-Z0-9]+[/.]\w{2,}$/;

        if (!state.email.trim()) {
            errors.email = 'The email not be empty';
        } else if (!regexEmail.test(state.email.trim())) {
            errors.email = 'The email is invalid';
        }
        if (!state.pass.trim()) {
            errors.pass = 'Password not be empty';
        }
        setState({ ...state, errors })
        return errors;
    }

    const signIn = async () => {
        const _errors = validators();
        if (Object.keys(_errors).length > 0) {
            return customAlert('Alert', 'Invalid field exist', 'error');
        }
        try {
            const user = await Auth.signIn({
                username: state.email,
                password: state.pass,
            });
            dispatch(login(state.email, navigate));
            // navigate('/home')
        } catch (error) {
            const message = error.message;
            if (message.includes('not confirmed')) {
                resendSignUn();
            } else {
                customAlert('Failed to login', message, 'error');
            }
        }
    }

    const resendSignUn = async () => {
        try {
            await Auth.resendSignUp(state.email)
            customAlert('Alert', 'You will receive a verification code to your email', 'success').then(() => {
                navigate(`/confirm-code?email=${state.email}`)
            })
        } catch (error) {
            customAlert('Failed to resendSignUp', error.message, 'error');
        }
    }

    // const handleCloseModal = () => {
    //     setState({
    //         ...state,
    //         openModal: false
    //     })
    // }

    // const openModal = () => {
    //     setState({
    //         ...state,
    //         openModal: true
    //     })
    // }
    return (
        <div className="_login" id="login">
            <div className="content">
                <div className="w-100 text-center">
                    <a href="https://fundlyst.net"><img src={logo} alt="logo" className="logo" /></a>
                </div>
                <h4>Sign in to your account</h4>

                <Form.Group className="mb-4">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="text"
                        value={state.email}
                        onChange={emailchange}
                        onBlur={handleBlur}
                        name="email"
                        placeholder="example@mycompany.com.mx" />
                </Form.Group>

                <InputPassword
                    textLabel="Password:"
                    pass={state.pass}
                    passChange={passChange}
                    blur={handleBlur}
                    errors={state.errors}
                >
                </InputPassword>

                {/* <p className="mb-0"><Link to={'/recover-password'}><span className="c-purple">Forgot your password? </span></Link></p> */}

                {/* <div className="btn-container">
                    <Button className=" my-2" variant="primary" onClick={signIn}>Sign In</Button>
                </div> 
                
                <p className="text-end"> <Link to={'/register'}><span className="c-purple">
                    Create an account
                </span></Link>
                </p> */}

                <div className='d-flex justify-content-center justify-content-md-start'>
                    <p className="mb-0"><Link to={'/recover-password'}><span className="c-purple">Forgot your password? </span></Link></p>
                </div>

                <div className='d-flex justify-content-center justify-content-md-end'>
                    <div className="btn-container w-auto">
                        <Button className=" my-2" variant="primary" onClick={signIn}>Sign In</Button>
                    </div> 
                </div>

                <div className='d-flex justify-content-center justify-content-md-end'>
                    <p> <Link to={'/register'}><span className="c-purple">
                        Create an account
                    </span></Link>
                    </p>
                </div>
            </div>
            {/* <button onClick={openModal}>v</button>
            <ModalUserBlocked modalIsOpen={state.openModal} onCloseModal={handleCloseModal}></ModalUserBlocked> */}
        </div>
    );
}

