import React, { useRef, useEffect, useCallback } from 'react'

import { Formik, Form, FormikProps, Field } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { MyTextInput } from '../../../@custom/components/form/MyTextInput';
import { MySelect } from '../../../@custom/components/form/MySelect';
import { Col, Row, Button } from 'react-bootstrap';
import { DropFile } from '../../../@custom/components/DropFile';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { getFunding, updateFileAction, updateFunding, requestUrlToUpload } from '../../../redux/actions/companyAction';
import { Funding } from '../../../@core/interfaces/company.model';
import CurrencyInput from "../../../@custom/components/CurrencyInput";
import { customAlert, customErrorAlert } from "../../../helpers/alerts";

interface Props {
    handleValidateForm: (name: string, e: boolean) => void
}

export const FundingComponent = ({handleValidateForm}: Props) => {

    const dispatch = useDispatch();

    const { investor: cats } = useSelector((store: StoreApp) => store.cats);
    const { funding, filledSections } = useSelector((store: StoreApp) => store.company);
    const { loadedCats } = useSelector((store: StoreApp) => store.ui);

    const formikRef = useRef<FormikProps<Funding>>();

    useEffect(() => {
        if (loadedCats && filledSections?.filled_sections >= 4 && _.isEmpty(funding))
            dispatch(getFunding());
    }, [dispatch, filledSections, loadedCats, funding]);

    useEffect(() => {
        if (_.isEmpty(funding)) return;
        formikRef.current?.setValues((v) => ({
            ...v,
            funding_purpose: _.unescape(funding?.funding_purpose) ?? '',
            funding_stage_id: funding?.funding_stage_id ?? 0,
            projected_raise: funding?.projected_raise ?? '',
            assistances: funding?.assistances ?? '',
            presentation_deck: funding?.presentation_deck ?? '',
            // presentation_deck: '',
        }));
    }, [funding]);

    const downloadFile = () => {
        let link = document.createElement("a");
        link.download = 'file';
        link.href = funding.presentation_deck;
        link.target = '_blank';
        link.click();
    }

    const handleFile = useCallback((b64, file, ext, inputFile=null) => {
        const valuesForm = formikRef.current?.values as Funding;
        const fileType = ext;

        const maxFileSizeMB = 35;
        // const formatFileOk = [
        //     'application/pdf', 
        //     'application/msword', 
        //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        //     'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        //     'application/vnd.ms-powerpoint'
        // ];

        const extOk = [
            'doc',
            'docx',
            'pptx',
            'ppt',
            'pdf'
        ]

        if(bytesToMegaBytes(file.size) > maxFileSizeMB || !extOk.includes(fileType) /*!formatFileOk.includes(fileType)*/ ){
            if(inputFile) {
                // console.log('inputFile',inputFile.target.value);
                inputFile.target.value=null;
            }
            customAlert('Warning', 'Something went wrong, your deck file can’t be uploaded, please check your file size and format or please contact FundLyst support.', 'warning');
            return false;
        }

        dispatch(updateFileAction(b64, valuesForm))
        dispatch(requestUrlToUpload(file, ext))
        // console.log('file',file);
        // console.log('bytesToMegaBytes',bytesToMegaBytes(file.size));

    }, [])
    
    const bytesToMegaBytes = (bytes: any) => bytes / (1024 ** 2);

    const handleSubmit = () => {
        if(formikRef.current?.errors?.presentation_deck){
            customErrorAlert('Deck file is required.', 'Alert');
        }else if(formikRef.current?.dirty){
            formikRef.current?.submitForm();
        }
        //if (formikRef.current?.dirty) formikRef.current?.submitForm();
    };

    const handleBlurFormValidate = () => {
        const validForm = formikRef?.current?.isValid;
        handleValidateForm('funding', validForm ? true : false);
    };

    return (
        <div className={filledSections.filled_sections === 6? "_funding disable-mode" : "_funding"}>
            <Formik
                validate={() => {
                    handleBlurFormValidate();
                }}
                // @ts-ignore: next-line
                innerRef={formikRef}
                initialValues={{
                    funding_purpose: '',
                    funding_stage_id: 0,
                    projected_raise: 0,
                    assistances: '',
                    presentation_deck: '',
                }}
                onSubmit={(values, { resetForm }) => {
                    const prePR = values.projected_raise.toString().replace(/[^0-9.-]+/g, "");
                    values.projected_raise = Number(prePR);

                    if(values.projected_raise>0){
                        dispatch(updateFunding(values, resetForm));
                    }
                }}
                validationSchema={Yup.object({
                    funding_purpose: Yup.string()
                        .required('Required'),
                    funding_stage_id: Yup.number()
                        .required('Required'),
                    // projected_raise: Yup.number().required('Required'),
                    projected_raise: Yup.string().required('Required'),
                    assistances: Yup.string()
                        .required("Required"),
                    presentation_deck: Yup.string()
                        .required("Required")
                })
                }>
                {(formik) => (
                    <Form>
                        <Row className='col-12 mx-0'>
                            <Col className='col-12 my-2'>
                                <MyTextInput
                                    textArea={true}
                                    label="Funding Purpose:"
                                    name="funding_purpose"
                                    placeholder="Purpose for the expected income."
                                    errors={formik.errors}
                                    disabled={filledSections.filled_sections === 6}
                                />
                            </Col>
                            <Col md={12} lg={6} className='my-2' >
                                <MySelect label="Funding Stage:" placeholder="In which stage of investment is currently on" name="funding_stage_id" errors={formik.errors} disabled={filledSections.filled_sections === 6}>
                                    <option value="">Select...</option>
                                    {cats?.catFunding.map((c) => (
                                        <option value={c.funding_stage_id} key={c.funding_stage_id}>{c.funding_stage_desc}</option>
                                    ))}

                                </MySelect>
                            </Col>
                            <Col md={12} lg={6} className='my-2'>
                                {/* <MyTextInput
                                    label="Projected Raise:"
                                    name="projected_raise"
                                    placeholder="Raise that is being expected after being invested."
                                    errors={formik.errors}
                                    disabled={filledSections.filled_sections === 6}
                                /> */}

                                <Field name="projected_raise">
                                    {({ field } : {field: any}) => (
                                        <CurrencyInput
                                        {...field}
                                        errors={formik.errors}
                                        placeholder="Raise that is being expected after being invested."
                                        label="Projected Raise:"
                                        type="text"
                                        disabled={filledSections.filled_sections === 6}
                                        />
                                    )}
                                </Field>
                            </Col>
                            <Col md={12} lg={6} className='my-2'>
                                <MySelect label="We Need Help Here" name="assistances" errors={formik.errors} disabled={filledSections.filled_sections === 6} >
                                    <option value="">Select...</option>
                                    {cats?.catCompanyAssistance.map((c) => (
                                        <option value={c.assistance_id} key={c.assistance_id}>{c.assistance_desc}</option>
                                    ))}
                                </MySelect>
                            </Col>
                            {
                                (funding?.presentation_deck && !funding?.presentation_deck?.includes('base64')) && (
                                    <div className='d-flex mt-4'>
                                        <div className='d-flex align-items-center flex-column' style={{ cursor: 'pointer' }} onClick={downloadFile}>
                                            <i className="fas fa-file-download" style={{ fontSize: '50px', color: '#3816b0' }}></i>
                                            <span className='mt-1' style={{ color: '#3816b0', fontSize: '13px' }}><b>Corporation Deck</b></span>
                                        </div>
                                    </div>

                                )
                            }
                            {
                                (funding?.presentation_deck && funding?.presentation_deck?.includes('base64')) && (
                                    <div className='d-flex mt-4'>
                                        <div className='d-flex align-items-center flex-column'>
                                            <i className="far fa-file-pdf" style={{ fontSize: '50px', color: '#229954' }}></i>
                                            <span className='mt-1' style={{ color: '#229954', fontSize: '13px' }}>
                                                <b>Corporation Deck</b>
                                                <i className="fas fa-check-circle ms-1" style={{ color: '#229954', fontSize: '15px' }}></i>
                                            </span>
                                        </div>
                                    </div>

                                )
                            }
                        </Row>
                    </Form>
                )}
            </Formik>

            {
                filledSections.filled_sections < 6 &&
                <DropFile handleFile={handleFile} />
            }

            <Row>
                <Col lg={12} className="row mx-0 mt-3">
                    <div className="d-block w-100 alinear_boton mb-3">
                        {/* <span className="textoRequerido">Please note all fields with * are required. </span> */}
                        <Button type="submit" variant="primary" className="btn-save" disabled={filledSections.filled_sections === 6} onClick={handleSubmit}>Save</Button>
                    </div>

                </Col>
            </Row>

        </div>
    )
}
