import { Col, Row, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SavedInvestors } from '../../../@core/interfaces/home.models';
import userImg from '../../../assets/images/user.jpg';

interface Props {
    savedInvestors: SavedInvestors[];
    handleGetIdToShow?: (id: number) => void;
}

export const SavedCompaniesHome = ({ savedInvestors, handleGetIdToShow }: Props) => {
    const navigate = useNavigate();
    return (
        <>
            {
                <>
                    <Row className="container-featured-investor">
                        {
                            savedInvestors.map((inv) => (
                                <Col md={6} sm={12} lg={4} key={inv.saved_investor_id}>
                                    <div className="contenedor_carta_relativ" >
                                        <Card className='my-3'>
                                            <Card.Img variant="top" src={inv.company_logo ? inv.company_logo : userImg} className='my-3 border-img'
                                                onClick={ ()=> (handleGetIdToShow && handleGetIdToShow(inv.company_id)) } />
                                            <Card.Body className='pt-0'>
                                                <Card.Title onClick={ ()=> (handleGetIdToShow && handleGetIdToShow(inv.company_id)) }>
                                                    {inv.company_name}
                                                </Card.Title>
                                                <p className="mb-1"><i className="fas fa-dollar-sign"></i>Annual Gross Revenue:
                                                    <br />&nbsp;&nbsp;&nbsp;&nbsp;<b>{inv.annual_gross_revenue}</b> </p>
                                                <p className="mb-1"><i className="fas fa-seedling"></i>Funding Stage: <b>{inv.funding_stage}</b> </p>
                                                <p className="mb-1"><i className="fas fa-industry"></i> <b>{inv.industries}</b> </p>
                                                <p className="mb-1"><i className="fas fa-map-marker-alt"></i> <b>{inv.city_state}</b></p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>

                    <div className="contenedor-btn mt-3">
                        <Button
                            type="submit"
                            variant="primary"
                            className="px-5"
                            onClick={() => navigate('/investor-saved')}
                        >
                            View All
                        </Button>
                    </div>
                </>
            }
        </>
    )
}