import { Button, Col, Row } from "react-bootstrap";
import { ProfileViewProfile } from "../../../@core/interfaces/profileView.model";



export const SearchCompanyInfo = () => {
    return (

        <>
            <div className="investor-company-info">
                <Row>
                    <Col md={8} sm={12}>
                        <p className='primer-parrafo'>
                            <span>MoonPay </span> <i className="fab fa-linkedin"></i> <i className="fas fa-globe"></i> <span> www.moonpay.com </span> <i className="fas fa-map-marker"></i> <span> Miami, FL</span>
                        </p>
                        <p className='espaciado-entre-palabras'>
                            <i className="fas fa-users"></i><span> Employees: <b>101-250</b> <i className="fas fa-calendar-alt"></i> Founded in: <b>2019</b></span>
                        </p>
                        <p className='primer-parrafo'>
                            <i className="fas fa-industry"></i> <span> Fintech, Financial Services, Other.</span>
                        </p>

                    </Col>

                    <Col md={4} sm={12} className='contenedor-botones'>
                        <button type="submit" className="btn btn-outline-primary estilos-boton" >Save Company</button>
                        <Button type="submit" variant="primary" className='estilos-boton'>Contact Company</Button>

                    </Col>
                </Row>
                <div>
                    <p className='segundo-parrafo my-3'>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint adipisci fugit dignissimos dolores veniam dolor laudantium, quo voluptatibus ipsam molestias, cupiditate modi est? Dicta quo voluptas, consequatur distinctio facilis magni!
                    </p>
                </div>
            </div>

        </>

    )
};
