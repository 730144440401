import { useEffect } from 'react';
import { Col, Row, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { getFeaturedInvestors, getKpis, getSavedInvestors, getMatchActivityHome } from '../../../redux/actions/homeAction';
import imgUser from '../../../assets/images/user.jpg';
import { FeautredInvestorItem } from "./FeaturedInvestorItem";
import { SavedInvestor } from "./SavedInvestor";
import { MatchActivityCompany } from './MatchActivityCompany'


interface Props {
    handleGetIdToShow: (id: number)=> void;
}


export const HomeCompany = ({handleGetIdToShow}: Props) => {

    const dispatch = useDispatch();
    const { kpis, matchActivity, featuredInvestors, savedInvestors } = useSelector((store: StoreApp) => store.home);

    useEffect(() => {
        dispatch(getKpis())
        dispatch(getFeaturedInvestors())
        dispatch(getMatchActivityHome())
        dispatch(getSavedInvestors('1','3'))
    }, [dispatch]);

    return (<>
        <div className="_home">

            <div className="contenedor-top-cards row company">
                <Col md={6} sm={12} lg={4}>
                    <Card className='tarjeta color1 mb-3'>
                        <Card.Body>
                            <Card.Text>
                                Investor Invitations:
                            </Card.Text>
                            <Row className='contenedor-txt-bottom'>
                                <Col className="fw-bolder">
                                    {kpis.investor_invitations}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} sm={12} lg={4}>
                    <Card className='tarjeta color2 mb-3'>
                        <Card.Body>
                            <Card.Text>
                                Requests Sent:
                            </Card.Text>
                            <Row className='contenedor-txt-bottom'>
                                <Col className="fw-bolder">
                                    {kpis.requests_sent}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} sm={12} lg={4}>
                    <Card className='tarjeta color3 mb-3'>
                        <Card.Body>
                            <Card.Text>
                                In Progress:
                            </Card.Text>
                            <Row className='contenedor-txt-bottom'>
                                <Col className="fw-bolder">
                                    {kpis.matches_in_progress}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <div className="contenedor-match-activity">
                <div className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>MATCH ACTIVITY</div>
                    <div className="line"></div>

                </div>
                <MatchActivityCompany matchActivity={matchActivity} handleGetIdToShow={handleGetIdToShow} />
            </div>

            <div className="contenedor_middle_featured">
                <p className='contenedor-titulo d-flex flex-nowrap align-items-center '>
                    <div className='titulo-seccion'>FEATURED INVESTORS</div>
                    <div className="line"></div>

                </p>
                <FeautredInvestorItem investors={featuredInvestors} handleGetIdToShow={handleGetIdToShow} />
            </div>
            <div className="contenedor_bottom_saved">
                <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>SAVED INVESTORS</div>
                    <div className="line"></div>

                </p>
                <SavedInvestor investors={savedInvestors} handleGetIdToShow={handleGetIdToShow} />

            </div>
        </div>
    </>
    )
}

