import { Card, Row, Col, Button } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { SavedInvestors } from "../../../@core/interfaces/home.models";
import imgUser from '../../../assets/images/user.jpg';
import { actionSavedInvestors } from "../../../redux/actions/homeAction";
import { useEffect, useState } from 'react';

interface Props {
    investors: SavedInvestors[];
    avalible: Boolean;
    handleGetIdToShow: (id: number) => void;
}

export const CardSavedInvestors = ({ investors, avalible, handleGetIdToShow }: Props) => {

    const dispatch = useDispatch();
    const [investors_data, set_investorsdata] = useState<SavedInvestors[]>([])

    const handleSendRequest = (savedInvestorId: number, companyId: number, investorId: number) => {
        dispatch(actionSavedInvestors(savedInvestorId, companyId, investorId, 'sendreq'))
    }

    const handleUnsave = (savedInvestorId: number, companyId: number, investorId: number) => {
        dispatch(actionSavedInvestors(savedInvestorId, companyId, investorId, 'unsave'))
    }

    useEffect(() => {
        const new_investors_av = investors.filter((investor) => investor.Available === 1)
        const new_investors_na = investors.filter((investor) => investor.Available === 0)
        if (avalible) {
            set_investorsdata(new_investors_av)
        }
        else {
            set_investorsdata(new_investors_na)
        }
    }, [investors, avalible])

    return (
        <>
            {
                investors_data.length > 0 &&
                <>
                    {
                        investors_data.map((inv) => (
                            <Card className='mt-4 card-content rounded-3 p-3' style={{ backgroundColor: inv.Available === 0 ? '#F0F1F2' : '#fff' }} key={inv.saved_investor_id}>
                                <Row className="justify-content-between flex-wrap col-12" style={{ margin: '0 auto'}}>

                                    <Col sm={'auto'} className="d-flex align-items-center justify-content-center px-3">
                                        <div className="card-content_image my-3">
                                            <img 
                                                src={inv?.investor_logo ? inv?.investor_logo : imgUser} 
                                                alt={inv.investor_name} 
                                                className="border-img" 
                                                onClick={() => handleGetIdToShow(inv.investor_id) } />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="card-content_text">
                                            <h3 className="card-tittle"
                                                onClick={() => handleGetIdToShow(inv.investor_id) }
                                            >
                                                {inv.investor_name} - {inv.investor_fund_name}
                                            </h3>
                                            {/* <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore explicabo culpa amet facilis distinctio velit expedita asperiore.</h5> */}
                                            <h5>{inv.investment_philosophy}</h5>
                                            <p className="mb-1"> <i className="fas fa-dollar-sign"></i>Range of investment:<b> {inv.range_of_investment}</b></p>
                                            <b><p className="mb-1"> <i className="fas fa-user"></i>{inv.investor_type_name}</p>
                                            <p className="mb-1"> <i className="fas fa-industry"></i>{inv.industries}</p>
                                            <p className="mb-1"> <i className="fas fa-map-marker-alt"></i>{inv.city_state}</p>
                                            </b>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} className='contenedor-botones my-3'>
                                        <div className="me-3 quitar-margin-mobile" style={{ backgroundColor: 'white', display: 'inline-block', borderRadius: '0.25rem'}}>

                                            <button
                                                type="submit"
                                                onClick={() => handleUnsave(inv.saved_investor_id, inv.company_id, inv.investor_id)}
                                                className="btn btn-outline-primary estilos-boton my-1">
                                                Remove
                                            </button>
                                        </div>

                                        {
                                            inv.Available === 1 &&
                                            <Button
                                                type="button"
                                                variant="primary"
                                                disabled={inv.send_request_button_enabled === 1 ? true : false}
                                                onClick={() => handleSendRequest(inv.saved_investor_id, inv.company_id, inv.investor_id)}
                                                className='estilos-boton my-1'
                                            >
                                                {inv.send_request_button_caption}
                                            </Button>
                                        }

                                        {
                                            inv.Available === 0 &&
                                            <Button
                                                type="button"
                                                variant="primary"
                                                className='my-1'


                                            >
                                                You're Not There Yet
                                            </Button>
                                        }

                                    </Col>
                                </Row>

                            </Card>
                        ))
                    }
                </>
            }
        </>
    )
}
