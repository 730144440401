import { Search, SearchState } from "../../@core/interfaces/search.model";
import { searchTypes } from "../types/searchTypes";

const initialState: SearchState = {
  search: false,
  resetPager:false,
  searchResponse: [],
  contactable: [],
  noContactable: [],
  searchVariables: {} as Search,
};

export const searchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case searchTypes.getSearch:
      return {
        ...state,
        search: true,
        searchResponse: [...action.payload.search],
      };
    // case searchTypes.searchNoContactable:
    //   return {
    //     ...state,
    //     noContactable: [...action.payload.search],
    //   };
    case searchTypes.searchContactable:
      return {
        ...state,
        contactable: [...action.payload.available],
        noContactable: [...action.payload.noAvailable],
      };
    case searchTypes.resetSearch:
      return {
        ...state,
        search: false,
        searchResponse: [],
      };
    case searchTypes.resetPager:
      return {
        ...state,
        resetPager: action.payload
      };
    case searchTypes.setSearch:
      return {
        ...state,
        searchVariables: { ...action.payload.search },
      };
    default:
      return state;
  }
};
