import React, { useState, useEffect, useCallback, useRef } from 'react'
import imgUser from '../../../assets/images/user.jpg';

export const CardChatContact = ({member}: any) => {
    return <>
        <div className="d-flex bd-highlight">
            <div className="img_cont">
                <div className="contenedor-img">
                    <img src={(member.investor_logo ? member.investor_logo : member.company_logo) || imgUser } />
                </div>
                {/* <span className="online_icon"></span> */}
            </div>
            <div className="user_info">
                <span>{(member.investor_fund_name ? member.investor_fund_name : member.company_name) || imgUser }</span>
            </div>
        </div>
    </>

}