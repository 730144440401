export const Footer = () => {
  return (
    <>
      {/* <footer className='border mt-3 px-5 d-flex justify-content-center py-3' style={{ backgroundColor: '#e9e9e9', position: "absolute", bottom: "0", width: "100%" }}> */}
      <footer className='_footer border w-100 position-absolute' style={{ backgroundColor: '#e9e9e9', bottom: "0", zIndex: "99" }}>
        <div className="container-footer">
            <a href='https://fundlyst.net/terms-of-services/' target="_blank" style={{ color: '#afafaf', margin: '0 10px' }}>Terms of service</a>
            <a href='https://fundlyst.net/privacy-policy-2/' target="_blank" style={{ color: '#afafaf', margin: '0 10px' }}>Privacy Policy</a>
            <p style={{ color: '#afafaf', margin: '0 10px' }}>
              FundLyst All Rights Reserved <span>&#xAE;</span> 
              <span style={{ color: '#afafaf', fontSize:'10px' }}> v1.1</span>
            </p>
        </div>
      </footer>
    </>
  )
}
