import { Card, Row, Col, Button } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from "../../../@core/interfaces/store";
import { SavedCompanies } from "../../../@core/interfaces/investor.model";
import imgUser from '../../../assets/images/user.jpg';
import { actionSaveCompany } from "../../../redux/actions/homeAction";

interface Props {
    savedCompany: SavedCompanies;
    handleGetIdToShow: (id: number) => void;
}

export const CardSavedCompanies = ({ savedCompany, handleGetIdToShow }: Props) => {

    const dispatch = useDispatch();
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
    const user_id = notificationCount.company_investor_id;

    const handleUnsave = (companyId : number, savedCompanyId: number) => {
        if(companyId){
            dispatch(actionSaveCompany(String(user_id), String(companyId), 'unsave', savedCompanyId));
        }
    }

    const handleContactCompany = (companyId: number) => { 
        if(savedCompany.contact_button_enabled === 1) return;
        if (companyId) {
            dispatch(actionSaveCompany(user_id, String(companyId), 'sendreq'));
        }
    }

    return (
        <>
            <Card className='mt-4 card-content rounded-3 p-3'>
                <Row className="justify-content-between flex-wrap col-12" style={{ margin: '0 auto'}}>

                    <Col sm={'auto'} className="d-flex align-items-center justify-content-center px-3">
                        <div className="card-content_image my-3">
                            <img 
                                src={savedCompany.company_logo ? savedCompany.company_logo : imgUser} 
                                alt={''} 
                                onClick={ () => handleGetIdToShow(savedCompany.company_id) } />
                        </div>
                    </Col>
                    <Col>
                        <div className="card-content_text">
                            <h3 onClick={ () => handleGetIdToShow(savedCompany.company_id) }>
                                {savedCompany.company_name}
                            </h3>
                            <h5>{savedCompany.company_description}</h5>
                            <p className="mb-1"><i className="fas fa-dollar-sign"></i> Annual Gross Renevue: <b>{savedCompany.annual_gross_revenue}</b> </p>
                            <p className="mb-1"><i className="fas fa-seedling"></i> Funding Stage: <b>{savedCompany.funding_stage}</b> </p>
                            <p className="mb-1"><i className="fas fa-industry"></i> <b>{savedCompany.industries}</b> </p>
                            <p className="mb-1"><i className="fas fa-map-marker-alt"></i> <b>{savedCompany.city_state}</b></p>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12} className='contenedor-botones my-3'>
                        <button
                            type="button"
                            className="btn btn-outline-primary estilos-boton modificador1 my-1"
                            onClick={() => handleUnsave(savedCompany.company_id, savedCompany.saved_company_id)}
                        >Remove</button>
                        {
                            <Button
                                type="button"
                                variant="primary"
                                disabled={savedCompany.contact_button_enabled === 1 }
                                onClick={() => handleContactCompany(savedCompany.company_id)}
                                className='estilos-boton my-1'
                            >{savedCompany.contact_button_caption}
                            </Button>
                        }
                    </Col>
                </Row>
            </Card>
        </>
    )
}
