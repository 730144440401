import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Accordion } from 'react-bootstrap';
import "../InvestorComp/MainInvestor.css";
import { PencilDot } from '../../../@custom/components/PencilDot/PencilDot';
import imgMainProfile from '../../../assets/images/Logo-placeholder.jpg';
import { Button, Tabs, Tab, ProgressBar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AboutUs } from '../Company/AboutUs';
import { getFilledSectionsCompany, submitCompany, updateCompanyBanner, updateCompanyLogo, setValidFormAction } from '../../../redux/actions/companyAction';
import { ValueProposition } from './ValueProposition';
import { Financials } from '../Company/Financials';
import { LeadershipTeam } from '../Company/LeadershipTeam';
import { FundingComponent } from '../Company/Funding';
import { setImgInfoAction } from '../../../redux/actions/uiAction';
import imgBanner from '../../../assets/images/background_main.jpg';
import { ModalSubmit } from "./ModalSubmit";
import { customAlert } from "../../../helpers/alerts";

export const Company = () => {
    const dispatch = useDispatch();
    const [key, setKey] = useState('1');
    const [percent, setPercent] = useState(0);
    // const [validForm, setValidForm] = useState({
    //     about: false,
    //     financials: false,
    //     leadership: false,
    //     valueProposition: false,
    //     funding: false
    // });
    const { aboutUs, filledSections, validForm } = useSelector(state => state.company);

    //Para probar datos
    // filledSections.filled_sections=5;
    //console.log('filledSections company',filledSections);

    const handleImgPencil = (img) => {
        dispatch(updateCompanyLogo(img));
        dispatch(setImgInfoAction(img));
    }

    const handleImgPencilBanner = (img) => {
        dispatch(updateCompanyBanner(img));
    }

    const handleSubmitCompany = () => {
        dispatch(submitCompany())
        if(filledSections.filled_sections <= 5){
            // openModalSubmit()
            customAlert('','Thank you for applying to join FundLyst. We will review your application within 24 hours. If you have any specific questions, please contact support@fundlyst.net for assistance.');

        }
    }

    const handleValidateForm = useCallback((componentName, isValid) => {
        // setValidForm({ ...validForm, [componentName]: isValid });
        dispatch(setValidFormAction(componentName, isValid))
    }, [validForm, dispatch])

    const checkColorTab = (no) => {
        if (key === String(no)) return 'text-secondary'
        if (no > filledSections.filled_sections) return 'text-muted text-opacity-50'
        if (filledSections.filled_sections >= no) return 'text-primary'
    }

    const checkIconTab = (no) => {
        if (no > filledSections.filled_sections)
            return 'far fa-dot-circle';
        else
            return 'far fa-check-circle';
    }

    useEffect(() => {
        dispatch(getFilledSectionsCompany());
    }, [dispatch]);

    useEffect(() => {
        setPercent(() => filledSections.filled_sections * 20)
    }, [filledSections]);


    const onValidateForm = () => {
        const values = Object.values(validForm).map((v) => v);
        const ev = values.every(v => v === true);
        return !ev
    }

    const handleActionModalSubmit = () => {
        let a = document.createElement('a');
        a.href = '#close';
        a.click();

    }
    const openModalSubmit = () => {
        let a = document.createElement('a');
        a.href = '#openModalSubmit';
        a.click();
    }

    const accordion = [
        { label: 'ABOUT US', element: 1 },
        { label: 'FINANCIALS', element: 2 },
        { label: 'VALUE PROPOSITION', element: 3 },
        { label: 'LEADERSHIP TEAM', element: 4 },
        { label: 'FUNDING', element: 5 },
    ]
    
    //console.log('validForm-company',validForm);
    return ( 
        <>
            <div className="imgMainCover" style={{ backgroundImage: 'url(' + (aboutUs.company_banner ? aboutUs?.company_banner : imgBanner) + ')' }}>
                <img src={aboutUs.company_logo || imgMainProfile} alt="logo" className="imgRedondaMain" />
                <div className={filledSections.filled_sections === 6 ? "contentDot disable-mode" : "contentDot"}>
                    <PencilDot isWebp={true} validation1={true} handleImgPencil={handleImgPencil} />
                </div>
                <div className={filledSections.filled_sections === 6 ? "contentDot2 disable-mode" : "contentDot2"}>
                    <PencilDot isWebp={true} validation1={true} handleImgPencil={handleImgPencilBanner} />
                </div>
            </div>

            <Row>
                <Col xs={1}></Col>
                <Col xs={12} className="main-contenedor-progreso">
                    <div className="contenedorProgreso">
                        <Row>
                            <Col sm={12}>
                                <div className="textoArribaProgreso mb-3"><span>Complete your profile to start the account validation process.</span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <ProgressBar now={percent} />
                                <br />
                                <span className="pasosCompletos">{filledSections.filled_sections > 5 ? 5 : filledSections.filled_sections}</span> <span> of <span className="pasosIncompletos"> 5</span> steps completed.</span>
                            </Col>
                            <Col sm={6} className="alineacionBtn">

                                    <Button
                                        type="button"
                                        className='px-5'
                                        variant="primary"
                                        disabled={filledSections.filled_sections !== 5 || onValidateForm()}
                                        onClick={handleSubmitCompany}
                                    >
                                        {filledSections.filled_sections > 5 ? 'Submitted' : 'Submit'}
                                    </Button>
                                

                            </Col>
                          

                        </Row>
                    </div>

                    <Accordion 
                        className='accordion-mobile'
                        onSelect={(k) => filledSections.filled_sections > (Number(k)-2) && setKey(k) }
                        defaultActiveKey="1">
                        {accordion.map( (el) => (
                            <Accordion.Item eventKey={String(el.element)}>
                                <Accordion.Header>
                                    <div className={`d-flex align-items-center ${checkColorTab(el.element)}`} >
                                        <i className={`${checkIconTab(el.element)} me-1`} style={{ fontSize: '26px' }}></i>
                                        <span>{el.label}</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {el.element === 1 && <AboutUs handleValidateForm={handleValidateForm} />}
                                    {el.element === 2 && filledSections.filled_sections > 0 && <Financials handleValidateForm={handleValidateForm} />}
                                    {el.element === 3 && filledSections.filled_sections > 1 && <ValueProposition handleValidateForm={handleValidateForm} />}
                                    {el.element === 4 && filledSections.filled_sections > 2 && <LeadershipTeam handleValidateForm={handleValidateForm} />}
                                    {el.element === 5 && filledSections.filled_sections > 3 && <FundingComponent handleValidateForm={handleValidateForm} />}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>

                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3 modif-wrap tabs-desktop">
                            {accordion.map( (el) => (
                                <Tab
                                    eventKey={String(el.element)}
                                    disabled={filledSections.filled_sections < (Number(el.element)-1)}
                                    title={
                                        <div className={`d-flex align-items-center ${checkColorTab(el.element)}`} >
                                            <i className={`${checkIconTab(el.element)} me-1`} style={{ fontSize: '26px' }}></i>
                                            <span>{el.label}</span>
                                        </div>}>

                                        {el.element === 1 && <AboutUs handleValidateForm={handleValidateForm} />}
                                        {el.element === 2 && filledSections.filled_sections > 0 && <Financials handleValidateForm={handleValidateForm} />}
                                        {el.element === 3 && filledSections.filled_sections > 1 && <ValueProposition handleValidateForm={handleValidateForm} />}
                                        {el.element === 4 && filledSections.filled_sections > 2 && <LeadershipTeam handleValidateForm={handleValidateForm} />}
                                        {el.element === 5 && filledSections.filled_sections > 3 && <FundingComponent handleValidateForm={handleValidateForm} />}
                                </Tab>
                            ))}
                    </Tabs>

                        
                    {/* <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3 modif-wrap">
                        <Tab
                            eventKey="1"
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(1)}`} >
                                    <i className={`${checkIconTab(1)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span>ABOUT US</span>
                                </div>}
                        >
                            <AboutUs handleValidateForm={handleValidateForm} />
                        </Tab>
                        <Tab
                            eventKey="2"
                            disabled={filledSections.filled_sections < 1}
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(2)}`}>
                                    <i className={`${checkIconTab(2)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span>FINANCIALS</span>
                                </div>}
                        >
                            <Financials handleValidateForm={handleValidateForm} />

                        </Tab>
                        <Tab
                            eventKey="3"
                            disabled={filledSections.filled_sections < 2}
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(3)}`}>
                                    <i className={`${checkIconTab(3)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span>VALUE PROPOSITION</span>
                                </div>}
                        >
                            <ValueProposition handleValidateForm={handleValidateForm} />
                        </Tab>
                        <Tab
                            eventKey="4"
                            disabled={filledSections.filled_sections < 3}
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(4)}`}>
                                    <i className={`${checkIconTab(4)} me-1`} style={{ fontSize: '26px' }}></i>

                                    <span>LEADERSHIP TEAM</span>
                                </div>}
                        >

                            <LeadershipTeam handleValidateForm={handleValidateForm} />

                        </Tab>
                        <Tab
                            eventKey="5"
                            disabled={filledSections.filled_sections < 4}
                            title={
                                <div className={`d-flex align-items-center ${checkColorTab(5)}`}>
                                    <i className={`${checkIconTab(5)} me-1`} style={{ fontSize: '26px' }}></i>
                                    <span>FUNDING</span>
                                </div>}
                        >

                            <FundingComponent handleValidateForm={handleValidateForm} />

                        </Tab>
                    </Tabs> */}

                    

                </Col>
                <Col xs={1}></Col>
            </Row>

            {/* boton de prueba para modal de boton submit */}
            {/* <a href={`#openModalSubmit`}>

                <Button
                    type="button"
                    className='px-5'
                    variant="primary"
                    // onClick={handleSubmitCompany}
                >
                    hola pruebame
                </Button>
            </a>
             */}

            <div id={`openModalSubmit`} className="modalDialog">
                <ModalSubmit
                />
            </div>
        </>


    )
}
