export const matchActivityTypes = {
    getMatchActivity: '[MATCH ACTIVITY] Get',

    removeProgress: '[MATCH ACTIVITY PROGRESS TRACKER] Remove Progress Tracker',
    contactFundlyst: '[MATCH ACTIVITY PROGRESS TRACKER] Contact to Fundlyst',
    thisFitProgress: '[MATCH ACTIVITY PROGRESS TRACKER] This a fit',
    notFitProgress: '[MATCH ACTIVITY PROGRESS TRACKER] Not a fit',

    removeRequestsSent: '[MATCH ACTIVITY] Remove Requests sent',
    cancelRequestsSent: '[MATCH ACTIVITY] Cancel Requests sent',
}