import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
// import { ErrorMessage } from "formik";

const defaultMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 11, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, label, textTooltip, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <Form.Group>
      {textTooltip && (
        <OverlayTrigger
          key={"top"}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-${label}`}>{textTooltip}</Tooltip>}
        >
          <i className="fas fa-info-circle me-2" style={{ color: "#ccc" }}></i>
        </OverlayTrigger>
      )}
      <Form.Label>{label}</Form.Label>

      <MaskedInput
        mask={currencyMask}
        {...inputProps}
        className={`form-control ${
          inputProps?.errors?.[inputProps.name] && "is-invalid"
        }`}
      />
      {(inputProps.errors?.[inputProps.name]) &&
          <div className="invalid-feedback">
              {inputProps.errors?.[inputProps.name]}
          </div>
      }
      {/* <ErrorMessage
        name={inputProps?.name}
        className="invalid-feedback"
        component="div"
      /> */}
    </Form.Group>
  );
};

CurrencyInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
};

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};

export default CurrencyInput;
