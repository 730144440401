export interface Notification {
  notifications: NotificationInfo[];
  notificationCount: NotificationCount;
  loadedInfo: boolean;
}

export interface NotificationInfo {
  request_notifications_id: number;
  company_investor_request_id: number;
  investor_logo: string;
  logo_img: string;
  notification: string;
  notification_status: string;
  go_to: string;
  show_at: string;
  notification_left: string;
  notification_middle: string;
  notification_right: string;
  company_id: number;
  investor_id: number;
}

export interface NotificationCount {
  user_id: string;
  company_investor_id: string;
  type: string;
  notif_count: number;
  accepted_status: string;
  is_admin: string;
}

export interface NotificationUpdate {
  user_id: string;
  user_type: string;
  id_action?: string;
  request_notifications_id: string;
  company_investor_request_id?: string;
}

export enum ViewNotification {
  VIEW = 1,
  DELETE,
}

export interface UserInfo {
  logo: string;
  name: string;
  username: string;
  user_id: number;
}
