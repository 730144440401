import { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ProfileViewProfile } from '../../../@core/interfaces/profileView.model';
import { StoreApp } from '../../../@core/interfaces/store';
import { getFinancials } from '../../../redux/actions/companyAction';

export const PublicProfileFinancials = () => {

    return (
        <>
            <div className='financial-section'>
                <p className='contenedor-titulo'>
                    <span className='titulo-seccion'>FINANCIALS</span> <hr />
                </p>
                <Row>
                    <Col md={4} sm={12}>

                        <Card className='tarjetas-financials' >
                            <Card.Body>
                                <Card.Title>Anual Gross Revenue:</Card.Title>
                                <Card.Text className='precio-financial'>
                                    $50M-$100M
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12}>
                        <Card className='tarjetas-financials' >
                            <Card.Body>
                                <Card.Title>Bootstrapped:</Card.Title>
                                <Card.Text className='precio-financial'>
                                    $1M
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12}>
                        <Card className='tarjetas-financials'>
                            <Card.Body>
                                <Card.Title>Capital Raised to Date:</Card.Title>
                                <Card.Text className='precio-financial'>
                                    $555M
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Financial Projections</Accordion.Header>
                        <Accordion.Body>
                            <Table responsive="lg">
                                <thead>
                                    <tr>
                                        <th style={{ color: '#3816b0' }}>Projections</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span><b>Gross Revenue</b></span>
                                        </td>
                                        <td className='modificador-celda-table'>
                                            <span></span>
                                        </td>
                                        <td className='modificador-celda-table'>
                                            <span></span>
                                        </td>
                                        <td className='modificador-celda-table'>
                                            <span></span>
                                        </td>
                                        <td className='modificador-celda-table'>
                                            <span></span>
                                        </td>
                                        <td className='modificador-celda-table'>
                                            <span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Direct Cost</b></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Operating Expenses</b></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Profit</b></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Profit Margin</b></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td>
                                            <span></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>
        </>
    )
};
