import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TypeUser } from '../../../@core/interfaces/common.model'
import { StoreApp } from '../../../@core/interfaces/store'
import { HomeCompany } from '../HomeCompany/HomeCompany'
import { HomeInvestor } from '../InvestorProfile_home/HomeInvestor'
import { useNavigate, useLocation } from "react-router-dom";
import { PublicProfileView } from '../../@components/Profile/PublicProfileView';
import { InvestorPublicProfile } from '../../@components/InvestorPublicProfile/InvestorPublicProfile';

export const Home = () => {

    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const { type } = useSelector((state: StoreApp) => state.notifications.notificationCount)
    const [isProfileShow, setIsProfileShow ] = useState(false);

    const userType = Number(type);
    const isCompany = userType === TypeUser.COMPANY;

    const handleShowProfile = (param:string) => {
        navigate({pathname: '/home',search: param});
        setIsProfileShow(true)

        if(param !== ''){
            window.scrollTo(0, 0)
        }
    }

    const handleGetIdToShow = (id: number) => {
        if(!id) return false;
        const param = '?'+(isCompany ? 'idInvestor' : 'idCompany')+'='+id;
        handleShowProfile(param)
    }

    const handleBack = () => {
        handleShowProfile('');
        setIsProfileShow(false);
    }

    useEffect(() => {
        console.log('search',search)
        if(search === ''){
            handleBack()
        }
        
    },[search])

  return (
        isProfileShow ? 
        <> 
            <div className='mb-1'>
                <a className='btn-profile-back' onClick={handleBack}>
                    <b><i className="fa fa-chevron-left"></i> Back</b>
                </a>
            </div>
            {isCompany ? <InvestorPublicProfile /> : <PublicProfileView />}
        </>
        :
        <>
            { isCompany && <HomeCompany handleGetIdToShow={handleGetIdToShow} /> }
            { userType === TypeUser.INVESTOR && <HomeInvestor handleGetIdToShow={handleGetIdToShow} /> }
        </>
  )
}
