import { default as ReactSelect } from "react-select";
import { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// import { SelectComponents } from "react-select/dist/declarations/src/components";
// import { CustomOption } from './CustomOption';
// import { CatValueLabel } from "../../../../@core/interfaces/investor.model";
// import { ErrorMessage } from 'formik';

interface Props {
    label: string;
    name: string
    onChange: any;
    onBlur: any;
    error: any;
    touched: any;
    value: any;
    isAll?: boolean;
    disabled?: boolean;
    options: any[];
    textTooltip?: string;
    infoIcon1?: boolean;

}

export const MultiSelect = ({ options, onChange, onBlur, value, name, error, label, isAll, disabled, textTooltip, infoIcon1 }: Props) => {

    const [catOptions, setCatOptions] = useState<any[]>([]);
    const rowAll = { value: 'all', label: 'All' };

    useEffect(() => {
        if (options?.length > 0) {
            let newOptions = options.map((opt) => {
                const keys = Object.getOwnPropertyNames(opt);
                return { value: opt[keys[0]], label: opt[keys[1]] }
            });
            if (isAll) {
                (newOptions as any[]).unshift(rowAll);
                // newOptions = disabledOptions(newOptions);
            }
            setCatOptions([...newOptions]);
        }
    }, [options, isAll])

    useEffect(() => {
        // console.log(value)
    }, [value])

    const handleChange = (value: any) => {
        // console.log(value)
        const last = value?.slice(-1) ?? [];
        const first = value?.[0];
        //console.log('first?.value',first?.value, value?.length, last[0]?.value)

        if (String(first?.value).toLowerCase() === 'all' && value?.length > 1) {
            const valueWithoutAll = value.filter((val: any) => String(val.value).toLowerCase() !== 'all')
            onChange(name, valueWithoutAll);
        } else
            if (last.length > 0 && last[0].value === 'all') {
                // const newOptions = disabledOptions(catOptions);
                // setCatOptions(catOptions);
                // const newOptions = catOptions.filter((c) => c.value !== 'all')
                onChange(name, [rowAll]);
            }
            //Pendiente de pruebas
            // else if(!first?.value && value?.length === 0){
            //     //Cuando no seleccione ninguno se debe de marcar all
            //     onChange(name, [rowAll]);
            // }
            else {
                // const opts = catOptions.map((o) => ({ ...o, disabled: false }));
                // setCatOptions(opts);
                onChange(name, value);
            }
    };

    const disabledOptions = (array: any[]): any[] => {
        return array.map((o) => {
            let disabled = true;
            if (o.value === 'all') disabled = false;
            return { ...o, disabled }
        });
    }

    const handleBlur = () => {
        onBlur(name, true);
    };

    // const customs: any = {
    //     option: (CustomOption as Partial<SelectComponents<any, true, any>> | undefined)
    // }

    return (

        <div className="form-group">

            {/* <i className="fas fa-info-circle me-2" style={{ color: '#ccc' }}></i>  */}
            {
                   infoIcon1 &&
                   (
                     <OverlayTrigger
                       key={'top'}
                       placement={'top'}
                       overlay={textTooltip ?
                         <Tooltip id={`tooltip-${label}`}>
                           {textTooltip}
                         </Tooltip> : <></>
                       }
                     >
                       <i className="fas fa-info-circle me-2" style={{ color: "#ccc" }}></i>
                     </OverlayTrigger>
                   )
            }
            <label className="form-label" htmlFor={name} style={{ color: '#222', fontWeight: 400 }}>{label}</label>
            <ReactSelect
                isDisabled={disabled}
                name={name}
                options={catOptions}
                value={value}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={handleChange}
                onBlur={handleBlur}
                isOptionDisabled={(option) => option.disabled}
                isSearchable={false}
            // components={customs}
            // allowSelectAll={true}
            // className={`form-control ${error && 'is-invalid'}`}
            />
            {
                error && (
                    <small style={{ color: '#dc3545', marginTop: '0.25rem' }}>{error}</small>
                )
            }
            {/* <ErrorMessage name={name} className={`invalid-feedback`} component="div" /> */}
        </div>
    )
}
