import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InvestorAbout } from "../../../@core/interfaces/investor.model";
import { StoreApp } from "../../../@core/interfaces/store";
import { actionSavedInvestors } from "../../../redux/actions/homeAction";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

interface Props {
    about: InvestorAbout;
}

export const InvestorPublicProfileInfo = React.memo(({ about }: Props) => {

    const dispatch = useDispatch();
    const { idCompany } = useSelector((state: StoreApp) => state.company);
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
    const [searchParams] = useSearchParams();
    const [available, setAvailable] = useState<string | null>(null)

    useEffect(() => {
        getParams();
    }, [])

    const getParams = () => {
        setAvailable(searchParams.get('available' || null))
    }

    const handleSaveInvestor = () => {
        if (about.investor_id) {
            dispatch(actionSavedInvestors(0, idCompany, Number(about.investor_id), 'save'))
        }
    }

    const handleSendReqInvestor = () => {
        if (about.investor_id) {
            dispatch(actionSavedInvestors(0, idCompany, Number(about.investor_id), 'sendreq'))
        }
    }

    return (
        <>
            <div className="investor-company-info">
                <Row>
                    <Col md={8} sm={12}>
                        <p className='primer-parrafo fw-bold'>
                            {/* MOBILE */}
                            <div className="contenedor-mobile mb-2">
                                <span style={{ fontSize: '20px' }}>{about.investor_name} </span>
                                <a href={about.linkedin} target="_blank" >
                                    <i className="fab fa-linkedin me-2"></i>
                                </a>
                            </div>
                            {/* DESKTOP */}
                            <span style={{ fontSize: '20px' }} className="visualidad-desktop">{about.investor_name} </span>
                            <a href={about.linkedin} target="_blank" className="visualidad-desktop ">
                                <i className="fab fa-linkedin me-2"></i>
                            </a>
                            <i className="fas fa-globe me-2 visualidad-desktop2"></i>
                            <a href={about.investor_website} target="_blank" className="me-2 visualidad-desktop2">
                                {about.investor_website}
                            </a>
                            <i className="fas fa-map-marker visualidad-desktop2"></i>
                            <span className="visualidad-desktop2"> {about.city_name}, {about.state_short_name} </span>
                        </p>
                        <p className='primer-parrafo fw-bold visualidad-desktop2'>
                            <i className="fas fa-industry"></i> <span> {about.industries}</span>
                        </p>
                        <p className='primer-parrafo fw-bold visualidad-desktop2'>
                            <i className="fas fa-user-tie"></i> <span> {about.investor_type_name}</span>
                        </p>
                        <p className='primer-parrafo visualidad-desktop2'>
                            <i className="fas fa-dollar-sign me-2"></i>Range of Investment: <span className='fw-bold'> {about.investment_bracket_description}</span>
                        </p>
                            {/* MOBILE */}
                        <div className="contenedor-mobile2 fw-bold mb-2">
                            <div>
                                <i className="fas fa-globe me-2"></i>
                                <a href={about.investor_website} target="_blank" className="me-2">
                                    {about.investor_website}
                                </a>
                            </div>
                            <div>
                                <i className="fas fa-map-marker "></i>
                                <span> {about.city_name}, {about.state_short_name} </span>
                            </div>
                            <div>
                                <i className="fas fa-industry"></i> <span> {about.industries}</span>

                            </div>
                            <div>
                                <i className="fas fa-user-tie"></i> <span> {about.investor_type_name}</span>

                            </div>
                            <div>
                                <i className="fas fa-dollar-sign me-2"></i> <span className="fw-normal">Range of Investment: </span><span className='fw-bold'> {about.investment_bracket_description}</span>

                            </div>
                        </div>

                    </Col>

                    <Col md={4} sm={12} className='contenedor-botones my-3'>

                        <button
                            type="button"
                            className="btn btn-outline-primary estilos-boton"
                            // disabled={Number(available) === 0 || notificationCount?.type !== '1'}
                            // disabled={notificationCount?.type !== '1'}
                            disabled={about.save_button_enabled === 0 || notificationCount?.type !== '1'}

                            onClick={handleSaveInvestor} >{about.save_button_caption}
                        </button>

                        <Button
                            type="button"
                            variant="primary"
                            className='estilos-boton'
                            // disabled={Number(available) === 0 || notificationCount?.type !== '1'}
                            // disabled={notificationCount?.type !== '1'} 
                            // disabled={Number(available) === 0 || about.send_button_enabled === 0 || notificationCount?.type !== '1'}
                            disabled={about.send_button_enabled === 0 || notificationCount?.type !== '1'}
                            // disabled={about.send_button_enabled === 0 || notificationCount?.type !== '1'}
                            onClick={handleSendReqInvestor}>{about.send_button_caption}</Button>


                    </Col>
                </Row>
                <div>
                    <p className='segundo-parrafo my-3'>
                        {_.unescape(about.investment_philosophy)}
                    </p>
                </div>
            </div>

        </>

    )
});
