import Swal from "sweetalert2";
import "./css/alerts.css";

export const customAlert = (title = "Alert", text = "", type = "success") => {
  return Swal.fire({
    title,
    text,
    icon: type,
    confirmButtonClass: "btn-alert-ok",
    showCloseButton: true,

  });
};

export const customErrorAlert = (text = "", title = "Alert") => {
  return Swal.fire({
    title,
    text,
    icon: "error",
    confirmButtonClass: "btn-alert-ok",
    showCloseButton: true,

  });
};

export const customLoader = (title = "loading") => {
  return Swal.fire({
    title,
    timerProgressBar: true,
  });
};

export const customQuestion = (title = "Question", confirmButtonText = 'Ok', denyButtonText = 'Cancel') => {
  return Swal.fire({
    title,
    showDenyButton: true,
    confirmButtonText,
    denyButtonText,
    showCloseButton: true,
    icon: "question",
  })
};
