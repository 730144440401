import { Leadership } from "../../../@core/interfaces/company.model";
import { CatValueLabel } from "../../../@core/interfaces/investor.model";
import imgUser from '../../../assets/images/user.jpg';
import { Col, Row } from "react-bootstrap";


interface Props {
    leadership: Leadership[]
}

export const PublicProfileLeadership = ({ leadership }: Props) => {

    const getExpertise = (exp: CatValueLabel[]) => {
        const labels = exp.map((x) => x.label)
        return labels.join(', ');
    }

    return (
        <>
            <div>
                <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>LEADERSHIP TEAM</div>
                    <div className="line"></div>
                </p>
                <div className="contenedor-cards-leadership contenedor-cards-leadership-v2">
                    {
                        leadership.map((l) => (
                            <Col md={6} lg={4}>
                                <div className="card-leadership-member" key={l.iIdTeamMember}>
                                    <div className="contenedor-imagen my-3">
                                        <img src={l.photo ? l.photo : imgUser} alt="" />
                                    </div>
                                    <span> <b>{l.sMemberName}</b> </span>
                                    <span><b> {l.sPosition}</b></span>
                                    <span>Expertise:<b> {getExpertise(l.sExpertice)} </b></span>
                                    <span>Previous  Exits:<b> {l.exits_number}</b></span>
                                    <a target='_blank' href={l.sLinkedin} rel="noreferrer">
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </div>
                            </Col>
                        ))
                    }
                </div>
            </div>
        </>
    )
};
