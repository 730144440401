import { UserInfo } from '../../@core/interfaces/notifications.model';
import { UI } from "../../@core/interfaces/store";
import { types } from "../types/types";

const initialState: UI = {
  // 'vidsoft@gmail.com'
  // vidalipn@hotmail.com
  // estebram34@gmail.com
  email: '',
  loading: false,
  loadedCats: false,
  notification: false,
  openSidebar: false,
  userInfo: {} as UserInfo
};

export const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.setLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case types.loadedCats:
      return {
        ...state,
        loadedCats: true,
      };
    case types.updateNotifications:
      return {
        ...state,
        notification: action.payload,
      };
    case types.setEmail:
      return {
        ...state,
        email: action.payload,
      };
    case types.openSidebar:
      return {
        ...state,
        openSidebar: action.payload,
      };
    case types.setUserInfo:
      return {
        ...state,
        userInfo: {...action.payload.userInfo},
      };
    case types.setImgProfile:
        return {
          ...state,
          userInfo: {...state.userInfo, logo:action.payload}
        }

    default:
      return state;
  }
};
