import React, { useState } from 'react';
import './ConfirmCode/ConfirmCode.css';
import logo from '../../assets/images/logo-color.svg';
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';


export const RecoverPassword = () => {
    const navigate = useNavigate();
    const navigate2 = useNavigate();


    const [state, setstate] = useState({
        email: ''
    });

    const { email } = state;

    const handleEmail = (e) => {
        setstate({
            ...state,
            email: e.target.value
        });
    }

    const postRecover = async () => {
        try {
            await Auth.forgotPassword(email);
            Swal.fire('Alert', 'You will receive a verification code to your email', 'success').then(() => {
                navigate(`/reset-password?email=${email}`)
            })
        } catch (error) {
            Swal.fire('Failed to postRecover', error.message, 'error');
        }
    }

    return (
        <>
            <div className="container main-container">
                <img className="logo" src={logo} alt="Fundlyst" />
                <div className="wrapper">
                    <h2 className="mb-5">Enter your email to reset the password</h2>
                    <div className="form-group">
                        <input type="text" className="form-control" id="code" value={email} placeholder="user@mail.com" onChange={handleEmail} />
                    </div>

                    <div className="btn-wrapper w-100 mt-5 d-flex justify-content-center">
                        <button type="button" onClick={() => navigate2('/login')} className="me-4 btn btn-outline-primary">Cancel</button>

                        <button type="button" className='btn-primary btn' onClick={postRecover}>Accept</button>
                    </div>
                </div>
            </div>

        </>
    )
}
