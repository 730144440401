import { Col, Row, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MatchActivityHome } from '../../../@core/interfaces/home.models';
import userImg from '../../../assets/images/user.jpg';
interface Props {
    matchActivity: MatchActivityHome[];
    handleGetIdToShow?: (id: number) => void;
}

export const InvestorMatchActivity = ({ matchActivity, handleGetIdToShow }: Props) => {
    const navigate = useNavigate();

    const onNavigate = () => {
        navigate('/match-activity')
    }

    const colors: { [key: number]: string } = {
        0: '#31a91a',
        1: '#F1C40F',
        2: '#E74C3C',
    }
    
    return (
        <>
            <Row className="cards-match-container">
                {
                    matchActivity.map((match) => (
                        <Col md={6} sm={12} lg={4} key={match.company_investor_request_id} >
                            <Card className='my-3' >
                                <Card.Img 
                                    variant="top" 
                                    src={match.company_logo ? match.company_logo : userImg} 
                                    className='my-3 border-img' 
                                    style={{'cursor':'pointer'}}
                                    onClick={ ()=> (handleGetIdToShow && handleGetIdToShow(match.company_id)) }
                                />
                                <Card.Body className='pt-0'>
                                    <Card.Title 
                                        style={{'cursor':'pointer'}}
                                        onClick={ ()=> (handleGetIdToShow && handleGetIdToShow(match.company_id)) }
                                    >{match.company_name}</Card.Title>
                                    <Card.Text></Card.Text>
                                    <p className="mb-1">{match.phase_description}:</p>
                                    <p className="mb-1 color-status" style={{ color: colors[match.color] }}>{match.home_message}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
            <div className="contenedor-btn mt-3">
                <Button
                    type="submit"
                    variant="primary"
                    className="px-5"
                    onClick={onNavigate}
                >
                    View All
                </Button>
            </div>
        </>
    )
}