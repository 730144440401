import { useEffect, useState } from 'react';
import { Invitation } from './Invitation';
import { RequestsSent } from './RequestsSent';
import { useNavigate, useLocation } from "react-router-dom";
import { ProgressTrackerComponent } from './ProgressTrackerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchActivity } from '../../../redux/actions/matchActivityAction';
import { StoreApp } from '../../../@core/interfaces/store';
import { fetchCatalogs } from '../../../helpers/fetch';
import { TypeUser } from '../../../@core/interfaces/common.model';
import { PublicProfileView } from '../../@components/Profile/PublicProfileView';
import { InvestorPublicProfile } from '../../@components/InvestorPublicProfile/InvestorPublicProfile';

export const MatchActivity = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const { invitations, requests, progress } = useSelector((state: StoreApp) => state.matchActivity)
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications)
    const [catRejection, setCatRejection] = useState<any[]>([]);
    const [isProfileShow, setIsProfileShow ] = useState(false);

    const userType = notificationCount.type;
    const isInvestorType = Number(userType) === TypeUser.INVESTOR;

    const handleShowProfile = (param:string) => {
        navigate({pathname: '/match-activity',search: param});
        setIsProfileShow(true)

        if(param !== ''){
            window.scrollTo(0, 0)
        }
    }

    const handleGetIdToShow = (id: number) => {
        if(!id) return false;
        const param = '?'+(isInvestorType ? 'idCompany' : 'idInvestor')+'='+id;
        handleShowProfile(param)
    }

    const handleBack = () => {
        handleShowProfile('');
        setIsProfileShow(false);
    }

    useEffect(() => {
        getCatalogRejection();
    }, [])

    useEffect(() => {
        if (notificationCount.company_investor_id && notificationCount.type) {
            dispatch(getMatchActivity(notificationCount.company_investor_id, notificationCount.type));
        }
    }, [dispatch, notificationCount]);

    const getCatalogRejection = async () => {
        const catname = isInvestorType ? 'cat_investor_rejection_reasons' : 'cat_company_rejection_reasons'; 
        const rejectJSON = await fetchCatalogs('prod?catname='+catname);
        const catRejection: any[] = await rejectJSON.json();
        if (Array.isArray(catRejection)) setCatRejection(catRejection);
    }

    useEffect(() => {
        if(search === ''){
            handleBack()
        }
        
    },[search])

    return (
        isProfileShow ? 
        <> 
            <div className='mb-1'>
                <a className='btn-profile-back' onClick={handleBack}>
                    <b><i className="fa fa-chevron-left"></i> Back</b>
                </a>
            </div>
            {isInvestorType ? <PublicProfileView /> : <InvestorPublicProfile />}
        </>
        :
        <>
            <div className="_matchactivity">
                <div className='contenedor-titulo mt-2 mb-5 d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>{isInvestorType ? 'COMPANY REQUEST' : 'INVITATIONS'}</div>
                    <div className="line"></div>
                </div>

                <Invitation invitations={invitations} catRejection={catRejection} handleGetIdToShow={handleGetIdToShow} />
                <br />

                <div className='contenedor-titulo my-5 d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>{isInvestorType ? 'INVITATIONS' : 'REQUESTS'} SENT</div>
                    <div className="line"></div>
                </div>

                <RequestsSent requests={requests} handleGetIdToShow={handleGetIdToShow} />
                <br />

                <div className='contenedor-titulo my-5 d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>PROGRESS TRACKER</div>
                    <div className="line"></div>
                </div>

                <ProgressTrackerComponent progress={progress} catRejection={catRejection} handleGetIdToShow={handleGetIdToShow} />
                <br />

                {/* <a href="#openModal">Lanzar el modal</a> */}

                {/* <div id="openModal" className="modalDialog">
                    <div>
                        <a href="#close" title="Close" className="close"><i className="far fa-times-circle text-primary"></i>
                        </a>
                        <div className="circulo my-2">
                            <i className="fas fa-thumbs-down"></i>
                        </div>
                        <p className='my-2'>Reason because is Not a Fit:</p>
                   
                        <select name="select" className='my-2'>
                            <option value="value1">Round Closed</option>
                        </select>

                        <Button type="submit" variant="primary" className='estilos-boton my-3'>Decline</Button>
                    </div>
                </div> */}

            </div>
        </>

    )
}