import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import get from "lodash/get";

export const DropFile = ({handleFile}) => {

    const dragCounter = useRef(0);
    const inputRef = useRef();
    const [dragOverlay, setDragOverlay] = useState(false);

    const handleDrop = (e) => {
        e.preventDefault();
        const files = [...e.dataTransfer.files];
        setDragOverlay(false);

        getB64(files[0], (imageB64, file, ext) => {
            handleFile(imageB64, file, ext, e);
        })
    }

    const handleInputFile = (e) => {
        const file = e.target.files[0];
        getB64(file, (imageB64, file, ext) => {
            handleFile(imageB64, file, ext, e);
        })
    }

    const getB64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const xname = file.name.split('.');
            const ext = xname[xname.length-1];
            cb(reader.result, file, ext)
        };
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    }
    const handleDragEnter = (e) => {
        e.preventDefault();
        dragCounter.current++;
        if (get(e, "dataTransfer.items.length") > 0) {
            setDragOverlay(true);
        }
    }
    const handleDragLeave = (e) => {
        e.preventDefault();
        dragCounter.current--;
        if (dragCounter.current === 0) {
            setDragOverlay(false);
        }
    }


    return (
        <div
            className="_drop_file"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}>
            <div
                className={dragOverlay ? 'recuadro_drop_here overlay' : 'recuadro_drop_here'}
            >
                <i className="fas fa-upload"></i>
                <div className='text-center'>
                    <span>
                        Drop your Pitch Deck file here!
                        <div>Maximum upload file size 35 MB, format: PDF, DOC and PPT</div>
                    </span>
                </div>
                <input 
                    type="file" 
                    accept='application/msword, application/vnd.ms-powerpoint, application/pdf' 
                    ref={inputRef} 
                    style={{ display: 'none' }} 
                    onChange={handleInputFile} />

                <Button type="submit" variant="primary" className="btn-file" onClick={() => inputRef.current.click()}>Add File</Button>
            </div>
        </div>
    )
}

DropFile.propTypes = {
    handleFile: PropTypes.func.isRequired
};