import {
  Invitations,
  ProgressTracker,
  Requests,
} from "../../@core/interfaces/matchActivity.model";
import { StoreApp } from "../../@core/interfaces/store";
import { customErrorAlert } from "../../helpers/alerts";
import { matchActivityTypes } from "../types/matchActivityTypes";
import { setLoading } from "./uiAction";
import {
  _invitations,
  _requests,
  _progressTracker,
  _removeProgressTracker,
  _thisFitProgressTracker,
  _notFitProgressTracker,
  _removeRequestsSent,
  _cancelRequestsSent,
  _declineInvitations,
  _acceptInvitations,
  _ContactFundlyst
} from "../../@core/services/matchActivity.service";

export const getMatchActivity = (userId: string, type: string, part = 'ALL', showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));
    try {
      // const { email } = getState().ui;
      //console.log('getState().matchActivity',getState().matchActivity );

      if(part === 'ALL'){
        const invitations = await _invitations(userId, type);
        const requests = await _requests(userId, type);
        const progress = await _progressTracker(userId, type);
        dispatch(getMatchActivityAction(invitations, requests, progress));
      }else{

        const cMatchActivity = getState().matchActivity;

        if(part === 'INVITATIONS'){
          dispatch(getMatchActivityAction(await _invitations(userId, type), cMatchActivity.requests, cMatchActivity.progress));
        }

        if(part === 'REQUEST'){
          dispatch(getMatchActivityAction(cMatchActivity.invitations, await _requests(userId, type), cMatchActivity.progress));
        }

        if(part === 'PROGRESS'){
          dispatch(getMatchActivityAction(cMatchActivity.invitations, cMatchActivity.requests, await _progressTracker(userId, type)));
        }
      }//else

      dispatch(setLoading(false));
    } catch (error: any) {
      if(showLoading){
        customErrorAlert(error, "Alert");
      }
      
      dispatch(setLoading(false));
    }
  };
};

const getMatchActivityAction = (
  invitations: Invitations[],
  requests: Requests[],
  progress: ProgressTracker[]
) => ({
  type: matchActivityTypes.getMatchActivity,
  payload: {  
    invitations,
    requests,
    progress,
  },
});

export const removeProgressTracker = (
  company_investor_request_id: string,
  investor_id: number,
  showModalRemove: ()=>void
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      // const { idCompany } = getState().company;
      const { notificationCount} = getState().notifications;
      const user_id = notificationCount.company_investor_id;
      const resp = await _removeProgressTracker(String(user_id),company_investor_request_id,notificationCount.type);
      if (resp.result === "ok") {
        showModalRemove();
        dispatch(getMatchActivity(notificationCount.company_investor_id, notificationCount.type,'PROGRESS'));
        dispatch(removeProgressTrackerAction(investor_id));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      // customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const removeProgressTrackerAction = (investor_id: number) => ({
  type: matchActivityTypes.removeProgress,
  payload: { investor_id },
});

export const contactFundlyst = (showContactModal: () => void) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      // const { idCompany } = getState().company;
      const { notificationCount } = getState().notifications;
      const user_id = notificationCount.company_investor_id;
      const { email } = getState().ui;
      const sNombre=email;

      //console.log('user_id',user_id,'email',email,'snombre',sNombre);

      const resp = await _ContactFundlyst(String(user_id),email,sNombre);

      if (resp.result === "ok") {
        dispatch(ContactAction(String(user_id),email,sNombre));
        showContactModal()
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      // customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const ContactAction = (idCompany: string,sEmail: string,sNombre: string) => ({
  type: matchActivityTypes.contactFundlyst,
  payload: { idCompany,sNombre,sEmail },
});

export const thisFitProgressTracker = (
  company_investor_request_id: string,
  phase_id: string,
  investor_id?: number
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      // const { idCompany } = getState().company;
      const { notificationCount } = getState().notifications;
      const user_id = notificationCount.company_investor_id;
      const resp = await _thisFitProgressTracker(String(user_id),company_investor_request_id,phase_id,notificationCount.type);
      if (resp.result === 'ok') {
        dispatch(getMatchActivity(notificationCount.company_investor_id, notificationCount.type,'PROGRESS'));
    }
      // dispatch(removeProgressTrackerAction(investor_id));
      dispatch(setLoading(false));
    } catch (error: any) {
      // customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const notFitProgressTracker = (
  company_investor_request_id: string,
  phase_id: string,
  company_rejection_reason_id: string
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      //const { idCompany } = getState().company;
      const { notificationCount } = getState().notifications;
      const user_id = notificationCount.company_investor_id;
      const resp = await _notFitProgressTracker(String(user_id),company_investor_request_id,phase_id,company_rejection_reason_id,notificationCount.type);

      if (resp.result === 'ok') {
          dispatch(getMatchActivity(notificationCount.company_investor_id, notificationCount.type,'PROGRESS'));
      }
      //console.log(resp);
      // dispatch(removeProgressTrackerAction(investor_id));
      dispatch(setLoading(false));
    } catch (error: any) {
      // customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const removeRequestsSent = (
  investor_id: number,
  company_investor_request_id: string,
  company_user_id: string,
  showModalRemove: ()=>void
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      // const { idCompany } = getState().company;
      const { notificationCount } = getState().notifications;
      const user_id = notificationCount.company_investor_id;
      const resp = await _removeRequestsSent(String(user_id),company_investor_request_id,company_user_id,notificationCount.type);
      if (resp.result === "ok") {
        showModalRemove();
        // TODO: Descomentar para refrescar todo el match activity
        dispatch(getMatchActivity(notificationCount.company_investor_id, notificationCount.type,'REQUEST'));

        // TODO: Elimina internamente el requests sent
        dispatch(removeRequestsSentAction(investor_id));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      // customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const removeRequestsSentAction = (investor_id: number) => ({
  type: matchActivityTypes.removeRequestsSent,
  payload: { investor_id },
});

export const cancelRequestsSent = (
  investor_id: number,
  company_investor_request_id: string
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      //const { idCompany } = getState().company;
      const { notificationCount } = getState().notifications;
      const user_id = notificationCount.company_investor_id;
      const resp = await _cancelRequestsSent(String(user_id),company_investor_request_id,notificationCount.type);

      if (resp.result === "ok") {
        // TODO: Descomentar para refrescar todo el match activity
        dispatch(getMatchActivity(notificationCount.company_investor_id, notificationCount.type,'REQUEST'));
        dispatch(cancelRequestsSentAction(investor_id));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      // customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const cancelRequestsSentAction = (investor_id: number) => ({
  type: matchActivityTypes.cancelRequestsSent,
  payload: { investor_id },
});

export const declineInvitations = (
  investor_id: number,
  company_investor_request_id: string,
  rejection_reason: string
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      //const { idCompany } = getState().company;
      const { notificationCount } = getState().notifications;
      const user_id = notificationCount.company_investor_id;
      //console.log('user_id',user_id);
      const resp = await _declineInvitations(
        String(user_id),
        company_investor_request_id,
        notificationCount.type,
        rejection_reason
      );
      if (resp.result === "ok") {
        dispatch(getMatchActivity(notificationCount.company_investor_id,notificationCount.type,'INVITATIONS'));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      // customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const acceptInvitations = (
  investor_id: number,
  company_investor_request_id: string
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      // const { idCompany } = getState().company;
      const { notificationCount } = getState().notifications;
      const resp = await _acceptInvitations(String(notificationCount.company_investor_id),company_investor_request_id,notificationCount.type);
      if (resp.result === "ok") {
        dispatch(getMatchActivity(notificationCount.company_investor_id,notificationCount.type));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};
