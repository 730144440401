import { memo } from "react";
import { Invitations } from "../../../@core/interfaces/matchActivity.model";
import { InvitationRow } from "./InvitationRow";

interface Props {
    invitations: Invitations[];
    catRejection: any[];
    handleGetIdToShow: (id: number) => void;
}

export const Invitation = memo(({ invitations, catRejection, handleGetIdToShow }: Props) => {
    return (
        <>
            {
                invitations.map((inv) => (
                    <InvitationRow
                        key={inv.company_investor_request_id}
                        inv={inv}
                        catRejection={catRejection}
                        handleGetIdToShow={handleGetIdToShow}
                    />
                ))
            }

        </>
    )
})