import { 
  _submitInvestor,
  _getCatIndustries, 
  _getHighlightsCatalog, 
  _getInvestmentPreferences, 
  _getInvestorAbout, 
  _getSavedCompanies, 
  _saveInvestmentPreferences,
  _getFilledSectionsInvestor } from '../../@core/services/investor.service'

import { customAlert, customErrorAlert } from '../../helpers/alerts'
import { fetchCatalogs } from '../../helpers/fetch'
import { types } from '../types/types'
import { refreshTypes } from "../types/refreshTypes";
import { setLoading } from './uiAction'

import { FilledSections } from "../../@core/interfaces/investor.model";

export const getInvestor = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { catIndustries } = getState().cats.investor

    try {
      const email = getState().ui.email
      const res = await fetchCatalogs(
        `prod/investorgralinfo?sUserName=${email}`
      ) 
      let investor = await res.json() 
      //console.log('investorActions.js',investor);

      if (investor.result === 'error') {

      } else {
        investor = investor[0]
        if (!investor.industries) {
          investor.industries = []
        } else {
          let industries = investor.industries.split(',').map((x) => Number(x))
          industries = industries.map((ind) => {
            const values = catIndustries.find((c) => c.value == ind)
            return {
              ...values
            }
          })
          investor.industries = industries
        }
        dispatch(setInvestor(investor))
      }
      dispatch(setLoading(false))
    } catch (error) {
      customErrorAlert(error, 'Alert')
      dispatch(setLoading(false))
    }
  }
}

export const updateInvestor = (investor, industries) => {
  return async (dispatch, getState) => {
    const { filledSections, gralInfo } = getState().investor;
    dispatch(setLoading(true))
    //console.log('investorAction.js:gralInfo->',gralInfo);
    try {

      // console.log('investor',investor);

      const investorRequest = {
        ...investor,
        investor_logo: gralInfo.investor_logo || "",
        investor_banner: gralInfo.banner || "",
      }

      //antes
      // if (gralInfo?.investor_logo.includes('base64')) {
      //   investorRequest.investor_logo = gralInfo.investor_logo.substr(
      //     gralInfo.investor_logo.indexOf(',') + 1
      //   )
      //   investorRequest.typeLogo = 'webp'
      // }

      if (!investorRequest?.investor_logo.includes('base64')) {
          delete investorRequest.investor_logo;
      }else{
        investorRequest.typeLogo = 'webp'
      }
      //Antes
      // if (gralInfo?.banner.includes('base64')) {
      //   console.log('si banner');
      //   investorRequest.banner = gralInfo.banner.substr(
      //     gralInfo.banner.indexOf(',') + 1
      //   )
      //   investorRequest.typeBanner = 'webp'
      // }

      if (!investorRequest?.investor_banner.includes('base64')) {
        delete investorRequest.investor_banner;
      }else{
        investorRequest.typeBanner = 'webp'
      }

      //console.log('investorAction.js:updateInvestor():investorRequest',investorRequest);

      const fetch = await fetchCatalogs('prod/investorgralinfo',investorRequest,'PUT')
      const resp = await fetch.json()

      if (resp?.errorMessage) {
        customErrorAlert(resp.errorMessage)
      } else {

        customAlert('General Info', 'information updated correctly', 'success')
        dispatch(saveInvestorAction(investor, industries))

        //console.log(filledSections);

        if (filledSections.filled_sections <= 0){
          dispatch(updateFilledSection(1));
        }
          
      }

      dispatch(setLoading(false))
    } catch (error) {
      customErrorAlert(error, 'Alert')
      dispatch(setLoading(false))
    }
  }
}

export const saveInvestor = (investor, industries) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))

    const { filledSections } = getState().investor;

    try {
      const investorRequest = { ...investor }
      if (investorRequest?.investor_logo?.includes('base64')) {
        investorRequest.investor_logo = investorRequest.investor_logo.substr(
          investorRequest.investor_logo.indexOf(',') + 1
        )
        investorRequest.typeLogo = 'webp'
      }
      if (investorRequest?.banner?.includes('base64')) {
        investorRequest.banner = investorRequest.banner.substr(
          investorRequest.banner.indexOf(',') + 1
        )
        investorRequest.typeBanner = 'webp'
      }
      delete investorRequest.investorId

      const fetch = await fetchCatalogs(
        'prod/investorgralinfo',
        investorRequest,
        'POST'
      )
      const resp = await fetch.json();
      console.log({resp})
      if (resp?.errorMessage) {
        customErrorAlert(resp.errorMessage)
      } else {
        customAlert(
          'General Info',
          'information registered correctly',
          'success'
        )

        //console.log('filledSections save',filledSections);

        if (filledSections.filled_sections <= 0){
          dispatch(updateFilledSection(1));
        }

        dispatch(saveInvestorAction(investor, industries))
        dispatch(setIdInvestor(Number(resp.msg)))
      }

      dispatch(setLoading(false))
    } catch (error) {
      customErrorAlert(error, 'Alert')
      dispatch(setLoading(false))
    }
  }
}

export const setInvestor = (investor) => ({
  type: types.setInvestor,
  payload: {
    investor
  }
})

export const saveInvestorAction = (investor, industries) => ({
  type: types.saveInvestor,
  payload: {
    investor,
    industries
  }
})

export const getTeammembers = (idInvestor) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const res = await fetchCatalogs('prod/investor/teammembers?investor_id='+idInvestor)

      const membersResponse = await res.json()
      const { catHighlights, catHighlights2 } = await _getHighlightsCatalog()

      const membersConverted = membersResponse.reduce((acc, m) => {
        let catHigh = []
        let catHigh2 = []
        if (m.expertise_highlights1_selection) {
          const high1 = m.expertise_highlights1_selection
            .split(',')
            .map((x) => Number(x))
          catHigh = high1.map((h1) => {
            const valuesCat = catHighlights.find((c) => c.value == h1)
            return {
              ...valuesCat
            }
          })
        }
        if (m.expertise_highlights2_selection) {
          const high2 = m.expertise_highlights2_selection
            .split(',')
            .map((x) => Number(x))
          catHigh2 = high2.map((h2) => {
            const valuesCat2 = catHighlights2.find((c) => c.value == h2)
            return {
              ...valuesCat2
            }
          })
        }
        acc.push({
          ...m,
          expertise_highlights1_selection: catHigh,
          expertise_highlights2_selection: catHigh2
        })
        return acc
      }, [])
      dispatch(setTeammembers(membersConverted))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.log(error)
    }
  }
}

export const setTeammembers = (teammembers) => ({
  type: types.setTeammembers,
  payload: {
    teammembers
  }
})

export const newTeammember = (teammember) => ({
  type: types.newMember,
  payload: {
    teammember
  }
})

export const saveNewTeammember = (member, email, resetForm) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))

    const { filledSections } = getState().investor;

    const iHighlights1 = member.expertise_highlights1_selection
      .map((p) => p.value)
      .join()
    const iHighlights2 = member.expertise_highlights2_selection
      .map((p) => p.value)
      .join()

    const request = {
      sUserName: email,
      sName: member.member_name,
      sPosition: String(member.investor_position_id),
      iHighlights1,
      iHighlights2,
      sLinkedin: member.investor_member_linkedin,
      iExits: String(member.investor_member_previous_exits)
    }

    if (member.photo.includes('base64')) {
      request.photo = member.photo
      request.typephoto = 'webp'
    }

    try {
      const fetch = await fetchCatalogs(
        'prod/investor/teammembers',
        request,
        'POST'
      )
      const res = await fetch.json()
      if (res.result === 'ok') {
        customAlert('Team', 'Team member added successfully', 'success')
        dispatch(
          updateTeammemberAction(
            member.investor_team_member_id,
            member,
            res.msg
          )
        )

        if (filledSections.filled_sections <= 1){
          dispatch(updateFilledSection(2));
        }

        resetForm({ values: { ...member, investor_team_member_id: res.msg } })
      } else {
        customErrorAlert(res.errorMessage, 'Alert')
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const updateTeammember = (member, email, resetForm) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    const iHighlights1 = member.expertise_highlights1_selection
      .map((p) => p.value)
      .join()
    const iHighlights2 = member.expertise_highlights2_selection
      .map((p) => p.value)
      .join()

    const request = {
      sUserName: email,
      teammemberid: String(member.investor_team_member_id),
      sName: member.member_name,
      sPosition: String(member.investor_position_id),
      iHighlights1,
      iHighlights2,
      sLinkedin: member.investor_member_linkedin,
      iExits: String(member.investor_member_previous_exits)
    }

    if (member.photo.includes('base64')) {
      request.photo = member.photo
      request.typephoto = 'webp'
    }

    try {
      const fetch = await fetchCatalogs(
        'prod/investor/teammembers',
        request,
        'PUT'
      )
      const resp = await fetch.json()
      if (resp.result === 'ok') {
        customAlert('Team', 'Team member updated successfully', 'success')
        dispatch(
          updateTeammemberAction(
            member.investor_team_member_id,
            member,
            member.investor_team_member_id
          )
        )
        resetForm({ values: { ...member } })
      } else {
        customErrorAlert(resp.errorMessage, 'Alert')
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const deleteTeammember = (id, email) => {
  return async (dispatch) => {
    dispatch(setLoading(true))

    try {
      const fetch = await fetchCatalogs(
        'prod/investor/teammembers',
        { sUserName: email, teammemberid: String(id) },
        'DELETE'
      )
      const resp = await fetch.json()
      if (resp.result === 'ok') {
        customAlert('Team', 'Team member deleted successfully', 'success')
        dispatch(
          deleteTeammemberAction(id)
        )
      } else {
        customErrorAlert(resp.errorMessage, 'Alert')
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const updateTeammemberAction = (idCurrent, member, idNew) => ({
  type: types.updateMember,
  payload: {
    idCurrent,
    member,
    idNew
  }
})

export const deleteTeammemberAction = (id) => ({
  type: types.deleteMember,
  payload: { id }
})

export const updatePhotoMember = (id, photo) => ({
  type: types.updatePhotoMember,
  payload: {
    id,
    photo
  }
})

export const updatePhotoCompany = (id, photo) => ({
  type: types.updatePhotoCompany,
  payload: {
    id,
    photo
  }
})

export const getCompanies = (investorId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))

    const catIndustries = await _getCatIndustries()

    try {
      const res = await fetchCatalogs(`prod/investor/companies?investor_id=${investorId}`)
      let companies = await res.json()

      // console.log('getCompanies',companies); 

      companies = companies.reduce((acc, c) => {
        if (c.industries_selection) {
          let industries = c.industries_selection
            .split(',')
            .map((x) => Number(x))
          industries = industries.map((ind) => {
            const values = catIndustries.find((c) => c.value == ind)
            return {
              ...values
            }
          })
          acc.push({
            ...c,
            industries_selection: industries
          })
        } else {
          acc.push(c)
        }
        return acc
      }, [])

      dispatch(setCompanies(companies))
      dispatch(setLoading(false))
    } catch (error) {
      customErrorAlert(error, 'Alert')
      dispatch(setLoading(false))
    }
  }
}

export const setCompanies = (companies) => ({
  type: types.setCompanies,
  payload: {
    companies
  }
})

export const saveNewCompany = (company, username, resetForm) => {
  return async (dispatch, getState) => {
    const industries = company.industries_selection.map((p) => p.value).join()
    const { filledSections } = getState().investor;

    const request = {
      sUserName: username,
      sCompanyName: company.portfolio_name,
      sCompanyWeb: company.portfolio_website,
      sInvestmenAmnt: company.portfolio_investment_amount,
      iInvestmentRound: company.funding_stage_id,
      sIndustries: industries,
      sLeadInvestor: company.lead_investor
    }

    if (company.portfolio_logo.includes('base64')) {
      request.portfolioLogo = company.portfolio_logo
      request.fileType = 'webp'
    }

    try {
      dispatch(setLoading(true))
      const fetch = await fetchCatalogs(
        'prod/investor/companies',
        request,
        'POST'
      )
      const res = await fetch.json()
      if (res.result === 'ok') {
        customAlert('Company', 'Company added successfully', 'success')
        dispatch(updateCompanyAction(company.portfolio_id, company, res.msg))

        if (filledSections.filled_sections <= 2){
          dispatch(updateFilledSection(3));
        }

        resetForm({ values: { ...company, portfolio_id: res.msg } })
      } else {
        customErrorAlert(res.errorMessage, 'Alert')
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const updateCompany = (company, username, resetForm) => {
  return async (dispatch) => {
    const industries = company.industries_selection.map((p) => p.value).join()

    const request = {
      IdPortafolio: String(company.portfolio_id),
      sUserName: username,
      sCompanyName: company.portfolio_name,
      sCompanyWeb: company.portfolio_website,
      sInvestmenAmnt: String(company.portfolio_investment_amount),
      iInvestmentRound: String(company.funding_stage_id),
      sIndustries: industries,
      sLeadInvestor: String(company.lead_investor)
    }

    if (company?.portfolio_logo?.includes('base64')) {
      request.portfolioLogo = company.portfolio_logo
      request.fileType = 'webp'
      company.portfolioLogo = request.portfolio_logo
    }

    try {
      dispatch(setLoading(true))
      const fetch = await fetchCatalogs(
        'prod/investor/companies',
        request,
        'PUT'
      )
      const res = await fetch.json()
      if (res.result === 'ok') {
        customAlert('Company', 'Company updated successfully', 'success')
        dispatch(
          updateCompanyAction(
            company.portfolio_id,
            company,
            company.portfolio_id
          )
        )
        resetForm({ values: { ...company } })
      } else {
        customErrorAlert(res.errorMessage, 'Alert')
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const updateCompanyAction = (idCurrent, company, idNew) => ({
  type: types.updateCompany,
  payload: {
    idCurrent,
    company,
    idNew
  }
})

export const newCompany = (company) => ({
  type: types.newCompany,
  payload: {
    company
  }
})

export const updateImgGralInfo = (img) => ({
  type: types.updateImgGralInfo,
  payload: {
    img
  }
})

export const updateBannerGralInfo = (img) => ({
  type: types.updateBannerGralInfo,
  payload: {
    img
  }
})

export const deletePortfolio = (id) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))

    try {
      const email = getState().ui.email
      const fetch = await fetchCatalogs(
        'prod/investor/companies',
        { sUserName: email, IdPortafolio: String(id) },
        'DELETE'
      )
      const resp = await fetch.json()
      if (resp.result === 'ok') {
        customAlert('Company', 'Company deleted successfully', 'success')
        dispatch(deletePortfolioAction(id))
      } else {
        customErrorAlert(resp.errorMessage, 'Alert')
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const deletePortfolioAction = (id) => ({
  type: types.deleteCompany,
  payload: { id }
})

export const getSavedCompanies = (idInvestor = '1', pageNum = '1', showLoading = true) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(showLoading))
    try {
      const companies = await _getSavedCompanies(idInvestor, pageNum)
      if (companies.length > 0) {
        dispatch(getSavedCompaniesAction(companies))
        dispatch(setSavedPageAction(pageNum))
      }

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      if(showLoading){
        customErrorAlert(error, 'Alert')
      }
    }
  }
}

const getSavedCompaniesAction = (companies) => ({
  type: types.getSavedCompanies,
  payload: companies
})

const setSavedPageAction = (page: string) => ({
  type: refreshTypes.setSavePageActive,
  payload: { saved: {page: page} },
});

export const getInvestorAbout = (idInvestor, showLoading = true) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(showLoading))
    try {
      const { company_investor_id } = getState().notifications.notificationCount;
      const email = getState().ui.email
      const about = await _getInvestorAbout(email, idInvestor, String(company_investor_id)) //pasar tercer parametro el company invetor _
      if (about.length > 0) dispatch(getInvestorAboutAction(about[0]))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      if(showLoading){
        customErrorAlert(error, 'Alert')
      }
    }
  }
}

const getInvestorAboutAction = (about) => ({
  type: types.getAboutInvestor,
  payload: about
})

export const setIdInvestor = (id) => ({
  type: types.setIdInvestor,
  payload: id
})


export const getInvestmentPreferences = (idInvestor) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { catStates, catIndustries, catFunding, catRevenue} = getState().cats.investor;
      const resp = await _getInvestmentPreferences(idInvestor)
      if (resp.length > 0) {
        const investment = resp[0]
        let state = investment.state.split(',').map((x) => Number(x))
        state = state.map((ind) => {
          const values = catStates.find((c) => c.state_id === ind);
          return {
            value: values['state_id'],
            label: values['state_name'],
          }
        });

        let industries = investment.industries.split(',').map((x) => Number(x))
        industries = industries.map((x) => {
          const values = catIndustries.find((c) => c.value === x);
          return {
            ...values,
          }
        });

        let funding = investment.funding.split(',').map((x) => Number(x))
        funding = funding.map((x) => {
          const values = catFunding.find((c) => c.funding_stage_id === x);
          return {
            value: values['funding_stage_id'],
            label: values['funding_stage_desc'],
          }
        });

        let annualGross = investment.annualGross.split(',').map((x) => Number(x))
        annualGross = annualGross.map((x) => {
          const values = catRevenue.find((c) => c.id === x);
          return {
            value: values['id'],
            label: values['description'],
          }
        });

        const respToRedux = {
          ...investment,
          state,
          industries,
          funding,
          annualGross
        }
        console.log(respToRedux)
        dispatch(getInvestmentPreferencesAction(respToRedux))
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const getInvestmentPreferencesAction = (preferences) => ({
  type: types.setPreferences,
  payload: { preferences }
})

export const saveInvestmentPreferences = (preferences) => {
  return async (dispatch, getState) => {
    const { filledSections } = getState().investor;
    dispatch(setLoading(true))
    try {
      const { investorId } = getState().investor;
      const preferencesReq = {
          industries: preferences.industries.map((x) => x.value).join(),
          funding: preferences.funding.map((x) => x.value).join(),
          state: preferences.state.map((x) => x.value).join(),
          annualGross: preferences.annualGross.map((x) => x.value).join(),
      }
      const res = await _saveInvestmentPreferences(investorId, preferencesReq)
      if (res.result === 'ok') {
        customAlert('Preferences', 'Information updated correctly', 'success')
        if (filledSections.filled_sections <= 3){
          dispatch(updateFilledSection(4));
        }
      }

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      customErrorAlert(error, 'Alert')
    }
  }
}

export const getFilledSectionsInvestor = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { email } = getState().ui;
      const sections = await _getFilledSectionsInvestor(email);
      if ((await sections).length > 0)
        dispatch(getFilledSectionsInvestorAction(sections[0]));
        dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };
};

export const getFilledSectionsInvestorAction = (
  filledSection
) => ({
  type: types.getFilledSections,
  payload: filledSection,
});

export const updateFilledSection = (id) => ({
  type: types.updateFilledSection,
  payload: id,
});

export const submitInvestor = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { email } = getState().ui;
      const submitted = await _submitInvestor(email);
      if (submitted.result === "ok") dispatch(submitInvestorAction());
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const submitInvestorAction = () => ({
  type: types.submitInvestor,
});

export const setValidFormAction = (component, isValid) => ({
  type: types.setValidForm,
  payload: {
    component,
    isValid,
  },
});