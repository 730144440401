import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSearchParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Chatclient } from './Chatclient';
import { StoreApp } from '../../../@core/interfaces/store';
// import { URL_SOCKET } from "../../../@core/interfaces/chat.model";
import { URL_SOCKET } from "../../../../src/environment";
import { setChatConnection, getUsersChatMatch, setActiveChat, setCountChat } from '../../../redux/actions/chatAction';
import imgUser from '../../../assets/images/user.jpg';
import { ChatResponse, PropsChatSocket } from "../../../@core/interfaces/chat.model";
import { TypeUser } from "../../../@core/interfaces/common.model";
import { setLoading } from "../../../redux/actions/uiAction";
import * as _ from 'lodash';
import { Gralinfo } from '../InvestorComp/Gralinfo';

export const Chat = () => {

  const dispatch = useDispatch(); 
  const { pathname } = useLocation();
  const socket = useRef<WebSocket | null>(null);
  const [searchParams] = useSearchParams();

  const [ members, setMembers ] = useState<ChatResponse[]>([]);
  const [ chatRows, setChatRows ] = useState<ChatResponse[]>([]);
  const { userInfo } = useSelector((state: StoreApp) => state.ui)
  const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
  const [ chatActive, setChatActive ] = useState<ChatResponse[]>([]);

  const { chatStatusConnection ,chatResponse, activeChat } = useSelector((store: StoreApp) => store.chat);
  const { company, investor } = useSelector((store: StoreApp) => store);
  const userType = Number(notificationCount.type);
  
  const user_id = userType == TypeUser.INVESTOR ? investor.investorId : (userType == TypeUser.COMPANY) ? company.idCompany : 0;
  const userTypeToSend = userType === TypeUser.INVESTOR ? TypeUser.COMPANY : TypeUser.INVESTOR;
  const isCompanyType = userType === TypeUser.COMPANY;
  const [reconect, setReconect] = useState(false)
  const [reconecting, setReconecting ] = useState(false)
  const [activePingSoccket, setActivePingSoccket] = useState(false)

  const idUserChatGet =  Number(searchParams.get('idUserChat')) || 0;
  const myActiveChat = idUserChatGet > 0 ? idUserChatGet : activeChat;

  //console.log('userInfo',userInfo);

 
  const onSocketOpen = useCallback(() => {

    if(isNaN(user_id) || isNaN(userType)){
        return
    }
    
    dispatch(setChatConnection(true));
    //Obtener todos los chats
    const allMessages = {"action": "getAllChatNames","id_usr":String(user_id),"type_usr":String(userType)}
    socket.current?.send(JSON.stringify(allMessages))

    //Login al chat
    console.log('Conected on chat With id:',String(user_id))
    const data = {action: "setName",id_usr:user_id,type_usr:userType}
    socket.current?.send(JSON.stringify(data))

  }, [user_id, userType]);

  const onSocketClose = useCallback(() => {
    //console.log('posible clear on onSocketClose')
    dispatch(setChatConnection(false))
    setMembers([])
    setChatRows([])

  }, [dispatch, setChatConnection]);
   
  const onSocketMessage = useCallback((dataStr) => {
    const data = JSON.parse(dataStr);
    console.log('onSocketMessage',data);

    const key = Date.now()
    const date = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format(key)+', Today'

    //Mis mensajes 
    if (data.action === 'ConfMsg') {
        //formatear el mensaje
        const msg: any = {
            chat_id: key,
            created_timestamp: date,
            message: data.mensaje,
            message_id: key,
            receiver_id: data.extradata,
            sender_id: user_id,
        }
        // console.log('msg my',msg)
        setChatRows(oldArray => [...oldArray, msg]);
    }
    //mensajes de contacto
    if (data.action === 'newMsg') {
        //formatear el mensaje
        const msg: any = {
            chat_id: key,
            created_timestamp: date,
            message: data.mensaje,
            message_id: key,
            receiver_id: user_id,
            sender_id: data.extradata,
        }
        //console.log('msg con',msg)
        setChatRows(oldArray => [...oldArray, msg ]);
    }
    
    //Cargar todos los mensajes de una conversacion
    if (data.action === 'ChatsMsg') {
        setChatRows([])

        const messages = data.extradata; 
        if(messages.length>0){

            const max = 30;
            const last50msg = messages.reverse().filter( (msgr: any, j: number) =>{
                return (j < max)
            })

            // console.log('last50msg',last50msg)
            // process.exit(1)
            //EN LO QUE NO HAYA UN PAGINADOR DE MENSAJES SE LIMITARA SOLO A MAX MENSAJES

            last50msg.reverse().map( (msg: any) => {

                const key = Date.now()
                let fnow = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(key)
                const aux = fnow.split('/').reverse().map( (l) => (l))
                fnow = aux[0]+'/'+aux[2]+'/'+aux[1]
                const t = msg.created_timestamp.split(' ');
                const f = t[0];
                let ff = f.replace(/-/g,'/');
                //console.log('msg',fnow)
                if(ff == fnow){
                    ff='Today'
                }

                const t1 = t[1];
                const pt = t1.split(':');
                const pt1 = Number(pt[0]);
                const date = (pt1>12?(pt1-12):pt1)+':'+pt[1]+' '+((pt1>12)?'PM':'AM')+', '+ff
            
                

                //console.log('msg',msg)

                const msgf: any = {
                    chat_id: msg?.chat_id,
                    created_timestamp: date,
                    message: msg?.message,
                    message_id: msg?.message_id,
                    receiver_id: msg?.receiver_id,
                    receiver_type: msg?.receiver_type,
                    sender_id: msg?.sender_id,
                    sender_type: msg?.sender_type,
                    viewed: msg?.viewed,
                }
                //console.log('msgf',msgf)

                setChatRows(oldArray => [...oldArray, msgf]);  
            })
        }
  
    }
    // Carlos los chats
    if (data.action === 'AllChatNames') {
        setMembers([])
        const chatMembers: PropsChatSocket[] = data.extradata
        // console.log('chatResponse',chatResponse);
        //console.log('chatMembers',chatMembers);

        const idUserChat =  Number(searchParams.get('idUserChat')) || 0;
        const imgUserChat0 =  String( searchParams.get('imgUserChat'))
        const nameUserChat =  String(searchParams.get('nameUserChat'));
        const imgUserChat = imgUserChat0.replace(/XBZSWRTYSOOOF/g,'&');

        //Si aun no tiene ningun mensaje con alguien
        if(chatMembers.length === 0 && idUserChat > 0){
            const temChatUser: ChatResponse[] = [{
                company_id: isCompanyType ? 0 : idUserChat,
                company_logo: isCompanyType? "" : imgUserChat,
                company_name: isCompanyType ? "" : nameUserChat,

                investor_fund_name: isCompanyType ? nameUserChat : "",
                investor_id: isCompanyType ? idUserChat : 0,
                investor_logo: isCompanyType ? imgUserChat : "",
                show_chat: 1
            }]

            //console.log('temChatUser', temChatUser)

            setChatActive(temChatUser)
            setMembers(temChatUser)

            // const userActive: ChatResponse[] = chatResponse.filter( (chat) => ( 
            //     (isCompanyType ? chat.investor_id : chat.company_id) === activeChat )
            // );

            // console.log('userActive',userActive)

            // setChatActive(userActive)
            // setMembers(userActive)
            
        } 
        //Si es que ya hay mensajes para enviar
        if(chatMembers.length > 0){
            const idChatActive = !isNaN(idUserChat) && idUserChat > 0 ? idUserChat : myActiveChat

            const formatedChatMembers0: ChatResponse[] = chatMembers.map( (chat)=>{
                return {
                    investor_id: chat.investor_id,
                    investor_logo: chat.url_logo,
                    investor_fund_name: chat.investor_fund_name,
                    company_id: chat.company_id,
                    company_logo: chat.url_logo,
                    company_name: chat.company_name,
                    show_chat: 1
                }
            })

            
            const temChatUser: ChatResponse[] = [{
                company_id: isCompanyType ? 0 : idUserChat,
                company_logo: isCompanyType? "" : imgUserChat, //'imgUser' usar si no se ve la imagen
                company_name: isCompanyType ? "" : nameUserChat,

                investor_fund_name: isCompanyType ? nameUserChat : "",
                investor_id: isCompanyType ? idUserChat : 0,
                investor_logo: isCompanyType ? imgUserChat : "", //'imgUser' usar si no se ve la imagen
                show_chat: 1
            }]

            // console.log('formatedChatMembers0',formatedChatMembers0)
            // console.log('temChatUser',temChatUser)

            let posibleNewMember: ChatResponse[] = temChatUser.filter( (chat) => ( 
                (isCompanyType ? chat.investor_id : chat.company_id) === idChatActive && idChatActive >0 )
            );

            //console.log('posibleNewMember',posibleNewMember)

            const aux = formatedChatMembers0.filter( (chat) =>(
                (isCompanyType ? chat.investor_id : chat.company_id) === (isCompanyType ? posibleNewMember[0]?.investor_id : posibleNewMember[0]?.company_id)
            ))

            //console.log('aux',aux)

            if(aux.length>0){
                posibleNewMember = [];
            }
            


            // let posibleNewMember: ChatResponse[] = chatResponse.filter( (chat) => ( 
            //     (isCompanyType ? chat.investor_id : chat.company_id) === activeChat )
            // );

            const formatedChatMembers = [...formatedChatMembers0, ...posibleNewMember];

            setMembers(formatedChatMembers)
            // setMembers(formatedChatMembers0)

            const userActive0 = chatMembers.filter( (chat: PropsChatSocket) => ( 
                (isCompanyType ? chat.investor_id : chat.company_id) === idChatActive )
            );

            const userActive: ChatResponse[] = userActive0.map( (chat: PropsChatSocket) => {
                return {
                    investor_id: chat.investor_id,
                    investor_logo: chat.url_logo,
                    investor_fund_name: chat.investor_fund_name,
                    company_id: chat.company_id,
                    company_logo: chat.url_logo,
                    company_name: chat.company_name,
                    show_chat: 1
                }
            })

            const userActiveToSet = posibleNewMember.length>0 ? posibleNewMember : userActive

            setChatActive(userActiveToSet)
            // setChatActive(userActive)
        }

        //Habiliar nuevamente el posible reconect socket
        setReconect(true)
        setReconecting(false)
        setActivePingSoccket(true)

        if(myActiveChat > 0){
            dispatch(setActiveChat(myActiveChat))
            handleGetClickUser(myActiveChat)
        }
    }

    dispatch(setLoading(false))

  }, [user_id,userInfo, isCompanyType, myActiveChat, chatResponse, reconect, imgUser]);

  const onConnect = useCallback((showLoader = true) => {
    if (socket.current?.readyState !== WebSocket.OPEN) {
      dispatch(setLoading(showLoader))
      setReconecting(true)
      socket.current = new WebSocket(URL_SOCKET);
      socket.current.addEventListener('open', onSocketOpen);
      socket.current.addEventListener('close', onSocketClose);
      socket.current.addEventListener('message', (event) => {
        onSocketMessage(event.data);
      });
    }
  }, [userInfo, user_id, chatResponse,URL_SOCKET, chatResponse, imgUser]);

  useEffect(() => { 
    

    if(chatResponse.length === 0){
        dispatch(getUsersChatMatch())
    }

  },[userInfo])


//   console.log('idUserChatGet 3',idUserChatGet)
//   console.log('activeChat ',activeChat)
  useEffect(() => {

    //console.log('myActiveChat',myActiveChat)
    if(myActiveChat !== 0){
        dispatch(setActiveChat(myActiveChat))
    }
    
    //Si hay contactos para chatear y si no se han asignado mienbros del chat a la lista
    // if(chatResponse.length>0 && members.length == 0){
    if(members.length == 0){
        dispatch(setCountChat(0));
        onConnect()
    } 

    return () => {
      socket.current?.close();
    };
  }, [userInfo, user_id, chatResponse, idUserChatGet, dispatch]);

    const reconectSocket = () => {
        if (socket.current?.readyState !== WebSocket.OPEN) {
            console.log('Reconectando...')
            onConnect()
        }
    }
    //--------------------------------------------------------RECONECTAR SOCKET ------------------------------
    if (socket.current?.readyState !== WebSocket.OPEN && reconect && !reconecting && pathname === '/chat') {
        setReconect(false)
        console.log('Reconectando...',socket.current?.readyState)
        onConnect(false)
    }
    //--------------------------------------------------------RECONECTAR SOCKET ------------------------------

  const onSendPrivateMessage = useCallback((message: string, to: string) => {
    reconectSocket()
    const privateMesaage = {"action": "sendMessage","Message": message,"from": user_id,"to": String(to),"type_to": userTypeToSend}
    console.log('privateMesaage',privateMesaage);
    socket.current?.send(JSON.stringify(privateMesaage));
  }, [user_id, userTypeToSend]);


  const onSendPublicMessage = useCallback((message) => {
    const jsonc = JSON.stringify({action: 'sendBroadcast', message: message });
    socket.current?.send(jsonc);
  }, []);

  const onDisconnect = useCallback(() => {
      socket.current?.close();
  }, []);

  //Click al contacto del menu izquierdo
  const handleGetClickUser = useCallback((id: number) => {
    
    dispatch(setActiveChat(id))

    reconectSocket()
    // console.log('id',id,members)

    //console.log('posible clear on click')
    setChatRows([])
    let idCompany = user_id;
    let idInvestor = id;

    if(userTypeToSend === TypeUser.COMPANY){
        idCompany = id;
        idInvestor = user_id;
    }

    const messages = {
        "action": "getChatsMsg",
        "id_company": String(idCompany),
        "id_investor": String(idInvestor),
        "type_usr":String(userTypeToSend),
        "all_msg":"1" // 1 all, 0 solo los nuevos
    };

    //console.log('messages',messages);
    socket.current?.send(JSON.stringify(messages))

  }, [user_id, userTypeToSend, members]);


    //interval para enviar ping
    useEffect(() => {
        const interval = setInterval(() => {
            if(pathname === '/chat'){
                //console.log('ping websocket chat')
                socket.current?.send(JSON.stringify({"action": "ping"}));
            }
        }, 300000);
    }, [dispatch]);

  return  <>
  <Chatclient
    isConnected={chatStatusConnection}
    members={members}
    chatRows={chatRows}
    chatActive={chatActive}
    onPublicMessage={onSendPublicMessage}
    onPrivateMessage={onSendPrivateMessage}
    // onConnect={onConnect}
    onDisconnect={onDisconnect}
    handleGetClickUser={handleGetClickUser}
  />
  {/* <button
    onClick={onDisconnect}
  >Cerrar</button> */}
  </>

}