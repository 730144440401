import { fetchCatalogs } from "../../helpers/fetch";
import { Search, SearchResponse } from "../interfaces/search.model";

export const searchService = async (
  idCompany: number,
  search: Search | null,
  pagina: string = '1',
  tipoBusqueda?: string,
): Promise<SearchResponse[]> => {
//   const num_pag = "1";
  const regxpag = "8"; // Número máximo de registros por página.
  //   const tipo_busqueda = "0";

  // tipo de búsqueda
  // 0: no contactables
  // 1: contactables
  // 2: Filtro normal

  let queryParams = "";
  if (search) {
      const valuesJoin = Object.keys(search).reduce((acc, key) => {
        return {
          ...acc,
          [key]: search[key as keyof Search].map((p) => p.value).join(),
        };
      }, {});
      Object.entries(valuesJoin).forEach(([key, value], i) => {
        queryParams += `&${key}=${value}`;
      });
  }

  const res = await fetchCatalogs(
    "prod/search/investor?company_id=" +
      idCompany +
      queryParams +
      "&regxpag=" +
      regxpag +
      "&tipo_busqueda=" +
      tipoBusqueda +
      "&num_pag=" +
      pagina
  );
  return await res.json();
};
