import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { Col, Row, Card } from 'react-bootstrap';
import { InvestorMatchActivity } from './InvestorMatchActivity'
import { FeaturedCompanies } from './FeaturedCompanies'
import { SavedCompaniesHome } from './SavedCompaniesHome'
import { getFeaturedInvestors, getKpis, getMatchActivityHome, getSavedInvestors } from '../../../redux/actions/homeAction';
import { TypeUser } from '../../../@core/interfaces/common.model';

interface Props {
    handleGetIdToShow: (id: number)=> void;
}

export const HomeInvestor = ({ handleGetIdToShow }: Props) => {

    const dispatch = useDispatch();
    const { kpis, featuredInvestors, savedInvestors, matchActivity } = useSelector((store: StoreApp) => store.home);
    const { company_investor_id } = useSelector((store: StoreApp) => store.notifications.notificationCount);
    const userType = Number(localStorage.getItem('type'));

    useEffect(() => {
        if(company_investor_id) {
            dispatch(getKpis())
            dispatch(getFeaturedInvestors())
            dispatch(getMatchActivityHome())
            dispatch(getSavedInvestors('1','3'))
        }
    }, [dispatch, company_investor_id]);

    const ContenedorTopCardsInvestor = () => {
        return (
            <div className="contenedor-top-cards row investor">
                <Col md={6} sm={12} lg={4}>
                    <Card className='tarjeta color1 mb-3'>
                        <Card.Body>
                            <Card.Text>
                                Company Requests:
                            </Card.Text>
                            <Row className='contenedor-txt-bottom'>
                                <Col className="fw-bolder">
                                    {kpis.company_requests}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} sm={12} lg={4}>
                    <Card className='tarjeta color2 mb-3'>
                        <Card.Body>
                            <Card.Text>
                                Invitations Sent:
                            </Card.Text>
                            <Row className='contenedor-txt-bottom'>
                                <Col className="fw-bolder">
                                    {kpis.invitations_sent}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} sm={12} lg={4}>
                    <Card className='tarjeta color3 mb-3'>
                        <Card.Body>
                            <Card.Text>
                                In Progress:
                            </Card.Text>
                            <Row className='contenedor-txt-bottom'>
                                <Col className="fw-bolder">
                                    {kpis.in_progress}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        );
    }

    // const ContenedorTopCardsCompany = () => {
    //     return (
            // <div className="contenedor-top-cards row company">
            //     <Col md={6} sm={12} lg={4}>
            //         <Card className='tarjeta color1 mb-3'>
            //             <Card.Body>
            //                 <Card.Text>
            //                     Investor Invitations:
            //                 </Card.Text>
            //                 <Row className='contenedor-txt-bottom'>
            //                     <Col className="fw-bolder">
            //                         {kpis.investor_invitations}
            //                     </Col>
            //                 </Row>
            //             </Card.Body>
            //         </Card>
            //     </Col>
            //     <Col md={6} sm={12} lg={4}>
            //         <Card className='tarjeta color2 mb-3'>
            //             <Card.Body>
            //                 <Card.Text>
            //                     Requests Sent:
            //                 </Card.Text>
            //                 <Row className='contenedor-txt-bottom'>
            //                     <Col className="fw-bolder">
            //                         {kpis.requests_sent}
            //                     </Col>
            //                 </Row>
            //             </Card.Body>
            //         </Card>
            //     </Col>
            //     <Col md={6} sm={12} lg={4}>
            //         <Card className='tarjeta color3 mb-3'>
            //             <Card.Body>
            //                 <Card.Text>
            //                     In Progress:
            //                 </Card.Text>
            //                 <Row className='contenedor-txt-bottom'>
            //                     <Col className="fw-bolder">
            //                         {kpis.matches_in_progress}
            //                     </Col>
            //                 </Row>
            //             </Card.Body>
            //         </Card>
            //     </Col>
            // </div>
    //     );
    // }


    console.log('matchActivity',matchActivity)
    return (
        <>
            <div className='_investor-home'>
             <ContenedorTopCardsInvestor /> 

                <div className="contenedor-match-activity">
                    <div className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                        <div className='titulo-seccion'>MATCH ACTIVITY</div>
                        <div className="line"></div>

                    </div>
                    {/* <FeautredInvestorItem investors={featuredInvestors}></FeautredInvestorItem> */}
                    <InvestorMatchActivity matchActivity={matchActivity} handleGetIdToShow={handleGetIdToShow} />
                </div>

                <div className="contenedor-match-activity">
                    <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                        <div className='titulo-seccion'>FEATURED COMPANIES</div>
                        <div className="line"></div>

                    </p>
                    {/* <FeautredInvestorItem investors={featuredInvestors}></FeautredInvestorItem> */}
                    <FeaturedCompanies featured={featuredInvestors} handleGetIdToShow={handleGetIdToShow} />
                </div>

                <div className="contenedor_bottom_saved">
                    <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                        <div className='titulo-seccion'>SAVED COMPANIES</div>
                        <div className="line"></div>                   </p>
                    <SavedCompaniesHome savedInvestors={savedInvestors} handleGetIdToShow={handleGetIdToShow} />
                </div>
            </div>
        </>

    )
}