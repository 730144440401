import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from '../../../@custom/components/form/MyTextInput';
import { MySelect } from '../../../@custom/components/form/MySelect';
import logoPlaceholder from '../../../assets/images/Logo-placeholder.jpg';
import { PencilDot } from '../../../@custom/components/PencilDot/PencilDot';
import { MultiSelect } from '../../../@custom/components/form/Multiselect/MultiSelect';
import { Col, Row, Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deletePortfolio, deletePortfolioAction, saveNewCompany, updateCompany, updatePhotoCompany } from '../../../redux/actions/investorAction';
import { customQuestion } from '../../../helpers/alerts';
import CurrencyInput from "../../../@custom/components/CurrencyInput";



export const PortfolioItem = React.forwardRef(({ portfolio, handleActiveKey, handleValidatePortfolio }, ref) => {


    const dispatch = useDispatch();
    const formikRef = useRef();
    const { filledSections } = useSelector((state) => state.investor);
    const { email, loading } = useSelector(state => state.ui);
    const { investor: cats } = useSelector(state => state.cats);
    const [reloadFormPortfolio, setreloadFormPortfolio] = useState(true);

    useImperativeHandle(ref, () => ({
        formikRef,
        handleSubmit() {
            if (formikRef.current.dirty && formikRef.current.values.portfolio_id !== 0) {
                formikRef.current.handleSubmit();
            }
            if (formikRef.current.values.portfolio_id === 0) formikRef.current.handleSubmit();
        },

    }));

    const handleSubmitEachCard = () => {
        const pia = formikRef.current.values.portfolio_investment_amount.toString();
        const value = pia.replace(/[^0-9.-]+/g, "");
        formikRef.current.setValues((v) => ({
            ...v,
            portfolio_investment_amount: value,
        }));

        if (formikRef.current.dirty && formikRef.current.values.portfolio_id !== 0) {
            formikRef.current.handleSubmit();
        }
        if (formikRef.current.values.portfolio_id === 0) formikRef.current.handleSubmit();
    }

    const handleImgPencil = (img) => {
        setreloadFormPortfolio(false);
        formikRef.current.setFieldValue('portfolio_logo', img)
        dispatch(updatePhotoCompany(portfolio.portfolio_id, img))
    }

    const handleDelete = async () => {
        if (portfolio.portfolio_id === 0) return dispatch(deletePortfolioAction(portfolio.portfolio_id));
        const result = await customQuestion('Are you sure you want to delete the company?', 'Yes, delete');
        if (result?.isConfirmed) {
            dispatch(deletePortfolio(portfolio.portfolio_id));
            handleValidatePortfolio();
        }
    }

    useEffect(() => {

        // console.log('reloadFormPortfolio',reloadFormPortfolio)
        // console.log('portfolio',portfolio) 

        if (reloadFormPortfolio) {
            formikRef?.current?.setValues((v) => ({ 
                ...v, 
                portfolio_id: portfolio.portfolio_id,
                portfolio_logo: portfolio.portfolio_logo,
                portfolio_name: portfolio.portfolio_name,
                portfolio_website: portfolio.portfolio_website,
                industries_selection: portfolio.industries_selection,
                portfolio_investment_amount: portfolio.portfolio_investment_amount,
                funding_stage_id: portfolio.funding_stage_id,
                lead_investor: portfolio.lead_investor
            }));
        }

    }, [portfolio, reloadFormPortfolio]);

    const handleBlurForm = () => {
        setTimeout(() => {
            handleValidatePortfolio();
        }, 100)
    };

    useEffect(() => {
        return () => {
            handleValidatePortfolio();
        }
    }, [])

    return (
        <>
            {
                portfolio &&
                <Accordion.Item
                    className="modifMargenBajo"
                    eventKey={portfolio.portfolio_id}
                    key={portfolio.portfolio_id}
                >

                    <Accordion.Header className="estilos-header" onClick={() => handleActiveKey(portfolio.portfolio_id)}>
                        <div className="centrar-header">

                            <div className="contenedorImgCabecera">
                                <img src={portfolio.portfolio_logo || logoPlaceholder} alt="logo" className="imgRedondaCabecera" />
                            </div>
                            <span className="textoCabecera">
                                {portfolio.portfolio_name}
                            </span>
                        </div>
                    </Accordion.Header>

                    <Accordion.Body>
                        <Formik
                            validate={() => { handleBlurForm() }}
                            innerRef={formikRef}
                            key={portfolio.portfolio_id}
                            initialValues={{
                                portfolio_id: portfolio.portfolio_id,
                                portfolio_logo: portfolio.portfolio_logo,
                                portfolio_name: portfolio.portfolio_name,
                                portfolio_website: portfolio.portfolio_website,
                                industries_selection: portfolio.industries_selection,
                                portfolio_investment_amount: portfolio.portfolio_investment_amount,
                                funding_stage_id: portfolio.funding_stage_id,
                                lead_investor: portfolio.lead_investor
                            }}
                            onSubmit={async (values, { resetForm }) => {
                                // console.log('values',values)
                                // return
                                if (values.portfolio_id === 0) {
                                    dispatch(saveNewCompany(values, email, resetForm));
                                }
                                // Update
                                if (values.portfolio_id !== 0) {
                                    dispatch(updateCompany(values, email, resetForm));
                                }
                            }}
                            validationSchema={Yup.object({
                                portfolio_logo: Yup.string().required('Required'),
                                portfolio_name: Yup.string().required('Required'),
                                portfolio_website: Yup.string().required('Required'),
                                industries_selection: Yup.array().min(1, 'Required').required('Required'),
                                // portfolio_investment_amount: Yup.string().required('Required'),
                                funding_stage_id: Yup.string().required('Required'),
                                lead_investor: Yup.string().required('Required')
                            })
                            }>

                            {(formik) => (
                                <Form>
                                    <Row className={filledSections.filled_sections === 5? "disable-mode" : ""}>
                                        <Col lg={2} className="my-2">
                                            <div className="cajaImg my-2">
                                                <img src={portfolio.portfolio_logo || logoPlaceholder} alt="logo" style={formik.errors?.portfolio_logo && { border: '1px solid rgb(220, 53, 69)' }}  className="imgRedonda1" />
                                                <div className="contentDot4">
                                                    <PencilDot isWebp={true} validation1={true} handleImgPencil={handleImgPencil} />
                                                </div>
                                            </div>
                                            {
                                                formik.errors?.portfolio_logo && (
                                                    <div className="w-100 text-center">
                                                        <small style={{ color: 'rgb(220, 53, 69)' }}>{'Required logo'} </small>
                                                    </div>
                                                )
                                            }

                                        </Col>

                                        <Col lg={10} className="my-2">
                                            <Row>
                                                <Col lg={6} className="my-2">
                                                    <MyTextInput
                                                        label="Company Name:"
                                                        name="portfolio_name"
                                                        placeholder="Put the company name here"
                                                        errors={formik.errors}
                                                    />
                                                </Col>
                                                <Col lg={6} className="my-2">
                                                    <MyTextInput
                                                        label="Company Website:"
                                                        name="portfolio_website"
                                                        placeholder="Put the company website here"
                                                        errors={formik.errors}
                                                    />
                                                </Col>
                                                <Col lg={6} className="my-2">
                                                    <MultiSelect
                                                        label="Industries:"
                                                        name="industries_selection"
                                                        onChange={formik.setFieldValue}
                                                        onBlur={formik.setFieldTouched}
                                                        error={formik.errors.industries_selection}
                                                        touched={formik.touched.industries_selection}
                                                        value={formik.values.industries_selection}
                                                        options={cats?.catIndustries}
                                                        textTooltip="Industry or industries where the company provides products/services"
                                                    >
                                                    </MultiSelect>

                                                </Col>
                                                <Col lg={6} className="my-2">

                                                    <MySelect label="Funding Stage:" name="funding_stage_id" errors={formik.errors}
                                                        infoIcon1={true}
                                                        textTooltip="What round did you invest in?">
                                                        <option value="">Select...</option>
                                                        {cats?.catFunding.map((p) => (
                                                            <option value={p.funding_stage_id} key={p.funding_stage_id}>{p.funding_stage_desc}</option>
                                                        ))}
                                                    </MySelect>
                                                </Col>
                                                <Col lg={6} className="my-2">
                                                    {/* <MyTextInput
                                                        label="Investment Amount:"
                                                        name="portfolio_investment_amount"
                                                        placeholder=""
                                                        errors={formik.errors}
                                                        infoIcon={true}
                                                        textTooltip="How much money did you invest in this company?"
                                                    /> */}
                                                    <MySelect label="Lead Investor" name="lead_investor" errors={formik.errors}
                                                        onChange={formik.setFieldValue}
                                                        onBlur={formik.setFieldTouched}
                                                        touched={formik.touched.lead_investor}
                                                        value={formik.values.lead_investor}
                                                        infoIcon1={true}
                                                        textTooltip="pendiente">
                                                        <option value="">Select...</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>

                                                    </MySelect>
                                                </Col>
                                                <Col lg={6} className="my-2">
                                                    {/* <MySelect label="Funding Stage:" name="funding_stage_id" errors={formik.errors}
                                                    infoIcon1={true}
                                                    textTooltip="What round did you invest in?">
                                                        <option value="">Select...</option>
                                                        {cats?.catFunding.map((p) => (
                                                            <option value={p.funding_stage_id} key={p.funding_stage_id}>{p.funding_stage_desc}</option>
                                                        ))}
                                                    </MySelect> */}
                                                    {/* <MyTextInput
                                                        label="Investment Amount:"
                                                        name="portfolio_investment_amount"
                                                        type="number"
                                                        placeholder=""
                                                        errors={formik.errors}
                                                        infoIcon={true}
                                                        textTooltip="How much money did you invest in this company?"
                                                    /> */}

                                                    <Field name="portfolio_investment_amount">
                                                        {({ field }) => (
                                                            <CurrencyInput
                                                                {...field}
                                                                errors={formik.errors}
                                                                placeholder="$00.00"
                                                                textTooltip="How much money did you invest in this company?"
                                                                label="Investment Amount (Optional):"
                                                                // onBlur={handleBlurFormTextMoney}
                                                                type="text"
                                                            />
                                                        )}
                                                    </Field>
                                                </Col>


                                                <Col md={12} className='mt-3'>
                                                    <div className="d-flex justify-content-end contenedor-botones-investor">
                                                        <button type="button" className="btn btn-outline-primary"
                                                            disabled={filledSections.filled_sections === 5}
                                                            onClick={handleDelete}>Delete Company</button>

                                                        <button type="button" className="btn btn-primary ms-3"
                                                            onClick={handleSubmitEachCard}
                                                            disabled={filledSections.filled_sections === 5}>
                                                            Save Company
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Accordion.Body>
                </Accordion.Item >
            }
        </>
    )
})
