import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import '../assets/font-awesome/css/all.min.css';
import { Login } from '../pages/public/login';
import { Register } from '../pages/public/Register';
import { MainScreen } from '../Mainscreen';
import { ConfirmCode } from '../pages/public/ConfirmCode/ConfirmCode';
import { ResetPassword } from '../pages/public/ResetPassword/ResetPassword';
import { RecoverPassword } from '../pages/public/RecoverPassword';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { PrivateRoute } from './PrivateRouter';
import { ToastContainer, ToastOptions } from "react-toastify";

const toastConfig: ToastOptions = {
  theme: 'colored',
  position: 'top-right',
  autoClose: 5000
}

export const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to='/home' replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/main" element={<MainScreen />} />
        <Route path="/confirm-code" element={<ConfirmCode />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </Routes>

      <ToastContainer {...toastConfig} />
    </BrowserRouter>
  );
};
