import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { useEffect, useRef } from 'react';
import { MultiSelect } from '../../../@custom/components/form/Multiselect/MultiSelect';
import { InvestmentPreferencesModel } from '../../../@core/interfaces/investor.model';
import { getInvestmentPreferences, saveInvestmentPreferences } from '../../../redux/actions/investorAction';

export const InvestmentPreferences = ({handleValidateForm}: any) => {

    const formikRef = useRef<FormikProps<InvestmentPreferencesModel>>();
    const dispatch = useDispatch();

    const { investor: cats } = useSelector((store: StoreApp) => store.cats);
    const { loadedCats } = useSelector((store: StoreApp) => store.ui);
    const { investorId, preferences, filledSections } = useSelector((store: StoreApp) => store.investor);

    const handleSubmit = () => {
        if (formikRef.current?.dirty) formikRef.current?.submitForm();
    }

    useEffect(() => {
      if (investorId && loadedCats) {
        dispatch(getInvestmentPreferences(investorId))
      }
    }, [investorId, dispatch, loadedCats])


  useEffect(() => {
      if (preferences) {
        formikRef.current?.setValues((v) => ({
            ...v,
            industries: preferences.industries || [],
            funding: preferences.funding || [],
            state: preferences.state || [],
            annualGross: preferences.annualGross || [],
          }));
          
          setTimeout(()=>{
            handleBlurForm()
          },500)
      }
  }, [preferences]);

  const handleBlurForm = () => {
    const validForm = formikRef.current?.isValid;
    handleValidateForm('investment',validForm);
  };

//   useEffect(() => {    
//     return () => {
//         handleValidateForm();
//     }
//   }, [])
    

    return (
        <div className={filledSections.filled_sections === 5? "_investmentPreferences disable-mode" : "_investmentPreferences"}>
            <Formik
                validate={(e) => { handleBlurForm() }}
                // @ts-ignore: next-line
                innerRef={formikRef}
                initialValues={{
                    industries: [],
                    funding: [],
                    state: [],
                    annualGross: [],
                }}
                onSubmit={(values) => {
                    //console.log(values)
                    dispatch(saveInvestmentPreferences(values));
                }}
                validationSchema={Yup.object({
                    industries: Yup.array().min(1, "Required").required("Required"),
                    funding: Yup.array().min(1, "Required").required("Required"),
                    state: Yup.array().min(1, "Required").required("Required"),
                    annualGross: Yup.array().min(1, "Required").required("Required"),
                })
                }>
                {(formik) => (
                    <Form>
                        <Row className='col-12 mx-0'>
                            <Col md={12} lg={6} className='my-2'>
                                <p>Select all that apply:</p>
                                <MultiSelect
                                    label="State:"
                                    name="state"
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    error={formik.errors.state}
                                    touched={formik.touched.state}
                                    value={formik.values.state}
                                    options={cats?.catStates}
                                ></MultiSelect>
                            </Col>
                            <Col md={12} lg={6} className='my-2'>
                            <p className='parrafo-salto-de-linea'>&nbsp;</p>
                                <MultiSelect
                                    label="Industries:"
                                    name="industries"
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    error={formik.errors.industries}
                                    touched={formik.touched.industries}
                                    value={formik.values.industries}
                                    options={cats?.catIndustries}
                                ></MultiSelect>
                            </Col>
                            <Col md={12} lg={6} className='my-2'>
                                <MultiSelect
                                    label="Funding Stage:"
                                    name="funding"
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    error={formik.errors.funding}
                                    touched={formik.touched.funding}
                                    value={formik.values.funding}
                                    options={cats?.catFunding}
                                ></MultiSelect>
                            </Col>
                            <Col md={12} lg={6} className='my-2'>
                                <MultiSelect
                                    label="Company Annual Gross Revenue:"
                                    name="annualGross"
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    error={formik.errors.annualGross}
                                    touched={formik.touched.annualGross}
                                    value={formik.values.annualGross}
                                    options={cats?.catRevenue}
                                ></MultiSelect>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>

            <Row>
                <Col lg={12} className="row mx-0 mt-3">
                    <div className="d-block w-100 d-flex justify-content-end contenedor-btn mb-3">
                        <Button type="button" variant="primary" className="px-4" 
                            disabled={filledSections.filled_sections === 5}
                            onClick={handleSubmit}>Save</Button>
                    </div>

                </Col>
            </Row>

        </div>
    )
}
