import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../@core/interfaces/store';
import imgUser from '../../assets/images/user.jpg';
import { useNavigate } from "react-router-dom";
import { NotificationInfo, ViewNotification } from '../../@core/interfaces/notifications.model';
import { updateNotification } from '../../redux/actions/notificationsAction';
import { showNotification } from '../../redux/actions/uiAction';
import { TypeUser } from "../../@core/interfaces/common.model";


interface Props {
    styles: {
        width: string;
        heigh: string;
        maxHeigh?: string;
        minxHeigh?: string;
    }

    HanddleCloseMenuNotif: () => void;
}

export const Notifications = ({ styles, HanddleCloseMenuNotif }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { notifications, notificationCount } = useSelector((state: StoreApp) => state.notifications);
    const userType = Number(notificationCount.type);
    const isCompany = userType === TypeUser.COMPANY;

    const navRoutes: { [key: string]: string } = {
        // 'profile': isCompany ? 'company' : 'investor',
        'profile': isCompany ? 'public-profile-investor?idInvestor=' : 'public-profile-company?idCompany=',
        'activities': 'match-activity',
    }

    const handleNotification = () => {
        //Antes se usaba esta funcion
        //dispatch(showNotification(false))
        HanddleCloseMenuNotif()
    }

    const goTo = (notif: NotificationInfo) => {
        HanddleCloseMenuNotif()
        let navExist = navRoutes[notif.go_to];
        if(notif.go_to === 'profile' && navExist){
            navExist+= isCompany? notif.investor_id : notif.company_id;
        }
        navigate(`${navExist ? '/' + navExist : '/' + notif.go_to}`);
        dispatch(showNotification(false))
        viewNotification(notif); 
    }

    const viewNotification = (notif: NotificationInfo) => {
        dispatch(updateNotification(ViewNotification.VIEW, String(notif.company_investor_request_id), String(notif.request_notifications_id)))
    }

    const deleteNotification = (e: any, notif: NotificationInfo) => {
        e.stopPropagation();
        dispatch(updateNotification(ViewNotification.DELETE, String(notif.company_investor_request_id), String(notif.request_notifications_id)))
    }

    // if (notifications.length === 0) return (
    //     <div className="_notifications" style={{width:250}}>
    //         <p className="text-danger m-2 text-center">No notifications found</p>
    //     </div>
    // )

    return (
        <div style={styles} className="_notifications">
            <div className="header py-2 px-3">
                <div className="title">
                    <i className="far fa-bell" style={{ fontSize: '22px', color: '#2d128d' }}></i>
                    <span className="ms-2 font-weight-bold" style={{ color: '#333', fontWeight: '500' }}>Notifications</span>
                </div>

                <i
                    className="far fa-times-circle notification-icon"
                    style={{ fontSize: '22px', cursor: 'pointer' }}
                    onClick={handleNotification}
                >
                </i>
            </div>

            <div className="_notifications__rows">
                {
                    notifications.length > 0 && (
                        notifications.map((notif) => (
                            <div
                                // style={{ backgroundColor: notif.notification_status === 'Not Viewed' ? '#E5E8E8' : 'white'}}
                                className={ notif.notification_status === 'Not Viewed' ? '_notifications__row py-2 not_viewed' : '_notifications__row py-2' }
                                key={notif.request_notifications_id}
                                onClick={() => goTo(notif)}
                            >
                                <img src={notif.logo_img ? notif.logo_img : imgUser} alt="" className='mx-3' />
                                {/* <span className='pe-2'>The investor <span className='text-primary'>John Baker - Accel </span>wants to contact you. </span> */}
                                <span className='pe-2'>
                                    {/* {notif.notification} */}
                                    {notif.notification_left} <span className="notf-middle">{notif.notification_middle}</span> {notif.notification_right}
                                </span>
                                <i className="fas fa-trash-alt mx-3" style={{ fontSize: '18px', color: 'gray' }} onClick={(e) => deleteNotification(e, notif)}></i>
                            </div>
                        ))
                    )
                }
                {
                    notifications.length === 0 && (
                        <p className="text-danger m-2 text-center">No notifications found</p>
                    )
                }
            </div>

        </div>
    )
};
