import {
  Search,
  SearchResponse,
  TypeSearch,
} from "../../@core/interfaces/search.model";
import { StoreApp } from "../../@core/interfaces/store";
import { searchService } from "../../@core/services/search.service";
import { searchCompaniesService } from "../../@core/services/investor.service";

import { customErrorAlert } from "../../helpers/alerts";
import { searchTypes } from "../types/searchTypes";
import { setLoading } from "./uiAction";
import { toast } from "react-toastify";

export const getSearch = (search: Search, pagina: string = "1") => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      const { idCompany } = getState().company;
      const searchResult = await searchService(
        idCompany,
        search,
        pagina,
        TypeSearch.SEARCH_CUSTOM
      );
      if (searchResult?.length > 0) {
        dispatch(getSearchAction(searchResult));
      } else {
        if (searchResult?.length === 0) toast.info("No results found");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const getSearchCompanies = (search: Search, pagina: string = "1") => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      console.log('searchResult');
      const { investorId } = getState().investor;
      console.log('investorId',investorId);
      const searchResult = await searchCompaniesService(investorId,search,pagina,TypeSearch.SEARCH_CUSTOM);
      console.log('searchResult',searchResult);
      if (searchResult?.length > 0) {
        dispatch(getSearchAction(searchResult));
      } else {
        if (searchResult?.length === 0) toast.info("No results found");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};


const getSearchAction = (search: SearchResponse[]) => ({
  type: searchTypes.getSearch,
  payload: { search },
});

// export const searchNoContactable = (search: Search | null, pagina: string = '1') => {
//   return async (dispatch: any, getState: () => StoreApp) => {
//     dispatch(setLoading(true));
//     try {
//       const { idCompany } = getState().company;
//       const searchResult = await searchService(idCompany, search, pagina, TypeSearch.SEARCH);
//       dispatch(searchNoContactableAction(searchResult));
//       if (searchResult?.length === 0) toast.info('No results found');
//       dispatch(setLoading(false));
//     } catch (error: any) {
//       customErrorAlert(error, "Alert");
//       dispatch(setLoading(false));
//     }
//   };
// };

// const searchNoContactableAction = (search: SearchResponse[]) => ({
//   type: searchTypes.searchNoContactable,
//   payload: { search },
// });

export const searchContactable = (
  search: Search | null | Partial<Search>,
  pagina: string = "1"
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));

    try {
      const { idCompany } = getState().company;
      const searchResult = await searchService(
        idCompany,
        search as Search,
        pagina,
        // TypeSearch.SEARCH
        TypeSearch.SEARCH_CUSTOM
      );

      if (searchResult?.length > 0) {
        const available = searchResult.filter((s) => s.available === 1);
        const noAvailable = searchResult.filter((s) => s.available === 0);
        dispatch(searchContactableAction(available, noAvailable));
      } else {
        toast.info("No results found");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const searchCompaniesContactable = (
  search: Search | null | Partial<Search>,
  pagina: string = "1"
) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));

    try {
      const { investorId } = getState().investor;
      const searchResult = await searchCompaniesService(
        investorId,
        search as Search,
        pagina,
        TypeSearch.SEARCH
      );

      if (searchResult?.length > 0) {
        dispatch(getSearchAction(searchResult));
      } else {
        toast.info("No results found");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};



const searchContactableAction = (
  available: SearchResponse[],
  noAvailable: SearchResponse[]
) => ({
  type: searchTypes.searchContactable,
  payload: { available, noAvailable },
});

export const resetSearchAction = () => ({
  type: searchTypes.resetSearch,
});

export const resetPagerAction = (reset: boolean) => ({
  type: searchTypes.resetPager,
  payload: reset,
});

export const setSearchVariablesAction = (search: Search) => ({
type: searchTypes.setSearch,
  payload: {search},
});