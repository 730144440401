import { NotificationCount, NotificationInfo, NotificationUpdate, ViewNotification } from "../../@core/interfaces/notifications.model";
import { StoreApp } from "../../@core/interfaces/store";
import { _getNotificationCount, _getNotificationInfo, _updateNotification } from "../../@core/services/notifications.service";
import { customErrorAlert } from "../../helpers/alerts";
import { notificationsTypes } from "../types/notificationsTypes";
import { setLoading } from "./uiAction";
import * as _ from 'lodash';

export const getNotifications = (showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    try {
      const { email } = getState().ui;
      const { notificationCount } = getState().notifications;
      if( ! _.isEmpty( notificationCount)){
          const num_notif=notificationCount.notif_count;
          const countsResponse = await _getNotificationCount(email);
        if (countsResponse.length > 0) {
          const count = countsResponse[0];
          if(count.notif_count!=num_notif){
            dispatch(getNotificationCountAction(count))
            const infoResponse = await _getNotificationInfo(count.type, count.company_investor_id);
            if (infoResponse.length) dispatch(getNotificationInfoAction(infoResponse))
          }
        }
          
      }
      else{
        dispatch(setLoading(showLoading));
        const countsResponse = await _getNotificationCount(email);
        if (countsResponse.length > 0) {
          const count = countsResponse[0];
          dispatch(getNotificationCountAction(count))
          const infoResponse = await _getNotificationInfo(count.type, count.company_investor_id)
          if (infoResponse.length) dispatch(getNotificationInfoAction(infoResponse))
        }
        dispatch(setLoading(false));
      }
      
      
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const getNotificationCountAction = (payload: NotificationCount) => ({
  type: notificationsTypes.getNotificationCount,
  payload
});

const getNotificationInfoAction = (payload: NotificationInfo[]) => ({
  type: notificationsTypes.getNotificationInfo,
  payload,
});


export const updateNotification = (
      status: number, 
      company_investor_request_id: string,
      request_notifications_id: string
    ) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      const { notificationCount } = getState().notifications;
      const { user_id, type} = notificationCount;
      const req: NotificationUpdate = {
        user_id,
        user_type: type,
        request_notifications_id,
        id_action: String(status),
        company_investor_request_id
      } 
      const res = await _updateNotification(req);
      console.log('res',res)
      if (res.result === 'ok') {
        if (status === ViewNotification.DELETE) {
          dispatch(deleteNotificationAction(req));
        } else {
          dispatch(updateNotificationAction(req));
        }
        //Reload notifications
        dispatch(getNotifications(false))
      }
      
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const updateNotificationAction = (notification: NotificationUpdate) => ({
  type: notificationsTypes.updateNotification,
  payload: { notification },
});

const deleteNotificationAction = (notification: NotificationUpdate) => ({
  type: notificationsTypes.deleteNotification,
  payload: { notification },
});
