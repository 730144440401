import { memo, useCallback, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { Invitations } from "../../../@core/interfaces/matchActivity.model"
import imgUser from '../../../assets/images/user.jpg';
import { acceptInvitations, declineInvitations } from "../../../redux/actions/matchActivityAction";
import { ModalRejection } from "./modalRejection";
import { TypeUser } from '../../../@core/interfaces/common.model';
import { StoreApp } from '../../../@core/interfaces/store';


interface Props {
    inv: Invitations;
    catRejection: any[];
    handleGetIdToShow: (id: number) => void;
}

export const InvitationRow = memo(({ inv, catRejection, handleGetIdToShow }: Props) => {

    //const userType = Number(localStorage.getItem('type'));
    const { type } = useSelector((state: StoreApp) => state.notifications.notificationCount)

    const dispatch = useDispatch();
    const [idRejection, setidRejection] = useState<number | string>('');

    const acceptRequestInvestor = () => {
        dispatch(acceptInvitations(inv.company_id, String(inv.company_investor_request_id)))
    }

    const acceptInvitationCompany = () => {
        dispatch(acceptInvitations(inv.investor_id, String(inv.company_investor_request_id)))
    }

    const handleIdRejection = useCallback((e: any) => {
        const value = e.target.value;
        setidRejection(value);
    }, [])

    const handleActionModal = useCallback(() => {
        let a = document.createElement('a');
        a.href = '#close';
        a.click();
        console.log({ idRejection, inv })
        dispatch(declineInvitations(inv.investor_id, String(inv.company_investor_request_id), String(idRejection)))
    }, [idRejection, dispatch])

    const InvitationRowCardInvestor = () => {
        return (
            <Card className='mt-4 card-content p-3 investor'>
                <Row className="fila">
                    <Col sm={12} lg={'auto'} className="d-flex align-items-center justify-content-center px-3" >
                        <div className="card-content_image my-3">
                            <img 
                                src={inv.company_logo ? inv.company_logo : imgUser} 
                                alt={'asdasf'} 
                                className='border-img' 
                                onClick={ ()=> handleGetIdToShow(inv.company_id) } />
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center  px-2 " >
                        <Row className="d-flex align-items-center justify-content-between px-2 col-12 alineacion-texto-mobile">
                            <Col sm={'auto'} className="d-flex align-items-center px-2" >
                                <p className="overflow-visible mb-0">
                                    <span className="card-content_text card-content_modificador-txt"
                                        onClick={ ()=> handleGetIdToShow(inv.company_id) } >
                                        {inv.company_name} - {inv.investor_fund_name}
                                    </span> {inv.message}
                                </p>
                            </Col>
                            <Col sm={'auto'} className='d-flex align-items-center justify-content-center contenedor-botones my-3'>
                                <a href={`#openModalInvitation${inv.company_investor_request_id}`}>
                                    <button type="button" className="btn btn-outline-primary estilos-boton modificador1">{inv.button_decline_caption}</button>
                                </a>
                                <Button type="button" variant="primary" className='estilos-boton' onClick={acceptRequestInvestor}>{inv.button_accept_caption}</Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Card>
        );
    }

    const InvitationRowCardCompany = () => {
        return (
            <Card className='mt-4 card-content p-3 company'>
                <Row className="fila">
                    <Col sm={12} lg={'auto'} className="d-flex align-items-center justify-content-center px-3" >
                        <div className="card-content_image my-3">
                            <img 
                            src={inv.investor_logo ? inv.investor_logo : imgUser} 
                            alt={'asdasf'} className='border-img' 
                            onClick={ ()=> handleGetIdToShow(inv.investor_id) } />
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center  px-2" >
                        <Row className="d-flex align-items-center justify-content-between px-2 col-12 alineacion-texto-mobile">
                            <Col sm={'auto'} className="d-flex align-items-center px-2" >
                                <p className="overflow-visible mb-0">
                                    <span className="card-content_text card-content_modificador-txt"
                                        onClick={ ()=> handleGetIdToShow(inv.investor_id) }>
                                            {inv.investor_name} - {inv.investor_fund_name}
                                    </span> {inv.message}
                                </p>
                            </Col>
                            <Col sm={'auto'} className='d-flex align-items-center justify-content-center contenedor-botones my-3'>
                                <a href={`#openModalInvitation${inv.company_investor_request_id}`}>
                                    <button type="button" className="btn btn-outline-primary estilos-boton modificador1">{inv.button_decline_caption}</button>
                                </a>
                                <Button type="button" variant="primary" className='estilos-boton' onClick={acceptInvitationCompany}>{inv.button_accept_caption}</Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Card>
        );
    }

    return (
        <>
            {Number(type) === TypeUser.INVESTOR ? <InvitationRowCardInvestor /> : <InvitationRowCardCompany />}

            <div id={`openModalInvitation${inv.company_investor_request_id}`} className="modalDialog">
                <ModalRejection
                    catRejection={catRejection}
                    handleActionModal={handleActionModal}
                    handleIdRejection={handleIdRejection}
                    idRejection={idRejection}
                    textTitle={`Reason why you're declining this invitation`}
                />
            </div>
        </>
    )
})
