import React, { useState, useRef, useEffect, useCallback } from "react";
import { Col, Row, Accordion, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { newTeammember } from "../../../redux/actions/investorAction";
import { TeamemberItem } from "./TeamemberItem";
import { getTeammembers } from '../../../redux/actions/investorAction';

export const Teamembers = React.memo(({handleValidateForm}) => {

    const dispatch = useDispatch();
    const itemsRef = useRef([])

    const [activeKey, setActiveKey] = useState(null)
    const { loading } = useSelector(state => state.ui);
    const { teammembers: members, investorId, filledSections } = useSelector(state => state.investor);

    // console.log('members',members); 

    const newMemberMap = {
        investor_team_member_id: 0,
        photo: '',
        member_name: '',
        expertise_highlights1_selection: [],
        expertise_highlights2_selection: [],
        investor_position_id: '',
        investor_member_linkedin: '',
        investor_member_previous_exits: ''
    }

    const handleAdd = () => {
        if (members.find((m) => m.investor_team_member_id === 0)) {
            itemsRef.current[0].handleSubmit()
        } else {
            dispatch(newTeammember(newMemberMap));
            setActiveKey(0)
        }
    }

    // const handleSubmit = () => {
    //     members.forEach((m) => {
    //         itemsRef.current[m.investor_team_member_id].handleSubmit()
    //     });
    // }

    const handleActiveKey = (i) => {
        if (activeKey === i) return setActiveKey(null);
        setActiveKey(i);
    }

    const handleValidateTeammembers = useCallback(() => {
        const validations = []
        itemsRef.current.forEach((iref) => {
            if (iref?.formikRef?.current) validations.push(iref.formikRef.current.isValid)
        })
        const isValidTeammember = validations.every((v) => v === true)
        handleValidateForm('leadership', isValidTeammember)
      }, [handleValidateForm]);

    return (
        <>
            <div className="_teammember">
                <Accordion className="mt-4" activeKey={activeKey}>
                    {members.map((m) => (

                        <TeamemberItem
                            member={m}
                            handleActiveKey={handleActiveKey}
                            handleValidateTeammembers={handleValidateTeammembers}
                            ref={el => itemsRef.current[m.investor_team_member_id] = el}
                            key={m.investor_team_member_id} />

                    ))
                    }
                </Accordion>


                <Row>
                    <Col lg={12} className=" mx-0 mt-3">
                        <div className="d-block w-100 alineacionMember mb-3">
                            <button type="submit" className="btn btn-outline-primary" 
                                disabled={filledSections.filled_sections === 5}
                                onClick={handleAdd}>+ Add Member</button>
                        </div>
                    </Col>

                    {/* <Col lg={12} className=" mx-0 mt-3">
                        <div className="d-block w-100 alineacionBtn">
                            <Button type="submit" variant="primary" className="btn-save" disabled={loading}
                                onClick={handleSubmit}>Save</Button>
                        </div>
                    </Col> */}
                </Row>

            </div>
        </>
    )
})
