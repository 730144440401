import { useEffect, useState } from 'react';
import { Col, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { getSavedInvestors } from '../../../redux/actions/homeAction';
import { CardSavedInvestors } from './CardSavedInvestors';
import { useNavigate, useLocation } from "react-router-dom";
import { InvestorPublicProfile } from '../../@components/InvestorPublicProfile/InvestorPublicProfile';


export const SavedInvestorsComponente = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const { savedInvestors } = useSelector((store: StoreApp) => store.home);
    const [pagination, setPagination] = useState(0);
    const [active, setActive] = useState('1');
    const [isProfileShow, setIsProfileShow ] = useState(false);

    const handleShowProfile = (param:string) => {
        navigate({pathname: '/saved-investors',search: param});
        setIsProfileShow(true)

        if(param !== ''){
            window.scrollTo(0, 0)
        }
    }

    const handleGetIdToShow = (id: number) => {
        if(!id) return false;
        handleShowProfile('?idInvestor='+id)
    }

    const handleBack = () => {
        handleShowProfile('');
        setIsProfileShow(false);
    }


    useEffect(() => {
        dispatch(getSavedInvestors())
    }, [dispatch]);

    useEffect(() => {
        if (savedInvestors.length > 0) {
            const pageNum = savedInvestors[0].pages_total;
            setPagination(pageNum);
        }
    }, [savedInvestors])

    const handleClickPagination = (page: string) => {
        setActive(page);
        dispatch(getSavedInvestors(page));
    }

    const disabledPrev = (): boolean | undefined => {
        if (active === '0' || active === '1') return true;
        return false;
    }

    useEffect(() => {
        if(search === ''){
            handleBack()
        }
        
    },[search])

    return (
        isProfileShow ? 
        <> 
            <div className='mb-1'>
                <a className='btn-profile-back' onClick={handleBack}>
                    <b><i className="fa fa-chevron-left"></i> Back</b>
                </a>
            </div>
            {<InvestorPublicProfile />}
        </>
        :
        <>

            <div className="_savedInvestorscomponente">
                <div className='contenedor-titulo mt-2 mb-5 d-flex flex-nowrap align-items-center justify-content-between'>
                    <div className='titulo-seccion'>YOUR SAVED INVESTORS</div>
                    <div className="line"></div>

                </div>
                <CardSavedInvestors investors={savedInvestors} avalible={true} handleGetIdToShow={handleGetIdToShow} />

                <div className='contenedor-titulo  my-5 d-flex flex-nowrap align-items-center justify-content-between'>
                    <span className='titulo-seccion'>YOU'RE NOT THERE YET</span>
                    <div className="line"></div>
                </div>
                <CardSavedInvestors investors={savedInvestors} avalible={false} handleGetIdToShow={handleGetIdToShow} />
            </div>

            {
                (pagination > 0) &&
                <Col sm={12} className="mt-4">
                    <Pagination className="justify-content-center">
                        <Pagination.Prev disabled={disabledPrev()} onClick={() => handleClickPagination(String(Number(active) - 1))} >Previous</Pagination.Prev>
                        {
                            Array.from(Array(pagination).keys()).map((k) => (
                                <Pagination.Item key={k} active={String(k + 1) === active} onClick={() => handleClickPagination(String(k + 1))}>
                                    {k + 1}
                                </Pagination.Item>
                            ))
                        }
                        <Pagination.Next disabled={Number(active) === pagination} onClick={() => handleClickPagination(String(Number(active) + 1))}>Next</Pagination.Next>
                    </Pagination>
                </Col>
            }
        </>

    )
}