import React, { useRef, useEffect } from "react";
import Table from "react-bootstrap/Table";
import * as _ from "lodash";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../../@custom/components/form/MyTextInput";
import { MySelect } from "../../../@custom/components/form/MySelect";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getFinancials,
  saveFinancials,
} from "../../../redux/actions/companyAction";
import CurrencyInput from "../../../@custom/components/CurrencyInput";

export const Financials = ({ handleValidateForm }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { headers, values } = useSelector((state) => state.company.financials);
  const { investor: cats } = useSelector((state) => state.cats);
  const { loadedCats } = useSelector((state) => state.ui);
  const { filledSections, financials, idCompany } = useSelector(
    (state) => state.company
  );

  const regExCurrency = /(\d)(?=(\d{3})+(?!\d))/g;

  useEffect(() => {
    if (
      loadedCats &&
      filledSections?.filled_sections >= 1 &&
      _.isEmpty(financials)
    )
      dispatch(getFinancials());
  }, [dispatch, filledSections, loadedCats]);

  useEffect(() => {
    if (values?.length > 0 && headers?.length > 0) {
      //   const regExCurrency = /(\d)(?=(\d{3})+(?!\d))/g;
      // console.log('$' + values[0]?.[headers?.[0]]?.replace(regExCurrency, '$1,'))
      //   console.log('$' + values[5]?.bootstrapped.toString().replace(regExCurrency, "$1,"))
      formikRef.current.setValues((v) => ({
        ...v,
        gross_1:
          "$" + values[0][headers[0]].replace(regExCurrency, "$1,") || "",
        gross_2:
          "$" + values[0][headers[1]].replace(regExCurrency, "$1,") || "",
        gross_3:
          "$" + values[0][headers[2]].replace(regExCurrency, "$1,") || "",
        gross_4:
          "$" + values[0][headers[3]].replace(regExCurrency, "$1,") || "",
        gross_5:
          "$" + values[0][headers[4]].replace(regExCurrency, "$1,") || "",

        direct_1:
          "$" + values[1][headers[0]].replace(regExCurrency, "$1,") || "",
        direct_2:
          "$" + values[1][headers[1]].replace(regExCurrency, "$1,") || "",
        direct_3:
          "$" + values[1][headers[2]].replace(regExCurrency, "$1,") || "",
        direct_4:
          "$" + values[1][headers[3]].replace(regExCurrency, "$1,") || "",
        direct_5:
          "$" + values[1][headers[4]].replace(regExCurrency, "$1,") || "",

        operating_1:
          "$" + values[2][headers[0]].replace(regExCurrency, "$1,") || "",
        operating_2:
          "$" + values[2][headers[1]].replace(regExCurrency, "$1,") || "",
        operating_3:
          "$" + values[2][headers[2]].replace(regExCurrency, "$1,") || "",
        operating_4:
          "$" + values[2][headers[3]].replace(regExCurrency, "$1,") || "",
        operating_5:
          "$" + values[2][headers[4]].replace(regExCurrency, "$1,") || "",

        profit_1: '$' + values[3][headers[0]].replace(regExCurrency, "$1,") || "",
        profit_2: '$' + values[3][headers[1]].replace(regExCurrency, "$1,") || "",
        profit_3: '$' + values[3][headers[2]].replace(regExCurrency, "$1,") || "",
        profit_4: '$' + values[3][headers[3]].replace(regExCurrency, "$1,") || "",
        profit_5: '$' + values[3][headers[4]].replace(regExCurrency, "$1,") || "",

        profitMargin_1: values[4][headers[0]] + " %" || "",
        profitMargin_2: values[4][headers[1]] + " %" || "",
        profitMargin_3: values[4][headers[2]] + " %" || "",
        profitMargin_4: values[4][headers[3]] + " %" || "",
        profitMargin_5: values[4][headers[4]] + " %" || "",

        annual: values[5]?.annual || "",
        bootstrapped: values[5]?.bootstrapped || "",
        capital: values[5]?.capital || "",
      }));
    }
  }, [values, headers]);

  const handleSubmit = () => {
    formikRef.current.handleSubmit();
  };

  const handleBlurGralData = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/[^0-9.-]+/g, "");
    // console.log({ name, value });
    formikRef.current.setValues((v) => ({
      ...v,
      [name]: value,
    }));
  };

  const handleBlurForm = (e) => {
    const name = e.target.name;
    const values = formikRef.current.values;
    const lastChart = name.charAt(name.length - 1);

    const gross = values[`gross_${lastChart}`].replace(/[^0-9.-]+/g, "");
    const profit = values[`profit_${lastChart}`].replace(/[^0-9.-]+/g, "");

    if (gross > "0" && profit > "0") {
      formikRef.current.setValues((v) => ({
        ...v,
        [`profitMargin_${lastChart}`]: (profit / gross).toFixed(3),
      }));
    }
  };

  const handleBlurFormValidate = () => {
    const validForm = formikRef.current.isValid;
    handleValidateForm('financials',validForm);
  };

  return (
    <div className="_financial">
      <Formik
        validate={() => {
          handleBlurFormValidate();
        }}
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          annual: "",
          bootstrapped: "",
          capital: "",

          gross_1: "",
          gross_2: "",
          gross_3: "",
          gross_4: "",
          gross_5: "",

          direct_1: "",
          direct_2: "",
          direct_3: "",
          direct_4: "",
          direct_5: "",

          operating_1: "",
          operating_2: "",
          operating_3: "",
          operating_4: "",
          operating_5: "",

          profit_1: "",
          profit_2: "",
          profit_3: "",
          profit_4: "",
          profit_5: "",

          profitMargin_1: "",
          profitMargin_2: "",
          profitMargin_3: "",
          profitMargin_4: "",
          profitMargin_5: "",
        }}
        validationSchema={Yup.object({
          annual: Yup.string().required("Required"),
          bootstrapped: Yup.string().required("Required"),
          capital: Yup.string().required("Required"),

          gross_1: Yup.string().required("Required"),
          gross_2: Yup.string().required("Required"),
          gross_3: Yup.string().required("Required"),
          gross_4: Yup.string().required("Required"),
          gross_5: Yup.string().required("Required"),

          direct_1: Yup.string().required("Required"),
          direct_2: Yup.string().required("Required"),
          direct_3: Yup.string().required("Required"),
          direct_4: Yup.string().required("Required"),
          direct_5: Yup.string().required("Required"),

          operating_1: Yup.string().required("Required"),
          operating_2: Yup.string().required("Required"),
          operating_3: Yup.string().required("Required"),
          operating_4: Yup.string().required("Required"),
          operating_5: Yup.string().required("Required"),

          profit_1: Yup.string().required("Required"),
          profit_2: Yup.string().required("Required"),
          profit_3: Yup.string().required("Required"),
          profit_4: Yup.string().required("Required"),
          profit_5: Yup.string().required("Required"),

          profitMargin_1: Yup.string().required("Required"),
          profitMargin_2: Yup.string().required("Required"),
          profitMargin_3: Yup.string().required("Required"),
          profitMargin_4: Yup.string().required("Required"),
          profitMargin_5: Yup.string().required("Required"),
        })}
        onSubmit={(valuesForm) => {
          dispatch(saveFinancials(valuesForm));
        }}
      >
        {(formik) => (
          <Form>
            <Row className="col-12 mx-0 contenedor_inputs">
              <Col md={6} lg={4} sm={12} className="my-2">
                <MySelect
                  label="Annual Gross Revenue:"
                  name="annual"
                  errors={formik.errors}
                  //   infoIcon1={true}
                  disabled={filledSections.filled_sections === 6}
                >
                  <option value="">Select...</option>
                  {cats?.catRevenue.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.description}
                    </option>
                  ))}
                </MySelect>
              </Col>
              <Col md={6} lg={4} sm={12} className="my-2">
                <Field name="bootstrapped">
                  {({ field }) => (
                    <CurrencyInput
                      {...field}
                      label="Bootstrapped:"
                      errors={formik.errors}
                      placeholder="$00.00"
                      disabled={filledSections.filled_sections === 6}
                      type="text"
                      onBlur={handleBlurGralData}
                      textTooltip={
                        "Money from the founders and existing revenue that has been invested in the company."
                      }
                    />
                  )}
                </Field>
              </Col>
              <Col md={6} lg={4} sm={12} className="my-2">
                <Field name="capital">
                  {({ field }) => (
                    <CurrencyInput
                      {...field}
                      label="Capital Raised to Date:"
                      errors={formik.errors}
                      placeholder="$00.00"
                      disabled={filledSections.filled_sections === 6}
                      type="text"
                      textTooltip={
                        "Money received from investors."
                      }
                      onBlur={handleBlurGralData}
                      // textTooltip={
                      //   "Money received from investors"
                      // }
                    />
                  )}
                </Field>
              </Col>
            </Row>
            {values && headers && (
              <Table responsive="lg">
                <thead>
                  <tr className="encabezados">
                    <th></th>
                    <th>{headers[0]}</th>
                    <th>{headers[1]}</th>
                    <th>{headers[2]}</th>
                    <th>{headers[3]}</th>
                    <th>{headers[4]}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {/* <i
                        className="fas fa-info-circle me-2"
                        style={{ color: "#ccc" }}
                      ></i> */}
                      <span>Gross Revenue</span>
                    </td>
                    <td className="modificador-celda-table">
                      <Field name="gross_1">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td className="modificador-celda-table">
                      <Field name="gross_2">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td className="modificador-celda-table">
                      <Field name="gross_3">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td className="modificador-celda-table">
                      <Field name="gross_4">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td className="modificador-celda-table">
                      {/* <MyTextInput
                                                name="gross_5"
                                                placeholder="$00.00"
                                                errors={formik.errors}
                                                onBlur={handleBlurForm}
                                                type='number'
                                            /> */}
                      <Field name="gross_5">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Direct Cost</span>
                    </td>
                    <td>
                      {/* <MyTextInput
                                                name="direct_1"
                                                placeholder="$00.00"
                                                errors={formik.errors}
                                                type='number'
                                            /> */}
                      <Field name="direct_1">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      {/* <MyTextInput
                                                name="direct_2"
                                                placeholder="$00.00"
                                                errors={formik.errors}
                                                type='number'

                                            /> */}
                      <Field name="direct_2">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      {/* <MyTextInput
                                                name="direct_3"
                                                placeholder="$00.00"
                                                errors={formik.errors}
                                                type='number'

                                            /> */}
                      <Field name="direct_3">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      {/* <MyTextInput
                                                name="direct_4"
                                                placeholder="$00.00"
                                                errors={formik.errors}
                                                type='number'

                                            /> */}
                      <Field name="direct_4">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      {/* <MyTextInput
                                                name="direct_5"
                                                placeholder="$00.00"
                                                errors={formik.errors}
                                                type='number'
                                            /> */}
                      <Field name="direct_5">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span>Operating Expenses</span>
                    </td>
                    <td>
                      <Field name="operating_1">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="operating_2">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="operating_3">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="operating_4">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="operating_5">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Profit</span>
                    </td>
                    <td>
                      <Field name="profit_1">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="profit_2">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="profit_3">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="profit_4">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="profit_5">
                        {({ field }) => (
                          <CurrencyInput
                            {...field}
                            errors={formik.errors}
                            placeholder="$00.00"
                            onBlur={handleBlurForm}
                            disabled={filledSections.filled_sections === 6}
                            type="text"
                          />
                        )}
                      </Field>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span>Profit Margin</span>
                    </td>
                    <td>
                      <MyTextInput
                        name="profitMargin_1"
                        placeholder="10%"
                        disabled
                        errors={formik.errors}
                        type="string"
                      />
                    </td>
                    <td>
                      <MyTextInput
                        name="profitMargin_2"
                        placeholder="10%"
                        errors={formik.errors}
                        type="string"
                        disabled
                      />
                    </td>
                    <td>
                      <MyTextInput
                        name="profitMargin_3"
                        placeholder="10%"
                        errors={formik.errors}
                        type="string"
                        disabled
                      />
                    </td>
                    <td>
                      <MyTextInput
                        name="profitMargin_4"
                        placeholder="10%"
                        errors={formik.errors}
                        type="string"
                        disabled
                      />
                    </td>
                    <td>
                      <MyTextInput
                        name="profitMargin_5"
                        placeholder="10%"
                        errors={formik.errors}
                        type="string"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Form>
        )}
      </Formik>

      <Row>
        <Col lg={12} className="row mx-0 mt-3">
          <div className="d-block w-100 alineacionBtn">
            {/* <span className="textoRequerido">Please note all fields with * are required. </span> */}
            <Button
              type="submit"
              variant="primary"
              className="btn-save"
              disabled={filledSections.filled_sections === 6}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
