import {
  FilledSections,
  GralInfo,
  InvestmentPreferences,
  Investor,
  InvestorAbout,
  ValidForm,
} from "../../@core/interfaces/investor.model";
import { types } from "../types/types";

const initialState: Investor = {
  investorId: 0,
  gralInfo: {} as GralInfo,
  companies: [],
  teammembers: [],
  savedCompanies: [],
  about: {} as InvestorAbout,
  preferences: {} as InvestmentPreferences,
  filledSections: {} as FilledSections,
  validForm: {
    about: false,
    leadership: false,
    portfolio: false,
    investment: false
  } as ValidForm,
};

export const investorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.setInvestor:
      return {
        ...state,
        investorId: action.payload.investor.investor_id,
        gralInfo: { ...action.payload.investor },
      };
    case types.saveInvestor:
      const { investor, industries } = action.payload;
      return {
        ...state,
        gralInfo: {
          // investor_logo: investor?.investor_logo ? investor.investor_logo : '',
          // banner: investor?.banner ? investor.banner : '',

          investor_logo: state.gralInfo.investor_logo,
          banner: state.gralInfo.banner,
          investor_name: investor.sInvestorName,
          investor_fund_name: investor.sFundName,
          investment_philosophy: investor.sInvestorPhilosophy,
          // state_id: investor.iIdState,
          state_id: investor.IdState,
          city_id: investor.IdCity,
          investor_website: investor.website,
          // linkedin: investor.linkedin,
          linkedin: investor.investor_linkedin,
          country_id: investor.iIdCountry,
          investor_type_id: investor.iTipeInvestor,
          exits: investor.iIdPreviousExits,
          investment_bracket_id: investor.iIdRangeOfInvestment,
          industries,
        },
      };
    case types.setTeammembers:
      return {
        ...state,
        teammembers: [...action.payload.teammembers],
      };
    case types.newMember:
      return {
        ...state,
        teammembers: [...state.teammembers, action.payload.teammember],
      };
    case types.updateMember:
      return {
        ...state,
        teammembers: state.teammembers.map((m) =>
          m.investor_team_member_id === action.payload.idCurrent
            ? {
                ...action.payload.member,
                investor_team_member_id: Number(action.payload.idNew),
              }
            : m
        ),
      };
    case types.updatePutMember:
      return {
        ...state,
        teammembers: state.teammembers.map((m) =>
          m.investor_team_member_id === Number(action.payload.id)
            ? {
                investor_team_member_id: Number(action.payload.id),
                photo: action.payload.member.photo,
                member_name: action.payload.member.sName,
                expertise_highlights1_selection: action.payload.high1,
                expertise_highlights2_selection: action.payload.high2,
                investor_position_id: action.payload.member.sPosition,
                investor_member_linkedin: action.payload.member.sLinkedin,
                investor_member_previous_exits: action.payload.member.iExits,
              }
            : m
        ),
      };
    case types.updatePhotoMember:
      return {
        ...state,
        teammembers: state.teammembers.map((m) =>
          m.investor_team_member_id === action.payload.id
            ? {
                ...m,
                photo: action.payload.photo,
              }
            : m
        ),
      };
    case types.updatePhotoCompany:
      return {
        ...state,
        companies: state.companies.map((m) =>
          m.portfolio_id === action.payload.id
            ? {
                ...m,
                portfolio_logo: action.payload.photo,
              }
            : m
        ),
      };
    case types.setCompanies:
      return {
        ...state,
        companies: [...action.payload.companies],
      };

    case types.newCompany:
      return {
        ...state,
        companies: [...state.companies, action.payload.company],
      };

    case types.updateCompany:
      return {
        ...state,
        companies: state.companies.map((m) =>
          m.portfolio_id === Number(action.payload.idCurrent)
            ? {
                ...action.payload.company,
                portfolio_id: Number(action.payload.idNew),
              }
            : m
        ),
      };

    case types.deleteCompany:
      return {
        ...state,
        companies: state.companies.filter(
          (m) => String(m.portfolio_id) !== String(action.payload.id)
        ),
      };

    case types.updateImgGralInfo:
      return {
        ...state,
        gralInfo: {
          ...state.gralInfo,
          investor_logo: action.payload.img,
        },
      };

    case types.updateBannerGralInfo:
      return {
        ...state,
        gralInfo: {
          ...state.gralInfo,
          banner: action.payload.img,
        },
      };

    case types.deleteMember:
      return {
        ...state,
        teammembers: state.teammembers.filter(
          (m) => String(m.investor_team_member_id) !== String(action.payload.id)
        ),
      };

    case types.getSavedCompanies:
      return {
        ...state,
        savedCompanies: [...action.payload],
      };

    case types.getAboutInvestor:
      return {
        ...state,
        about: { ...action.payload },
      };
    case types.setIdInvestor:
      return {
        ...state,
        investorId: action.payload,
      };
    case types.setPreferences:
      return {
        ...state,
        preferences: {...action.payload.preferences },
      };

      case types.deleteSavedCompany:
        return {
          ...state,
          savedCompanies: state.savedCompanies.filter((s) => s.saved_company_id !== action.payload),
        };

    case types.getFilledSections:
      return {
        ...state,
        filledSections: { ...action.payload },
      };

      case types.updateFilledSection:
      return {
        ...state,
        filledSections: {
          ...state.filledSections,
          filled_sections: action.payload,
        },
      };

      case types.submitInvestor:
        return {
          ...state,
          filledSections: {
            ...state.filledSections,
            filled_sections: 5,
          },
        };

      case types.setValidForm:
        const validform = {
          ...state,
          validForm: {
            ...state.validForm,
            [action.payload.component]: action.payload.isValid
          },
        };
        return validform;

    default:
      return state;
  }
};
