

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './PencilDot.css';
import { customAlert } from "../../../helpers/alerts";


export const PencilDot = React.memo(({ id, isWebp, handleImgPencil, validation1 = false }) => {
    const [loading, setLoading] = useState(false);
    const inputFile = useRef();
    const canvasRef = useRef();

    const maxImgSizeMb = 2;
    const errorFileSize = `The image you’re trying to save exceeds the size limit of ${maxImgSizeMb}MB. Please resize or upload a smaller image.`;

    const handleOpenFiles = () => {
        inputFile.current.click();
    }

    const convertWebp = (e) => {
        setLoading(true);
        const file = e.target.files[0];
        if(!file){
            setLoading(false);
            return false;
        }

        if(validation1 && bytesToMegaBytes(file.size) > maxImgSizeMb){
            setLoading(false);
            customAlert('', errorFileSize, 'warning');
            e.target.value=null;
            return false
        }

        const _canvasRef = canvasRef;
        const ctx = _canvasRef.current.getContext("2d");

        let urlImage = '';
        let webpImg = '';

        urlImage = URL.createObjectURL(file);
        webpImg = new Image();

        const onLoadPromise = new Promise((resolve) => {
            webpImg.onload = () => {
                console.log(_canvasRef)
                _canvasRef.current.width = webpImg.naturalWidth;
                _canvasRef.current.height = webpImg.naturalHeight;
                ctx.drawImage(webpImg, 0, 0, _canvasRef.current.width, _canvasRef.current.height);
                const convertedImg = _canvasRef.current.toDataURL("image/webp", 1.0);
                resolve(convertedImg);
            }
        });
        onLoadPromise.then((b64) => {
            setLoading(false);
            handleImgPencil(b64, id, file, e);
        })

        webpImg.src = urlImage;
        //console.log('here 2')
    }

    const bytesToMegaBytes = (bytes) => bytes / (1024 ** 2);

    const handleImage = (e) => {
        if (!e || loading) return;
        if (isWebp) return convertWebp(e);
        const file = e.target.files[0];
        getB64(file, (imageB64, file) => {
            handleImgPencil(imageB64, id, file);
        })
    }

    const getB64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            cb(reader.result, file)
        };
    }

    return (

        <div className="pencil-dot" onClick={handleOpenFiles}>
            <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handleImage} ref={inputFile} name="dotPencil" style={{ display: 'none' }} />
            {
                (loading)
                    ? (<i className="fas fa-spinner rotating-pencil" style={{ color: '#fff' }}></i>)
                    : (<i className="fas fa-pencil-alt" style={{ color: '#fff' }}></i>)
            }
            <canvas style={{ display: 'none' }} ref={canvasRef}></canvas>
        </div>
    )
})

PencilDot.propTypes = {
    // id: PropTypes.number.isRequired, 
    isWebp: PropTypes.bool.isRequired,
    handleImgPencil: PropTypes.func.isRequired,
};