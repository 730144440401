import { CatValueLabel } from "./investor.model";

export interface Search {
  company_id: CatValueLabel[];
  investor_type: CatValueLabel[];
  industries: CatValueLabel[];
  countries: CatValueLabel[];
  states: CatValueLabel[];
  p_range_investment: CatValueLabel[];
  p_exits: CatValueLabel[]
  fundingstage: CatValueLabel[];
  annualGross: CatValueLabel[];
  numberEmployees: CatValueLabel[];
  previousExists: CatValueLabel[];
  delLeadership: CatValueLabel[];
}

export interface SearchState {
  search: boolean;
  resetPager: boolean;
  searchResponse: SearchResponse[];
  contactable: SearchResponse[];
  noContactable: SearchResponse[];
  searchVariables: Search;
}

export interface SearchResponse {
  investor_name: string;
  investor_type_name: string;
  exits: number;
  state_name: string;
  country_name: string;
  industries: string;
  investment_bracket_description: string;
  investor_fund_name: string;
  investment_philosophy: string;
  investor_logo: string;
  investor_id: number;
  state_id: number;
  industry_id: string;
  investor_type_id: number;

  city_name: string;
  company_description: string;
  company_logo: string;
  company_name: string;
  funding_stage_desc: string;
  company_id: number;

  investment_bracket_id: number;
  min_range_investment: number;
  max_range_investment: number;
  available: number;
  pages_total: number;
  page_num: number;
  rows_by_page: number;
  annual_gross_revenue: string;

  handleShowProfile: (param: string) => void;
}

export enum TypeSearch {
  SEARCH_CUSTOM = "1",
  SEARCH = "2",
}
