import {
  ProfileViewProfile,
  ProfileViewState,
} from "../../@core/interfaces/profileView.model";
import { profileViewTypes } from "../types/profileViewTypes";

const initialState: ProfileViewState = {
  profile: {} as ProfileViewProfile,
};

export const profileViewReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case profileViewTypes.getProfile:
      return {
        ...state,
        profile: { ...action.payload.profile },
      };
    default:
      return state;
  }
};
