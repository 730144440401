import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button, Card } from 'react-bootstrap';
import { CardChatContact } from './CardChatContact'
import { ChatResponse } from "../../../@core/interfaces/chat.model";
import { StoreApp } from '../../../@core/interfaces/store';
import { TypeUser } from "../../../@core/interfaces/common.model";


interface Props {
    idActive: number;
    members: ChatResponse[];
    onHandleSelectContact: (member: ChatResponse, id: number)=> void;
}

export const ChatContacts = ({members, onHandleSelectContact}: Props) => {

    const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
    const [ idActive, setIdActive ] = useState(-1);
    const userType = Number(notificationCount.type);
    const setUserChat = (member: ChatResponse)=> {
        const id = userType === TypeUser.COMPANY ? member.investor_id : member.company_id
        setIdActive(id);
        onHandleSelectContact(member,id)
    }

    // the value of the search field 
    const [name, setName] = useState('')

    // the search result
    const [foundUsers, setFoundUsers] = useState(members)

    const [typeSomething, setTypeSomething ] = useState(false);
    
    const filter = (e: any) => {
        const keyword = e.target.value;
    
        if (keyword !== '') {

            setTypeSomething(true)
            const results = members.filter((member) => {

            if(userType === TypeUser.COMPANY){
                return member?.investor_fund_name.toLowerCase().startsWith(keyword.toLowerCase());
            }
            if(userType === TypeUser.INVESTOR){
                return member?.company_name.toLowerCase().startsWith(keyword.toLowerCase());
            }

          });
          setFoundUsers(results);
        } else {
            setFoundUsers(members)
            setTypeSomething(false)
        }
    
        setName(keyword);
    }

    useEffect( ()=> {
        if(members.length>0){
            setFoundUsers(members)
        }
    },[members])

    return <>
        <div className="card mb-sm-3 mb-md-0 contacts_card">
            <div className="card-header">
                <div className="input-group">

                    <input 
                        type="text" 
                        placeholder="Search..." 
                        name="" 
                        onChange={filter}
                        className="form-control search" />
                    <div className="input-group-prepend">
                        <span className="input-group-text search_btn"><i className="fas fa-search"></i></span>
                    </div>
                </div>
            </div>
            <div className="card-body contacts_body">
                <ul className="contacts">
            {foundUsers && foundUsers.length > 0 ? (
                        foundUsers.map( (member) => 
                        (
                            <li 
                            key={'li'+Math.random()+(userType === TypeUser.COMPANY ?member.company_id:member.investor_id)}
                            onClick={()=>setUserChat(member)}
                            className={ (member.company_id ===  idActive || member.investor_id === idActive)? 'active':'' }>
                                <CardChatContact 
                                    key={userType === TypeUser.COMPANY ?member.company_id:member.investor_id}
                                    member={member} />
                            </li>
                        ))
                ) : (
                    <li className='text-center'>{typeSomething ? 'No results found!' : ''}</li>
                    )}
                </ul>
            </div>
            {/* <div className="card-footer"></div> */}
        </div>
    </>

}