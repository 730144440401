import { Card, Row, Col } from "react-bootstrap"
import { Companies } from "../../../@core/interfaces/investor.model";
import imgUser from '../../../assets/images/user.jpg';

interface Props {
    company: Companies;
}


export const CardsInvestorPublicProfilePortfolio = ({ company }: Props) => {

    const industries = (company: Companies): string => {
        const names = (company.industries_selection.map((s) => s.label)).join(', ');
        return names
    }

    return (
        <>
            <Card className='mt-4 card-content rounded-3 p-3'>
                <Row className="justify-content-center flex-wrap col-12" style={{ margin: '0 auto' }}>

                    <Col sm={'auto'} className="d-flex align-items-center justify-content-center px-3">
                        <div className="card-content_image my-3">
                            <img src={ company.portfolio_logo ? company.portfolio_logo : imgUser} alt={company.portfolio_name} />
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center px-3 alineando-text-portfolio">
                        <div className="card-content_text">
                            <p><b>{company.portfolio_name} - <a style={{color: "#2d128d", textDecoration: "none"}} href={company.portfolio_website} target="_blank">{company.portfolio_website}</a></b></p>
                            <p>Industry: <b>{industries(company)}</b></p>
                            <p className="desktopPort">Funding Stage: <b>{company.funding_stage_desc}</b> - Lead Investor: <b>{ company.lead_investor === "0" ? "No" : "Yes" }</b> - Investment Amount: <b>{company.portfolio_investment_amountF ?? 'NA'}</b> </p>
                            <p className="mobilePort">Funding Stage: <b>{company.funding_stage_desc}</b> </p>
                            <p className="mobilePort">Lead Investor: <b>{ company.lead_investor === "0" ? "No" : "Yes" }</b></p>
                            <p className="mobilePort">Investment Amount: <b>{company.portfolio_investment_amountF ?? 'NA'}</b></p>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
