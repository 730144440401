import './App.css';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';


export const MainScreen=() =>{
    const navigate = useNavigate();

    const signOut= async() => {
        try {
            const  user  = await Auth.signOut({ global:true
            });
            console.log(user);
            navigate('/')
        } catch (error) {
            console.log('error signing up:', error);
        }
    }
    
    return (
        <div className="App">
            <header className="App-header">
                <h2>Pantalla Principal</h2>
                <button onClick={signOut}>Sign Out</button>
            </header>
        </div>
    );
}

