import { useRef, useEffect, memo, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { StoreApp } from '../../@core/interfaces/store';
import { useDispatch } from 'react-redux';

import { 
    getSearch, 
    resetSearchAction, 
    searchContactable, 
    searchCompaniesContactable, 
    getSearchCompanies, 
    setSearchVariablesAction, 
    resetPagerAction } from '../../redux/actions/searchAction';

import { MultiSelect } from '../../@custom/components/form/Multiselect/MultiSelect';
import { MySelect } from '../../@custom/components/form/MySelect';
import { TypeUser } from '../../@core/interfaces/common.model';



interface Props {
    userType: Number;
    collapseSearch: boolean;
    handdleMenuBurguer?: () => void;
}

export const SearchSidebar = memo(({ userType, collapseSearch, handdleMenuBurguer }: Props) => {
    const dispatch = useDispatch();
    const formikRef = useRef<FormikProps<any>>(null);
    const { investor: cats } = useSelector((store: StoreApp) => store.cats);

    useEffect(() => {
        if(userType === TypeUser.INVESTOR){
            dispatch(searchCompaniesContactable(formikRef.current?.values, '1'));
        }else{
            dispatch(searchContactable(formikRef.current?.values, '1'));
        }

        return () => {
            dispatch(resetSearchAction());
        }
    }, [dispatch, userType])


    const resetSearch = () => {
        formikRef.current?.resetForm();
    }

    const handleSearch = () => {
        dispatch(resetPagerAction(true))
        handdleMenuBurguer && handdleMenuBurguer();
        formikRef.current?.handleSubmit();
    }

    const handleSearchCompanies = () => {
        dispatch(resetPagerAction(true))
        formikRef.current?.handleSubmit();
        handdleMenuBurguer && handdleMenuBurguer();
    }

    useEffect(() => {

        //Pendiente
        // setTimeout(()=>{
        //     formikRef.current?.resetForm({values: {...searchVariables}});
        // },100)

        const values = formikRef.current?.values;
        dispatch(setSearchVariablesAction( values ));
    }, [dispatch])

    const SearchSidebar = () =>{
        return (
            <div className={collapseSearch ? 'search-sidebar d-none' : 'search-sidebar' }>
                <Formik
                    // @ts-ignore: next-line
                    innerRef={formikRef}
                    initialValues={{
                        investor_type: [
                            { label: "All", value: "All" }],
                        industries: [
                            { label: "All", value: "All" }],
                        countries: [
                            { label: "All", value: "All" }],
                        states: [
                            { label: "All", value: "All" }],
                        p_range_investment: [
                            { label: "All", value: "All" }],
                        fundingstage: [
                            { label: "All", value: "All" }],
                    }}
                    onSubmit={(values, { resetForm }) => {
                        dispatch(getSearch({ ...values }));
                        dispatch(setSearchVariablesAction({ ...values }));
                    }}
                    validationSchema={Yup.object({
                        // investor: Yup.string()
                        //     .required('Required'),
                        // portfolio_website: Yup.string()
                        //     .required('Required'),
                        // industries_selection: Yup.array()
                        //     .min(1, 'Required')
                        //     .required('Required'),
                        // portfolio_investment_amount: Yup.string()
                        //     .required('Required'),
                        // funding_stage_id: Yup.string()
                        //     .required('Required')
                    })
                    }>

                    {(formik) => (
                        <Form>
                            <Row className="col-12 mx-0 justify-content-end">

                                <Col sm={6} md={11} className="my-2">
                                    <MultiSelect
                                        label="Type of Investor:"
                                        name="investor_type"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.investor_type}
                                        touched={formik.touched.investor_type}
                                        value={formik.values.investor_type}
                                        options={cats?.catInvestorType}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} md={11} className="my-2">
                                    <MultiSelect
                                        label="Location of Investor:" name="states"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.states}
                                        touched={formik.touched.states}
                                        value={formik.values.states}
                                        options={cats?.catStates}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} md={11} className="my-2">
                                    <MultiSelect
                                        label="Industry:" name="industries"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.industries}
                                        touched={formik.touched.industries}
                                        value={formik.values.industries}
                                        options={cats?.catIndustries}
                                        isAll={true} />
                                </Col>

                                {/* <Col sm={11} className="my-2">
                                    <MultiSelect
                                        label="Industry:" name="industries"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.industries}
                                        touched={formik.touched.industries}
                                        value={formik.values.industries}
                                        options={cats?.catIndustries}
                                        isAll={true} />
                                </Col> */}

                                <Col sm={6} md={11} className="my-2">
                                    <MultiSelect
                                        label="Funding Stage:" name="fundingstage"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.fundingstage}
                                        touched={formik.touched.fundingstage}
                                        value={formik.values.fundingstage}
                                        options={cats?.catFunding}
                                        isAll={true} />
                                </Col>

                                {/* <Col sm={11} className="my-2">
                                    <MultiSelect
                                        label="Country:" name="countries"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.countries}
                                        touched={formik.touched.countries}
                                        value={formik.values.countries}
                                        options={cats?.catCountries}
                                        isAll={true} />
                                </Col> */}

                                <Col sm={6} md={11} className="my-2">
                                    <MultiSelect
                                        label="Range of Investment:" name="p_range_investment"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.p_range_investment}
                                        touched={formik.touched.p_range_investment}
                                        value={formik.values.p_range_investment}
                                        options={cats?.catRange}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} md={11} className='mt-3 text-end actions d-flex flex-column justify-content-center align-items-end'>
                                    <div style={{ backgroundColor: 'white', display: 'inline-block', borderRadius: '0.25rem',  marginBottom: '13px'  }}>
                                        <button type="button" className="btn btn-outline-primary" onClick={resetSearch}>Clear filters</button>
                                    </div>                                    
                                    <button type="button" className="btn btn-primary" onClick={handleSearch}>Search</button>
                                </Col>

                            </Row>

                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

    const SearchInvestorSidebar = () =>{
        return (
            <div className={collapseSearch ? 'search-sidebar d-none' : 'search-sidebar' }>
                <Formik
                    // @ts-ignore: next-line
                    innerRef={formikRef}
                    initialValues={{
                        states: [
                            { label: "All", value: "All" }],
                        industries: [
                            { label: "All", value: "All" }],
                        annual_g: [
                            { label: "All", value: "All" }],
                        p_funding: [
                            { label: "All", value: "All" }],
                        p_num_emp: [
                            { label: "All", value: "All" }],
                        // p_exits: [],
                        p_exits: [{ label: "", value: "All" }],
                        p_leadership: [{ label: "", value: "All" }],
                    }}
                    onSubmit={(values, { resetForm }) => {
                        dispatch(getSearchCompanies({ ...values }));
                        dispatch(setSearchVariablesAction({ ...values }));
                    }}
                    validationSchema={Yup.object({
                        // investor: Yup.string()
                        //     .required('Required'),
                        // portfolio_website: Yup.string()
                        //     .required('Required'),
                        // industries_selection: Yup.array()
                        //     .min(1, 'Required')
                        //     .required('Required'),
                        // portfolio_investment_amount: Yup.string()
                        //     .required('Required'),
                        // p_funding_id: Yup.string()
                        //     .required('Required')
                    })
                    }>

                    {(formik) => (
                        <Form>
                            <Row className="col-12 mx-0 justify-content-end">

                                <Col sm={6} lg={11} className="my-2">
                                    <MultiSelect
                                        label="State:"
                                        name="states"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.states}
                                        touched={formik.touched.states}
                                        value={formik.values.states}
                                        options={cats?.catStates}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} lg={11} className="my-2">
                                    <MultiSelect
                                        label="Industry:" name="industries"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.industries}
                                        touched={formik.touched.industries}
                                        value={formik.values.industries}
                                        options={cats?.catIndustries}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} lg={11} className="my-2">
                                    <MultiSelect
                                        label="Annual Gross Revenue:" name="annual_g"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.annual_g}
                                        touched={formik.touched.annual_g}
                                        value={formik.values.annual_g}
                                        options={cats?.catRevenue}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} lg={11} className="my-2">
                                    <MultiSelect
                                        label="Funding Stage:" name="p_funding"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.p_funding}
                                        touched={formik.touched.p_funding}
                                        value={formik.values.p_funding}
                                        options={cats?.catFunding}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} lg={11} className="my-2">
                                    <MultiSelect
                                        label="Number of Employees:" name="p_num_emp"
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        error={formik.errors.p_num_emp}
                                        touched={formik.touched.p_num_emp}
                                        value={formik.values.p_num_emp}
                                        options={cats?.catEmployees}
                                        isAll={true} />
                                </Col>

                                <Col sm={6} lg={11} className="my-2">
                                    <MySelect
                                        label="Previous Exits in Leadership Team:" name="p_exits">
                                        <option value="All">No</option>
                                        <option value="1">Yes</option>
                                    </MySelect>
                                </Col>

                                <Col sm={6} lg={11} className="my-2">
                                    <MySelect
                                        label="DEI in Leadership Team:" name="p_leadership">
                                        <option value="All"></option>
                                        <option value="1">Yes</option>
                                    </MySelect>
                                </Col>

                                <Col sm={6} lg={11} className='mt-3 text-end actions d-flex flex-column justify-content-center align-items-end'>
                                    <div style={{ backgroundColor: 'white', display: 'inline-block', borderRadius: '0.25rem', marginBottom: '13px' }} >
                                        <button type="button" className="btn btn-outline-primary" onClick={resetSearch}>Clear filters</button>
                                    </div>
                                    
                                    <button type="button" className="btn btn-primary" onClick={handleSearchCompanies}>Search</button>
                                </Col>

                            </Row>

                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

    return (
        <>
            {
                userType == 2 ?  <SearchInvestorSidebar /> : <SearchSidebar />
            }
        </>
    )
})
