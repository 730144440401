import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Row, Button, Accordion } from "react-bootstrap";
import { LeadershipTeamItem } from "./LeadershipTeamItem";
import { useDispatch, useSelector } from "react-redux";
import {
  addLeadership,
  getLeadership,
} from "../../../redux/actions/companyAction";
import * as _ from "lodash";

export const LeadershipTeam = ({handleValidateForm}) => {
  const dispatch = useDispatch();
  const itemsRef = useRef([]);
  const { loadedCats } = useSelector((state) => state.ui);
  const { leadership, filledSections } = useSelector((state) => state.company);
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (
      loadedCats &&
      filledSections?.filled_sections >= 3 &&
      _.isEmpty(leadership)
    )
      dispatch(getLeadership());
  }, [dispatch, filledSections, loadedCats, leadership]);

  const newLeadershipMock = {
    iIdTeamMember: 0,
    sMemberName: "",
    photo: "",
    iPosition: "",
    iGender: "",
    iEthniciti: "",
    sExpertice: "",
    sExits: "",
    sLinkedin: "",
  };

  const handleAdd = () => {
    if (leadership.find((m) => m.iIdTeamMember === 0)) {
      itemsRef.current[0].handleSubmit();
    } else {
      //console.log('newLeadershipMock',newLeadershipMock);
      dispatch(addLeadership(newLeadershipMock));
      setActiveKey(0);
    }
  };

  const handleActiveKey = useCallback(
    (i) => {
      if (activeKey === i) return setActiveKey(null);
      setActiveKey(i);
    },
    [activeKey]
  );

  const handleValidateLeadership = useCallback(() => {
    // console.log(idLeadership, valid);
    const validations = []
    itemsRef.current.forEach((iref) => {
        if (iref?.formikRef?.current) validations.push(iref.formikRef.current.isValid)
    })
    const isValidLeadership = validations.every((v) => v === true)
    // console.log(isValidLeadership)
    handleValidateForm('leadership', isValidLeadership)
  }, [handleValidateForm]);

  return (
    <div className="_leadershipTeam">
      <Accordion className="mt-4" activeKey={activeKey}>
        {leadership.map((lead) => (
          <LeadershipTeamItem
            key={lead.iIdTeamMember}
            leadership={lead}
            handleActiveKey={handleActiveKey}
            handleValidateLeadership={handleValidateLeadership}
            ref={(el) => (itemsRef.current[lead.iIdTeamMember] = el)}
          />
        ))}
      </Accordion>

      <Row>
        <Col lg={12} className=" mx-0 mt-3">
          <div className="d-block w-100 alineacionMember mb-3">
            <Button
              type="button"
              variant="outline-primary"
              onClick={handleAdd}
              disabled={filledSections.filled_sections === 6}
            >
              + Add Member
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
