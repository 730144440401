import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Modal } from "react-bootstrap"
import { ProgressTracker } from "../../../@core/interfaces/matchActivity.model";
import imgUser from '../../../assets/images/user.jpg';
import * as _ from 'lodash';
import { ProgressTrackerRow } from "./ProgressTrackerRow";
import { contactFundlyst, removeProgressTracker } from "../../../redux/actions/matchActivityAction";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TypeUser } from '../../../@core/interfaces/common.model';
import { StoreApp } from '../../../@core/interfaces/store';


interface Props {
    progress: ProgressTracker[];
    catRejection: any[];
    handleGetIdToShow: (id: number) => void;
}

interface StateProgressTracker {
    [x: string]: ProgressTracker[];
}

export const ProgressTrackerComponent = ({ progress, catRejection, handleGetIdToShow }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const userType = Number(localStorage.getItem('type'));
    const { type } = useSelector((state: StoreApp) => state.notifications.notificationCount)

    const isInvestorType = Number(type) === TypeUser.INVESTOR;

    const phaseName: { [key: number]: string } = {
        1: 'Deck Presented',
        2: 'Follow-up Conversation',
        3: 'Due Dillegence',
        4: 'Deal Closed',
    }

    const [state, setSate] = useState<StateProgressTracker>({})
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [showContact, setShowContact] = useState(false);

    const [infoModal, setInfoModal] = useState<ProgressTracker>({} as ProgressTracker);

    useEffect(() => {
        if (!progress || progress.length === 0) return;
        //const group = _.groupBy(progress, 'investor_id') as unknown;
        const str_grup_by = isInvestorType ? 'company_id' : 'investor_id'; 
        const group = _.groupBy(progress, str_grup_by) as unknown;
        const groupState = group as StateProgressTracker;
        setSate(groupState);
    }, [progress])


    const handleRemove = (progress: ProgressTracker) => {
        setInfoModal(progress);
        const id = isInvestorType ? progress.company_id : progress.investor_id; 
        dispatch(removeProgressTracker(String(progress.company_investor_request_id), id, handleShowModalRemove));
    }

    const handleGoToChat = ( userChat: ProgressTracker ) => {
        
        const idUserChat = isInvestorType ? userChat.company_id : userChat.investor_id
        const imgUserChatPre = isInvestorType ? userChat.company_logo : userChat.investor_logo
        const imgUserChat = encodeURI(imgUserChatPre)
        const nameUserChat = isInvestorType ? userChat.company_name : userChat.investor_fund_name
        const aux = imgUserChat.replace(/&/g,'XBZSWRTYSOOOF');

        navigate(`/chat?idUserChat=${idUserChat}&imgUserChat=${aux}&nameUserChat=${nameUserChat}`)
    }


    const handleShowModalRemove = () => setShowModalRemove(true);
    const handleCloseModalRemove = () => setShowModalRemove(false);

    const handleShowContactModal = () => setShowContact(true);
    const handleCloseContactModal = () => setShowContact(false);

    const handleShowContact = () => {
        setShowContact(true);
        dispatch(contactFundlyst(handleShowContactModal));
    }

    return (
        <>
            {
                Object.keys(state).map((idProgress) => (
                    <React.Fragment key={idProgress} >
                        <Card className='mt-4 card-content p-3'>
                            <Row className="fila">

                                <Col sm={12} lg={'auto'} className="d-flex flex-column align-items-center justify-content-center px-3 mb-3">
                                    <div className="card-content_image my-3">
                                        <img 
                                        onClick={ () => handleGetIdToShow(isInvestorType ? state[idProgress][0].company_id : state[idProgress][0].investor_id ) }
                                        src={
                                            state[idProgress][0].company_logo ? state[idProgress][0].company_logo : 
                                            (state[idProgress][0].investor_logo ? state[idProgress][0].investor_logo : imgUser)
                                        } 
                                        alt={'asdasf'} 
                                        className="border-img"/>
                                    </div>
                                    <span className="titulo-img"
                                        onClick={ () => handleGetIdToShow(isInvestorType ? state[idProgress][0].company_id : state[idProgress][0].investor_id ) } >
                                        {isInvestorType ? state[idProgress][0].company_name:state[idProgress][0].investor_fund_name}
                                    </span>
                                </Col>
                                <Col className="d-flex align-items-center justify-content-center px-2" >
                                    {
                                        <Row className="align-items-center px-2 col-12">
                                            {
                                                state[idProgress].map((progress) => (
                                                    <div key={progress.phase_id}>
                                                        <ProgressTrackerRow
                                                            id={progress.phase_id}
                                                            name={phaseName[progress.phase_id]}
                                                            prog={progress}
                                                            catRejection={catRejection}
                                                            nameCompanyInvestor={isInvestorType ? state[idProgress][0].company_name:state[idProgress][0].investor_fund_name}
                                                        />
                                                        <hr />
                                                    </div>
                                                ))
                                            }
                                        </Row>
                                    }
                                </Col>

                                <Col sm={12}>
                                    <div 
                                        style={{'flexWrap':'wrap'}}
                                        className="d-flex justify-content-center justify-content-md-end align-items-center mt-2 contenedor-btns-final">
                                        {
                                            state[idProgress][0].show_chat === 1 && (
                                                <Button 
                                                onClick={ ()=> handleGoToChat( state[idProgress][0] ) }
                                                type="button" className="mx-2 px-4" variant="primary">Chat</Button>
                                            )
                                        }
                                        {
                                            state[idProgress][0].show_contact_fundlyst === 1 && (
                                                <Button 
                                                type="button" 
                                                className="mx-2 mb-2 mb-sm-0" 
                                                variant="outline-primary" 
                                                onClick={handleShowContact}
                                                >Contact FundLyst Support</Button>
                                            )
                                        }
                                        {
                                            state[idProgress][0].show_delete === 1 && (


                                                <Button
                                                    type="button"
                                                    className="mx-2 mb-2 mb-sm-0"
                                                    variant="primary"
                                                    onClick={() => handleRemove(state[idProgress][0])}
                                                >Remove</Button>
                                            )
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card>

                        <Modal
                            show={showModalRemove}
                            onHide={handleCloseModalRemove}
                            centered
                            dialogClassName="modal-common"
                            animation={false}
                        >
                            <Modal.Body>
                                <div className="d-flex justify-content-end">
                                    <i className="far fa-times-circle text-primary"
                                        onClick={handleCloseModalRemove}
                                        style={{ fontSize: '24px', cursor: 'pointer' }}
                                    ></i>
                                </div>
                                <div className="text-center px-3 py-4 lh-sm">
                                    <p className="mb-0"><b>{infoModal.investor_fund_name}</b></p>
                                    <p>Will be moved to your Saved {isInvestorType ? 'Companies' : 'Investors'}. </p>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={showContact}
                            onHide={handleCloseContactModal}
                            centered
                            dialogClassName="modal-common"
                            animation={false}
                        >
                            <Modal.Body>
                                <div className="d-flex justify-content-end">
                                    <i className="far fa-times-circle text-primary"
                                        onClick={handleCloseContactModal}
                                        style={{ fontSize: '24px', cursor: 'pointer' }}
                                    ></i>
                                </div>

                                <div className="text-center px-3 py-4 lh-sm">
                                    <span>
                                        Thank you for getting in touch.
                                    </span>
                                    <br />
                                    <span>
                                        A FundLyst team member will contact you within 24 hours.
                                    </span>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </React.Fragment>
                ))
            }
        </>
    )
}