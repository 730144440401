import { fetchCatalogs } from "../../helpers/fetch";
import { Result } from "../interfaces/common.model";
import { ChatResponse } from "../interfaces/chat.model";
import {
  Invitations,
  ProgressTracker,
  Requests,
} from "../interfaces/matchActivity.model";

export const _invitations = async (
  idUser: string = "1",
  idType: string = "1"
): Promise<Invitations[]> => {
  const res = await fetchCatalogs(
    `prod/matchactivity?user_id=${idUser}&usr_type=${idType}&sel_type=1`
  );
  return await res.json();
};

export const _requests = async (
  idUser: string = "1",
  idType: string = "1"
): Promise<Requests[]> => {
  const res = await fetchCatalogs(
    `prod/matchactivity?user_id=${idUser}&usr_type=${idType}&sel_type=2`
  );
  return await res.json();
};

export const _progressTracker = async (
  idUser: string = "1",
  idType: string = "1"
): Promise<ProgressTracker[]> => {
  const res = await fetchCatalogs(
    `prod/matchactivity?user_id=${idUser}&usr_type=${idType}&sel_type=3`
  );
  return await res.json();
};

export const _progressTrackerChat = async (
  idUser: string = "1",
  idType: string = "1"
): Promise<ChatResponse[]> => {
  const res = await fetchCatalogs(
    `prod/matchactivity?user_id=${idUser}&usr_type=${idType}&sel_type=3`
  );
  return await res.json();
};

export const _removeProgressTracker = async (
  idCompany: string,
  company_investor_request_id: string,
  user_type: string
): Promise<Result> => {
  const req = {
    user_id: idCompany,
    company_investor_request_id,
    user_type,
    type: "remove",
  };
  const res = await fetchCatalogs(`prod/matchactivity`, req, "POST");
  return await res.json();
};
export const _ContactFundlyst = async (
  idCompany: string,
  sNombre: string,
  sEmail: string
): Promise<Result> => {
  const req = {
    nombre: sNombre,
    id: idCompany,
    email: sEmail,
  };const res = await fetchCatalogs(`prod/contactrequest`, req, "POST");
  return await res.json();
};

export const _thisFitProgressTracker = async (
  idCompany: string,
  company_investor_request_id: string,
  phase_id: string,
  user_type: string,
): Promise<Result> => {
  const req = {
    user_id: idCompany,
    company_investor_request_id,
    phase_id,
    type: "thisisafit",
    user_type,
  };
  const res = await fetchCatalogs(`prod/matchactivity`, req, "POST");
  return await res.json();
};

export const _notFitProgressTracker = async (
  idCompany: string,
  company_investor_request_id: string,
  phase_id: string,
  company_rejection_reason_id: string,
  user_type: string
): Promise<Result> => {
  const req = {
    user_id: idCompany,
    company_investor_request_id,
    phase_id,
    company_rejection_reason_id,
    user_type,
    type: "notafit",
  };
  const res = await fetchCatalogs(`prod/matchactivity`, req, "POST");
  return await res.json();
};


export const _removeRequestsSent = async (
  idCompany: string,
  company_investor_request_id: string,
  company_user_id: string,
  user_type: string
): Promise<Result> => {
  const req = {
    user_id: idCompany,
    company_investor_request_id,
    company_user_id,
    user_type,
    type: "remove_req",
  };
  const res = await fetchCatalogs(`prod/matchactivity`, req, "POST");
  return await res.json();
};

export const _cancelRequestsSent = async (
  idCompany: string,
  company_investor_request_id: string,
  user_type: string
): Promise<Result> => {
  const req = {
    user_id: idCompany,
    company_investor_request_id,
    user_type,
    type: "cancel_req",
  };
  const res = await fetchCatalogs(`prod/matchactivity`, req, "POST");
  return await res.json();
};

export const _declineInvitations = async (
  idCompany: string,
  company_investor_request_id: string,
  user_type: string,
  company_rejection_reason_id: string
): Promise<Result> => {
  const req = {
    user_id: idCompany,
    company_investor_request_id,
    user_type,
    company_rejection_reason_id,
    type: "decline",
  };
  const res = await fetchCatalogs(`prod/matchactivity`, req, "POST");
  return await res.json();
};

export const _acceptInvitations = async (
  userId: string,
  company_investor_request_id: string,
  user_type: string,
): Promise<Result> => {
  const req = {
    user_id: userId,
    company_investor_request_id,
    user_type,
    company_rejection_reason_id: 0,
    type: "accept",
  };
  const res = await fetchCatalogs(`prod/matchactivity`, req, "POST");
  return await res.json();
};