import { useCallback, useEffect, useState, useRef } from 'react';
import logo from '../../assets/images/logo-color.svg';
import imgMainProfile from '../../assets/images/Logo-placeholder.jpg';
import rowIcon from '../../assets/icons/arrow_down.svg';
import notificationIcon from '../../assets/icons/notification.svg';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../@core/interfaces/store';
import { getNotifications } from '../../redux/actions/notificationsAction';
import { getUserInfo, showNotification } from '../../redux/actions/uiAction';
import { Badge, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Notifications } from './Notifications';
import { SidebarCustom } from './SidebarCustom';
import { useNavigate } from "react-router-dom";
import { setCountChat } from '../../redux/actions/chatAction';

const banner = (require as any).context('../../assets/images', true)

// import { useMedia } from '../../hooks/useMedia';

interface Props {
    onDisconnect: () => void;
  }


export const Navbar = (props: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const isMobile = useMedia("(max-width: 1100px)");
    const { notification, userInfo } = useSelector((state: StoreApp) => state.ui)
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications)
    const { countChat } = useSelector((store: StoreApp) => store.chat);
    const buttonNotifRef=useRef<HTMLButtonElement>(null);
    const [menuBurguer, setMenuBurguer] = useState(false);

    useEffect(() => {
        //se llama la primera vez desde aqui para evitar el delay inicial
        dispatch(getNotifications())
        const interval = setInterval(() => {
            dispatch(getNotifications(false))
        }, 30000);


    }, [dispatch]);

    useEffect(() => {
        if (notificationCount.company_investor_id && notificationCount.type) {
            dispatch(getUserInfo(notificationCount.company_investor_id, notificationCount.type))
        }
    }, [notificationCount, dispatch]);


    // const openNotification = () => {
    //     dispatch(showNotification(!notification))
    // }

    // const openSidebar = () => {
    //     dispatch(openSidebarAction(true))
    // }

    const closeSession = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("idCompanyInvestor");
        localStorage.removeItem("type");

    }

    const goToChat = () => {
        props.onDisconnect()
        navigate('/chat');
        dispatch(setCountChat(0))
    }

    const HanddleMenuBurguer = useCallback( () => {
        document.body.classList.toggle('__body-overflow-hidden', !menuBurguer);
        setMenuBurguer(!menuBurguer)
    },[menuBurguer])

    const HanddleCloseMenuNotif = () =>{
        //console.log('click')
        buttonNotifRef.current?.click()
    }

    return (
        <>
            <nav className="navbar navbar-light bg-light px-5 border-bottom estilos-navbar">
                <div className="container-navbar">

                    <div className="__nav-list">
                        {/* {
                        isMobile && (
                            <i
                                onClick={openSidebar}
                                className="fas fa-bars text-primary"
                                style={{ fontSize: '25px', cursor: 'pointer' }}
                            >
                            </i>
                        )
                    } */}
                        <nav className="burguer-menu">
                            <label htmlFor="menu" onClick={HanddleMenuBurguer}>
                                <i className="fas fa-bars text-gris" style={{ fontSize: '25px', cursor: 'pointer' }} ></i>
                            </label>
                        </nav>

                        <a href="/home">
                            <div className="logo">

                                <img src={logo} alt="fundlyst" />
                            </div>
                        </a>

                        <div className="feature-profile">
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={goToChat}
                                className="icon-chat">
                                <i className="far fa-comment-alt" style={{ fontSize: '23px', color:'#2d128d' }}></i>
                                {
                                    countChat === 1 && (
                                        <Badge pill bg="danger" style={{width:'15px', height:'15px' }} >&nbsp;</Badge>
                                    )
                                }
                            </div>

                            <div className="icon-notifications">

                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic" ref={buttonNotifRef}>
                                        <i className="far fa-bell" style={{ fontSize: '23px', color:'#2d128d' }}></i>
                                        <Badge pill bg="danger">
                                            {notificationCount.notif_count}
                                        </Badge>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className='custom-dropdown-menu'>
                                        <Notifications
                                            HanddleCloseMenuNotif={HanddleCloseMenuNotif}
                                         styles={{ width: '400px', heigh: '700px' }} />
                                    </Dropdown.Menu>
                                </Dropdown>


                                {/* <Button 
                                variant="light" 
                                onClick={openNotification}>
                                    <i className="far fa-bell" style={{ fontSize: '23px' }}></i>
                                    <Badge pill bg="danger">
                                        {notificationCount.notif_count}
                                    </Badge>
                                </Button> */}
                                {/* <img src={notificationIcon} width="30" alt="row" style={{ cursor: 'pointer' }}  /> */}
                            </div>
                            
                            <Dropdown className='dropdown-user'>
                                <Dropdown.Toggle variant="light">
                                    <div className="image-profile mx-2"  style={{ backgroundImage: 'url(' + (userInfo.logo ? userInfo.logo : imgMainProfile) + ')' }}>
                                        <div className="profile"></div>
                                    </div>
                                    <div className="desc-profile mx-2">
                                        <p>{userInfo.username}</p>
                                        <p>{userInfo.name}</p>
                                    </div>
                                    <div className="px-2">
                                        <i className='fa fa-angle-down'></i>
                                        {/* <Button variant="light" onClick={openNotification}>
                                            <img src={rowIcon} width="30" alt="row" />

                                        </Button> */}
                                        {/* <DropdownButton id="dropdown-basic-button" title="" variant="light" className='btn-cerrar-sesion'>
                                            <Dropdown.Item onClick={closeSession} href="/login">Log Out</Dropdown.Item>
                                        </DropdownButton> */}
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end" className='custom-dropdown-menu-user'>
                                    <Dropdown.Item onClick={closeSession} href="/login"><i className="fas fa-arrow-right-from-bracket"></i><span style={{'color':'#3816b0'}}>Log Out</span></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </nav>
            {
                <div className={menuBurguer ? 'contenedor-menu' : 'd-none' }>
                    <SidebarCustom handdleMenuBurguer={HanddleMenuBurguer}></SidebarCustom>
                </div>
            }
        </>
    )
}
