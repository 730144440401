import React, { useState, useRef, useCallback } from 'react';
import { Col, Row, Accordion, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PortfolioItem } from './PortfolioItem';
import { newCompany } from '../../../redux/actions/investorAction';

export const Portfolio = ({handleValidateForm}) => {

    const dispatch = useDispatch();
    const itemsRef = useRef([])
    const { filledSections } = useSelector((state) => state.investor);
    const [activeKey, setActiveKey] = useState(null);
    const { companies } = useSelector(state => state.investor);
    const { loading } = useSelector(state => state.ui);

    const newCompanyObj = {
        portfolio_id: 0,
        portfolio_logo: '',
        portfolio_name: '',
        portfolio_website: '',
        industries_selection: [],
        portfolio_investment_amount: '',
        funding_stage_id: '',
        lead_investor: ''
    }

    const handleAdd = () => {
        if (companies.find((m) => m.portfolio_id === 0)) {
            itemsRef.current[0].handleSubmit()
        } else {
            dispatch(newCompany(newCompanyObj));
            setActiveKey(0)
        }
    }

    // const handleSubmit = () => {
    //     companies.forEach((c) => {
    //         itemsRef.current[c.portfolio_id].handleSubmit()
    //     });
    // }

    const handleActiveKey = (i) => {
        if (activeKey === i) return setActiveKey(null);
        setActiveKey(i);
    }

    const handleValidatePortfolio = useCallback(() => {
        const validations = []
        itemsRef.current.forEach((iref) => {
            if (iref?.formikRef?.current) validations.push(iref.formikRef.current.isValid)
        })
        const isValidTeammember = validations.every((v) => v === true)
        handleValidateForm('portfolio', isValidTeammember)
      }, [handleValidateForm]);

    return (
        <>
            <div className="_portfolio">
                <Accordion className="mt-4" activeKey={activeKey}>
                    {companies.map((c) => (
                        <PortfolioItem
                            portfolio={c}
                            handleActiveKey={handleActiveKey}
                            handleValidatePortfolio={handleValidatePortfolio}
                            ref={el => itemsRef.current[c.portfolio_id] = el}
                            key={c.portfolio_id}
                        />
                    ))
                    }
                </Accordion>
                <Row>
                    <Col lg={12} className="mx-0 mt-3">
                        <div className="d-block w-100 alineacionPortfolio mb-3">
                            <button type="submit" className="btn btn-outline-primary"
                                disabled={filledSections.filled_sections === 5}
                                onClick={handleAdd}>+ Add Company</button>
                        </div>
                    </Col>
                    {/* <Col lg={12} className="mx-0 mt-3">
                        <div className="d-block w-100 alineacionBtn">
                            <Button type="submit" variant="primary" className="btn-save" onClick={handleSubmit} disabled={loading}>Save</Button>
                        </div>
                    </Col> */}
                </Row>
            </div>
        </>
    )
}
