import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Col, Row, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import { getLeadership, getValueProposition } from '../../../redux/actions/companyAction';
import { getProfileView } from '../../../redux/actions/profileViewAction';
import { PublicProfileFinancials } from './PublicProfileFinancials';
import { PublicProfileInfoAdminView } from './PublicProfileInfoAdminView';
import { PublicProfileLeadership } from './PublicProfileLeadership';
import { PublicProfileProposition } from './PublicProfileProposition';
import imgMainProfile from '../../../assets/images/Logo-placeholder.jpg';
import imgBanner from '../../../assets/images/background_main.jpg';
import { CustomCardProfile } from './@custom/CustomCardProfile';
import { getFinancials } from '../../../redux/actions/companyAction';
import { TypeUser } from '../../../@core/interfaces/common.model';
import _ from "lodash";

export const PublicProfileViewAdminView = () => {

    const dispatch = useDispatch();
    const { profile } = useSelector((state: StoreApp) => state.profileView)
    const { valueProposition, leadership } = useSelector((state: StoreApp) => state.company)
    const { company_investor_id } = useSelector((state: StoreApp) => state.notifications.notificationCount)
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications);
    const [searchParams] = useSearchParams();
    const [idCompany, setIdCompany] = useState<string | number>(0);

    const userType = Number(notificationCount.type);

    useEffect(() => {
      getParams();
    }, [])

    const getParams = () => {
      setIdCompany(searchParams.get('idCompany') || 0);
    }

    useEffect(() => {
            
        getParams();
        const id = Number(idCompany);
        if(id === 0) return;

        dispatch(getProfileView(id))
        dispatch(getValueProposition(id));
        dispatch(getLeadership(id));
        dispatch(getFinancials(true, id))

    }, [dispatch, idCompany])

    const downloadFile = () => {
        let link = document.createElement("a");
        link.target = '_blank';
        link.download = 'prsentation_deck';
        link.href = profile.presentation_deck;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            {
                profile && (
                    <div className="_PublicProfileView">
                        <div
                            className="img-main-cover-publicProfile"
                            style={{backgroundImage: 'url(' + (profile.company_banner ? profile.company_banner : imgBanner) + ')',}}>
                            <img src={profile.company_logo ? profile.company_logo : imgMainProfile} alt="logo" className="img-redonda-main-publicProfile" />
                        </div>

                        <PublicProfileInfoAdminView profile={profile} />

                        <PublicProfileFinancials profile={profile} />

                        <PublicProfileProposition proposition={valueProposition} />

                        <PublicProfileLeadership leadership={leadership} />

                        {/* Contenedor funding */}
                        <div>
                            <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                                <div className='titulo-seccion'>FUNDING</div>
                                <div className="line"></div>
                            </p>
                            <p className='parrafo-funding'>
                                {_.unescape(profile.Funding_Text)}
                            </p>
                            <Row>
                                <Col md={4} sm={12}>
                                    <CustomCardProfile title="Funding State:" text={profile.Funding_Stage} />
                                </Col>
                                <Col md={4} sm={12}>
                                    <CustomCardProfile title="Projected Raise:" text={profile.Projected_Raise} />
                                </Col>
                                <Col md={4} sm={12}>
                                    <CustomCardProfile title="We Need Help Here:" text={profile.We_could_use_help_here} />
                                </Col>
                            </Row>
                        </div>
                        <div style={{ textAlign: 'center', margin: '25px 0' }}>
                            <Button type="button" variant="outline-primary" disabled={!profile?.presentation_deck} onClick={downloadFile}>
                                Download Pitch Deck
                            </Button>
                        </div>

                    </div>
                )
            }
        </>
    )
}
