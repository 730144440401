import {
  Notification,
  NotificationCount
} from '../../@core/interfaces/notifications.model'
import { notificationsTypes } from '../types/notificationsTypes'

const initialState: Notification = {
  notifications: [],
  notificationCount: {} as NotificationCount,
  loadedInfo: false
}

export const notificationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case notificationsTypes.getNotificationCount:
      return {
        ...state,
        loadedInfo: true,
        notificationCount: { ...action.payload }
      }
    case notificationsTypes.getNotificationInfo:
      return {
        ...state,
        notifications: [...action.payload]
      }
    // case notificationsTypes.updateNotification:
    //   return {
    //     ...state,
    //     notifications: state.notifications.map((m) =>
    //       m.company_investor_request_id ===
    //       action.notification.company_investor_request_id
    //         ? {
    //             ...m,
    //             request_notifications_id:
    //               action.payload.notification.request_notifications_id,
    //           }
    //         : m
    //     ),
    //   };
    case notificationsTypes.deleteNotification:
      return {
        ...state,
        notifications: state.notifications.filter((m) => Number(m.company_investor_request_id) !== Number(action.payload.notification.company_investor_request_id)),
        //Pendiente de optimizarce
        // notificationCount: state.notifications.filter((m) => {
        //   if(Number(m.company_investor_request_id) !== Number(action.payload.notification.company_investor_request_id)){
        //     return m.notification_status === 'Not Viewed' ? 
        //       state.notificationCount 
        //         :
        //       { ...state.notificationCount, 
        //         notif_count: state.notificationCount.notif_count > 0 ? (state.notificationCount.notif_count - 1 ) : 0 
        //       }
        //   }
        // }),

        // notificationCountX: { ...state.notificationCount, 
        //                     notif_count: state.notificationCount.notif_count > 0 ? (state.notificationCount.notif_count - 1 ) : 0 
        //                   }
      }
    default:
      return state
  }
}
