import { ErrorMessage, useField } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";

interface Props {
  label: string;
  name: string;
  type?: "text" | "email" | "password" | 'number';
  placeholder?: string;
  infoIcon?: boolean;
  linkedIcon?: boolean;
  textTooltip?: string;
  [x: string]: any;
}

export const MyTextInput = ({ label, textArea, infoIcon, disableLabel, textTooltip, linkedIcon, ...props }: Props) => {
  const [field] = useField(props);

  return (
    <>
      <Form.Group>
        {infoIcon && (
          <OverlayTrigger
            key={'top'}
            placement={'top'}
            overlay={textTooltip ?
              <Tooltip id={`tooltip-${label}`}>
                {textTooltip}
              </Tooltip> : <></>
            }
          >
            <i className="fas fa-info-circle me-2" style={{ color: "#ccc" }}></i>
          </OverlayTrigger>
        )}
        {linkedIcon && (
          <i className="fab fa-linkedin me-2" style={{ color: '#0C66C2', fontSize: '25px' }}></i>
        )}
        {
          !disableLabel &&
          <Form.Label>{label}</Form.Label>
        }
        {
          textArea ?
            <Form.Control as="textarea"
              className={`form-control ${props?.errors?.[props.name] && "is-invalid"
                }`}
              {...field}
              {...props}
            /> : <Form.Control
              className={`form-control ${props?.errors?.[props.name] && "is-invalid"
                }`}
              {...field}
              {...props}
            />
        }
        {(props.errors?.[props.name]) &&
            <div className="invalid-feedback">
                {props.errors?.[props.name]}
            </div>
        }
        {/* <ErrorMessage
          name={props.name}
          className="invalid-feedback"
          component="div"
        /> */}
      </Form.Group>
    </>
  );
};
