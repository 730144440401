import { Router } from './routes/Router'
import { Loader } from './helpers/Loader'
import './assets/font-awesome/css/all.min.css'
import { useSelector } from 'react-redux'
import { StoreApp } from './@core/interfaces/store'
import 'react-toastify/dist/ReactToastify.css'

export const App = () => {
  const { loading } = useSelector((state: StoreApp) => state.ui)
  return (
    <>
      {
        loading && <Loader />
      }
      <Router />
    </>
  )
}

/* import logo from './logo.svg';
import React,{useState} from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

const App=() =>{
    const [state,setState] = useState({phone:"",email:""});
    const phonechange=(e) => { setState({...state,phone:e.target.value}) }
    const emailchange=(e) => { setState({...state,email:e.target.value}) }
    const signUp= async() => {
        console.log(state);
        try {
            const { user } = await Auth.signUp({
                username:state.email,
                password:"Cf123445566",
                attributes: {
                    email:state.email,          // optional
                    phone_number:state.phone,   // optional - E.164 number convention
                    website:"Personalizada",
                    // other custom attributes
                }
            });
            console.log(user);
        } catch (error) {
            console.log('error signing up:', error);
        }
    }
    return (
        <div className="App">
            <header className="App-header">
                <AmplifySignOut />
                <h2>My App Content</h2>
                <label>Correo: </label><input value={state.email} onChange={emailchange} type="text"/>
                <label>Telefono: </label><input value={state.phone} onChange={phonechange} type="text"/>
                <button onClick={signUp}>Registro</button>
            </header>
        </div>
    );
}

export default withAuthenticator(App);
//export default App();
Amplify.configure(awsconfig); */
