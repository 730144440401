import { memo } from 'react';
import { Card, Row, Col } from "react-bootstrap"
import { SearchResponse } from "../../../@core/interfaces/search.model"
import { useNavigate, createSearchParams } from 'react-router-dom'
import { TypeUser } from '../../../@core/interfaces/common.model';
import userImg from '../../../assets/images/user.jpg';


const CardSearch = (data: SearchResponse) => {
    const navigate = useNavigate();
    const userType = Number(localStorage.getItem('type'));

    const handleNavigate = () => {
        // if (!data.company_id) return;
        if (!data.investor_id) return;
        data.handleShowProfile('?idInvestor='+data.investor_id)

        // navigate({
        //     pathname: "/public-profile-investor",
        //     search: `?${createSearchParams({
        //         // idInvestor: String(data.company_id)
        //         idInvestor: String(data.investor_id), 
        //         available: String(data.available)
        //     })}`
        // });
    }


    const handleNavigateCompany = () => {
        if (!data.company_id) return;
        data.handleShowProfile('?idCompany='+data.company_id)
        // navigate({
        //     pathname: "/public-profile-company",
        //     search: `?${createSearchParams({
        //         idCompany: String(data.company_id)
        //     })}`
        // });
    }

    const CardInvestor = () => {
        return (
            <Card
                className='mt-4 card-content rounded-3 p-3 cardInvestor'
                style={{ cursor: 'pointer' }}
                onClick={handleNavigate}
            >
                <Row className="modificador-orden col-12">
                    <Col lg={'auto'} md={12} className="d-flex align-items-center justify-content-center px-3">
                        <div className="card-content_image my-3">
                            <img src={data.investor_logo ? data.investor_logo : userImg} alt={data.investor_name} className="border-img" />
                        </div>
                    </Col>
                    <Col lg={10} md={12} className="alineacion-mobile">
                        <div className="card-content_text">
                            <h3>{data.investor_name} - {data.investor_fund_name} </h3>
                            <h5 className='modificador-pholosofy'>{data.investment_philosophy}</h5>
                            {/* <p className='modificador-pholosofy'>Si los autores que me han precedido hubieran omitido el elogio de la historia en sí, sin duda sería necesario que yo urgiera a todos la elección y transmisión de tratados de este tipo, ya que para los hombres no existe enseñanza más clara que el conocimiento de los hechos pretéritos. Pero no sólo algunos, ni de vez en cuando, sino que prácticamente todos los autores, al principio y al final, nos proponen tal apología; aseguran que del aprendizaje de la historia resultan la formación y la preparación para una actividad política; afirman también que la rememoración de las peripecias ajenas es la más clarividente y la única maestra que nos capacita para soportar con entereza los cambios de fortuna. Es obvio, por consiguiente, que nadie, y mucho menos nosotros, quedaría bien si repitiera lo que muchos han expuesto ya bellamente. Porque la propia originalidad de los hechos acerca de los cuales nos hemos propuesto escribir se basta por sí misma para atraer y estimular a cualquiera, joven y anciano, a la lectura de nuestra obra. En efecto, ¿puede haber algún hombre tan n</p> */}
                            <p className="mb-1"> <i className="fas fa-dollar-sign"></i>Range of Investment:<b> {data.investment_bracket_description}</b></p>
                            <p className="mb-1"> <i className="fas fa-user-tie"></i><b>{data.investor_type_name}</b></p>
                            <p className="mb-1"> <i className="fas fa-industry"></i><b>{data.industries}</b></p>
                            <p className="mb-1"> <i className="fas fa-map-marker-alt"></i><b>{data.city_name}, {data.state_name}</b></p>
                        </div>
                    </Col>

                </Row>
            </Card>
        );
    }

    const CardCompany = () => {
        return (

            <Card
                className='mt-4 card-content rounded-3 p-3 CardCompany'
                style={{ cursor: 'pointer' }}
                onClick={handleNavigateCompany}
            >
                <Row className="modificador-orden col-12">

                    <Col lg={'auto'} md={12} className="d-flex align-items-center justify-content-center px-3">
                        <div className="card-content_image my-3">
                            <img src={data.company_logo ? data.company_logo : userImg} alt={data.company_name} className="border-img" />
                        </div>
                    </Col>
                    <Col lg={10} md={12} className="alineacion-mobile">
                        <div className="card-content_text">
                            <h3>{data.company_name} </h3>
                            <h5 className='modificador-pholosofy'>{data.company_description}</h5>
                            {/* <p className='modificador-pholosofy'>Si los autores que me han precedido hubieran omitido el elogio de la historia en sí, sin duda sería necesario que yo urgiera a todos la elección y transmisión de tratados de este tipo, ya que para los hombres no existe enseñanza más clara que el conocimiento de los hechos pretéritos. Pero no sólo algunos, ni de vez en cuando, sino que prácticamente todos los autores, al principio y al final, nos proponen tal apología; aseguran que del aprendizaje de la historia resultan la formación y la preparación para una actividad política; afirman también que la rememoración de las peripecias ajenas es la más clarividente y la única maestra que nos capacita para soportar con entereza los cambios de fortuna. Es obvio, por consiguiente, que nadie, y mucho menos nosotros, quedaría bien si repitiera lo que muchos han expuesto ya bellamente. Porque la propia originalidad de los hechos acerca de los cuales nos hemos propuesto escribir se basta por sí misma para atraer y estimular a cualquiera, joven y anciano, a la lectura de nuestra obra. En efecto, ¿puede haber algún hombre tan n</p> */}
                            <p className="mb-1"> <i className="fas fa-dollar-sign"></i>Annual Gross Revenue:<b> {data.annual_gross_revenue}</b></p>
                            <p className="mb-1"> <i className="fas fa-seedling"></i>Funding Stage: <b>{data.funding_stage_desc}</b></p>
                            <p className="mb-1"> <i className="fas fa-industry"></i><b>{data.industries}</b></p>
                            <p className="mb-1"> <i className="fas fa-map-marker-alt"></i><b>{data.city_name}, {data.state_name}</b></p>
                        </div>
                    </Col>

                </Row>
            </Card>
        );
    }

    return (
        <>
            {Number(userType) === TypeUser.INVESTOR ?  <CardCompany /> : <CardInvestor />}
        </>
    )
}

export default memo(CardSearch);
