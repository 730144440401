import React from 'react'
import { Button } from 'react-bootstrap'

interface Props {
    handleActionModal: () => void;
}

export const ModalSubmit = React.memo(({ handleActionModal }: Props) => {


    return (
        <div className='text-justify modalsubmit_investor'>
            <a href="#close" title="Close" className="close">
                <i className="far fa-times-circle text-primary"></i>
            </a>
            {/* <div className="circulo mb-2 mt-4">
                <i className="fas fa-thumbs-up"></i>
            </div> */}
            <br></br>
            <div className="circulo mb-2 mt-4">
                <i className="fas fa-thumbs-up"></i>
            </div>
            <p className='my-2'  style={{ textAlign: 'justify'}}>
                Thank you for applying to join FundLyst. We will review your application within 24 hours. If you have any specific questions, please contact support@fundlyst.net for assistance.
            </p>
            <div className="contenedor-btns my-3">
                <a href="#close" className='me-2' >
                    {/* <Button
                        type="button"
                        variant="primary"
                        className='estilos-boton'
                    >
                        Cancel
                    </Button> */}
                    <button
                        type="button"
                        className="btn btn-primary estilo-btn"

                    >
                        Ok
                    </button>
                </a>
                {/* <Button
                    type="button"
                    variant="primary"
                    className='estilos-boton '
                    onClick={handleActionModal}
                >
                    Yes, Continue
                </Button> */}
            </div>

        </div>
    )
})
