import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ProfileViewProfile } from "../../../@core/interfaces/profileView.model";
import { StoreApp } from "../../../@core/interfaces/store";
import { TypeUser } from '../../../@core/interfaces/common.model';
import { actionSaveCompany } from "../../../redux/actions/homeAction";
import _ from "lodash";

interface Props {
    profile: ProfileViewProfile;
}

export const PublicProfileInfo = React.memo(({ profile }: Props) => {

    const dispatch = useDispatch();
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications);

    const user_id = notificationCount.company_investor_id;
    const userType = Number(notificationCount.type);
    const canSaveCompany = userType === TypeUser.INVESTOR;
    const canContacCompany = userType === TypeUser.INVESTOR;

    const handleSaveCompany = () => {
        if (!canSaveCompany) return;
        if (profile.company_id) {
            dispatch(actionSaveCompany(user_id, String(profile.company_id), 'save'));
        }
    }

    const handleContactCompany = () => {
        if (!canContacCompany) return;
        if (profile.company_id) {
            dispatch(actionSaveCompany(user_id, String(profile.company_id), 'sendreq'));
        }
    }

    return (<>
        {
            profile &&
            <>
                <Row>
                    <Col sm={12} md={8}>
                        <p className='primer-parrafo mb-0'>

                            {/* MOBILE*/}
                            <div className="contenedor-mobile mb-2">
                                <span style={{ fontSize: '20px' }}>{profile.company_name}</span>
                                <a href={profile.linkedin} target="_blank" className="mx-3" rel="noreferrer">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                            </div>
                            {/* DESKTOP */}
                            <span style={{ fontSize: '20px' }} className="visualidad-desktop">{profile.company_name}</span>
                            <a href={profile.linkedin} target="_blank" className="mx-2 visualidad-desktop" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <i className="fas fa-globe me-2 visualidad-desktop2"></i>
                            <a href={profile.company_website} target="_blank" className="enlaces-estilos me-1 visualidad-desktop2" rel="noreferrer" style={{color:'#2d128d'}}>
                                <span>{profile.company_website}</span>
                            </a>

                            <i className="fas fa-map-marker me-2 visualidad-desktop2"></i>
                            <span className="visualidad-desktop2">{profile.city_name}, {profile.state_name}</span>
                            {/* MOBILE */}
                            <div className="contenedor-mobile2 mb-2">
                                <div>
                                    <i className="fas fa-globe me-2"></i>
                                    <a href={profile.company_website} target="_blank" className="enlaces-estilos me-1" rel="noreferrer">
                                        <span>{profile.company_website}</span>
                                    </a>
                                </div>
                                <div>
                                    <i className="fas fa-map-marker me-2"></i>
                                    <span>{profile.city_name}, {profile.state_name}</span>
                                </div>
                                <div>
                                    <i className="fas fa-users"></i> <span className="me-2 fw-normal">Employees:</span><b><span>{profile.employees}</span></b>
                                </div>
                                <div>
                                    <i className="fas fa-calendar-alt"></i> <span className="fw-normal">Founded in: <b>{profile.founding_year}</b></span>
                                </div>
                                <div>
                                    <i className="fas fa-industry"></i> <span>{profile.industries}</span>
                                </div>
                            </div>
                        </p>
                        {/* DESKTOP */}
                        <p className='espaciado-entre-palabras visualidad-desktop2 mb-0'>
                            <i className="fas fa-users"></i> <span className="me-2">Employees:</span><b><span className="me-5">{profile.employees}</span></b>  <i className="fas fa-calendar-alt"></i> <span>Founded in: <b>{profile.founding_year}</b></span>
                        </p>
                        <p className='primer-parrafo visualidad-desktop2'>
                            <i className="fas fa-industry"></i> <span>{profile.industries}</span>
                        </p>



                    </Col>

                    {/* {notificationCount?.type === '2' && */}
                    <Col sm={12} md={4} className='contenedor-botones my-3'>
                        {/* <Button type="button" variant="outline-primary" disabled={!canSaveCompany} onClick={handleSaveCompany}>{profile.save_button_caption}</Button> */}
                        <Button type="button" variant="outline-primary"
                            disabled={!canSaveCompany || profile.save_button_enabled === 0}
                            onClick={handleSaveCompany}>
                            {profile.save_button_caption}
                        </Button>

                        <br />
                        <Button type="button" variant="primary" disabled={!canSaveCompany || profile.send_button_enabled === 0} onClick={handleContactCompany} >{profile.send_button_caption}</Button>
                    </Col>
                    {/* } */}
                </Row>
                <div className='mt-2'>
                    <p className='segundo-parrafo'>
                        {_.unescape(profile.company_description)}
                    </p>
                </div>
            </>
        }
    </>)
});
