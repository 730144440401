import { types } from '../types/types';

export const catalogsReducer = (state = {}, action) => {

    switch (action.type) {
        case types.setCatalogsInvestor:
            return {
                ...state,
                investor: { ...state.investor, ...action.payload }
            }
        default:
            return state;
    }

}