import { TeamMembers } from "../../../@core/interfaces/investor.model";
import imgUser from '../../../assets/images/user.jpg';
import { Row, Col, Container } from 'react-bootstrap'

interface Props {
    members: TeamMembers[];
}

export const InvestorPublicProfileLeadership = ({ members }: Props) => {
    return (
        <>
            <div className="investor-public-leadership">
                <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>LEADERSHIP TEAM</div> 
                    <div className="line"></div>

                </p>
                <div className="contenedor-cards-leadership" >
                    {
                        members.map((m) => (
                            <Col lg={4}>
                                <div className="card-leadership-member" key={m.investor_team_member_id}>
                                    <div className="contenedor-imagen my-3">
                                        <img src={m.photo ? m.photo : imgUser} alt={m.member_name} />
                                    </div>
                                    <span> <b>{m.member_name}</b> </span>
                                    <span><b>{m.investor_position}</b></span>
                                    <span>Expertise: <b>{m.expertise_highlights1_selection.map( (exp,i,exps) => exp.label+(exps[i+1] ? ', ' : '')  ) }</b></span>
                                    <span>Previus Exists: <b>{m.exits_number}</b></span>
                                    <a target='_blank' href={m.investor_member_linkedin}>
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </div>
                            </Col>
                        ))
                    }
                </div>
            </div>
        </>
    )
};