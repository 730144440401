import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../../@core/interfaces/store';
import imgUser from '../../../assets/images/Logo-placeholder.jpg';
import { InvestorPublicProfileInfo } from "./InvestorPublicProfileInfoAdminView";
import { InvestorPublicProfileLeadership } from "./InvestorPublicProfileLeadership";
import { InvestorPublicProfilePortfolio } from "./InvestorPublicProfilePortfolio";
import { getCompanies, getInvestorAbout, getTeammembers } from '../../../redux/actions/investorAction';
import imgBanner from '../../../assets/images/background_main.jpg';
const banner = (require as any).context('../../../assets/images', true)

export const InvestorPublicProfileAdminView = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const  {companies, about, teammembers} = useSelector((state: StoreApp) => state.investor)
    //const  {company_investor_id} = useSelector((state: StoreApp) => state.notifications.notificationCount)

    const [idInvestor, setIdInvestor] = useState<string | null>(null)


    useEffect(() => {
      getParams();
    }, [])

    const getParams = () => {
        setIdInvestor(searchParams.get('idInvestor') || null);
    }

    useEffect(() => {
        getParams();
        const id = idInvestor;
        if (!id) return;
        dispatch(getInvestorAbout(id));
        dispatch(getTeammembers(id));
        dispatch(getCompanies(id));
    }, [idInvestor, dispatch, idInvestor])


    return (
        <>
            <div className="_InvestorPublicProfile">
                <div className="imgMainCover" style={{ backgroundImage: 'url(' + (about.investor_banner ? about.investor_banner : imgBanner) + ')' }}>
                    <img src={ about.investor_logo ? about.investor_logo : imgUser} alt="logo" className="imgRedondaMain" />
                </div>
                <InvestorPublicProfileInfo about={about} />
                <InvestorPublicProfileLeadership members={teammembers}/>
                <InvestorPublicProfilePortfolio companies={companies}/>
            </div>

        </>
    )
}
