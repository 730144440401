import React from 'react';
// import imgLoader from '../assets/images/FundLyst-icon.svg';
import imgLoader from '../assets/images/FundLyus-paper.svg';



export const Loader = () => {
    return (
        <div className="_loader">
        {/* <div className="loader"></div> */}

            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>

    )
}
