import { TypeUser } from "../../@core/interfaces/common.model";
import {
  AboutUs,
  AboutUsRequest,
  FilledSections,
  Financials,
  FinancialsValues,
  Funding,
  FundingRequest,
  Leadership,
  LeadershipRequest,
  ValueProposition,
} from "../../@core/interfaces/company.model";
import { CatValueLabel } from "../../@core/interfaces/investor.model";
import { StoreApp } from "../../@core/interfaces/store";
import {
  _createAboutUs,
  _getFilledSectionsCompany,
  _submitCompany,
  _updateAboutUs,
  _getUrlToUploadFile,
  _uploadFileFundingCompany,
  _getConfirUpload
} from "../../@core/services/company.service";
import { _getNotificationCount } from "../../@core/services/notifications.service";
import { customAlert, customErrorAlert } from "../../helpers/alerts";
import { fetchCatalogs } from "../../helpers/fetch";
import { companyTypes } from "../types/companyTypes";
import { setIdInvestor } from "./investorAction";
import { setIdCompany, setLoading } from "./uiAction";

export const getAboutUs = () => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));

    const { catIndustries } = getState().cats.investor;
    const email = getState().ui.email;
    try {
      const res = await fetchCatalogs(`prod/company/aboutus?sUserName=${email}`);
      let aboutUs = await res.json();
      aboutUs = aboutUs[0];
      if (!aboutUs.industries) {
        aboutUs.industries = [];
      } else {
        let industries = aboutUs.industries
          .split(",")
          .map((x: string) => Number(x));
        industries = industries.map((ind: any) => {
          const values = catIndustries.find((c) => c.value == ind);
          return {
            ...values,
          };
        });
        aboutUs.industries = industries;
      }
      dispatch(getAboutAction(aboutUs));
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

// export const saveAboutUs = (about: AboutUs) => {
//   return async (dispatch: any, getState: () => StoreApp) => {
//     dispatch(setLoading(true));
//     const email = getState().ui.email;

//     try {
//       let aboutRequest: AboutUsRequest = {
//         sUserName: email,
//         sCompanyName: about.company_name,
//         sWebsite: about.company_website,
//         sDescription: about.company_description,
//         sIndustries: about.industries.map((p) => p.value).join(),
//         sLinkedin: about.sLinkedin,
//         // "sIndustries": about.industries.split(",").map((x) => Number(x)),
//         sbracketid: String(about.employees_bracket_id),
//         ifoundingyear: String(about.founding_year),
//         iState: String(2), // ?? pending
//       };

//       if (about?.company_logo.includes('base64')) {
//         aboutRequest.companyLogo = about.company_logo;
//       }
//       if (about?.company_banner.includes('base64')) {
//         aboutRequest.companyBanner = about.company_banner;
//       }

//       const fetch = await fetchCatalogs(
//         "prod/company/aboutus",
//         aboutRequest,
//         "POST"
//       );
//       const resp = await fetch.json();
//       if (resp?.errorMessage) {
//         customErrorAlert(resp.errorMessage);
//       } else {
//         customAlert("About Us", "information created correctly", "success");
//         dispatch(updateFilledSection(1));
//         dispatch(setIdCompany(resp.msg));
//         dispatch(saveAboutAction(about, about.industries));
//       }

//       dispatch(setLoading(false));
//     } catch (error: any) {
//       customErrorAlert(error, "Alert");
//       dispatch(setLoading(false));
//     }
//   };
// };

export const updateAboutUs = (about: AboutUs) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    const email = getState().ui.email;
    const { filledSections } = getState().company;

    try {
      let resp;
      console.log('update')
      // if (filledSections.filled_sections <= 0) {
      //   resp = await _createAboutUs(email, about);
      //   const respCount = await _getNotificationCount(email);
      //   const notifCount = respCount[0];
      //   console.log(notifCount);
      //   localStorage.setItem("idCompany", notifCount.company_investor_id);
      //   localStorage.setItem("type", notifCount.type);

      //   if (Number(notifCount.type) === TypeUser.COMPANY) {
      //     dispatch(setIdCompany(notifCount.company_investor_id))
      //   }
      //   if (Number(notifCount.type) === TypeUser.INVESTOR) {
      //     dispatch(setIdInvestor(notifCount.company_investor_id))
      //   }
      // } else {
        resp = await _updateAboutUs(email, about);
      //}

      if (resp?.result === "ok") {
        customAlert(
          "About Us",
          `information ${
            filledSections.filled_sections <= 0 ? "created" : "updated"
          } correctly`,
          "success"
        );

        dispatch(saveAboutAction(about, about.industries));
        if (filledSections.filled_sections <= 0)
          dispatch(updateFilledSection(1));
        // dispatch(getFilledSectionsCompany());
      } else {
        customErrorAlert(resp?.errorMessage ?? "An unexpected error occurred");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const updateCompanyLogo = (img: string) => ({
  type: companyTypes.updateCompanyLogo,
  payload: img
});

export const updateCompanyBanner = (img: string) => ({
  type: companyTypes.updateCompanyBanner,
  payload: img
});

export const getAboutAction = (about: AboutUs) => ({
  type: companyTypes.getAboutUs,
  payload: {
    about,
  },
});

export const saveAboutAction = (
  about: AboutUs,
  industries: CatValueLabel[]
) => ({
  type: companyTypes.setAboutUs,
  payload: {
    about,
    industries,
  },
});

// ** Value proposition ** //

export const getValueProposition = (idCompanyParam = 0, showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));
    try {
      //const email = getState().ui.email;
      //const res = await fetchCatalogs(`prod/company/valueproposition?sUserName=${email}`); 
      const { idCompany } = getState().company;
      const id = idCompanyParam ? idCompanyParam : idCompany;
      // console.log('idCompany ->',id);
      const res = await fetchCatalogs(`prod/company/valueproposition?company_id=${id}`);
      let response = await res.json();
      dispatch(setValuePropositionAction(response[0]));
      //dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      //dispatch(setLoading(false));
    }

    if(showLoading){
      dispatch(setLoading(false));
    }
  };
};

export const saveValueProposition = (vp: ValueProposition) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      const email = getState().ui.email;
      const { filled_sections } = getState().company.filledSections;

      const request = {
        sUserName: email,
        sProblemSolve: vp.customer_problem,
        sProductosServicios: vp.products_services,
        sHelp: vp.sHelp,
        sOpportunity: vp.target_market,
        sBModel: vp.business_model,
        sSalesMark: vp.sales_marketing_strategy,
        sCompetitors: vp.competitors,
        sCompetitiveAdv: vp.competitive_advantage,
      };

      const res = await fetchCatalogs(
        `prod/company/valueproposition`,
        request,
        "POST"
      );
      let resp = await res.json();

      if (resp?.errorMessage) {
        customErrorAlert(resp.errorMessage);
      } else {
        customAlert(
          "Value Proposition",
          "Information updated correctly",
          "success"
        );
        dispatch(setValuePropositionAction(vp));
        if (filled_sections < 3) dispatch(updateFilledSection(3));
      }

      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const setValuePropositionAction = (vp: ValueProposition) => ({
  type: companyTypes.setValueProposition,
  payload: {
    ...vp,
  },
});

// ** Leadership ** //

export const getLeadership = (idCompanyParam = 0, showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));
    try {
      //console.log('getState().notifications',getState());
      const { company_investor_id } = getState().notifications.notificationCount;
      const { idCompany } = getState().company;
      //console.log('company_investor_id',company_investor_id,'idCompanyParam',idCompanyParam);
      const id = idCompanyParam ? idCompanyParam : (company_investor_id || idCompany);
      // console.log('ID',id);
      const res = await fetchCatalogs("prod/company/teammembers?company_id=" + id);
      const membersResponse = await res.json();
      const catHighJson = await fetchCatalogs("prod?catname=cat_highlights1");
      const catHigh = await catHighJson.json();

      const catHighlights = catHigh.map((ind: any) => ({
        value: ind["expertise_highlight1_id"],
        label: ind["expertise_highlight1"],
      }));

      const membersConverted = membersResponse.reduce((acc: any[], m: any) => {
        let catHigh = [];
        if (m.sExpertice) {
          const high1 = m.sExpertice.split(",").map((x: string) => Number(x));
          catHigh = high1.map((h1: any) => {
            const valuesCat = catHighlights.find(
              (c: CatValueLabel) => c.value === h1
            );
            return {
              ...valuesCat,
            };
          });
        }
        acc.push({
          ...m,
          sExpertice: catHigh,
        });
        return acc;
      }, []);
      if (membersConverted.length > 0)
        dispatch(getLeadershipAction(membersConverted));
      //dispatch(setLoading(false));
    } catch (error) {
      //dispatch(setLoading(false));
      console.log(error);
    }
    if(showLoading){
      dispatch(setLoading(false));
    }
  };
};

export const saveLeadership = (leader: Leadership, resetForm: any) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    const email = getState().ui.email;
    const { filled_sections } = getState().company.filledSections;
    const sExpertice = leader.sExpertice
      .map((p: CatValueLabel) => p.value)
      .join();

    const request: LeadershipRequest = {
      sUserName: email,
      sMemberName: leader.sMemberName,
      iPosition: leader.iPosition,
      iGender: leader.iGender,
      iEthniciti: leader.iEthniciti,
      sExpertice,
      sExits: leader.sExits,
      sLinkedin: leader.sLinkedin,
    };
    //console.log('request',request,'leader',leader);

    if (leader.photo.includes("base64")) {
      request.photo = leader.photo;
      request.typephoto = "webp";
    }

    try {
      const fetch = await fetchCatalogs(
        "prod/company/teammembers",
        request,
        "POST"
      );
      const res = await fetch.json();
      if (res.result === "ok") {
        customAlert("Leadership", "Leadership added successfully", "success");
        dispatch(setLeadershipAction(leader.iIdTeamMember, leader, res.msg));
        if (filled_sections < 4) dispatch(updateFilledSection(4));
        resetForm({ values: { ...leader, iIdTeamMember: res.msg } });
      } else {
        customErrorAlert(res.errorMessage, "Alert");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      customErrorAlert(error, "Alert");
    }
  };
};

export const updateLeadership = (leader: Leadership, resetForm: any) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    const email = getState().ui.email;
    const sExpertice = leader.sExpertice.map((p) => p.value).join();
    const request: LeadershipRequest = {
      sUserName: email,
      iIdTeamMember: String(leader.iIdTeamMember),
      sMemberName: leader.sMemberName,
      iPosition: String(leader.iPosition),
      iGender: String(leader.iGender),
      iEthniciti: leader.iEthniciti,
      sExpertice,
      sExits: String(leader.sExits),
      sLinkedin: leader.sLinkedin,
    };

    if (leader.photo.includes("base64")) {
      request.photo = leader.photo;
      request.typephoto = "webp";
    }

    try {
      const fetch = await fetchCatalogs(
        "prod/company/teammembers",
        request,
        "PUT"
      );
      const res = await fetch.json();
      if (res.result === "ok") {
        customAlert("Leadership", "Leadership updated successfully", "success");
        // dispatch(getFilledSectionsCompany());
        dispatch(
          setLeadershipAction(
            leader.iIdTeamMember,
            leader,
            leader.iIdTeamMember
          )
        );
        resetForm({ values: { ...leader } });
      } else {
        customErrorAlert(res.errorMessage, "Alert");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      customErrorAlert(error, "Alert");
    }
  };
};

export const deleteLeadership = (id: number) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));

    try {
      const email = getState().ui.email;
      const fetch = await fetchCatalogs(
        "prod/company/teammembers",
        { sUserName: email, iIdTeamMember: String(id) },
        "DELETE"
      );
      const resp = await fetch.json();
      if (resp.result === "ok") {
        customAlert("Leadership", "Leadership deleted successfully", "success");
        dispatch(deleteLeadershipAction(id));
      } else {
        customErrorAlert(resp.errorMessage, "Alert");
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      customErrorAlert(error, "Alert");
    }
  };
};

const getLeadershipAction = (leadership: Leadership) => ({
  type: companyTypes.getLeadership,
  payload: { leadership },
});

const setLeadershipAction = (
  idCurrent: number,
  leadership: Leadership,
  idNew: number
) => ({
  type: companyTypes.setLeadership,
  payload: {
    idCurrent,
    leadership,
    idNew,
  },
});

export const addLeadership = (leadership: Leadership) => ({
  type: companyTypes.addLeadership,
  payload: {
    leadership,
  },
});

export const updatePhotoLeadership = (id: number, photo: string) => ({
  type: companyTypes.updatePhotoLeadership,
  payload: {
    id,
    photo,
  },
});

export const deleteLeadershipAction = (id: number) => ({
  type: companyTypes.deleteLeadership,
  payload: { id },
});

export const getFinancials = (showDecimals?: boolean, idCompanyParam = 0, showLoading = true) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));

    try {
      const { idCompany } = getState().company;
      const id = idCompanyParam ? idCompanyParam : idCompany;
      //console.log('getFinancials idcompaby:',id); 
      const prefixURL = `prod/company/financials?company_id=${id}`;
      const URL = !showDecimals ? prefixURL : prefixURL + "&show_decimals=0";
      const res = await fetchCatalogs(URL);
      const financials = await res.json();
      const headers = Object.keys(financials[0])
        .map((k) => k)
        .slice(0, 5);

      dispatch(getFinancialsAction(financials, headers));
      //dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      //dispatch(setLoading(false));
    }

    if(showLoading){
      dispatch(setLoading(false));
    }
  };
};

export const saveFinancials = (f: any) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));

    const { headers, values } = getState().company.financials;
    const { idCompany, filledSections } = getState().company;

    const keys = [
      "gross_",
      "direct_",
      "operating_",
      "profit_",
      "profitMargin_",
    ];
    const request = {
      [headers[0]]: [...keys.map((k) => f[k + "1"].replace(/[^0-9.-]+/g, ""))],
      [headers[1]]: [...keys.map((k) => f[k + "2"].replace(/[^0-9.-]+/g, ""))],
      [headers[2]]: [...keys.map((k) => f[k + "3"].replace(/[^0-9.-]+/g, ""))],
      [headers[3]]: [...keys.map((k) => f[k + "4"].replace(/[^0-9.-]+/g, ""))],
      [headers[4]]: [...keys.map((k) => f[k + "5"].replace(/[^0-9.-]+/g, ""))],
      company_id: String(idCompany),
      annual: f.annual,
      bootstrapped: f.bootstrapped.toString().replace(/[^0-9.-]+/g, ""),
      capital: f.capital.toString().replace(/[^0-9.-]+/g, ""),
    };

    const updatedRedux = [...values];
    // console.log(updatedRedux);
    updatedRedux[0][headers[0]] = f["gross_1"].replace(/[^0-9.-]+/g, "");
    updatedRedux[0][headers[1]] = f["gross_2"].replace(/[^0-9.-]+/g, "");
    updatedRedux[0][headers[2]] = f["gross_3"].replace(/[^0-9.-]+/g, "");
    updatedRedux[0][headers[3]] = f["gross_4"].replace(/[^0-9.-]+/g, "");
    updatedRedux[0][headers[4]] = f["gross_5"].replace(/[^0-9.-]+/g, "");

    updatedRedux[1][headers[0]] = f["direct_1"].replace(/[^0-9.-]+/g, "");
    updatedRedux[1][headers[1]] = f["direct_2"].replace(/[^0-9.-]+/g, "");
    updatedRedux[1][headers[2]] = f["direct_3"].replace(/[^0-9.-]+/g, "");
    updatedRedux[1][headers[3]] = f["direct_4"].replace(/[^0-9.-]+/g, "");
    updatedRedux[1][headers[4]] = f["direct_5"].replace(/[^0-9.-]+/g, "");

    updatedRedux[2][headers[0]] = f["operating_1"].replace(/[^0-9.-]+/g, "");
    updatedRedux[2][headers[1]] = f["operating_2"].replace(/[^0-9.-]+/g, "");
    updatedRedux[2][headers[2]] = f["operating_3"].replace(/[^0-9.-]+/g, "");
    updatedRedux[2][headers[3]] = f["operating_4"].replace(/[^0-9.-]+/g, "");
    updatedRedux[2][headers[4]] = f["operating_5"].replace(/[^0-9.-]+/g, "");

    updatedRedux[3][headers[0]] = f["profit_1"];
    updatedRedux[3][headers[1]] = f["profit_2"];
    updatedRedux[3][headers[2]] = f["profit_3"];
    updatedRedux[3][headers[3]] = f["profit_4"];
    updatedRedux[3][headers[4]] = f["profit_5"];

    updatedRedux[4][headers[0]] = f["profitMargin_1"].replace(/[^0-9.-]+/g, "");
    updatedRedux[4][headers[1]] = f["profitMargin_2"].replace(/[^0-9.-]+/g, "");
    updatedRedux[4][headers[2]] = f["profitMargin_3"].replace(/[^0-9.-]+/g, "");
    updatedRedux[4][headers[3]] = f["profitMargin_4"].replace(/[^0-9.-]+/g, "");
    updatedRedux[4][headers[4]] = f["profitMargin_5"].replace(/[^0-9.-]+/g, "");

    let isCreate = false;

    if (updatedRedux[5]?.annual) {
      updatedRedux[5].annual = f.annual;
      updatedRedux[5].bootstrapped = request.bootstrapped;
      updatedRedux[5].capital = request.capital;
    } else {
      isCreate = true;
      updatedRedux[5].annual = f.annual;
      updatedRedux[5].bootstrapped = f.bootstrapped;
      updatedRedux[5].capital = f.capital;
      // @ts-ignore
      // updatedRedux.push({
      //   indicator: "General_Data",
      //   annual: f.annual,
      //   bootstrapped: f.bootstrapped,
      //   capital: f.capital,
      // });
    }

    try {
      const res = await fetchCatalogs(
        `prod/company/financials?company_id=${idCompany}`,
        request,
        isCreate ? "POST" : "PUT"
      );
      const resp = await res.json();

      if (resp?.errorMessage) {
        customErrorAlert(resp.errorMessage);
      }
      if (resp.result === "ok") {
        customAlert("Financials", "information updated correctly", "success");
        dispatch(setFinancialsAction(idCompany, updatedRedux, headers));
        if (filledSections.filled_sections < 2)
          dispatch(updateFilledSection(2));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

const getFinancialsAction = (financials: Financials, headers: string[]) => ({
  type: companyTypes.getFinancials,
  payload: { financials, headers },
});

const setFinancialsAction = (
  id: number,
  financials: FinancialsValues[],
  headers: string[]
) => ({
  type: companyTypes.setFinancials,
  payload: { id, financials, headers },
});

// ** Funding *** //

export const getFunding = () => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      const { email } = getState().ui;
      const res = await fetchCatalogs(
        "prod/company/funding?sUserName=" + email
      );
      const funding: Funding = (await res.json())[0];

      dispatch(getFundingAction(funding));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };
};

export const updateFunding = (fund: Funding, resetForm: any) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      const { filledSections } = getState().company;
      const { email } = getState().ui;
      const { presentation_deck } = getState().company.funding;
      const req: FundingRequest = {
        sUserName: email,
        sFundingPurpose: String(fund.funding_purpose),
        sFundingStage: String(fund.funding_stage_id),
        sProjectedRaise: String(fund.projected_raise),
        sUseHelp: fund.assistances,
      };

      // if (presentation_deck.includes("base64")) {
      //   req.deckfile = presentation_deck;
      //   req.typefile = "pdf";
      // }

      const res = await fetchCatalogs("prod/company/funding", req, "PUT");
      const resp = await res.json();
      if (resp.result === "ok") {
        customAlert("Funding", "Funding updated successfully", "success");
        dispatch(getFundingAction(fund));
        // dispatch(updateFilledSection(4));
        if (filledSections.filled_sections < 5) dispatch(updateFilledSection(5));
        resetForm({ values: { ...fund } });
      } else {
        customErrorAlert(resp.errorMessage, "Alert");
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };
};

const getFundingAction = (funding: Funding) => ({
  type: companyTypes.getFunding,
  payload: { funding },
});

export const updateFileAction = (file: string, funding: Funding) => ({
  type: companyTypes.updateFileFunding,
  payload: { file, funding },
});

export const requestUrlToUpload =(file: any,  extencion: string) => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true)); 
    try {
      const { company_investor_id } = getState().notifications.notificationCount;
      const resp = await _getUrlToUploadFile(company_investor_id, extencion.toLocaleLowerCase());

      if (resp.result === "ok") {
        const urlToUpload = resp.msg[0].url;
        const filename = resp.msg[0].FileName;
        
        const uploadFile = await _uploadFileFundingCompany(file, urlToUpload);
        console.log('uploadFileFundingCompany',uploadFile);
        
        if(uploadFile){

          const resp2 = await _getConfirUpload(company_investor_id, filename);
          console.log('getConfirUpload',resp2);
        }

      }

    } catch (error) {
      console.log(error);
    }
    dispatch(setLoading(false)); 
  }
}

export const getFilledSectionsCompany = () => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true)); 
    try {
      const { email } = getState().ui;
      const sections = await _getFilledSectionsCompany(email);
      if ((await sections).length > 0){
        // para probar el submit
        // sections[0].filled_sections=5;
        dispatch(getFilledSectionsCompanyAction(sections[0]));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };
};

export const getFilledSectionsCompanyAction = (
  filledSection: FilledSections
) => ({
  type: companyTypes.getFilledSections,
  payload: filledSection,
});

export const updateFilledSection = (id: number) => ({
  type: companyTypes.updateFilledSection,
  payload: id,
});

export const submitCompany = () => {
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(true));
    try {
      const { email } = getState().ui;
      const submitted = await _submitCompany(email);
      if (submitted.result === "ok"){
        dispatch(submitCompanyAction());
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const submitCompanyAction = () => ({
  type: companyTypes.submitCompany,
});

export const setValidFormAction = (component: any, isValid: any) => ({
  type: companyTypes.setValidForm,
  payload: {
    component,
    isValid,
  },
});
