import { ChatState } from "../../@core/interfaces/chat.model";
import { chatTypes } from "../types/chatTypes";

const initialState: ChatState = {
  chat: false,
  countMsg: 0,
  countChat: 0,
  chatStatusConnection: false,
  chatResponse: [],
  activeChat: 0,
};

export const chatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // case chatTypes.getChat:
    //   return {
    //     ...state,
    //     chat: true,
    //     chatResponse: [...action.payload.chat],
    //   };

    case chatTypes.setChat:
        //console.log('action.payload.chat',action.payload.chat)
      return {
        ...state,
        chat: true,
        //chatResponse: [...action.payload.chat],
        chatResponse: action.payload.chat.map( (e: any) => {
            return {
                investor_id: e.investor_id || 0,
                investor_logo: e.investor_logo || "",
                investor_fund_name: e.investor_fund_name || "",
                company_id: e.company_id || 0,
                company_logo: e.company_logo || "",
                company_name: e.company_name || "",
                show_chat: e.show_chat,
            };
        }),
      };

    case chatTypes.setChatConnection:
      return {
        ...state,
        chatStatusConnection: action.payload,
      };

    case chatTypes.setActiveChat:
      return {
        ...state,
        activeChat: action.payload,
      };

    case chatTypes.setCountMsg:
      return {
        ...state,
        countMsg: action.payload,
      };

    case chatTypes.setCountChats:
      return {
        ...state,
        countChat: action.payload,
      };

    case chatTypes.resetChat:
      return {
        ...state,
        chat: false,
        chatResponse: [],
      };

    // case chatTypes.setChat:
    //   return {
    //     ...state,
    //     chatResponse: { ...action.payload.chat },
    //   };
    default:
      return state;
  }
};
