import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col } from "react-bootstrap"
import { ProgressTracker } from "../../../@core/interfaces/matchActivity.model";
import { notFitProgressTracker, thisFitProgressTracker } from "../../../redux/actions/matchActivityAction";
import { StoreApp } from "../../../@core/interfaces/store";
import { ModalRejection } from "./modalRejection";
import { ModalConfirmThisFit } from "./ModalConfirmThisFit";

interface Props {
    name: string;
    id: number;
    prog: ProgressTracker;
    catRejection: any[];
    nameCompanyInvestor: string;
}

export const ProgressTrackerRow = React.memo(({ name, id, prog, catRejection, nameCompanyInvestor }: Props) => {

    const dispatch = useDispatch();
    const [idRejection, setidRejection] = useState<number | string>('');

    const colors: { [key: number]: string } = {
        0: '#31a91a',
        1: '#31a91a',
        2: '#fe0202',
        3: '#f77a00',
    }


    const handleIdRejection = (e: any) => {
        const value = e.target.value;
        setidRejection(value);
    }

    const handleThisFit = () => {
        console.log("fase: " + prog.phase_id)
        dispatch(thisFitProgressTracker(String(prog.company_investor_request_id), String(prog.phase_id)))
    }

    const handleActionModal = () => {
        let a = document.createElement('a');
        a.href = '#close';
        a.click();
        dispatch(notFitProgressTracker(String(prog.company_investor_request_id), String(prog.phase_id), String(idRejection)))
    }

    const handleActionModalThisFit = () => {
        let a = document.createElement('a');
        a.href = '#close';
        a.click();
        console.log("fase: " + prog.phase_id)
        handleThisFit();
    }

    //console.log('ProgressTrackerRow.tsx',prog);
    //Cambio de circulo-disabled a txt0containers-disabled y en css tambien
    return (
        <>
            <Col sm={12} className="d-flex align-items-center justify-content-between px-2 mb-2 contenedor-info-card">
                <div className={`d-flex txt-containers${prog.phase_enabled === 0 ? ' disabled' : '' } `}>
                    <div className="circulo">{id}</div>
                    <span>{name}</span>
                </div>

                <div className="state-containers">
                    <span style={{ color: colors[prog.color] }} >{prog?.message} {prog.rejection_reason && ('- ' + prog.rejection_reason)}</span>
                </div>

                {
                    (prog.show_not_a_fit === 1 || prog.show_this_is_a_fit === 1) && (
                        <div className="btn-containers">
                            {
                                prog.show_not_a_fit === 1 &&
                                <a href={`#openModalProgressTracker${prog.company_investor_request_id}${prog.phase_id}`} className="boton-notFit">
                                    <button type="button" className="btn btn-outline-primary estilos-boton modificador1 modificador2 mb-2">
                                        Not a fit
                                    </button>
                                </a>
                            }
                            {
                                prog.show_this_is_a_fit === 1 &&
                                <a href={`#openModalThisFit${prog.company_investor_request_id}${prog.phase_id}`}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary estilos-boton modificador2 mb-2"
                                    >
                                        This is a fit
                                    </button>
                                </a>
                            }
                        </div>
                    )
                }

            </Col>

            <div id={`openModalProgressTracker${prog.company_investor_request_id}${prog.phase_id}`} className="modalDialog">
                <ModalRejection
                    catRejection={catRejection}
                    handleActionModal={handleActionModal}
                    handleIdRejection={handleIdRejection}
                    idRejection={idRejection}
                    // textTitle={`Tell us why <b>${prog.investor_fund_name}</b> was Not a Fit`}
                    textTitle={`Tell us why <b>${nameCompanyInvestor}</b> was Not a Fit`}
                />
            </div>

            <div id={`openModalThisFit${prog.company_investor_request_id}${prog.phase_id}`} className="modalDialog">
                <ModalConfirmThisFit
                    handleActionModal={handleActionModalThisFit}
                />
            </div>
        </>
    )
})