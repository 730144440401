import { fetchCatalogs } from "../../helpers/fetch";
import { FeaturedInvestors, Kpis, MatchActivityHome, SavedInvestors, TypeActionInvestor } from "../interfaces/home.models";
import { TypeUser, Result } from '../interfaces/common.model';

export const _getKpis = async (idCompany: string, userType: string): Promise<Kpis[]> => {
  const url = Number(userType ) === TypeUser.INVESTOR ?"prod/investor/actions/kpi?investor_id=":"prod/company/actions/kpis?company_id="; 
  const res = await fetchCatalogs(url + idCompany);
  return await res.json();
};

export const _getFeaturedInvestors = async (idCompany: string, userType: string): Promise<FeaturedInvestors[]> => {
  const url = Number(userType ) === TypeUser.COMPANY 
    ? "prod/company/actions/suggested_investors?company_id=" 
    : "prod/investor/actions/suggested_company?investor_id="; 
  const res = await fetchCatalogs(url + idCompany);
  return await res.json();
};

// TODO: idCompany hardcoded 
export const _getSavedInvestors = async (idCompany: string, userType: string, page: string = '1', maxPage: string = '8'): Promise<SavedInvestors[]> => {
  const url = Number(userType ) === TypeUser.COMPANY 
  ? "prod/company/actions/saved_investors?company_id="
  : "prod/investor/actions/savedcompanies?investor_id=" ; 
  


// prod/investor/actions/savedcompanies?investor_id=20&regxpag=8&pagenum=1
  const res = await fetchCatalogs(url + idCompany +`&regxpag=${maxPage}&pagenum=${page}`);
  // const res = await fetchCatalogs(url + idCompany +`&regxpag=8&pagenum=1`);

  return await res.json();
};

export const _actionSaveCompany = async (
  user_id: string,
  company_id: string,
  type: string
): Promise<Result> => {
  const req = {
    company_id: company_id,
    investor_id: user_id,
    tipo: type,
  };
  const res = await fetchCatalogs(`prod/investor/actions/savedcompanies`, req, "POST");
  return await res.json();
};

export const _actionSavedInvestors = async (company_id: string, investor_id: string, tipo: TypeActionInvestor): Promise<{result: string, msg: string}> => {
  const res = await fetchCatalogs(`prod/company/actions/saved_investors`, {tipo, company_id, investor_id}, 'POST');
  return await res.json();
};
 // MATCH ACTIVITY
export const _getMatchActivityHome = async (company_id: string, userType: string): Promise<MatchActivityHome[]> => {
  const url = Number(userType ) === TypeUser.COMPANY 
  ? "prod/company/home/progress_tracker?company_id=" 
  : "prod/investor/home/progress_tracker?investor_id="; 
  const res = await fetchCatalogs(url + company_id);
  return await res.json();
};
