import { TypeUser } from "../../@core/interfaces/common.model";
import { _getRefresh } from "../../@core/services/refresh.service";
import { StoreApp } from "../../@core/interfaces/store";
import { getFeaturedInvestors, getKpis, getSavedInvestors, getMatchActivityHome } from '../../redux/actions/homeAction';
import { getSavedCompanies } from '../../redux/actions/investorAction';
import { getInvestorAbout } from '../../redux/actions/investorAction';
import { getProfileView } from '../../redux/actions/profileViewAction';
import { getMatchActivity } from '../../redux/actions/matchActivityAction';


export const refreshPathActivity = (path: string, id_profile: number, userType: number ) => {
    return async (dispatch: any, getState: () => StoreApp) => {
      try {
        //console.log('path',path)
        let page = ''

        if(path.startsWith('/home')){
            page = 'Home'
        }

        if(path.startsWith('/public-profile-company') || path.startsWith('/public-profile-investor')){
            page = 'Profile'
        }
                    //lado company                  lado investor
        if(path.startsWith('/saved-investors') || path.startsWith('/investor-saved')){
            page = 'Saved'
        }

        if(path.startsWith('/match-activity')){
            page = 'Match Activity'
        }

        if(page === ''){
            console.log('Refresh no para', path)
            return
        }

        const { company, investor, notifications, refresh } = getState();

        const ncount = notifications.notificationCount;

        const id_company  = Number(company.idCompany);
        const id_investor = Number(investor.investorId);
        const comp_inv_id = Number(ncount.company_investor_id);

        const resp = await _getRefresh(String(id_investor),String(id_company),page,ncount.type,'get_refresh');
        //console.log('resp',resp)

        if(resp.length>0){
            
            if(page === 'Home'){
                executeHomeActions(resp, dispatch)
            }

            if(page === 'Profile'){
                if(id_profile === 0 || userType === 0){
                    console.log('Sin id perfil')
                    return
                }
                executeProfileActions(resp, id_profile, userType, dispatch)
            }

            if(page === 'Saved'){
                const page = String(refresh.saved.page);
                executeSavedActions(resp, String(id_investor), page, Number(ncount.type), dispatch)
            }

            if(page === 'Match Activity'){
                executeMatchAction(resp, String(comp_inv_id), String(ncount.type), dispatch)
            }

            //Descomentar para mandar el confirm
            const resp2 = await _getRefresh(String(id_investor),String(id_company),page,ncount.type,'conf_refresh');
        }
        
      } catch (error) {
        console.log('error',error)
      }
    }
}

export const executeHomeActions = (resp: any, dispatch: any) => {
    resp.map( (action: any) => {
        const type = action.item.trim()
        if(type === 'kpis'){
            dispatch(getKpis(false))
        }

        if(type === 'match activity'){
            dispatch(getMatchActivityHome(false))
        }

        if(type === 'featured'){
            dispatch(getFeaturedInvestors(false))
        }

        if(type === 'saved'){
            dispatch(getSavedInvestors('1','3',false))
        }
    })
}

export const executeProfileActions = (resp: any, id_profile: number, userType: number, dispatch: any) => {
    //console.log('id', id_profile, 'type', userType)
    resp.map( (action: any) => {
        const type = action.item.trim()
        if(type === 'buttons'){
            if(userType === TypeUser.INVESTOR){
                dispatch(getInvestorAbout(id_profile,false))
            }

            if(userType === TypeUser.COMPANY){
                dispatch(getProfileView(id_profile,false))
            }
        }
    })
}

export const executeSavedActions = (resp: any, id_user: string, page: string, userType: number, dispatch: any) => {

    resp.map( (action: any) => {
        const type = action.item.trim()
        if(type === 'buttons'){
            if(userType === TypeUser.COMPANY){
                dispatch(getSavedInvestors(page, '8', false))
            }

            if(userType === TypeUser.INVESTOR){
                dispatch(getSavedCompanies(id_user, page, false))
            }
        }
    })
}

export const executeMatchAction = (resp: any, company_investor_id: string, userType: string, dispatch: any) => {

    //console.log(company_investor_id, userType)
    dispatch(getMatchActivity(company_investor_id, userType, 'ALL', false));
    // resp.map( (action: any) => {
    //     console.log(action)
    //     const type = action.item.trim()
    //     if(type === 'buttons'){
            
    //         dispatch(getMatchActivity(company_investor_id, userType));
            
    //     }
    // })
}