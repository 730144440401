import React, { useEffect, useState } from 'react';
import './ConfirmCode.css';
import logo from '../../../assets/images/logo-color.svg';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from 'react-bootstrap';


export const ConfirmCode = (props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [state, setState] = useState({
        code: '',
        isNew: false,
        email: null,
    })

    const { email, code } = state;


    useEffect(() => {
        getParams();
    }, []);

    const getParams = () => {
        const getEmail = searchParams.get('email') || null;
        setState({
            ...state,
            email: getEmail
        })
    }

    const resendSignUn = async () => {
        try {
            await Auth.resendSignUp(state.email)
            Swal.fire('Alert', 'forwarded email', 'success');
        } catch (error) {
            Swal.fire('Failed to resendSignUp', error.message, 'error');
        }
    }

    const confirmSignUp = async () => {
        try {
            await Auth.confirmSignUp(email, code)
            Swal.fire('Alert', 'Email confirmed', 'success').then(() => {
                // TODO: iniciar sesion y guardar token
                navigate('/')
            })
        } catch (error) {
            //console.log('error',error)
            let msg_error = error.message;
            if(msg_error.includes('ember must satisfy regular expression pattern') || msg_error.includes('nvalid verification code provided')){
                msg_error = 'The verification code does not accomplish with the criteria of: six digit number, please verify or contact FundLyst support';
            }
            
            Swal.fire('Failed to confirm sign up', msg_error, 'error');
        }
    }

    const handleCode = (e) => {
        const val = e.target.value.replace(/\D+/g, '');;
        if(isNaN(val)) return false;

        setState({
            ...state,
            code: val
        })
    }

    return (
        <>
            <div className="container main-container">
                <img src={logo} alt="Fundlyst" />
                <div className="wrapper mt-3">
                    <h2 className="mb-5 text-center">Enter the code you received in your email to confirm your username:</h2>
                    <div className="form-group">
                        <input type="text" autoComplete="off" className="form-control" id="code" value={code} placeholder="Type code" onChange={handleCode} />
                        <p className="resend" onClick={resendSignUn}>Resend code</p>
                    </div>

                    <div className="btn-wrapper w-100 mt-5 text-center">
                        <Button type="button" variant="primary" className='estilos-boton'  onClick={confirmSignUp}>Accept</Button>
                    </div>
                </div>
            </div>

        </>
    )
}
