import { TypeUser } from "../../@core/interfaces/common.model";
import { _getNotificationCount, _getUserInfo } from "../../@core/services/notifications.service";
import { customErrorAlert } from "../../helpers/alerts";
import { companyTypes } from "../types/companyTypes";
import { types } from "../types/types";
import { setIdInvestor } from "./investorAction";

export const setLoading = (payload) => ({
  type: types.setLoading,
  payload,
});

export const ladedCats = () => ({
  type: types.loadedCats,
});

export const showNotification = (payload) => ({
  type: types.updateNotifications,
  payload,
});

export const login = (email, navigate) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      localStorage.setItem("email", email);
      dispatch(setEmailAction(email));
      const respCount = await _getNotificationCount(email);
      const notifCount = respCount[0];
    
      localStorage.setItem("idCompany", notifCount.company_investor_id);
      localStorage.setItem("type", notifCount.type);

      if (Number(notifCount.type) === TypeUser.COMPANY) {
        dispatch(setIdCompany(notifCount.company_investor_id))
      }
      if (Number(notifCount.type) === TypeUser.INVESTOR) {
        dispatch(setIdInvestor(notifCount.company_investor_id))
      }

      //Por el momento siempre mandar al home
      const lastPath = localStorage.getItem("lastPath") || "/home";
      let topath = lastPath;
      if(!lastPath.startsWith('/view-profile')){
        topath = "/home";
      }

      //const lastPath = "/home";
      navigate(topath);
      dispatch(setLoading(false));
    } catch (error) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const setImgInfoAction = (imgb64) => ({
  type: types.setImgProfile,
  payload: imgb64 ,
});
export const getUserInfo = (company_investor_id, type) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
        // TODO: Obtener datos del perfil loggeado
        const respUserInfo = await _getUserInfo(company_investor_id, type);
        const userInfo = respUserInfo[0];
        dispatch(setUserInfoAction(userInfo));

      dispatch(setLoading(false));
    } catch (error) {
      customErrorAlert(error, "Alert");
      dispatch(setLoading(false));
    }
  };
};

export const setEmailAction = (email) => ({
  type: types.setEmail,
  payload: email,
});

export const setIdCompany = (id) => ({
  type: companyTypes.setIdCompany,
  payload: id,
});

export const openSidebarAction = (open) => ({
  type: types.openSidebar,
  payload: open,
});

export const setUserInfoAction = (userInfo) => ({
  type: types.setUserInfo,
  payload: { userInfo },
});
