import { useEffect, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import { MyTextInput } from "../../../@custom/components/form/MyTextInput";
import { MySelect } from "../../../@custom/components/form/MySelect";
import { MultiSelect } from "../../../@custom/components/form/Multiselect/MultiSelect";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/actions/uiAction";
import { toast } from 'react-toastify';

import {
  updateInvestor,
  saveInvestor,
} from "../../../redux/actions/investorAction";
import { StoreApp } from "../../../@core/interfaces/store";
import { CatValueLabel } from "../../../@core/interfaces/investor.model";
import { fetchCatalogs } from "../../../helpers/fetch";

interface GralInfoRequest {
  sInvestorName: string;
  iTipeInvestor: string;
  sFundName: string;
  iIdCountry: string;
  IdState: string;
  IdCity: string;
  sPreferedIndustries: CatValueLabel[];
  iIdRangeOfInvestment: string;
  iIdPreviousExits: string;
  website:string;
  investor_linkedin:string;
  sInvestorPhilosophy: string;
  investor_logo: string;
  investor_banner: string;
}

// type FormikRef = FormikProps<GralInfoRequest>;

export const Gralinfo = ({reloadAbouUs, handleValidateForm}: any) => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<GralInfoRequest>>(null);
  

  const { investor: investorCat } = useSelector((state: StoreApp) => state.cats);
  const { gralInfo, investorId, filledSections } = useSelector((state: StoreApp) => state.investor);
  const { loading, email } = useSelector((state: StoreApp) => state.ui);
  const [cities, setCities] = useState<string[]>([]);

  //console.log('investorId',investorId);
  //console.log('gralInfo-------',gralInfo); 
  // console.log('filledSections',filledSections); 

//   const options = {
//     onOpen: props => console.log(props.foo),
//     onClose: props => console.log(props.foo),
//     autoClose: 6000,
//     type: toast.TYPE.INFO,
//     hideProgressBar: false,
//     position: toast.POSITION.TOP_LEFT,
//     pauseOnHover: true,
//     progress: 0.2
//     // and so on ...
// };

const getCatCity = async (id: number) => {
  //console.log(id);
  const citiesFetch = await fetchCatalogs(
    "prod/gralinfo/cities?state_id=" + id
  );
  const citiesResponse = await citiesFetch.json();
  //console.log(citiesResponse); 
  setCities(Array.isArray(citiesResponse) ? citiesResponse : []);
};

  useEffect(() => {
    
    if (_.isEmpty(gralInfo) || !reloadAbouUs) return;

    //console.log('gralInfo',gralInfo.state_id)
    // console.log('gralInfo',gralInfo)

    if (gralInfo?.state_id) getCatCity(gralInfo.state_id) 

    const data: GralInfoRequest = {
      sInvestorName: gralInfo.investor_name,
      iTipeInvestor: gralInfo.investor_type_id? String(gralInfo.investor_type_id) : "",
      sFundName: gralInfo.investor_fund_name,
      iIdCountry: gralInfo.country_id? String(gralInfo.country_id) : "",
      IdState: gralInfo.state_id? String(gralInfo.state_id) : "",
      IdCity: gralInfo.city_id? String(gralInfo.city_id) : "",
      sPreferedIndustries: gralInfo.industries || [],
      iIdRangeOfInvestment:  gralInfo.investment_bracket_id? String(gralInfo.investment_bracket_id) : "",
      iIdPreviousExits: gralInfo.exits? String(gralInfo.exits) : "",
      website:gralInfo.investor_website,
      investor_linkedin:gralInfo.linkedin,
      sInvestorPhilosophy: _.unescape(gralInfo.investment_philosophy) || "",
      investor_logo:gralInfo.investor_logo || "",
      investor_banner: gralInfo.banner || "",
    }

    formikRef.current?.resetForm({values: {...data}});
    handleBlurForm();

  }, [gralInfo, reloadAbouUs]);

  const handleSubmit = () => {
    // console.log(formikRef.current);
    formikRef.current?.submitForm();
  };

  const handleBlurForm = () => {
    const validForm = formikRef.current?.isValid;
    handleValidateForm('about',validForm);
  };

  return (
    <div className={filledSections.filled_sections === 5? "card _investorGralInfo disable-mode" : "card _investorGralInfo"}>
      <Formik
        // @ts-ignore:next-line
        validate={(e) => { handleBlurForm() }}
        innerRef={formikRef}
        initialValues={{
          sInvestorName: "",
          iTipeInvestor: "",
          sFundName: "",
          iIdCountry: "1",
          IdState: "",
          IdCity: "",
          sPreferedIndustries: [],
          iIdRangeOfInvestment: "",
          iIdPreviousExits: "",
          website:'',
          investor_linkedin:"",
          sInvestorPhilosophy: "",
          investor_logo: "",
          investor_banner: "",
        }}
        onSubmit={async (values) => {            
          const prefered = (values.sPreferedIndustries.map((p) => p.value)).join();
          const req = {
            ...values,
            sUserName: email,
            sPreferedIndustries: prefered,
            investorId: investorId,
          };

          // console.log('investorId',investorId);
          // console.log('req',req);

          if (investorId>0) {
            dispatch(updateInvestor(req, values.sPreferedIndustries));
          } else {
            dispatch(saveInvestor(req, values.sPreferedIndustries));
          }

        }}
        validationSchema={Yup.object({
          sInvestorName: Yup.string().required("Required"),
          iTipeInvestor: Yup.string().required("Required"),
          sFundName: Yup.string().required("Required"),
          // iIdCountry: Yup.string().required("Required"),
          IdState: Yup.string().required("Required"),
          IdCity: Yup.string().required("Required"),
          // sPreferedIndustries: Yup.array().min(1, "Required").required("Required"),
          iIdRangeOfInvestment: Yup.string().required("Required"),
          // iIdPreviousExits: Yup.string().required("Required"),
          sInvestorPhilosophy: Yup.string().required("Required"),
        })}
      >
        {(formik) => (
          <Form>
            <Row className="col-12 mx-0">
              <Col lg={6} className="my-2">
                <MyTextInput
                  label="Investor Name:"
                  name="sInvestorName"
                  placeholder="Put your name here"
                  errors={formik.errors}
                />
              </Col>

              <Col lg={6} className="my-2">
                <MyTextInput
                  label="Fund Name:"
                  name="sFundName"
                  placeholder="Put your fund name here"
                  errors={formik.errors}
                />
              </Col>

              <Col lg={6} className="my-2">
                <MySelect
                  label="Type of Investor:"
                  name="iTipeInvestor"
                  errors={formik.errors}
                >
                  <option value="">Select...</option>
                  {investorCat?.catInvestorType.map((inv) => (
                    <option
                      value={inv.investor_type_id}
                      key={inv.investor_type_id}
                    >
                      {inv.investor_type_name}
                    </option>
                  ))}
                </MySelect>
              </Col>

              <Col lg={6} className="my-2">
                <MySelect
                  label="Range of Investment:"
                  name="iIdRangeOfInvestment"
                  infoIcon={true}
                  textTooltip="Amount of money you typically invest in a round"
                  errors={formik.errors}
                >
                  <option value="">Select...</option>
                  {investorCat?.catRange.map((c) => (
                    <option
                      value={c.investment_bracket_id}
                      key={c.investment_bracket_id}
                    >
                      {c.investment_bracket_description}
                    </option>
                  ))}
                </MySelect>
              </Col>

              {/* <Col lg={6} className="my-2">
                <MySelect
                  label="Country:"
                  name="iIdCountry"
                  errors={formik.errors}
                >
                  <option value="">Select...</option>
                  {investorCat?.catCountries.map((c) => (
                    <option value={c.country_id} key={c.country_id}>
                      {c.country_name}
                    </option>
                  ))}
                </MySelect>
              </Col> */}

              <Col lg={6} className="my-2">
                <MySelect 
                  label="State:" 
                  name="IdState" 
                  errors={formik.errors}
                  onChange={(name: string, value: number) => {
                    formik.setFieldValue(name, value);
                    formik.setFieldValue('IdCity', '');
                    getCatCity(value);
                  }}
                  >

                  <option value="">Select...</option>
                  {investorCat?.catStates.map((c) => (
                    <option value={c.state_id} key={c.state_id}>
                      {c.state_name}
                    </option>
                  ))}
                </MySelect>
              </Col>

              <Col md={12} className="my-2 col-lg">
                  <MySelect
                    label="City:"
                    name="IdCity"
                    errors={formik.errors}
                    onChange={(name: string, value: number) => {
                      formik.setFieldValue(name, value);
                    }}
                    // disabled={filledSections.filled_sections === 6}
                  >
                    <option value="">Select...</option>
                    {cities.map((c: any) => (
                      <option value={c.city_id} key={c.city_id}>
                        {c.city_name}
                      </option>
                    ))}
                  </MySelect>
                </Col>

              {/* <Col lg={4} className="my-2">
                <MultiSelect
                  label="Preferred Industries:"
                  name="sPreferedIndustries"
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.sPreferedIndustries}
                  touched={formik.touched.sPreferedIndustries}
                  value={formik.values.sPreferedIndustries}
                  options={investorCat?.catIndustries}
                  textTooltip="Select all industries you invest in"

                ></MultiSelect>
              </Col> */}
              
              {/* <Col lg={4} className="my-2">
                <MySelect
                  label="Previous Exits:"
                  name="iIdPreviousExits"
                  errors={formik.errors}
                >
                  <option value="">Select...</option>
                  {investorCat?.catPreviousExits.map((c) => (
                    <option value={c.exit_id} key={c.exit_id}>
                      {c.exits_number}
                    </option>
                  ))}
                </MySelect>
              </Col> */}

              <Col md={6} className="my-2">
                  <MyTextInput
                      label="Website:"
                      name="website"
                      placeholder=""
                      errors={formik.errors}
                  />
              </Col>

              <Col md={6} className="my-2">
                  <MyTextInput
                      label="Linkedln Profile:"
                      name="investor_linkedin"
                      placeholder="Put your linkedln profile here"
                      linkedIcon={true}
                      errors={formik.errors}
                  />
              </Col>


              <Col lg={12} className="my-2">
                <MyTextInput
                  label="Investor Philosophy:"
                  name="sInvestorPhilosophy"
                  placeholder="Put your philosophy here"
                  errors={formik.errors}
                  textArea={true}
                  rows={6}
                  infoIcon={true}
                  textTooltip="What are the values you follow and look for in a company, what type of involvement do you typically want to have with a company, communication style, etc"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Row>
        <Col lg={12} className="row mx-0 mt-3">
          <div className="d-block w-100 alineacionBtn">
            {/* <span className="textoRequerido">Please note all fields with * are required.{" "}</span> */}
            <Button
              type="submit"
              disabled={filledSections.filled_sections === 5}
              variant="primary"
              onClick={handleSubmit}
              className="btn-save"
            >
              Save
            </Button>

            {/* <Button onClick={() => toast.success("Hello")}>Toast</Button> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
