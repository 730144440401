import React, { useRef, useEffect } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../../@custom/components/form/MyTextInput";
import { Col, Row, Button, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getValueProposition,
  saveValueProposition,
} from "../../../redux/actions/companyAction";
import _ from "lodash";

export const ValueProposition = ({ handleValidateForm }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { valueProposition, filledSections } = useSelector(
    (state) => state.company
  );
  const { loadedCats } = useSelector((state) => state.ui);

  useEffect(() => {
    if (
      loadedCats &&
      filledSections?.filled_sections >= 2 &&
      _.isEmpty(valueProposition)
    )
      dispatch(getValueProposition());
  }, [dispatch, filledSections, loadedCats, valueProposition]);

  useEffect(() => {
    if (_.isEmpty(valueProposition)) return;
    formikRef.current.setValues((v) => ({
      ...v,
      customer_problem: _.unescape(valueProposition.customer_problem) ?? "",
      products_services: _.unescape(valueProposition.products_services) ?? "",
      sHelp: _.unescape(valueProposition.sHelp) ?? "",
      target_market: _.unescape(valueProposition.target_market) ?? "",
      business_model: _.unescape(valueProposition.business_model) ?? "",
      sales_marketing_strategy: _.unescape(valueProposition.sales_marketing_strategy) ?? "",
      competitors: _.unescape(valueProposition.competitors) ?? "",
      competitive_advantage: _.unescape(valueProposition.competitive_advantage) ?? "",
    }));
  }, [valueProposition]);

  const handleSubmit = () => {
    formikRef.current.submitForm();
  };

  const handleBlurFormValidate = () => {
    const validForm = formikRef.current.isValid;
    handleValidateForm('valueProposition', validForm);
  };

  return (
    <div className="_value-preposition">
      <Formik
        validate={() => {
          handleBlurFormValidate();
        }}
        innerRef={formikRef}
        initialValues={{
          customer_problem: "",
          products_services: "",
          sHelp: "",
          target_market: "",
          business_model: "",
          sales_marketing_strategy: "",
          competitors: "",
          competitive_advantage: "",
        }}
        validationSchema={Yup.object({
          customer_problem: Yup.string().required("Required"),
          products_services: Yup.string().required("Required"),
          sHelp: Yup.string().required("Required"),
          target_market: Yup.string().required("Required"),
          business_model: Yup.string().required("Required"),
          sales_marketing_strategy: Yup.string().required("Required"),
          competitors: Yup.string().required("Required"),
          competitive_advantage: Yup.string().required("Required"),
        })}
        onSubmit={async (values) => {
          console.log(values);
          dispatch(saveValueProposition(values));
        }}
      >
        {(formik) => (
          <Form>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>The Problem We Solve:</Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="customer_problem"
                    errors={formik.errors}
                    disableLabel={true}
                    textArea={true}
                    disabled={filledSections.filled_sections === 6}
                    placeholder={
                      "Describe the problem your company solves and why it matters."
                    }
                    // infoIcon={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Products &#38; Services:</Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="products_services"
                    errors={formik.errors}
                    disableLabel={true}
                    disabled={filledSections.filled_sections === 6}
                    textArea={true}
                    placeholder={
                      "What products and services do you offer to your clients."
                    }
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Who We Help:</Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="sHelp"
                    errors={formik.errors}
                    disableLabel={true}
                    textArea={true}
                    disabled={filledSections.filled_sections === 6}
                    placeholder={
                      // "Describe the market segments where your company is focous in,  for example  lifestyle, age,  gender."
                      "Describe the market segments where your company is focus in, for example lifestyle, age, gender."
                    }
                    // infoIcon={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Market Opportunity:</Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="target_market"
                    placeholder={"What markets your company is pointing to."}
                    errors={formik.errors}
                    disableLabel={true}
                    textArea={true}
                    disabled={filledSections.filled_sections === 6}
                    // infoIcon={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Business Model:</Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="business_model"
                    errors={formik.errors}
                    disableLabel={true}
                    textArea={true}
                    disabled={filledSections.filled_sections === 6}
                    placeholder={
                      "What is your company business plan for making profit, example, target market or any anticipated expenses."
                    }
                    // infoIcon={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Sales &#38; Marketing Strategy:
                </Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="sales_marketing_strategy"
                    placeholder={
                      "What's the company's sales and marketing strategy."
                    }
                    errors={formik.errors}
                    disableLabel={true}
                    textArea={true}
                    disabled={filledSections.filled_sections === 6}
                    // infoIcon={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Competitors:</Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="competitors"
                    placeholder={
                      "Which are the companies you are compiting with."
                    }
                    errors={formik.errors}
                    disableLabel={true}
                    textArea={true}
                    disabled={filledSections.filled_sections === 6}
                    // infoIcon={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Competitive Advantage:</Accordion.Header>
                <Accordion.Body>
                  <MyTextInput
                    name="competitive_advantage"
                    placeholder={
                      "Which are your company strengths comparing with your competitors."
                    }
                    errors={formik.errors}
                    disableLabel={true}
                    textArea={true}
                    disabled={filledSections.filled_sections === 6}
                    // infoIcon={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Form>
        )}
      </Formik>

      <Row>
        <Col lg={12} className="row mx-0 mt-3">
          <div className="d-block w-100 alineacionBtn">
            {/* <span className="textoRequerido">Please note all fields with * are required. </span> */}
            <Button
              type="submit"
              variant="primary"
              className="btn-save"
              disabled={filledSections.filled_sections === 6}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
