import { RefreshState, Saved } from "../../@core/interfaces/refresh.models";
import { refreshTypes } from "../types/refreshTypes";

const initialState: RefreshState = {
  saved: {} as Saved,
};


export const refreshReducer = (state = initialState, action: any) => {
  switch (action.type) {
    
    case refreshTypes.setSavePageActive:
      return {
        ...state,
        saved: {...action.payload.saved },
      };

    default:
      return state;
  }
};
