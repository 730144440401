import { useState } from 'react';

export const useForm = (initialForm, validateForm) => {

    const [form, setForm] = useState(initialForm);
    const [errors, setErrors] = useState({});
    const [loading] = useState(false);
    const [response] = useState(null);

    const handleChange = (e, isChecked) => {
        const { name, value, checked } = e.target;
        setForm({
            ...form,
            [name]: isChecked ? checked : value
        })
        if (isChecked) setErrors(validateForm(form))
    }
    const handleBlur = (e) => {
        handleChange(e);
        setErrors(validateForm(form));
    }
    const handleSubmit = (isSubmit = false) => {
        setErrors(validateForm(form, isSubmit));
    }

    return {
        form,
        errors,
        loading,
        response,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrors
    }
}
