import { Accordion } from "react-bootstrap";
import { ValueProposition } from "../../../@core/interfaces/company.model";


export const SearchCompanyValueProposition = () => {
    return (<>
        {
            <>
                <div>
                    <p className='contenedor-titulo'>
                        <span className='titulo-seccion'>VALUE PROPOSITION</span> <hr />
                    </p>

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>The Problem We Solve</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Products &#38; Services</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Who We Help</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Market Opportunity</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Business Model</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Sales &#38; Marketing Strategy</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Competitors</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Competitive Advantage</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                </div>
            </>
        }
    </>)
};
