import { StoreApp } from "../../@core/interfaces/store";
import { customErrorAlert } from "../../helpers/alerts";
import { setLoading } from "./uiAction";
import { toast } from "react-toastify";
import { ProfileViewProfile } from "../../@core/interfaces/profileView.model";
import { profileViewTypes } from "../types/profileViewTypes";
import { getProfileViewService } from "../../@core/services/profileView.service";

export const getProfileView = (idCompany: any, showLoading = true) => {
  // console.log('(',idCompany,') --');
  return async (dispatch: any, getState: () => StoreApp) => {
    dispatch(setLoading(showLoading));
    try {
      const { company_investor_id } = getState().notifications.notificationCount;
      // const profile = await getProfileViewService(String(company_investor_id));
      const profile = await getProfileViewService(String(idCompany),String(company_investor_id));
      if (profile.length > 0) {
        dispatch(getProfileAction(profile[0]));
      } else {
        if(showLoading){
          toast.info("No results found");
        }
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      if(showLoading){
        customErrorAlert(error, "Alert");
      }
      dispatch(setLoading(false));
    }
  };
};

const getProfileAction = (profile: ProfileViewProfile) => ({
  type: profileViewTypes.getProfile,
  payload: { profile },
});
