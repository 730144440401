import { useCallback, useState, useRef } from 'react';
import logo from '../../assets/images/logo-color.svg';
import imgMainProfile from '../../assets/images/Logo-placeholder.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { StoreApp } from '../../@core/interfaces/store';
import { Dropdown} from 'react-bootstrap';
import { SidebarCustom } from './SidebarCustom';
import { useNavigate } from "react-router-dom";

interface Props {
    onDisconnect: () => void;
  }


export const NavbarAdmin = (props: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const isMobile = useMedia("(max-width: 1100px)");
    const { notification, userInfo } = useSelector((state: StoreApp) => state.ui)
    const { notificationCount } = useSelector((state: StoreApp) => state.notifications)
    const [menuBurguer, setMenuBurguer] = useState(false);



    const closeSession = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("idCompanyInvestor");
        localStorage.removeItem("type");

    }

    const HanddleMenuBurguer = useCallback( () => {
        document.body.classList.toggle('__body-overflow-hidden', !menuBurguer);
        setMenuBurguer(!menuBurguer)
    },[menuBurguer])

    return (
        <>
            <nav className="navbar navbar-light bg-light px-5 border-bottom estilos-navbar">
                <div className="container-navbar">

                    <div className="__nav-list">
                        <nav className="burguer-menu">
                            <label htmlFor="menu" onClick={HanddleMenuBurguer}>
                                <i className="fas fa-bars text-gris" style={{ fontSize: '25px', cursor: 'pointer' }} ></i>
                            </label>
                        </nav>

                        <a>
                            <div className="logo">
                                <img src={logo} alt="fundlyst" />
                            </div>
                        </a>

                        <div className="feature-profile">
                            
                            <Dropdown className='dropdown-user'>
                                <Dropdown.Toggle variant="light">
                                    <div className="image-profile mx-2"  style={{ backgroundImage: 'url(' + (userInfo.logo ? userInfo.logo : imgMainProfile) + ')' }}>
                                        <div className="profile"></div>
                                    </div>
                                    <div className="desc-profile mx-2">
                                        <p>{userInfo.username}</p>
                                        <p>{userInfo.name}</p>
                                    </div>
                                    <div className="px-2">
                                        <i className='fa fa-angle-down'></i>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end" className='custom-dropdown-menu-user'>
                                    <Dropdown.Item onClick={closeSession} href="/login"><i className="fas fa-arrow-right-from-bracket"></i><span style={{'color':'#3816b0'}}>Log Out</span></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </nav>
            {
                <div className={menuBurguer ? 'contenedor-menu' : 'd-none' }>
                    <SidebarCustom handdleMenuBurguer={HanddleMenuBurguer}></SidebarCustom>
                </div>
            }
        </>
    )
}
