import { HomeState, Kpis } from "../../@core/interfaces/home.models";
import { homeTypes } from "../types/homeTypes";

const initialState: HomeState = {
  kpis: {} as Kpis,
  featuredInvestors: [],
  savedInvestors: [],
  matchActivity: [],
};

export const homeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case homeTypes.getKpis:
      return {
        ...state,
        kpis: { ...action.payload.kpis },
      };
    case homeTypes.getFeaturedInvestors:
      return {
        ...state,
        featuredInvestors: [...action.payload.investors],
      };
    case homeTypes.getSavedInvestors:
      return {
        ...state,
        savedInvestors: [...action.payload.investors],
      };
    case homeTypes.sendRequestInvestor:
      //Se agrego 2 parametros send_request_button_caption,send_request_button_enabled
      return {
        ...state,
        savedInvestors: state.savedInvestors.map((s) =>
          s.saved_investor_id === action.payload
            ? {
                ...s,
                request_status: "1",
                send_request_button_caption: "Request Sent",
                send_request_button_enabled: 1
              }
            : s
        ),
      };
    case homeTypes.getMatchActivityHome:
      return {
        ...state,
        matchActivity: [...action.payload.matchActivity],
      };
    case homeTypes.deleteSavedInvestor:
        return {
          ...state,
          savedInvestors: state.savedInvestors.filter((s) => s.saved_investor_id !== action.payload),
        };

    default:
      return state;
  }
};
