import { Companies } from '../../../@core/interfaces/investor.model';
// import { StoreApp } from '../../../@core/interfaces/store';
import { CardsInvestorPublicProfilePortfolio } from './CardsInvestorPublicProfilePortfolio';

interface Props {
    companies: Companies[]
}


export const InvestorPublicProfilePortfolio = ({ companies }: Props) => {

    return (
        <>

            <div className="investor-public-portfolio">
                <p className='contenedor-titulo d-flex flex-nowrap align-items-center'>
                    <div className='titulo-seccion'>PORTFOLIO</div>
                    <div className="line"></div>

                </p>
                
                {
                    companies.map((comp, i) => (
                        <CardsInvestorPublicProfilePortfolio company={comp} key={i}/>
                    ))
                }
            </div>



        </>

    )
}