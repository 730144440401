import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
// import './InputPassword.css';
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InputPassword = ({ pass, passChange, textLabel, blur, errors, focus }) => {

    const [state, setState] = useState({
        hidePassword: true,
    });

    const { hidePassword } = state;

    const handleHidePassword = () => {
        setState({
            ...state,
            hidePassword: !hidePassword
        })
    }

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>{textLabel}</Form.Label>
                <div className="content-input">
                    <Form.Control autoComplete="off"
                        type={hidePassword ? 'password' : 'text'}
                        // className={`form-control ${errors?.pass && 'is-invalid'}`}
                        value={pass}
                        onChange={passChange}
                        onBlur={blur}
                        onFocus={focus}
                        name="password"
                        placeholder="****" />
                    <div className="icon-font" onClick={handleHidePassword}>
                        <FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye} size="lg" />
                    </div>

                </div>
            </Form.Group>

            {/* {<div className="form-group">
                {<label htmlFor="password">{textLabel}</label>}
                <div className="content-input">
                    <input
                        autoComplete="off"
                        type={hidePassword ? 'password' : 'text'}
                        className={`form-control ${errors?.pass && 'is-invalid'}`}
                        value={pass}
                        onChange={passChange}
                        onBlur={blur}
                        name="password"
                        placeholder="****"
                    />

                </div>
                {(errors?.pass) &&
                    <pre className="text-error">{errors?.pass}</pre>
                }
            </div>} */}
        </>
    )
}
