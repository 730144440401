import { ErrorMessage, useField } from 'formik';
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  infoIcon1?: boolean;
  textTooltip?: string;

  [x: string]: any;
}


export const MySelect = ({ label, infoIcon1, onChange, textTooltip, ...props }: Props) => {

  const [field] = useField(props);

  const handleChange = (event: any) => {
    if (onChange) onChange(props.name, event.target.value);
  }

  return (
    <>
      <Form.Group>
        {
          infoIcon1 &&
          (
            <OverlayTrigger
              key={'top'}
              placement={'top'}
              overlay={textTooltip ?
                <Tooltip id={`tooltip-${label}`}>
                  {textTooltip}
                </Tooltip> : <></>
              }
            >
              <i className="fas fa-info-circle me-2" style={{ color: "#ccc" }}></i>
            </OverlayTrigger>
          )
          // <i className="fas fa-info-circle me-2" style={{ color: '#ccc' }}></i>


        }
        <Form.Label style={{ color: '#222', fontWeight: 300 }}>{label}</Form.Label>
        {
          onChange ?
            <Form.Select
              {...field}
              {...props}
              className={`form-control ${props?.errors?.[props.name] && 'is-invalid'}`}
              onChange={handleChange}
            ></Form.Select> :
            <Form.Select
              {...field}
              {...props}
              className={`form-control ${props?.errors?.[props.name] && 'is-invalid'}`}
            ></Form.Select>
        }
        {(props.errors?.[props.name]) &&
            <div className="invalid-feedback">
                {props.errors?.[props.name]}
            </div>
        }
        {/* <ErrorMessage name={props.name} className="invalid-feedback" component="div" /> */}
      </Form.Group>
    </>
  )
}
